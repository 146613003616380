import React from "react";

export const LysaAsIntermediarySV = () => (
  <article className="lysa-doc lysa-as-intermediary-kf">
    <header>
      <h1>Förhandsinformation om försäkringsdistribution - Lysa AB</h1>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>
        Försäkringsförmedlaren Lysa AB, org. nr 559028-0821, (<i>"Lysa"</i>)
        bedriver värdepappersrörelse och står under Finansinspektionens tillsyn.
        Lysas ambition är att ha marknadens nöjdaste kunder. Ytterligare
        information om Lysa, Lysas tjänster samt allmän företagsinformation
        finns att tillgå på Lysas hemsida,{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://lysa.se/">
          www.lysa.se
        </a>
        .
      </p>
      <p>
        Den information som enligt lag (2018:1219) om försäkringsdistribution
        samt Finansinspektionens föreskrifter (FFFS 2018:10) om
        försäkringsdistribution ska lämnas innan ett försäkringsavtal ingås
        tillhandahålls genom denna förhandsinformation.
      </p>
    </section>
    <section>
      <h2>Försäkringsdistribution</h2>
      <p>
        Lysa har Finansinspektionens tillstånd för: Livförsäkring (direkt);
        Försäkring anknuten till värdepappersfonder (direkt); Förvaltare av
        fondandelar; Portföljförvaltning avseende finansiella instrument;
        Förvara finansiella instrument för kunders räkning och ta emot medel med
        redovisningsskyldighet.
      </p>
      <p>
        Lysa är ett helägt dotterbolag till Lysa Group AB och i koncernen ingår
        också bolaget Lysa Fonder AB. Inget av bolagen inom koncernen har ett
        kvalificerat innehav i ett försäkringsbolag. Inte heller har något
        försäkringsbolag ett kvalificerat innehav i Lysa eller dess moderbolag.
      </p>
      <p>
        Lysa agerar som försäkringsförmedlare och tillhandahåller en
        kapitalförsäkringsdepå där Futur Pension Aktiebolag (Publ) (“
        <strong>Futur</strong>”) är försäkringsgivare. Kunder som önskar
        rådgivning kring försäkringsskydd bör uppsöka en annan aktör. Lysa
        distribuerar försäkringar genom:
      </p>

      <ol>
        <li>
          ett digitalt verktyg utan inslag av rådgivning i samband med
          nykundsregistrering eller nyteckning av försäkringsprodukt av
          befintlig kund. Lysa genomför försäkringsdistributionen genom att
          utföra en passandebedömning av försäkringsprodukten samt en
          lämplighetsbedömning av om de instrument i vilka medel ska placeras
          inom försäkringen är lämpliga för kunden, eller
        </li>
        <li>
          ett personligt möte med Lysas anställda försäkringsförmedlare. Lysa
          genomför då en passande- och lämplighetsbedömning samt tillhandahåller
          rådgivning genom att göra en behovsanalys tillsammans med kund vilket
          resulterar i en rekommendation från försäkringsförmedlaren.
        </li>
      </ol>

      <p>
        Lysa är inte förpliktigat att distribuera försäkringar uteslutande för
        ett eller flera försäkringsbolags räkning. I den mån rådgivning bedrivs
        är den inte grundad på en opartisk och personlig analys. För tillfället
        distribueras endast kapitalförsäkringar tillhandahållna av Futur Pension
        Försäkringsaktiebolag (publ). Lysa AB agerar för kundens räkning och
        beaktar dennes intressen vid försäkringsdistributionen.
      </p>

      <p>Ansvarig försäkringsförmedlare hos Lysa är Axel Hellners.</p>
    </section>

    <section>
      <h2>Gränsdragning mellan regelverk</h2>
      <p>
        Lysa agerar som försäkringsförmedlare vad gäller samtliga frågor som
        avser nyteckning av försäkring och de premier som inom försäkringen
        används till investeringar. Det innebär att samtliga de
        rekommendationer,relaterade till försäkring, kund erhåller från Lysa
        inklusive Lysas investeringsförslag utgör försäkringsförmedling.
      </p>
      <p>
        När Lysa tillhandahåller portföljförvaltning utanför en försäkring och
        när en ny kund blir kund hos Lysa så regleras Lysas verksamhet av
        värdepappersrättsliga regelverk, bl.a. lag (2007:528) om
        värdepappersmarknaden.
      </p>
      <p>
        När Lysa agerar som försäkringsförmedlare omfattas Lysas rådgivning av
        en ansvarsförsäkring för sådan rådgivning, se nedan.
      </p>
    </section>

    <section>
      <h2>Dokumentation av rådgivning</h2>
      <p>
        All kundkontakt i samband med nyteckning av försäkringsprodukter hos
        Lysa dokumenteras, oavsett om det rör sig om en
        passande/lämplighetsbedömning eller ett rådgivningsmöte med en av Lysas
        försäkringsförmedlare. En dokumentation upprättas under pågående
        teckningsprocess/kundmöte och görs tillgänglig för kunden antingen
        genast efter avslutat teckningsprocess via Lysas hemsida eller senast 7
        dagar efter ett fysiskt kundmöte. Dokumentationens omfattning bestäms av
        huruvida försäkringen är tecknad digitalt eller vid fysiskt möte
        tillsammans med en försäkringsförmedlare.
      </p>
      <p>
        Lysa sparar dokumentationen minst i tio år efter tecknande av
        försäkringsprodukt eller genomfört fysiskt rådgivningsmöte.
      </p>
    </section>

    <section>
      <h2>Register och tillsyn</h2>
      <p>
        Lysa är registrerat hos Bolagsverkets näringslivsregister. Dessa
        uppgifter kan kontrolleras genom:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://snr4.bolagsverket.se/snrgate/startIn.do"
        >
          https://snr4.bolagsverket.se/snrgate/startIn.do
        </a>
        .<br />
        Bolagsverket har följande kontaktuppgifter: Bolagsverket, 851 81
        Sundsvall, webbadress:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bolagsverket.se"
        >
          www.bolagsverket.se
        </a>
        , telefon: 060-18 40 00 och e-post:{" "}
        <a href="mailto:bolagsverket@bolagsverket.se">
          bolagsverket@bolagsverket.se
        </a>
        .
      </p>

      <p>
        Lysa står under Finansinspektionens tillsyn. Finansinspektionen ska på
        begäran av kund eller annan upplysa om bolagets tillstånd samt vilka av
        bolagets anställda som har rätt att förmedla försäkringar. Uppgifter hos
        Finansinspektionen kan kontrolleras genom kontakt med
        Finansinspektionen. Finansinspektionen har följande kontaktuppgifter:
        Finansinspektionen, Box 6750, 113 85 Stockholm, webbadress{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.fi.se">
          www.fi.se
        </a>
        , telefon: 08-787 80 00 och e-post:{" "}
        <a href="mailto:finansinspektionen@fi.se">finansinspektionen@fi.se</a>.
      </p>

      <p>
        Lysa står även under Konsumentverkets tillsyn vad avser marknadsföring.
        Konsumentverket har följande kontaktuppgifter: Konsumentverket, Box 48,
        651 02 Karlstad, telefon: 0771-42 33 00, e-post:{" "}
        <a href="mailto:konsumentverket@konsumentverket.se">
          konsumentverket@konsumentverket.se
        </a>
        , webbadress:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.konsumentverket.se"
        >
          www.konsumentverket.se
        </a>
        .
      </p>

      <p>
        Lysa är anslutet till Insuresec och Lysas anställda
        försäkringsförmedlare är registrerade i Insuresecs register. Såväl Lysa
        som anställda försäkringsförmedlare följer därmed Insuresecs regelverk
        vilket bl.a. anger att försäkringsförmedlare ska uppträda hederligt,
        rättvist och professionellt samt med kundens bästa för ögonen. Såsom
        kund kan du anmäla Bolagets eller dess anställdas eventuella
        överträdelser av regelverket till Insuresec: telefon: 08-410 415 75,
        e-post: info@insuresec.se, webbadress:{" "}
        <a href="mailto:info@insuresec.se">info@insuresec.se</a>, webbadress:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.insuresec.se"
        >
          www.insuresec.se
        </a>
        .
      </p>
    </section>

    <section>
      <h2>Kostnader och avgifter</h2>
      <p>
        Lysa tar inte ut några avgifter för den kapitalförsäkring som förmedlas
        utöver den avgift som försäkringsgivaren tar ut. Försäkringsgivarens
        avgifter redovisas i Ansökan Kapitalförsäkring Depåförsäkring samt i
        kunds inloggat läge på Lysas hemsida. Lysa mottar inte någon
        tredjepartsersättning för försäkringsförmedling.
      </p>

      <p>
        Den enda avgiften som Lysa tar ut, utöver ordinarie avgift för fonderna
        (inklusive fondförvaltningsavgift till Lysa Fonder AB), är den
        portföljförvaltningsavgift som kunden betalar för portföljförvaltningen.
        Avgiften till Lysa är helt separat från kapitalförsäkringen. Avgiftens
        storlek beror på storleken av kundens förvaltade kapital och framgår av
        rådgivningsdokumentationen som lämnats i samband med
        försäkringsförmedlingen. Kund kan även vid vart tillfälle se sina
        kostnader i inloggat läge på Lysas hemsida.
      </p>
    </section>

    <section>
      <h2>Ansvarsförsäkring</h2>
      <p>
        Lysa har tecknat ansvarsförsäkring för ren förmögenhetsskada som kan
        orsakas kund eller någon som härleder sin rätt från kund i samband med
        försäkringsförmedling. Ansvarsförsäkring innehas hos följande
        försäkringsbolag (”Ansvarsförsäkringsgivaren”):
        <br />
        If Skadeförsäkring AB (publ), organisationsnummer 516401-8102,
        postadress 106 80 Stockholm, telefonnummer 0771 43 00 00 och webbadress:{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.if.se">
          www.if.se
        </a>
        . Meddelande kan skickas via följande länk:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.if.se/foretag/kundservice/mejla-oss"
        >
          www.if.se/foretag/kundservice/mejla-oss
        </a>
        .
      </p>

      <p>
        Den högsta ersättningen som kan betalas ut för en skada är 1 300 380
        euro. Den högsta ersättningen som kan betalas ut under ett år är 2 600
        760 euro. Försäkringen omfattar även ersättning vid fondandelsförmedling
        där den högsta ersättningen som kan betalas ut för en skada är 500 000
        euro och den högsta ersättningen som kan betalas ut under ett år är 750
        000 euro.
      </p>

      <p>
        Drabbas kund av skada eller ekonomisk förlust där kund anser att
        förmedlaren vållat skadan genom sitt agerande har kunden möjlighet att
        rikta krav direkt mot Ansvarsförsäkringsgivaren i den mån kund inte fått
        ersättning av förmedlaren. Önskar kund göra anspråk på skadestånd måste
        kund rikta sådant anspråk inom skälig tid från att kund upptäckte eller
        borde ha upptäckt att skada uppstått.
      </p>
    </section>

    <section>
      <h2>Klagomålshantering</h2>
      <p>
        Om du har ett klagomål ska du kontakta Lysas klagomålsansvarig på
        klagomal@lysa.se. Lysas klagomålsansvarig är Linn Kihlstrand Klagomål
        framställs kostnadsfritt. Klagomål hanteras skyndsamt och besvaras inom
        fjorton dagar med besked om hur Lysa bedömt ärendet och hur ärendet
        följs upp. I den händelse att Lysa inte kan besvara ärendet inom fjorton
        dagar lämnas besked om när företagets utredning förväntas bli klar. Alla
        svar ska ske skriftligt och i klartext som är lätt att förstå.
      </p>

      <p>
        Om ett slutligt beslut fattas som inte helt och hållet uppfyller den
        klagandes önskemål ska detta beslut innehålla en grundlig förklaring av
        företagets ståndpunkt och ge den klagande information om möjligheten att
        gå vidare med klagomålet. Den klagande har rätt att få sitt klagomål
        prövat av Allmänna Reklamationsnämnden (”ARN”) (näringsidkare kan dock
        inte skicka in klagomål till ARN) eller till allmän domstol, i första
        hand tingsrätt. Som konsument kan du även få råd och information från
        Konsumenternas Bank och Finansbyrå, Konsumenternas försäkringsbyrå,
        Konsumentverket (Hallå Konsument) samt den kommunala
        konsumentvägledningen.
      </p>
    </section>

    <section>
      <h2>Personuppgiftsbehandling</h2>
      <p>
        Lysa behandlar och sparar dina personuppgifter i enlighet med vid var
        tid gällande dataskyddslagstiftning. Lysa behandlar personuppgifter som
        är nödvändiga för att kunna bedriva verksamhet som
        försäkringsförmedlare, till exempel för att kunna göra en bedömning av
        om en viss lösning passar dig som kund. Lysas personuppgiftspolicy finns
        att läsa på Lysas hemsida.
      </p>
    </section>

    <section>
      <h2>Hållbarhetsinformation</h2>
      <h4>Integrering av hållbarhetsrisker i investeringsbeslutsprocessen</h4>
      <p>
        Som kund kan du välja mellan två olika investeringsinriktningar när du
        investerar med Lysa, “Bred” och “Hållbar”. Eftersom att Lysa inte
        investerar direkt i enskilda bolag handlar Lysas
        investeringsbeslutsprocess om att välja ut vilka fonder som ska
        inkluderas i portföljen för respektive investeringsinriktning. Detta
        görs genom en urvalsprocess där bland annat värdering av olika typer av
        risker, däribland hållbarhetsrisker, utgör en integrerad del. I
        praktiken innebär detta att Lysa, både inför ett val av fond och
        därefter löpande, utvärderar hur fonden och dess fondbolag arbetar med
        att integrera hållbarhetsrisker i sin investeringsbeslutsprocess.
      </p>
      <p>
        När det gäller investeringsbeslut som fattas inom Lysas Breda
        investeringsinriktning bedömer Lysa att hållbarhetsrisker inte är
        relevanta eftersom syftet är att erbjuda maximal riskspridning och om
        hållbarhetsrisker beaktas blir diversifieringen mindre.
      </p>
      <p>
        När det gäller investeringsbeslut som fattas inom Lysas Hållbara
        investeringsinriktning bedöms hållbarhetsriskerna vara relevanta för att
        uppnå syftet med investeringsinriktningen. Utvärderingen av
        hållbarhetsrisker inverkar på de investeringsbeslut som fattas genom att
        Lysa väljer ut fonder och fondbolag som bedöms integrera
        hållbarhetsrisker i sin investeringsbeslutsprocess på ett
        tillfredsställande sätt. Eftersom beaktandet av hållbarhetsrisker
        innebär att den Hållbara investeringsinriktningen blir mindre
        diversifierad bedömer Lysa att det finns risk att den riskjusterade
        avkastningen blir något lägre än om de inte hade beaktats.{" "}
      </p>
    </section>

    <section>
      <h4>Beaktande av negativa konsekvenser för hållbar utveckling</h4>
      <p>
        När du som kund väljer Lysas Breda investeringsinriktning får du en
        fondportfölj för vilken Lysa inte beaktar investeringsbesluts negativa
        konsekvenser för hållbar utveckling. Anledningen till detta är att
        syftet med fondportföljen är att erbjuda maximal riskspridning och
        skulle negativa konsekvenser för hållbar utveckling beaktas blir
        diversifieringen bli mindre.
      </p>
    </section>

    <section>
      <h4>Främjandet av miljörelaterade och sociala egenskaper</h4>
      <p>
        När du som kund väljer Lysas Hållbara investeringsinriktning får du en
        fondportfölj som främjar miljörelaterade och sociala egenskaper genom
        att underliggande investeringar på olika sätt främjar dessa egenskaper.
        I praktiken görs det genom att de fonder som ingår i Lysas Hållbara
        investeringsinriktning, matarfonderna Lysa Sverige Aktier, Lysa Global
        Aktier Hållbar och Lysa Räntor Hållbar, investerar i mottagarfonderna
        Öhman Sverige Marknad Hållbar, Öhman Global Marknad Hållbar och Öhman
        Räntefond Kompass Hållbar (“Öhmans Fonder”).{" "}
      </p>
      <p>
        Öhmans Fonder investerar endast i bolag som följer praxis för god
        bolagsstyrning och både miljörelaterade och sociala egenskaper främjas i
        förvaltningen genom att Öhmans Fonder bland annat väljer bort bolag som
        är involverade i vissa typer av produkter och tjänster (t.ex. vapen,
        droger, kommersiell spelverksamhet och fossila bränslen) samt bolag som
        kränker internationella normer som rör frågor om miljö, mänskliga
        rättigheter, arbetsvillkor och affärsetik. Vidare väljer Öhmans Fonder
        in bolag som dess fondbolag, Öhman Fonder AB, utifrån en
        hållbarhetsanalys identifierar som en långsiktigt hållbar investering.
      </p>
      <p>
        För mer information om Öhmans Fonder och Öhman Fonder AB:s
        hållbarhetsarbete, se{" "}
        <a href="https://www.ohman.se/fonder/fondlista/" rel="noopener">
          https://www.ohman.se/fonder/fondlista/
        </a>{" "}
        och{" "}
        <a href="https://www.ohman.se/hallbarhet/" rel="noopener">
          https://www.ohman.se/hallbarhet/
        </a>
        .
      </p>
    </section>

    <section>
      <h2>Lysas kontaktuppgifter </h2>
      <address>
        Lysa AB
        <br />
        Löjtnantsgatan 21
        <br />
        115 50 Stockholm
        <br />
        <a href="tel:+46852503570">08-525 035 70</a>
        <br />
        <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a>
      </address>
    </section>
  </article>
);

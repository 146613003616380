import React from "react";
import { DocEntityProps } from "../../Language";
import { PLACEHOLDER } from "../../Language";

export const BjornLundenSV = (props: DocEntityProps) => (
  <article className="lysa-doc proxy-agreement">
    <header>
      <h1>Fullmakt till Björn Lundén AB</h1>
    </header>

    <ul>
      <li>
        <strong>Datum:</strong> {props.date ?? PLACEHOLDER[props.language]}
      </li>
      <li>
        <strong>Fullmaktsgivare:</strong>{" "}
        {props.name ?? PLACEHOLDER[props.language]}
        {props.id ? `, ${props.id}` : ""}
      </li>
      <li>
        <strong>Fullmaktshavare:</strong> Björn Lundén AB, org. nr 556293-9982 (
        <strong>"BL"</strong>)
      </li>
    </ul>

    <section>
      <h3>Fullmaktens omfattning:</h3>
      <p>
        Fullmaktsgivaren ger BL fullmakt att läsa och administrera samtliga
        Fullmaktsgivarens konton hos Lysa AB, org. nr 559028-0821 (
        <strong>“Lysa”</strong>).
      </p>
    </section>

    <section>
      <h3>Fullmaktens syfte:</h3>
      <p>
        Syftet med denna fullmakt är att fullmaktsgivaren ska få information om
        sina Lysakonton utan att behöva logga in hos Lysa
      </p>
    </section>

    <section>
      <h3>Fullmaktens giltighetstid:</h3>
      <p>
        Fullmakten gäller tills vidare eller till dess att fullmakten
        återkallas.
      </p>
    </section>
  </article>
);

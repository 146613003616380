import React, { FC } from "react";
import { DocProps, DocMap } from "../../Language";
import { InformationAboutLysaSV } from "./InformationAboutLysaSV";
import { InformationAboutLysaEN } from "./InformationAboutLysaEN";
import "../../Documents.css";

const docsMap: DocMap = {
  InformationAboutLysaSV,
  InformationAboutLysaEN,
};

export const InformationAboutLysa: FC<DocProps> = ({ language }) => {
  const Doc = docsMap[`InformationAboutLysa${language}`];
  return <Doc />;
};

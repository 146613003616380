import React from "react";

export const AccountTermsSV = () => (
  <article className="lysa-doc account-terms">
    <header>
      <h1>Allmänna bestämmelser för depå/konto</h1>
      <p>v. 2020:1</p>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>
        Mellan depå-/kontoinnehavaren, nedan kallad kunden, och Lysa AB, org. nr
        559028-0821 (<b>"Lysa"</b>) har avtal träffats om depå/konto för kunden
        (<b>"depå-/kontoavtalet"</b>) av vilket dessa Allmänna bestämmelser för
        depå/konto (<b>"bestämmelserna"</b>) utgör del.
      </p>
      <p>Lysa och kunden har även ingått Avtal om portföljförvaltning.</p>
      <p>
        Syftet med depån/kontot är att tillhandahålla portföljförvaltning. Lysa
        erbjuder inte möjlighet till köp och försäljning av finansiella
        instrument.
      </p>
    </section>

    <section>
      <h2>Definitioner</h2>
      <p>I depå-/kontoavtalet, inklusive i dessa bestämmelser, förstås med:</p>
      <ol>
        <li>
          <b>värdepapper:</b> dels finansiellt instrument såsom definierat i
          lagen (2007:528) om värdepappersmarknaden, dvs. 1) överlåtbara
          värdepapper som kan bli föremål för handel på kapitalmarknaden, 2)
          penningmarknadsinstrument, 3) fondandelar och 4) finansiella
          derivatinstrument, dels värdehandling, varmed avses handling som inte
          kan bli föremål för handel på kapitalmarknaden, dvs. 1) aktie eller
          enkelt skuldebrev som enligt definitionen ovan inte är finansiellt
          instrument 2) borgensförbindelse, 3) gåvobrev, 4) pantbrev eller
          liknande handling.
        </li>
        <li>
          <b>avräkningsnota:</b> besked om att en order/ett affärsuppdrag har
          utförts.
        </li>
        <li>
          <b>reglerad marknad:</b> såsom definierat i lagen (2007:528) om
          värdepappersmarknaden, dvs. ett multilateralt system inom EES som
          sammanför eller möjliggör sammanförande av ett flertal köp- och
          säljintressen i finansiella instrument från tredje man – regelmässigt,
          inom systemet och i enlighet med icke skönsmässiga regler – så att
          detta leder till avslut.
        </li>
        <li>
          <b>handelsplats:</b> en reglerad marknad eller en handelsplattform
          (MTF).
        </li>
        <li>
          <b>utförandeplats:</b> en handelsplats, en systematisk internhandlare
          eller en marknadsgarant inom EES eller en annan person som
          tillhandahåller likviditet inom EES.
        </li>
        <li>
          <b>förvaring av värdepapper:</b> såväl förvaring av fysiska
          värdepapper som sådant förvar av dematerialiserade värdepapper som
          uppkommer genom registrering i depå/konto.
        </li>
        <li>
          <b>depåförande tredjepart:</b> värdepappersinstitut som på uppdrag av
          Lysa eller annan depåförande tredjepart förvarar värdepapper i depå
          för kunders räkning.
        </li>
        <li>
          <b>värdepappersinstitut:</b> såsom definierat i lagen (2007:528) om
          värdepappersmarknaden, dvs. värdepappersbolag, svenska kreditinstitut
          med tillstånd att driva värdepappersrörelse och utländska företag som
          driver värdepappersrörelse från filial, eller genom att använda
          anknutna ombud etablerade i Sverige, samt utländska företag med
          tillstånd att bedriva verksamhet som motsvarar värdepappersrörelse.
        </li>
        <li>
          <b>värdepapperscentral:</b> såsom definierat i lagen (1998:1479) om
          kontoföring av finansiella instrument, dvs. detsamma som i artikel
          2.1.1 i förordningen om värdepapperscentraler, i den ursprungliga
          lydelsen.
        </li>
        <li>
          <b>bankdag:</b> dag i Sverige som inte är söndag eller allmän helgdag
          eller som vid betalning av skuldebrev är likställd med allmän helgdag
          (sådana likställda dagar är f n lördag, midsommarafton, julafton samt
          nyårsafton).
        </li>
        <li>
          <b>Lysas fonder:</b> värdepappersfonder distribuerade och förvaltade
          av bolag inom Lysa-koncernen
        </li>
      </ol>
    </section>

    <section>
      <h2>A. Förteckning och förvaring i depå eller konto m.m.</h2>
      <h3>A.1 Förteckning hos Lysa</h3>
      <p>
        Lysa ska i depån/på kontot registrera sådant värdepapper som mottagits
        av Lysa för förvaring m.m. i depå/på konto. Lysa förbehåller sig rätten
        att från tid till annan bestämma vilka värdepapper som kan förtecknas
        och tas emot av Lysa på kundens depå/konto. Mottagna värdepapper
        förvaras av Lysa för kundens räkning.
      </p>
      <p>
        Finansiellt instrument i kontobaserat system hos värdepapperscentral
        eller motsvarande ska anses ha mottagits när Lysa erhållit rätt att
        registrera eller låta registrera uppgifter avseende instrument i sådant
        system. Övriga värdepapper anses ha mottagits när de överlämnats till
        Lysa.
      </p>
      <p>
        Lysa förbehåller sig rätten att pröva mottagandet av visst värdepapper.
        Om kunden har flera depåer/konton hos Lysa och kunden inte instruerat
        Lysa om i vilken depå/konto visst värdepapper ska registreras, får Lysa
        självt bestämma i vilken depå/konto registreringen ska ske.
      </p>
      <p>
        Lysa tar inte emot fysiska värdepapper. Lysa utför ingen äkthetskontroll
        av kundens värdepapper.
      </p>
      <p>
        Lysa förbehåller sig skälig tid för registrering, överföring och
        utlämnande av värdepapper.
      </p>
      <p>
        Lysa får avregistrera värdepapper från kundens depå/konto när den som
        gett ut värdepappret försatts i konkurs eller värdepappret av annat skäl
        förlorat sitt värde. Om det är praktiskt möjligt och lämpligt ska Lysa,
        bl.a. med beaktande av kundens intresse, i sådant fall försöka
        registrera värdepappret i kundens namn.
      </p>
      <h3>A.2 Depåförvaring hos tredjepart</h3>
      <p>
        Lysa får förvara kundens värdepapper hos annat värdepappersinstitut, i
        sverige eller utlandet. Depåförande tredjepart kan i sin tur anlita
        annan depåförande tredjepart för förvaring av kundens värdepapper.
      </p>
      <p>
        Depåförande tredjepart utses av Lysa efter eget val, med iakttagande av
        de skyldigheter som åligger Lysa enligt EU-förordning, lag och
        föreskrifter. Vid förvar hos depåförande tredjepart i utlandet (inom
        eller utanför EES) omfattas kundens värdepapper av tillämplig nationell
        lag, vilket kan innebära att kundens rättigheter avseende dessa
        värdepapper kan variera jämfört med vad som skulle gälla vid en
        förvaring i Sverige.
      </p>
      <p>
        Förvaring hos depåförande tredjepart sker normalt i Lysas namn för
        kunders räkning. I sådant fall får kundens värdepapper registreras
        tillsammans med andra ägares värdepapper, exempelvis på samlingskonto.
        Lysa får även uppdra åt depåförande tredjepart att i Lysas ställe låta
        sig registreras för kundens värdepapper.
      </p>
      <p>
        I särskilda fall får Lysa även låta kundens värdepapper ingå i ett för
        flera ägare gemensamt dokument.
      </p>
      <p>
        Vid förvaring av kundens värdepapper på samlingskonto hos depåförande
        tredjepart följer kundens rättigheter av tillämplig nationell
        lagstiftning.
      </p>
      <p>
        Huruvida kunden har en sakrättsligt skyddad separationsrätt i händelse
        av att Lysa eller depåförande tredjepart skulle försättas i konkurs
        eller drabbas av annan åtgärd med motsvarande rättsverkningar kan
        variera och är beroende av tillämplig nationell lag. I Sverige
        föreligger sakrättsligt skyddad separationsrätt under förutsättning att
        värdepappren hålls avskilda från depåförande tredjepartens eller Lysas
        egna värdepapper.
      </p>
      <p>
        Vid förvaring hos depåförande tredjepart i utlandet kan det också, till
        följd av tillämplig utländsk lag, vara omöjligt att identifiera kunders
        värdepapper separat från den tredje partens eller Lysas egna
        värdepapper. I sådant fall finns en risk att kundens värdepapper vid en
        konkurssituation eller annan åtgärd med motsvarande rättsverkningar
        skulle kunna komma att anses ingå i den tredje partens eller Lysas
        tillgångar.
      </p>
      <p>
        Depåförande tredjepart eller värdepapperscentral kan ha säkerhet i eller
        kvittningsrätt avseende kundens värdepapper och till dem kopplade
        fordringar.
      </p>
      <h3>A.3 Förvaltarregistrering m.m.</h3>
      <p>
        Lysa har rätt att vidta samtliga åtgärder för att i Lysas namn
        förvaltarregistrera kundens innehav i depån/på kontot samt samregistrera
        innehavet med övriga innehav i Lysas namn för respektive värdepapper.
      </p>
      <p>
        Lysa har vidare rätt att såsom förvaltare vidta samtliga åtgärder för
        att för kundens räkning i Lysas namn låta registrera mottagna
        värdepapper i eget namn hos den som i Sverige eller i utlandet fullgör
        registreringsuppgift avseende finansiella instrument, t.ex. Euroclear
        Sweden AB. Därvid får kundens finansiella instrument registreras
        tillsammans med andra ägares finansiella instrument av samma slag. Lysa
        får även låta kundens finansiella instrument ingå i ett för flera ägare
        gemensamt dokument. Lysa får uppdra åt depåförande tredjepart att i
        Lysas namn låta sig registreras för kundens finansiella instrument.
      </p>
    </section>

    <section>
      <h2>B. Lysas åtaganden avseende värdepapper</h2>
      <h3>B.1 Allmänt</h3>
      <p>
        Lysa åtar sig att för kundens räkning vidta under punkterna B.2–B.4
        angivna åtgärder beträffande mottagna värdepapper.
      </p>
      <p>
        Åtagandena inträder - om inte annat anges nedan eller särskilt
        överenskommits - för svenska finansiella instrument registrerade hos
        värdepapperscentral fr.o.m. den femte svenska bankdagen, och för övriga
        svenska (dvs. utgivna av emittenter med säte i Sverige) värdepapper samt
        för utländska finansiella instrument fr.o.m. den femtonde svenska
        bankdagen efter det att värdepapperen mottagits av Lysa. Lysa är således
        inte skyldigt att bevaka frister som löper ut dessförinnan.
      </p>
      <p>
        Lysa vidtar i punkterna B.2–B.4 angivna åtgärder under förutsättning att
        Lysa i god tid fått fullgod information om den omständighet som
        föranleder åtgärden genom meddelande från kunden, depåförande
        tredjepart, emittent, agent (motsvarande) eller värdepapperscentral.
      </p>
      <p>
        Lysa får helt eller delvis underlåta att vidta en åtgärd, om det på
        depån eller kontot inte finns medel eller kreditutrymme för åtgärden
        eller om Lysa inte får de uppgifter som krävs för åtgärden eller för att
        uppfylla krav enligt EU-förordning, lag och föreskrifter.
      </p>
      <p>
        Lysa får vidta eller underlåta att vidta åtgärd som anges i punkterna
        B.2 och B.3 om Lysa särskilt angivit detta i meddelande till kunden och
        denne inte lämnat instruktion om annat inom den svarstid som angetts i
        meddelandet. Kunden är därefter bunden av åtgärd som Lysa vidtagit eller
        underlåtit att vidta på samma sätt som om kunden själv gett uppdrag om
        åtgärden.
      </p>
      <h3>B.2 Svenska finansiella instrument</h3>
      <p>
        Med svenska finansiella instrument avses i denna punkt B.2 finansiella
        instrument som är utgivna av emittenter med säte i Sverige och som är
        registrerade hos värdepapperscentral som har tillstånd att bedriva
        verksamhet i Sverige samt är upptagna till handel i Sverige eller
        handlas på svensk handelsplats. Beträffande andra finansiella instrument
        gäller i stället vad som anges under punkt B.3 beträffande utländska
        finansiella instrument.
      </p>
      <p>Beträffande aktier åtar sig Lysa att</p>
      <ol>
        <li>
          ta emot utdelning. Om kunden har rätt att välja mellan utdelning i
          kontanter eller i annan form, får Lysa välja utdelning i form av
          kontanter om kunden inte lämnat annan instruktion,
        </li>
        <li>
          vid nyemission avseende aktier i vilka kunden har företrädesrätt,
          underrätta kunden härom samt biträda kunden med önskade åtgärder i
          samband därmed. Om instruktion om annat inte lämnats senast tre
          bankdagar före sista dagen för handel med teckningsrätten ska Lysa –
          om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
          beaktande av kundens intresse – sälja teckningsrätt som inte
          utnyttjats,
        </li>
        <li>
          vid offentligt erbjudande om överlåtelse av finansiellt instrument
          riktat till kunden från emittenten (inlösen/återköp) eller annan
          (uppköp) underrätta kunden om och efter särskilt uppdrag från kunden
          biträda denne med önskade åtgärder. Motsvarande gäller vid offentligt
          erbjudande om förvärv av finansiellt instrument riktat till kunden,
        </li>
        <li>
          vid fondemission avseende aktier dels om möjligt göra sådana tillköp
          av fondaktierätter som behövs för att alla fondaktierätter som
          tillkommer kunden på grund av i depån/på kontot registrerade aktier
          ska kunna till fullo utnyttjas i fondemissionen, dels i depån/på
          kontot förteckna så många nya aktier som kunden därefter är berättigad
          till,
        </li>
        <li>
          såvitt avser aktier i avstämningsbolag underrätta kunden om begärd
          tvångsinlösen,
        </li>
        <li>
          såvitt avser aktier i avstämningsbolag vid nedsättning av
          aktiekapitalet, inlösen eller likvidation motta respektive lyfta
          kapitalbelopp samt annat förfallet belopp, samt
        </li>
        <li>
          på uppdrag av kunden ombesörja att hos värdepapperscentral
          förvaltarregistrerade aktiers rösträtt registreras i kundens namn.
          Detta förutsätter dock att uppdraget är Lysa tillhanda senast femte
          bankdagen före sista dag för införande i aktieboken för rätt att delta
          i bolagsstämma och att sådan rösträttsregistrering kan ske enligt
          gängse rutin hos värdepapperscentral.
        </li>
      </ol>
      <p>
        Om en depå innehas av två eller flera gemensamt och det i uppdraget inte
        angetts i vems namn aktierna ska registreras sker rösträttsregistrering
        av aktierna i proportion till vars och ens andel. Rösträttsregistrering
        sker inte av överskjutande aktier.
      </p>
      <p>
        Beträffande <i>teckningsoptioner</i> åtar sig Lysa att i god tid
        underrätta kunden om sista dag för aktieteckning och, efter särskilt
        uppdrag från kunden, söka verkställa kompletteringsköp av
        teckningsoptioner och verkställa nyteckning av aktier. Om
        överenskommelse om annat inte träffats senast tre bankdagar före sista
        dag för handel med teckningsoptioner ska Lysa - om Lysa bedömer det som
        praktiskt möjligt och lämpligt, bl.a. med beaktande av kundens intresse
        - sälja teckningsoption som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>inköpsrätter</i> åtar sig Lysa att i god tid underrätta
        kunden om sista dag för köpanmälan och, efter särskilt uppdrag av
        kunden, söka verkställa kompletteringsköp av inköpsrätt och ombesörja
        köpanmälan. Om överenskommelse om annat inte träffats senast tre
        bankdagar före sista dag för handel med inköpsrätten, ska Lysa - om Lysa
        bedömer det som praktiskt möjligt och lämpligt, bl.a. med beaktande av
        kundens intresse - sälja inköpsrätt som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>inlösenrätter</i> åtar sig Lysa att i god tid underrätta
        kunden om sista dag för inlösenanmälan och, efter särskilt uppdrag av
        kunden, söka verkställa kompletteringsköp av inlösenrätt och ombesörja
        inlösenanmälan. Om överenskommelse om annat inte träffats senast tre
        bankdagar före sista dag för handel med inlösenrätten, ska Lysa - om
        Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med beaktande
        av kundens intresse - sälja inlösenrätt som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>svenska depåbevis</i> avseende utländska aktier åtar sig
        Lysa att vidta motsvarande åtgärder som för svenska aktier enligt ovan
        om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
        beaktande av kundens intresse.
      </p>
      <p>
        Beträffande <i>skuldebrev och andra skuldförbindelser</i> som kan bli
        föremål för handel på kapitalmarknaden åtar sig Lysa att
      </p>

      <ol>
        <li>
          motta respektive lyfta ränta samt kapitalbelopp eller annat belopp som
          vid inlösen, utlottning eller uppsägning förfallit till betalning
          efter det att skuldförbindelsen mottagits,
        </li>
        <li>
          beträffande premieobligationer dessutom lyfta vinst på
          premieobligation som enligt dragningslista utfallit vid dragning som
          skett efter det att premieobligation mottagits av Lysa samt underrätta
          kunden om utbyte och biträda kunden med önskade åtgärder med anledning
          därav,
        </li>
        <li>
          beträffande konvertibler och andra konverteringsbara skuldförbindelser
          dessutom i god tid underrätta kunden om sista konverteringsdag samt
          efter särskilt uppdrag av kunden verkställa konvertering,
        </li>
        <li>
          vid emission av skuldebrev/ skuldförbindelser i vilka kunden har
          företrädesrätt, för kundens räkning teckna sådana
          skuldebrev/skuldförbindelser om inte annat överenskommits. Därvid ska
          gälla vad som angivits ovan i punkt B.2.2 b,
        </li>
        <li>
          vid offentligt erbjudande om överlåtelse av finansiellt instrument
          riktat till kunden från emittenten eller annan och som Lysa erhållit
          information om, på sätt som angetts ovan i punkt B.1.3, underrätta
          kunden därom och efter särskilt uppdrag av kunden biträda denne med
          önskade åtgärder i samband därmed. Motsvarande gäller vid offentligt
          erbjudande om förvärv av finansiellt instrument riktat till kunden,
          samt
        </li>
        <li>
          vid meddelande om förtida inlösen och kallelse till fordringshavarmöte
          eller liknande förfarande avseende skuldebrev/skuldförbindelser i
          vilka kunden är innehavare och som Lysa erhållit information om, på
          sätt som angivits ovan i punkt B.1.3, underrätta kunden därom och
          efter särskild överenskommelse med kunden biträda denne i samband
          därmed.
        </li>
      </ol>
      <p>
        Beträffande finansiella instrument, som inte omfattas av punkterna ovan,
        såsom optioner, terminer och fondandelar, omfattar Lysas åtagande att
        beträffande fondandelar lyfta utdelning samt i övrigt att vidta de
        åtgärder som Lysa bedömer som praktiskt möjligt och lämpligt bland annat
        med beaktande av kundens intresse eller som Lysa åtagit sig i särskilt
        avtal med kunden.
      </p>
      <h3>B.3 Utländska finansiella instrument</h3>
      <p>
        Beträffande aktier och skuldförbindelser som inte omfattas av B.2 och
        som är upptagna till handel på en reglerad marknad eller på motsvarande
        marknad utanför EES eller på en MTF, omfattar Lysas åtagande att vidta
        samma åtgärder som för svenska finansiella instrument, när Lysa bedömer
        detta som praktiskt möjligt och lämpligt, bl.a. med beaktande av kundens
        intresse.
      </p>
      <p>
        Kunden uppmärksammas särskilt på att det för vissa utländska aktier
        registrerade hos värdepapperscentral eller utländsk motsvarighet kan
        råda, på grund av inskränkningar i dess åtaganden, begränsade
        möjligheter för aktieägare att utöva vissa rättigheter, t.ex. att
        medverka vid bolagsstämma och att delta i emissioner samt att erhålla
        information därom.
      </p>
      <p>
        Beträffande andra utländska finansiella instrument än de i föregående
        punkter angivna omfattar Lysas åtagande endast att vidta de åtgärder
        Lysa åtagit sig i särskilt avtal med kunden.
      </p>
      <p>
        Kunden är införstådd med att kundens rättigheter avseende utländska
        finansiella instrument kan variera beroende på tillämplig utländsk lag
        eller regelverk. Kunden är även införstådd med att Lysa då åtgärd avser
        utländskt finansiellt instrument ofta kan komma att tillämpa andra
        tidsfrister gentemot kunden än dem som tillämpas i det land där åtgärd
        ska verkställas.
      </p>
      <h3>B.4 Svenska och utländska värdehandlingar</h3>
      <p>
        Beträffande svenska och utländska värdehandlingar omfattar Lysas
        åtagande att göra vad Lysa åtagit sig i särskilt avtal med kunden.
      </p>
    </section>

    <section>
      <h2>C. Klientmedel</h2>
      <p>
        Lysa mottar och förvarar kundens likvida medel med
        redovisningsskyldighet (klientmedel), vilka placeras på ett från Lysas
        tillgångar avskilt konto hos bank eller kreditinstitut
        (klientmedelskonto).
      </p>
      <p>
        Klientmedel utgörs av likvida medel i den valuta som tillämpas för det
        klientmedelskonto vilket kundens likvida medel placeras på. Lysa kan
        även motta likvida medel i annan valuta enligt särskild överenskommelse
        med kunden. Lysa förbehåller sig rätten att avböja mottagandet av
        klientmedel utgörandes av annan valuta som Lysa av någon anledning inte
        kan förvara och/eller hantera.
      </p>
      <p>
        Klientmedel utgörs av förskott för köpuppdrag eller likvid vid
        säljuppdrag (motsvarande), avkastning på i depån/kontot förvarade
        värdepapper samt medel som kunden överlämnat till Lysa eller som Lysa
        uppburit för kundens räkning och som har samband med depån/kontot,
        såvitt kunden inte har särskilt anvisat Lysa om annat konto för
        insättning.
      </p>
      <p>
        Lysa får ur klientmedel tillhörande kunden tillgodogöra sig belopp som
        kunden beordrat eller godkänt samt för varje utlägg, kostnad eller
        förskotterad skatt som har samband med depån/kontot. Lysa får vidare
        tillgodogöra sig belopp motsvarande dels utlägg, kostnad och arvode för
        uppdrag i övrigt som Lysa utfört åt kunden, dels likvid för annan
        förfallen obetald fordran som Lysa vid var tid har gentemot kunden.
      </p>
      <p>
        Medel i annan valuta som Lysa erlägger respektive mottager för kundens
        räkning ska innan klientmedelskontot belastas respektive krediteras – om
        överenskommelse inte träffas om förvaring i annan valuta eller på annat
        konto – omräknas till den valuta som tillämpas för det klientmedelskonto
        vilket kundens likvida medel placeras på genom Lysas försorg enligt av
        Lysa vid var tid tillämpad omräkningskurs.
      </p>
      <p>
        Om kunden är i betalningsdröjsmål, har Lysa rätt till dröjsmålsränta på
        förfallet belopp från förfallodagen till dess betalning sker med den
        räntesats som Lysa vid var tid tillämpar.
      </p>
    </section>

    <section>
      <h2>D. Pant</h2>
      <p>
        Bestämmelser om pant finns, förutom i detta avsnitt D, även i
        depå-/kontoavtalet under punkten Pantsättning.
      </p>
      <p>
        Avkastning av pant och andra rättigheter som grundas på panten omfattas
        också av pantsättningen och utgör pant.
      </p>
      <p>
        Lysas totala åtagande i egenskap av panthavare avseende pantsatt egendom
        framgår av depå-/kontoavtalet, inklusive dessa bestämmelser.
      </p>
      <p>
        Pant ska inte utgöra säkerhet för fordran på kunden som Lysa har
        förvärvat eller kan komma att förvärva från annan än kunden, om nämnda
        fordran varken har samband med kundens handel med finansiella instrument
        eller har uppkommit genom belastning av kundens konto.
      </p>
      <p>
        Om kunden inte fullgör sina skyldigheter mot Lysa enligt avtal eller
        skyldigheter som uppkommit i samband med kundens transaktioner med
        finansiella instrument får Lysa ta panten i anspråk på det sätt Lysa
        finner lämpligt. Lysa ska förfara med omsorg och, i förväg underrätta
        kunden om ianspråktagandet om det är möjligt och underrättelse enligt
        Lysas bedömning kan ske utan att vara till nackdel för Lysa i egenskap
        av panthavare. Lysa får bestämma i vilken ordning ställda säkerheter ska
        tas i anspråk. Pantsatta värdepapper får säljas utanför den handelsplats
        där värdepappret handlas eller är upptaget till handel. Om panten utgörs
        av klientmedel får Lysa, utan att i förväg underrätta kunden, omedelbart
        ta ut förfallet belopp från kontot.
      </p>
      <p>
        Kunden ger Lysa bemyndigande att själv eller genom någon som Lysa utser,
        teckna kundens namn, då detta krävs för att genomföra pantrealisation
        eller för att tillvarata eller utöva Lysas rätt med avseende på pantsatt
        egendom. Därmed får Lysa även öppna särskild depå och/eller Vp-konto hos
        Euroclear Sweden eller konto i annat kontobaserat system. Kunden kan
        inte återkalla detta bemyndigande så länge panträtten består.
      </p>
      <p>
        Kunden får inte utan att samtycke lämnats av Lysa till annan pantsätta
        egendom som är pantsatt enligt depå-/kontoavtalet. Sådan pantsättning
        till annan ska göras enligt Lysas anvisningar. Sker pantsättning i strid
        mot denna bestämmelse får Lysa säga upp depå-/kontoavtalet till
        omedelbart upphörande utan iakttagande av i punkt G.7 nedan nämnd
        uppsägningstid.
      </p>
      <p>
        Om kunden till annan pantsatt värdepapper eller medel på depån/kontot
        får Lysa, även om kunden invänt mot detta, utlämna/ överföra värdepapper
        respektive överföra medel på till depån anslutet konto till panthavaren
        eller annan efter instruktioner från panthavaren. Redovisning av sådant
        utlämnande/ överföring ska sändas till kunden.
      </p>
      <p>
        Kunden får inte på annat sätt förfoga över i depå-/kontoavtalet
        pantsatta värdepapper eller medel utan Lysas samtycke i varje särskilt
        fall.
      </p>
    </section>

    <section>
      <h2>E. Handel med värdepapper över depån/kontot</h2>
      <p>
        På uppdrag av kunden - även innefattande vad kunden och Lysa eventuellt
        har kommit överens om i särskilt avtal om handel via elektroniskt medium
        - utför Lysa köp och försäljning av finansiella instrument samt andra
        uppdrag avseende handel med finansiella instrument för kundens räkning.
        Efter fullgörandet och om förutsättningar för detta föreligger redovisar
        Lysa dessa transaktioner i kundens depå/konto.
      </p>
      <p>
        Kunden är medveten om att Lysa kan spela in och bevara telefonsamtal och
        annan elektronisk kommunikation i samband med kundens kommunikation med
        Lysa.
      </p>
      <p>
        I och med att kunden undertecknar avtalet eller tar Lysas tjänster i
        anspråk vad avser portföljförvaltning är kunden bunden av Lysas vid var
        tid gällande särskilda Riktlinjer för utförande av order samt
        sammanläggning och fördelning av order och de villkor som vid var tid
        gäller för handel med visst finansiellt instrument. Lysa ska på kunds
        begäran tillhandahålla kunden gällande riktlinjer och villkor som avses
        i första stycket på Lysas hemsida. Vid handel med finansiella instrument
        kan utöver de riktlinjer och villkor som avses i första stycket
        tillkomma regler antagna av Lysa, svensk eller utländsk emittent,
        utförandeplats, clearingorganisation eller värdepapperscentral. På
        kundens förfrågan kan Lysa lämna kunden uppgift om var informationen
        finns tillgänglig.
      </p>
      <p>
        Lysa har rätt att makulera köp eller försäljning där avslut träffats för
        kundens räkning i den omfattning som avslutet makulerats av aktuell
        utförandeplats. Samma rätt gäller om Lysa i annat fall finner makulering
        av avslut vara nödvändig med hänsyn till att ett uppenbart fel begåtts
        av Lysa, marknadsmotpart eller av kunden själv eller, om kunden genom
        order handlat i strid med gällande lag, annan författning eller om
        kunden i övrigt brutit mot god sed på värdepappersmarknaden.
      </p>
      <p>
        För den händelse någon av parterna skulle försättas i konkurs eller
        företagsrekonstruktion skulle beslutas för kunden, enligt lagen
        (1996:764) om företagsrekonstruktion, ska samtliga utestående
        förpliktelser med anledning av handel med finansiella instrument mellan
        parterna avräknas mot varandra genom slutavräkning per den dag sådan
        händelse inträffar. Vad som efter sådan slutavräkning tillkommer ena
        parten är omedelbart förfallet till betalning.
      </p>
    </section>

    <section>
      <h2>F. Skatter m.m.</h2>
      <p>
        Kunden ska svara för skatter och andra avgifter som ska erläggas enligt
        svensk eller utländsk lag, svensk/utländsk myndighets föreskrift eller
        beslut, mellanstatligt avtal eller Lysas avtal med svensk/utländsk
        myndighet med avseende på värdepapper registrerade i depån/på kontot,
        t.ex. preliminärskatt, utländsk källskatt respektive svensk kupongskatt
        på utdelning.
      </p>
      <p>
        Lysa kan, till följd av svensk/utländsk lag, svensk/utländsk myndighets
        föreskrift eller beslut, mellanstatligt avtal eller Lysas avtal med
        svensk/utländsk myndighet vara skyldigt att för kundens räkning vidta
        åtgärder beträffande skatt och andra avgifter grundat på
        utdelning/ränta/avyttring/innehav avseende kundens värdepapper. Det
        åligger kunden att tillhandahålla den information, inkluderande
        undertecknande av skriftliga handlingar, som Lysa bedömer vara nödvändig
        för att fullgöra sådan skyldighet. Om Lysa till följd av skyldighet för
        kundens räkning betalat skatt får Lysa belasta anslutet konto med
        motsvarande belopp på sätt som anges i punkt C.3.
      </p>
      <p>
        Lysa ska, på särskilt uppdrag av kunden, om rätt därtill föreligger och
        om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
        beaktande av kundens intresse söka medverka till nedsättning eller
        restitution av skatt samt utbetalning av tillgodohavande hos
        skattemyndighet. Lysa får i samband med detta teckna kundens namn samt
        även lämna uppgift om kunden och kundens värdepapper i den omfattning
        som krävs.
      </p>
    </section>

    <section>
      <h2>G. Övriga bestämmelser</h2>
      <h3>G.1 Avgifter m.m.</h3>
      <p>
        För förvaring samt för övriga tjänster enligt depå-/kontoavtalet tas ut
        avgifter enligt vad som framgår i depå-/kontoavtalet eller som Lysa
        senare meddelat kunden på sätt som anges i punkt G.9.
      </p>
      <p>
        Upplysning om vid var tid gällande avgifter finns tillgängliga på Lysas
        hemsida.
      </p>
      <p>Avgifter debiteras i svenska kronor om inte Lysa meddelar annat.</p>
      <p>
        Kunden ska ersätta Lysas kostnader och utlägg som har samband med Lysas
        uppdrag enligt depå-/kontoavtalet samt kostnader och utlägg för att
        bevaka och driva in Lysas fordran hos kunden.
      </p>
      <h3>G.2 Meddelanden m.m.</h3>
      <h4>Meddelande från Lysa</h4>
      <p>
        Meddelande från Lysa till kunden får skickas med e-post eller annan
        elektronisk kommunikation, exempelvis via inloggat läge på Lysas
        hemsida.
      </p>
      <p>
        Meddelande genom e-post eller annan elektronisk kommunikation anses ha
        kommit kunden tillhanda vid avsändandet om det sänds till den
        elektroniska adress som kunden har uppgivit eller via inloggat läge på
        Lysas hemsida. Om ett sådant meddelande når kunden utanför normal
        kontorstid ska meddelandet anses ha kommit mottagaren tillhanda vid
        början av påföljande bankdag.
      </p>
      <p>
        Kunden ska utan dröjsmål underrätta Lysa om ändring av kontaktuppgifter.
        Sådan underrättelse ska ske genom att lämna uppdaterad information i
        inloggat läge på Lysas hemsida.
      </p>
      <h4>Meddelande till Lysa</h4>
      <p>
        Meddelande från Kunden till Lysa ska ställas till{" "}
        <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a>, såvida Lysa inte
        begärt svar till annan adress. Meddelande genom e-post eller annan
        elektronisk kommunikation anses ha kommit Lysa tillhanda vid
        avsändandet. Om ett sådant meddelande når Lysa utanför normal kontorstid
        ska meddelandet anses ha kommit Lysa tillhanda vid början av påföljande
        bankdag i Sverige.
      </p>
      <p>
        För meddelande avseende reklamation och hävning med anledning av
        kommissionsuppdrag som konsument lämnat i egenskap av icke professionell
        kund enligt Lysas kategorisering, gäller att meddelande kan göras
        gällande om kunden kan visa att det skickats på ett ändamålsenligt sätt,
        även om det försenats, förvanskats eller inte kommit fram. Kunden ska
        dock, om denne har anledning att anta att Lysa inte fått meddelandet
        eller att det förvanskats, skicka om meddelandet till Lysa.
      </p>
      <h3>G.3 Redovisning</h3>
      <p>
        Redovisning för depån/kontot lämnas löpande för kunden via inloggat läge
        på Lysas hemsida. Detsamma gäller redovisning av årssammandrag och
        kontrolluppgifter inför deklarationen.
      </p>
      <p>
        Lysa ansvarar inte för riktigheten av information om värdepapper, som
        inhämtats av Lysa från extern informationslämnare.
      </p>
      <h3>G.4 Felaktig registrering i depån/kontot m.m.</h3>
      <p>
        Om Lysa av misstag skulle registrera värdepapper eller medel i kundens
        depå/konto, har Lysa rätt att snarast möjligt korrigera registreringen
        eller insättningen i fråga. Om kunden förfogat över av misstag
        registrerade värdepapper eller insatta medel, ska kunden snarast möjligt
        till Lysa lämna tillbaka värdepapperen eller betala tillbaka de medel
        som mottagits vid avyttringen eller insättningen. Om kunden underlåter
        detta har Lysa rätt att köpa in värdepapperen i fråga och belasta
        kundens konto med beloppet för Lysas fordran samt, vid kundens
        förfogande över medel, belasta kundens konto med beloppet i fråga.
      </p>
      <p>
        Lysa ska omedelbart underrätta kunden om att korrigering vidtagits
        enligt ovan. Kunden har inte rätt att ställa några krav gentemot Lysa
        med anledning av sådana misstag.
      </p>
      <p>
        Vad som angetts i de två föregående styckena gäller även när Lysa i
        andra fall registrerat värdepapper eller medel i depån/konto, som inte
        skulle ha tillkommit kunden.
      </p>
      <h3>G.5 Begränsning av Lysas ansvar m.m.</h3>
      <h4>Tekniska problem</h4>
      <p>
        Kunden är medveten om att det kan uppkomma driftavbrott i kundens, Lysas
        eller annans datasystem (hård- eller mjukvara), tele- eller elsystem
        eller liknande fel som kan medföra att tjänsterna enligt
        depå-/kontoavtalet inte kan utföras som avtalat, exempelvis:
      </p>
      <ul>
        <li>att kunden inte kan göra en insättning,</li>
        <li>att kunden inte kan göra ett uttag, eller</li>
        <li>
          att information som Lysa tillhandahåller inte är tillgänglig för
          kunden eller är felaktig.
        </li>
      </ul>
      <p>
        Lysa ansvarar endast för skada som kunden åsamkats till följd av
        ovanstående tekniska problem om Lysa varit oaktsamt. För skada som
        uppkommit vid kommissionsuppdrag i förhållande till konsument ankommer
        det på Lysa att visa att skadan inte uppkommit på grund av Lysas
        vårdslöshet. Lysa ansvarar endast för indirekt skada som orsakats av
        Lysas grova vårdslöshet. Begränsningen gäller inte vid uppdrag som
        lämnats av konsument om den indirekta skadan orsakats av Lysas
        vårdslöshet.
      </p>
      <p>
        Om kunden upptäcker fel eller störningar som anges i denna punkt är
        kunden skyldig att omedelbart påtala detta för Lysa. Om kunden inte gör
        detta trots att kunden upptäckt eller borde ha upptäckt sådant fel eller
        sådan störning förlorar kunden rätt att begära ersättning eller göra
        gällande någon annan påföljd i anledning av felet eller störningen.
      </p>
      <p>
        För utfört kommissionsuppdrag lämnat av en konsument i egenskap av
        icke-professionell kund gäller att reklamation i ovanstående fall ska
        framföras inom skälig tid från det att kunden insåg eller borde ha
        insett felet eller störningen.
      </p>
      <h4>Ansvarsbegränsning</h4>
      <p>
        Lysa är inte ansvarigt för skada som beror av svenskt eller utländskt
        lagbud, svensk eller utländsk myndighetsåtgärd, krigshändelse, strejk,
        blockad, bojkott, lockout, epidemi, pandemi, karantän eller annan
        liknande omständighet. Förbehållet i fråga om strejk, blockad, bojkott
        och lockout gäller även om Lysa självt är föremål för eller vidtar sådan
        konfliktåtgärd.
      </p>
      <p>
        Lysa är inte ansvarigt för skada som orsakats av - svensk eller utländsk
        - utförandeplats, depåförande tredjepart, värdepapperscentral,
        clearingorganisation, eller andra som tillhandahåller motsvarande
        tjänster, och inte heller av uppdragstagare som Lysa med tillbörlig
        omsorg anlitat eller som anvisats av kunden. Detsamma gäller skada som
        orsakats av att ovan organisationer eller uppdragstagare blivit
        insolventa. Lysa ansvarar inte för skada som uppkommer för kunden eller
        annan med anledning av förfogandeinskränkning som kan komma att
        tillämpas mot Lysa beträffande finansiella instrument.
      </p>
      <p>
        Lysa ansvarar endast för skada som uppkommit i andra fall om Lysa varit
        oaktsamt. Vid skada som uppkommit vid kommissionsuppdrag i förhållande
        till konsument ankommer det på Lysa att visa att skadan inte uppkommit
        på grund av Lysas vårdslöshet. Lysa ansvarar endast för indirekt skada
        som orsakats av Lysas grova vårdslöshet. Begränsningen gäller inte vid
        uppdrag som lämnats av konsument om den indirekta skadan orsakats av
        Lysas vårdslöshet.
      </p>
      <p>
        Föreligger hinder för Lysa, på grund av omständighet som anges i första
        stycket, att helt eller delvis utföra åtgärd enligt depå-/kontoavtalet
        eller köp- eller säljuppdrag avseende finansiella instrument får åtgärd
        skjutas upp till dess hindret har upphört. Om Lysa till följd av sådan
        omständighet är förhindrad att verkställa eller ta emot betalning/
        leverans, ska varken Lysa eller kunden vara skyldig att betala ränta.
      </p>
      <p>
        Vad ovan sagts gäller i den mån inte annat följer av lagen (1998:1479)
        om värdepapperscentraler och kontoföring av finansiella instrument.
      </p>
      <h3>G.6 Avstående från uppdrag</h3>
      <p>
        Lysa har rätt att avsäga sig uppdrag enligt depå-/kontoavtalet avseende
        svenskt finansiellt instrument registrerat hos värdepapperscentral t.ex.
        Euroclear Sweden inom fem bankdagar, och för övriga svenska värdepapper
        samt för utländska finansiella instrument inom femton bankdagar efter
        det att värdepapperen mottagits av Lysa. För utlämnande/överföring av
        värdepappret i fråga gäller därvid vad som i punkt G.7 anges om
        utlämnande/överföring vid uppsägning.
      </p>
      <h3>G.7 Uppsägning</h3>
      <p>
        Lysa kan säga upp depå-/kontoavtalet till upphörande två månader efter
        det att kunden enligt punkten G.2 ska anses ha fått meddelandet.
      </p>
      <p>
        Kunden kan omgående säga upp avtalet i inloggat läge på Lysas hemsida.
        Kunden kan även säga upp avtalet på de sätt som framgår enligt G.2 till
        upphörande en månad efter det Lysa enligt samma punkt ska anses ha fått
        meddelandet.
      </p>
      <p>
        Konsument kan även säga upp avtalet till upphörande efter en månad
        muntligen via Lysas kundtjänst efter att ha vederbörligen identifierat
        sig med den verifieringsmetod som Lysa vid var tid godkänner.
      </p>
      <p>
        Depå-/kontoavtalet upphör att gälla, med beaktande av nedan bestämmelser
        om reglering av parternas skyldigheter, senast den dag då Avtal om
        portföljförvaltning mellan parterna upphör att gälla.
      </p>
      <p>
        Vid depå-/kontoavtalets upphörande ska parterna genast reglera samtliga
        sina skyldigheter enligt depå-/kontoavtalet. Depå-/kontoavtalet gäller
        dock i tillämpliga delar till dess att part fullgjort samtliga sina
        åtaganden gentemot motparten. Vidare får var och en av Lysa och kunden
        säga upp uppdrag enligt depå-/kontoavtalet avseende visst värdepapper på
        samma villkor som anges ovan.
      </p>
      <p>
        Oavsett vad som sagts i föregående stycke får part säga upp
        depå-/kontoavtalet med omedelbar verkan om motparten väsentligen brutit
        mot avtalet. I sådant fall ska varje avtalsbrott, där rättelse trots
        anmaning inte snarast möjligt vidtagits, anses som ett väsentligt
        avtalsbrott. Lysa får även säga upp depå-/kontoavtalet med omedelbar
        verkan:
      </p>
      <ul>
        <li>
          om det föranleds av värdepappersrättsliga eller andra restriktioner i
          Sverige eller annat land,
        </li>
        <li>
          om kunden försätts i konkurs, blivit föremål för
          företagsrekonstruktion, fått till stånd offentlig ackordsförhandling
          eller vid utmätning befunnits sakna tillgångar att betala sina
          skulder,
        </li>
        <li>
          om kunden ställt in sina betalningar eller i övrigt anses vara på
          obestånd,
        </li>
        <li>
          om kunden via transaktioner med Lysa agerat i strid med gällande lag,
          eller
        </li>
        <li>
          vid förändringar av kundens skatterättsliga hemvist som medför att
          Lysa inte längre kan fullgöra sin skyldighet att för kundens räkning
          vidta åtgärder beträffande skatt enligt vad som anges i avsnitt F
          eller att fullgörandet av sådan skyldighet avsevärt försvåras.
        </li>
      </ul>
      <p>
        Vid depå-/kontoavtalets upphörande ska Lysa till kunden överföra
        samtliga i depån/på kontot förtecknade värdepapper och likvida medel.
        kunden ska lämna anvisningar till Lysa om överföringen. Om inte sådana
        anvisningar lämnats inom sextio kalenderdagar efter den dag
        depå-/kontoavtalet enligt uppsägningen upphört eller om överföringen
        inte kan ske enligt av kunden lämnade anvisningar, får Lysa sälja
        aktuella värdepapper och överföra likviden till kundens förvalda
        uttagskonto.
      </p>
      <p>
        Fondandelar i Lysas fonder måste säljas vid depå-/kontoavtalets
        upphörande. Vid depå-/kontoavtalets upphörande äger Lysa rätt att sälja
        kundens innehav i Lysas fonder och betala ut likviden till kundens
        föranmälda uttagskonto. Om kunden inte har ett föranmält uttagskonto ska
        kunden lämna anvisningar till Lysa om överföringen.
      </p>
      <h3>G.8 Begränsning av åtaganden och förhållanden till andra avtal</h3>
      <p>
        Lysa är inte skyldigt att vidta andra åtgärder än vad som anges i
        depå-/kontoavtalet om inte särskilt skriftligt avtal träffats om detta.
        Vad som uttryckligen anges i sådant särskilt avtal ska gälla före
        depå-/kontoavtalet.
      </p>
      <h3>G.9 Ändring av depå-/kontoavtalet och avgifter</h3>
      <p>
        Ändringar av depå-/kontoavtalet, inklusive dessa bestämmelser, eller
        Lysas avgifter ska ha verkan gentemot kunden två månader efter det att
        kunden enligt punkt G.2 ska anses ha mottagit meddelandet. Om kunden
        inte godtar ändringen har kunden rätt att inom nämnda tid säga upp
        depå-/kontoavtalet till omedelbart upphörande utan iakttagande av i
        punkt G.7 angiven uppsägningstid.
      </p>
      <p>
        En ändring som anses vara obetydlig för kunden får genomföras utan att
        kunden informeras om det enligt ovan.
      </p>
      <p>
        Ändringar och tillägg till depå-/kontoavtalet ska ske skriftligen för
        att vara gällande.
      </p>
      <h3>
        G.10 Information om stängning av Lysas hemsida vid planerat underhåll
      </h3>
      <p>
        Vid stängning av Lysas hemsidaför planerat underhåll m.m. ska Lysa i god
        tid informera kunden om detta.
      </p>
      <h3>G.11 Överlåtelse</h3>
      <p>
        Lysa äger rätt att överlåta sina rättigheter eller skyldigheter enligt
        depå-/kontoavtalet till företag inom samma koncern som bolaget ingår
        eller annat företag till följd av associationsrättslig reglering.
      </p>
      <h3>G.12 Tillämplig lag</h3>
      <p>
        Tolkning och tillämpning av depå-/kontoavtalet och dessa bestämmelser
        ska ske enligt svensk rätt.
      </p>
    </section>
  </article>
);

import React from "react";

export function InformationAboutLysaEN() {
  return (
    <article className="lysa-doc information-about-lysa">
      <header>
        <h1>Information about Lysa</h1>
        v. 2020:4
      </header>

      <section>
        <h2>Contact information</h2>
        <p>
          You are always welcome to contact us. You can find our contact details
          on{" "}
          <a
            href="https://lysa.capital/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.capital/contact
          </a>
        </p>
      </section>

      <section>
        <h2>Language</h2>
        <p>
          Lysa uses Swedish or English in all documentation. Information from
          Lysa may also be in the language of the country in which the customer
          registers. The contacts between Lysa and the customer will be in
          Swedish or English.
        </p>
      </section>

      <section>
        <h2>Authorisations</h2>
        <p>
          Lysa AB, Reg. No. 559028-0821, conducts securities business under the
          supervision of the Swedish Financial Supervisory Authority. Lysa has
          the following authorisations:
        </p>
        <ul>
          <li>
            Portfolio management (the Swedish Securities Markets Act (2007:528),
            Chapter 2, Section 1, item 4)
          </li>
          <li>
            Safekeeping of financial instruments on behalf of customers and
            receive funds associated with requirements of record keeping (the
            Swedish Securities Markets Act, Chapter 2, Section 2, item 1)
          </li>
          <li>
            Register as manager of fund units (the Swedish Mutual Funds Act
            (2004:46), Chapter 4, Section 12)
          </li>
          <li>
            Insurance distribution of direct insurance in the insurance category
            Ia. Life insurance and III Insurance linked to securities funds (the
            Swedish Insurance Distribution Act (2018:1219) Chapter 2, Section 1
            and 2, and the Swedish Insurance Business Act (2010:2043) Chapter 2,
            Section 12.
          </li>
        </ul>
        <p>
          For more information, please visit the Swedish Financial Supervisory
          Authority’s website{" "}
          <a href="https://fi.se" target="_blank" rel="noopener noreferrer">
            fi.se
          </a>
          . If you would like to contact the Swedish Financial Supervisory
          Authority, you can reach them on phone number{" "}
          <a href="tel:+46840898000">+468 408 980 00</a> or adress
          Finansinspektionen, Box 7821, 103 97 Stockholm, Sweden.
        </p>
      </section>

      <section>
        <h2>
          Reports from Lysa and information about the customer portfolio etc.
        </h2>
        <p>
          Each customer has continuous access to all information about the
          portfolio management (such as information of the portfolio and its
          value, the total sum of all costs and performed transactions) when
          signed in to Lysa’s website. Contract notes regarding completed
          transactions are also available when signed in to Lysa’s website.
        </p>

        <p>
          For information about the financial instruments that may be part of
          the portfolio, the method and frequency for valuation of these,
          reference values against which the portfolio’s development can be
          compared and the objectives of the management as well as the risk
          level, please refer to our general conditions and at the each time
          applicable investment guidelines for the customer’s account with Lysa.
        </p>

        <p>
          For information on pledge, please refer to our general terms and
          conditions.
        </p>
      </section>

      <section>
        <h2>
          Information about customer categorisation and customer protection
        </h2>
        <p>
          Pursuant to the Swedish Securities Markets Act and EU regulation, Lysa
          is obligated to divide its customers in categories. The categorisation
          is based on your knowledge, experience and other conditions. The
          customer category you fall into affects, among other things, which
          information you receive from Lysa and thus, your customer protection
          level. Retail (non-professional) customers have the highest level of
          protection, while eligible counterparties have the lowest.
        </p>

        <p>All of Lysa’s customers are categorised as retail customers.</p>
        <p>The customer categories are described below.</p>

        <h4>Retail customers</h4>
        <p>
          Retail customers belong to the highest level of protection. This
          means, among other things, that Lysa has an extensive information
          obligation. For example, you will receive information about financial
          instruments and the risks associated with these, as well as
          information about costs and fees.
        </p>

        <p>
          In order for Lysa to be able to provide portfolio management to a
          retail customer, Lysa must first make an assessment of the customer (a
          so called suitability assessment) based on the customer’s financial
          situation, whether the customer can bear the financial risk associated
          with the investment, risk propensity, investment horizon, the
          customer’s needs and desires and its investment objectives.
        </p>

        <h4>Professional customers </h4>
        <p>
          Professional customers do not have as high protection as retail
          customers. Professional customers include e.g., larger companies,
          financial institutions and institutional investors. The protection of
          professional customers includes e.g.:
        </p>
        <ul>
          <li>
            Information about risks in conjunction with investment in financial
            instruments.
          </li>
          <li>
            When providing portfolio management to professional customers, Lysa
            must first make a suitability assessment based on the objectives
            with the investment and whether the customer can bear the financial
            risk associated with the investment.
          </li>
        </ul>

        <h4>Eligible counterparty</h4>
        <p>
          An eligible counterparty has the lowest level of protection. For
          example, securities companies, insurance companies and credit
          institutions belong to this group.
        </p>

        <h4>Change of customer category</h4>
        <p>
          A customer can request to change customer category. Such a request
          must be in writing. Lysa determines whether the customer may change
          the customer category and informs the customer whether the change is
          approved or not. A change in customer category means, among other
          things, that the customer will have another protection, see the
          descriptions above.
        </p>
      </section>

      <section>
        <h2>Fees and compensation</h2>
        <p>
          Information about fees and compensation can be found on Lysa’s
          website.
        </p>
      </section>

      <section>
        <h2>Customer complaints</h2>
        <p>
          Lysa strives to maintain a high level of service. If you are
          dissatisfied with our services, we gladly accept your views through
          our customer service. If you are still dissatisfied after such a
          contact, and you have a claim for financial compensation or
          correction, you can refer the case to our complaint officer.
        </p>
        <p>
          Your complaint must be made in writing. Specify times and events as
          extensively as you can. Please also attach the supporting documents
          you have in the form of notes, correspondence etc. This to ensure that
          all the facts and the background to the complaint are included in the
          complaint. Also state your claim for financial compensation.
        </p>
        <p>
          Lysa handles the complaints we receive with care and urgency, and our
          goal is that all cases should be answered within fourteen days. If an
          investigation of the case would require longer processing time, you
          will receive information about this and when we think we will be able
          to respond. If we are unable to meet your complaint, you as a customer
          have the right to receive a written reply with the reasons for this.
        </p>
        <p>
          The complaint officer is Helena Olander. You can find contact details
          to our Complaints Officer function on{" "}
          <a
            href="https://lysa.capital/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.capital/contact
          </a>
          .
        </p>
        <p>
          You have the opportunity to receive free advice from the Swedish
          Consumers’ Banking and Finance Bureau, see{" "}
          <a
            href="https://www.konsumenternas.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.konsumenternas.se
          </a>
          . If you do not have success with your case at Lysa’s Complaints
          Officer function, you have the opportunity to refer the case to the
          Swedish National Board for Consumer Disputes (
          <i>Swe. Allmänna reklamationsnämnden - ARN</i>), see{" "}
          <a
            href="https://www.arn.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.arn.se
          </a>{" "}
          for information on the National Board for Consumer Disputes, or to the
          general court.
        </p>
      </section>

      <section>
        <h2>Conflicts of interest</h2>
        <p>
          A securities company such as Lysa must identify the conflicts of
          interest that may arise in the business, for example between Lysa and
          its customers or between different customers in connection with Lysa
          providing services. Examples of circumstances which are to be
          considered are whether Lysa or its owners, in addition to agreed
          remuneration, may gain a profit or avoid a financial loss at the
          customer’s expense.
        </p>
        <p>
          Furthermore, Lysa has an obligation to prevent customers’ interests
          from being adversely affected by conflicts of interest. If measures
          taken are not sufficient to prevent the customer’s interests from
          being adversely affected, Lysa must clearly inform the customer about
          the nature or source of the conflict of interest before performing a
          service on behalf of the customer.
        </p>
        <p>
          The Board of Directors of Lysa has adopted an instruction for handling
          conflicts of interest. The purpose of the instruction is to prevent
          conflicts of interest and, if a conflict still arises, to prevent a
          negative impact on the customers’ interests. The instruction describes
          potential conflicts of interest and the measures taken to prevent such
          adverse effects. The instruction states, among other things, how a
          sufficient degree of independence can be achieved in order to, as far
          as possible, avoid that conflicts of interest arise between different
          departments and units within Lysa and how any conflicts of interest
          that arise should be managed.
        </p>
        <p>
          At the customer’s request, Lysa can provide further information on the
          instruction for handling conflicts of interest.
        </p>
      </section>

      <section>
        <h2>Paid compensation for collaboration</h2>
        <p>
          Lysa pays compensation to affiliates and partners when a customer
          becomes a customer through collaboration with a third party or through
          an affiliate link.
        </p>
      </section>

      <section>
        <h2>Customers’ financial instruments and funds</h2>
        <p>
          Lysa is obliged to keep your securities separate from its own assets.
          The same applies to the money Lysa receives in connection with the
          portfolio management that are associated with requirements of record
          keeping. Since your securities and money must be separate from Lysa’s
          assets, you are entitled to them if Lysa enters into bankruptcy.
          Lysa’s external auditor verifies once a year that the company’s
          measures and procedures to ensure the preservation of the separation
          right are appropriate and suitable.
        </p>
        <p>
          For fund units and funds, the assets of an individual fund cannot be
          used in the event of a bankruptcy in Lysa or in the fund company. The
          assets of a mutual fund are jointly owned by the fund’s unit holders
          and stored at a depositary. If the customer’s fund units are
          registered on the deposit account with Lysa, they are nominee
          registered with the fund company. The customer’s holding of fund units
          is registered in Lysa’s unit-holder register. In the register, Lysa
          differentiates the customer’s holdings from other customers’ holdings.
        </p>

        <h4>Investor protection</h4>
        <p>
          In the event that Lysa nevertheless cannot dispense your property, for
          example because if it after the bankruptcy is not possible to find out
          which are yours and the institution’s assets, respectively, you are
          entitled to compensation from the investor protection. The
          compensation is limited to SEK 250,000 per customer and institute. The
          protection does not apply to changes in the value of shares and other
          securities.
        </p>
        <p>
          The investor protection is regulated in the Swedish Act (1999:158) on
          Investor Protection. Investor protection means that the state protects
          customers’ securities in transactions or when stored with a bank or
          securities company. The right to reimbursement also applies to money
          received by the institute from customers which are associated with
          requirements of record keeping, i.e. money that must not be mixed with
          the institution’s own assets.
        </p>
        <p>
          More information on the investor protection can be found on the
          website of the Swedish National Debt Office,{" "}
          <a
            href="https://www.riksgalden.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.riksgalden.se
          </a>
          .
        </p>

        <h4>Protection of client funds - deposit guarantee</h4>
        <p>
          Lysa receives money from customers with an obligation of record
          keeping. This means that the customer’s money must be kept separate
          from Lysa’s own assets. The customer is thereby entitled to its money
          in the event of Lysa’s bankruptcy.
        </p>
        <p>
          Lysa keeps customers’ money in a client funds account, currently with
          Danske Bank A/S, Denmark (the “<b>Credit Institution</b>”). Your money
          is protected under the Danish deposit guarantee called the Guarantee
          Fund (<i>Dan. Garantiformuen</i>). It provides protection for an
          amount equivalent to EUR 100,000 per depositor.
        </p>
        <p>
          A client funds account is an account where money has been deposited on
          behalf of several customers on the same account. The general rule for
          client accounts is that the underlying owners of the funds, that is,
          each customer at Lysa, can each receive compensation from the deposit
          guarantee up to the maximum amount.
        </p>
        <p>
          If the Credit Institution fails, the Guarantee Fund in Denmark will
          within seven working days repay your deposits with up to the maximum
          amount of EUR 100,000 by sending a payment order to the institution in
          your home country that administers the deposit guarantee fund, in
          Sweden the National Debt Office, which will then make the payment.
          Compensation is given in the local currency.
        </p>
        <p>
          More information about the Guarantee Fund can be found on the
          Guarantee Fund’s website,{" "}
          <a
            href="https://www.fs.dk/garantiformuen/om-garantiformuen"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.garantifonden.dk
          </a>
          .
        </p>
      </section>

      <section>
        <h2>Distance contracts and right of withdrawal</h2>
        <p>
          Lysa enters into distance agreements with private individuals
          regarding investment savings accounts and discretionary portfolio
          management. According to national legislation based on Directive
          2011/83/EU of the European Parliament and of the Council of 25 October
          2011 on consumer rights, in Sweden law (2005:59) on distance contracts
          and agreements outside business premises (the Distance Contracts Act),
          Lysa must provide the consumer with a reasonable amount of time before
          entering into a financial services agreement. In Information about
          Lysa above, you can find company and business information and below
          you can find general information about the right of withdrawal. In
          addition to this, reference is made to the general terms and
          conditions of our services.
        </p>

        <h4>General information about the right of withdrawal </h4>
        <p>
          The account holder has the right to cancel the contract entered into
          (right of withdrawal) with Lysa by sending a message to Lysa within 14
          days from the date the agreement was concluded. The right of
          withdrawal applies only to the initial product agreement and not to
          services or transactions already performed. This means that you cannot
          use the right of withdrawal if you have deposited money into your
          account and Lysa has invested these for you.
        </p>

        <p>
          Of course, even after the right of withdrawal has expired, you have
          the right to terminate your agreements, in accordance with the general
          terms and conditions.
        </p>
      </section>
    </article>
  );
}

import React from "react";

export const AccountTermsEN = () => (
  <article className="lysa-doc account-terms">
    <header>
      <h1>General terms and conditions for custody accounts</h1>
      <p>v. 2020:1</p>
    </header>

    <section>
      <h2>Introduction</h2>
      <p>
        The account holder (the customer) and Lysa AB, Reg. No. 559028-0821 (“
        <b>Lysa</b>”) have entered into an agreement on a custody account for
        the customer (the “<b>Custody Account Agreement</b>”) of which these
        general terms and conditions for custody accounts (the “
        <b>General Terms and Conditions</b>”) is part.
      </p>
      <p>
        Lysa and the customer have also entered into an Agreement on portfolio
        management.
      </p>
      <p>
        The purpose of the custody account is to provide portfolio management.
        Lysa does not offer the possibility to purchase and sell financial
        instruments.
      </p>
    </section>

    <section>
      <h2>Definitions</h2>
      <p>
        In the Custody Account Agreement, including in these General Terms and
        Conditions, the following terms shall have the following meaning.
      </p>
      <ol>
        <li>
          <b>securities:</b> shall mean both financial instruments as defined in
          the Securities Market Act (2007:528), i.e. 1) transferable securities
          which may be admitted to trading on the capital market, 2)
          money-market instruments, 3) fund units, and 4) financial derivative
          instruments, and non-traded instruments, by which is meant documents
          that may not be admitted to trading on the capital market, i.e. 1)
          shares or debentures or other debt securities which, according to the
          definition above, do not constitute financial instruments, 2)
          guarantees, 3) deeds of gift, 4) mortgage deeds or similar documents.
        </li>
        <li>
          <b>contract note:</b> shall mean a note that an order/commercial
          transaction has been executed.
        </li>
        <li>
          <b>regulated market:</b> the meaning as defined in the Securities
          Market Act (2007:528), i.e. a multilateral system within the European
          Economic Area (EEA) which brings together, or facilitates the bringing
          together of, multiple third party buying and selling interests in
          financial instruments – in the system and in accordance with its
          non-discretionary rules – in a way that results in a trade.
        </li>
        <li>
          <b>marketplace:</b> shall mean a regulated market or a multilateral
          trading facility (MTF).
        </li>
        <li>
          <b>trading venue:</b> a marketplace, a systematic internaliser or a
          market maker within the EEA or any other person that provides
          liquidity within the EEA or an entity that corresponds to any of the
          aforementioned outside of the EEA.
        </li>
        <li>
          <b>safe custody of securities:</b> both the safe custody of physical
          securities as well as the safe custody of dematerialised securities
          which arise through registration on a custody account.
        </li>
        <li>
          <b>third party custodian:</b> shall mean a securities institution
          which, at the request of Lysa or other third party custodian, holds
          securities in safekeeping on custody accounts on behalf of clients.
        </li>
        <li>
          <b>securities institution:</b> as defined in the Securities Markets
          Act (2007:528), i.e. securities companies, Swedish credit institutions
          which are authorised to conduct securities business and foreign
          undertakings which conduct securities business from branches, and
          foreign companies authorised to conduct operations equivalent to
          securities business.
        </li>
        <li>
          <b>central securities depository:</b> as defined in the Financial
          Instruments Accounts Act (1998:1479), i.e. Swedish limited companies
          which, pursuant to Chapter 2 of the aforementioned Act, are authorised
          to record financial instruments in CSD registers.
        </li>
        <li>
          <b>bank day:</b> shall mean a day in Sweden which is not a Sunday or
          other public holiday or, in conjunction with the payment of promissory
          notes, a day which is equated with a public holiday (such equivalent
          days are currently Saturdays, Midsummer Eve, Christmas Eve and New
          Year's Eve).
        </li>
        <li>
          <b>Lysa’s funds:</b> security funds which are distributed and managed
          by companies within the Lysa group.
        </li>
      </ol>
    </section>

    <section>
      <h2>A. Safe custody on custody account, etc</h2>
      <h3>A.1 Safe custody with Lysa</h3>
      <p>
        Lysa will register such securities that have been received by Lysa for
        safe custody etc. on a custody account. Lysa reserves the right to, from
        time to time, decide which securities may be listed and received by Lysa
        on the customer’s custody account. Securities received are held in safe
        custody by Lysa on behalf of the customer.
      </p>
      <p>
        Financial instruments recorded in book entry systems at a central
        securities depository or the equivalent are regarded as received when
        Lysa has been authorised to register information concerning the
        instrument in such a system, or caused such information to be
        registered. Other securities are regarded as received when they have
        been delivered to Lysa.
      </p>
      <p>
        Lysa reserves the right to consider whether to take receipt of a
        particular security. If the customer has several custody accounts with
        Lysa and the customer has not instructed Lysa as to on which custody
        account a particular security shall be registered, Lysa may itself
        determine on which custody account registration will take place.
      </p>
      <p>
        Lysa does not receive physical securities. Lysa does not carry out any
        examination into the authenticity of the customer’s securities.
      </p>
      <p>
        Lysa reserves the right to use reasonable time in which to affect
        registration, transfer and release of securities.
      </p>
      <p>
        Lysa may deregister securities from the customer’s custody account in
        the event the party which issued the securities is placed in bankruptcy
        or the security, for other reasons, has lost its value. Where
        practically possible and appropriate in such cases, Lysa will, among
        other things taking into consideration the interest of the customer,
        attempt to register the security in the customer’s name.
      </p>
      <h3>A.2 Safe custody with third party custodian</h3>
      <p>
        Lysa may hold the customer’s securities in safe custody with another
        securities institution, in Sweden or abroad. The third party custodian
        may, in turn, retain the services of another third party custodian for
        safe custody of the customer’s securities.
      </p>
      <p>
        Third party custodians are appointed by Lysa in its sole discretion in
        compliance with the obligations incumbent upon Lysa pursuant to EU
        regulation, law and regulations. In conjunction with safe custody with a
        third party custodian abroad (within or outside of the EEA), the
        customer’s securities are covered by applicable national legislation
        which may entail that the customer’s rights regarding such securities
        may vary as compared with what would apply in conjunction with safe
        custody in Sweden.
      </p>
      <p>
        Safe custody with a third party custodian normally takes place in Lysa’s
        name on behalf of the customer. In such cases, the customer’s securities
        may be registered together with the securities of other owners, for
        example on a joint account. Lysa may also retain the services of a third
        party custodian to be registered for the customer’s securities in lieu
        of Lysa.
      </p>
      <p>
        In special cases, Lysa may also cause the customer’s securities to be
        included jointly in a document for several owners.
      </p>
      <p>
        In conjunction with the safe custody of the customer’s securities on
        joint accounts with a third party custodian, the customer’s rights are
        governed by the applicable national legislation.
      </p>
      <p>
        Whether the customer has a protected right of separation under property
        law in the event Lysa or the third party custodian is placed in
        bankruptcy or is the object of any other measure with equivalent legal
        effects may vary and is dependent on the applicable national
        legislation. In Sweden, there is a protected right of separation under
        property law provided the security is held separate from the securities
        of the third party custodian or Lysa.
      </p>
      <p>
        As a consequence of applicable foreign law, in conjunction with safe
        custody with a third party custodian abroad, it may be impossible to
        identify the customer’s securities separately from the securities of the
        third party custodian or Lysa. In such cases, there is a risk that the
        customer’s securities, in a bankruptcy situation or regarding other
        measures with equivalent legal effects, might be deemed to be included
        in the assets of the third party custodian or Lysa.
      </p>
      <p>
        Third party custodians or central securities depositories may have
        security in, or rights of set-off regarding, the customer’s securities
        and any claims related thereto.
      </p>
      <h3>A.3 Nominee registration, etc. </h3>
      <p>
        Lysa is entitled to take all measures in order to register the
        customer’s holdings in the custody account in the name of Lysa and to
        co-register the holding with other holdings in Lysa’s name for
        respective securities.
      </p>
      <p>
        In addition, Lysa is entitled to take all measures to manage, on behalf
        of the customer in the name of Lysa, to register received securities in
        its own name with anyone who in Sweden or abroad fulfills the
        registration information regarding financial instruments, e.g. Euroclear
        Sweden AB. In doing so, the customer's financial instruments may be
        registered together with other owners' financial instruments of the same
        type. Lysa may also include the customer’s financial instruments in a
        document shared by several owners. Lysa may instruct the third party
        custodian to register in the name of Lysa for the customer's financial
        instruments.
      </p>
    </section>

    <section>
      <h2>B. LYSA’S UNDERTAKINGS IN RESPECT OF SECURITIES</h2>
      <h3>B.1 General</h3>
      <p>
        Lysa undertakes on behalf of the customer to perform the measures set
        out in Sections B.2-B.4 in respect of securities received.
      </p>
      <p>
        Unless otherwise stated or specifically agreed, the undertakings in
        respect of Swedish (i.e. issued by an issuer with its registered office
        in Sweden) financial instruments registered with a central securities
        depository will apply commencing on the fifth bank day and, with respect
        to other Swedish securities and foreign financial instruments,
        commencing on the fifteenth bank day after the security has been
        received by Lysa. Accordingly, Lysa is not obliged to monitor deadlines
        that expire prior to that time.
      </p>
      <p>
        Lysa will take the measures stated in Sections B.2-B.4 provided that
        Lysa has received complete information in due time, through notice from
        the customer, a third party custodian, an issuer, agent (equivalent) or
        central securities depository, regarding the circumstance that gives
        rise to such measures.
      </p>
      <p>
        Lysa may refuse, in whole or in part, to take a measure if there are not
        enough funds or credit available for the measure on the account or where
        Lysa does not receive the information required for the measure or
        required to fulfill requirements pursuant to EU regulation, law or
        regulations.
      </p>
      <p>
        Lysa may take or refuse to take measures as stated in Section B.2 and
        B.3 where Lysa has specifically so stated in a notice to the customer
        and the customer has failed to provide other instructions within the
        reply period stated in the notice. The customer is subsequently bound by
        any measures Lysa has taken or refused to take in the same manner as if
        the customer itself had given instructions to carry out the measure.
      </p>
      <h3>B.2 Swedish financial instruments</h3>
      <p>
        In this Section B.2, <i>Swedish financial instruments</i> means
        financial instruments issued by an issuer whose registered office is
        located in Sweden and which are registered with a central securities
        depository permitted to conduct operations in Sweden and are admitted to
        trading in Sweden or are traded on a Swedish marketplace. With respect
        to other financial instruments the provisions set forth in Section B.3
        regarding foreign financial instruments shall apply instead.
      </p>
      <p>As regards shares, Lysa undertakes to:</p>
      <ol>
        <li>
          take receipt of dividends. Where the customer is entitled to choose
          between dividends in cash or in another form, Lysa may choose
          dividends in the form of cash, unless the customer has instructed
          otherwise;
        </li>
        <li>
          notify the customer of shares issued in conjunction with a rights
          issue and assist the customer with any measures requested in
          connection therewith. Lysa will sell any subscription right which not
          is exercised where Lysa deems it feasible and appropriate taking into
          considerations, amongst other things, the customer’s interests, unless
          the customer has instructed Lysa otherwise at the latest three bank
          days prior to the last day of trading of the subs right;
        </li>
        <li>
          notify the customer in conjunction with a public offer from an issuer
          regarding a transfer of financial instruments, as directed to the
          customer from the issuer (redemption/buyback) or from another party
          (buy-out) and, following a separate assignment from the customer,
          assist the customer with any measures requested. The equivalent
          applies in conjunction with public offers regarding the acquisition of
          financial instruments directed to the customer;
        </li>
        <li>
          notify the customer of any bonus issue regarding shares and, if
          possible, both make such purchases of such bonus share rights required
          in order for all bonus share rights vesting in the customer as a
          result of the shares registered in the custody account to be fully
          utilised in the bonus issue, and in the custody account register as
          many new shares as the customer then is entitled to;
        </li>
        <li>
          with respect to shares in CSD companies (companies affiliated to
          Central Securities Depository), notify the customer in the event
          compulsory redemption is requested;
        </li>
        <li>
          as far as concerns shares in CSD companies, in the event of a
          reduction of the share capital, or a redemption or liquidation, accept
          delivery of, or withdraw, principal and other amounts due and payable;
          and
        </li>
        <li>
          upon request by the customer, provide for the voting rights for
          nominee-registered shares to be registered with a central securities
          depository in the customer’s name. However, this requires that the
          instructions are received by Lysa not later than the fifth bank day
          prior to the final day for registration in the share register for the
          right to participate at a general meeting and that such voting rights
          registration may be effective in accordance with prevailing
          procedures. In the event a custody account is held by two or more
          persons jointly and the request does not state in whose name the
          shares shall be registered, voting rights registration of the shares
          shall be effected pro rata in relation to each holder’s part. Voting
          rights will not be registered by excess shares.
        </li>
      </ol>
      <p>
        As regards <i>warrants</i>, Lysa undertakes to notify the customer in
        due time for the final day for subscribing for shares and, following
        separate instructions from the customer, attempt to effect supplemental
        purchases of warrants and execute new subscription for shares. Unless
        otherwise agreed, not later than three bank days prior to the last day
        for trading in the warrants, Lysa shall sell any warrants which are not
        exercised, provided that Lysa deems it to be feasible and appropriate
        taking into consideration, among other things, the interests of the
        customer.
      </p>
      <p>
        As regards <i>purchase rights</i>, Lysa undertakes to notify the
        customer of the final day for applying to exercise purchase rights and,
        following separate instructions from the customer, attempt to effect
        supplemental purchases of purchase rights and arrange for the purchase
        application. Unless otherwise agreed, not later than three bank days
        prior to the last day for trading in the purchase rights, Lysa will sell
        any purchase rights which are not exercised, provided that Lysa deems it
        to be feasible and appropriate taking into consideration, among other
        things, the interests of the customer.
      </p>
      <p>
        As regards <i>redemption rights</i>, Lysa undertakes to notify the
        customer of the final day for redemption applications and, following
        separate instructions from the customer, attempt to effect supplemental
        purchases of redemption rights and arrange for the redemption
        application. Unless otherwise agreed, not later than three bank days
        prior to the last day for trading in the redemption rights, Lysa will
        sell any redemption rights which are not exercised, provided that Lysa
        deems it to be feasible and appropriate taking into consideration, among
        other things, the interests of the customer.
      </p>
      <p>
        As regards <i>Swedish depositary receipts</i> regarding foreign shares,
        Lysa undertakes to effect equivalent measures as for Swedish shares as
        set forth above provided that Lysa deems such measures to be feasible
        and appropriate taking into consideration, among other things, the
        interest of the customer.
      </p>
      <p>
        As regards <i>debentures and other debt instruments</i> which may be
        admitted to trading on the capital market, Lysa undertakes to:
      </p>

      <ol>
        <li>
          receive or draw interest as well as principal or other amounts which,
          in conjunction with redemption, drawing of lots or termination, have
          become due and payable after the debt instrument has been received;
        </li>
        <li>
          regarding premium bonds also draw winnings on premium bonds which,
          according to the draw list, have become due and payable in conjunction
          with a draw that occurred after the premium bonds had been received by
          Lysa and notify the customer regarding the winnings and assist the
          customer with any measures requested in connection therewith;
        </li>
        <li>
          as regards convertible securities and other convertible debt
          instruments notify the customer in due time of the final date for
          conversion and, following separate instructions from the customer,
          effect conversion;
        </li>
        <li>
          in conjunction with issues of debentures/debt instruments in which the
          customer has pre-emption rights, subscribe for such debentures/debt
          instruments only following separate instructions from the customer. In
          conjunction therewith, the provisions of Section B.2.2 b above
          applies;
        </li>
        <li>
          in conjunction with public offers from the issuer or another party for
          the transfer or debentures/debt instruments, as directed to the
          customer and in respect of which Lysa has received information in the
          manner stated above in Section B.1.3, notify the customer thereof and,
          following specific instructions from the customer, assist the customer
          with the measures requested in conjunction therewith; and
        </li>
        <li>
          notify the customer in conjunction with notice of early redemption and
          notices to attend meetings of creditors or similar proceedings
          regarding debentures/debt instruments which the customer holds and
          which Lysa has received information about in the manner stated above
          in Section B.1.3, notify the customer thereof and, following specific
          instructions from the customer, assist the customer with the measures
          requested in conjunction therewith.
        </li>
      </ol>
      <p>
        As regards financial instruments which are not covered sections above,
        such as warrants, futures and fund shares, Lysa’s obligations include,
        concerning fund shares to raise dividends and otherwise to perform such
        actions which Lysa considers feasible and appropriate taking into
        considerations, amongst other things, the customer’s interests, or which
        Lysa has undertaken to perform in a separate agreement with the
        customer.
      </p>
      <h3>B.3 Foreign financial instruments</h3>
      <p>
        As regards shares and debt instruments which are not covered by Section
        B.2 above and which are admitted to trading on a regulated market within
        the EEA or an equivalent market outside the EEA, Lysa’s obligations
        include to take the same measures as with respect to corresponding
        Swedish financial instruments where, in Lysa’s opinion, such are
        feasible and appropriate, taking into consideration, among other things,
        the interests of the customer.
      </p>
      <p>
        The customer should pay special attention to that as regards certain
        foreign shares registered with a central securities depository or the
        foreign equivalent, due to restrictions in their obligations,
        shareholders may have limited opportunities to exercise certain rights,
        e.g. to participate in general meetings and to participate in issues and
        receive information thereof.
      </p>
      <p>
        As regards foreign financial instruments other than those stated in the
        preceding paragraphs, Lysa’s obligations comprise only the obligation to
        perform measures that Lysa has undertaken in a specific written
        agreement with the customer.
      </p>
      <p>
        The customer is aware and acknowledges that the customer’s rights with
        respect to foreign financial instruments vary depending on the laws of
        the country by which such financial instruments are covered. The
        customer is also aware and acknowledges that, when a measure relates to
        foreign financial instruments, Lysa may apply different time limits
        vis-à-vis the customer than those applied in the country in which the
        measure is to be executed.
      </p>
      <h3>B.4 Swedish and foreign non-traded instruments</h3>
      <p>
        With respect to Swedish and foreign non-traded instruments, Lysa’s
        undertakings will be indicated in a specific written agreement with the
        customer.
      </p>
    </section>

    <section>
      <h2>C. Client funds</h2>
      <p>
        Lysa receives and reposits the customer's money with requirements of
        record keeping (client funds), which are placed in an account separated
        from Lysa's assets with a bank or credit institution (client funds
        account - <i>Swe. Klientmedelskonto</i>).
      </p>
      <p>
        Client funds consist of money in the currency used for the client funds
        account on which the customer's money is placed. Lysa can also receive
        money in other currencies under a specific agreement with the customer.
        Lysa reserves the right to decline the receipt of client funds made up
        of another currency which Lysa for any reason cannot reposit and/or
        manage.
      </p>
      <p>
        Client funds consist of advances for purchase orders or liquidation in
        the case of sales orders (equivalent), return on securities stored in
        the custody account and funds that the customer has transferred to Lysa
        or which Lysa has collected on behalf of the customer and which is
        connected to the custody account, unless the customer has specifically
        instructed Lysa of another deposit account.
      </p>
      <p>
        Lysa may from the client funds belonging to the customer utilise the
        amount ordered or approved by the customer as well as for every expense,
        cost or tax paid in advance which is associated with the custody
        account. In addition, Lysa may debit an amount corresponding to
        expenses, costs and fees for other services which Lysa has performed for
        the customer, as well as proceeds for other due and unpaid claims which
        Lysa may have from time to time against the customer.
      </p>
      <p>
        Funds in foreign currencies that Lysa pays to each recipient on behalf
        of the customer are, before the client funds account is charged or
        credited - if an agreement is not made for storage in another currency
        or in another account - converted to the currency used for the client
        funds account on which the customer's money are placed through Lysa’s
        provision according to Lysa's conversion rate applied at each time.
      </p>
      <p>
        Where the customer is in arrears in payment, Lysa is entitled to penalty
        interest on the due amount from the due date until payment is made at an
        interest rate which Lysa applies frome time to time.
      </p>
    </section>

    <section>
      <h2>D. Collateral</h2>
      <p>
        Provisions regarding collateral are to be found, in addition to this
        Section D, in the Custody Account Agreement in the section entitled “
        <i>Pledge</i>”.
      </p>
      <p>
        Income from collateral and other rights which are based on the
        collateral is also covered by the pledge and constitute collateral.
      </p>
      <p>
        Lysa’s obligations in its capacity as pledgee in respect of pledged
        property shall be no more extensive than as stated in these Custody
        Account Agreement, including these General Terms and Conditions.
      </p>
      <p>
        Collateral shall not constitute security for claims against the customer
        that Lysa has acquired or may acquire from a third party, where the
        aforementioned claims are neither connected to the customer’s trading in
        financial instruments nor have arisen through the customer’s cash
        account being debited.
      </p>
      <p>
        In the event the customer fails to perform its obligations to Lysa
        pursuant to the Custody Account Agreement or other obligations arising
        in connection with the customer’s transactions in financial instruments,
        Lysa may have recourse to the collateral in the manner Lysa deems
        appropriate. Lysa will act with care and notify the customer of such
        recourse in advance, where such is feasible and, in Lysa’s opinion, may
        take place without prejudice to Lysa in its capacity as pledge holder.
        Lysa is entitled to determine the order in which recourse shall be had
        to security provided. Pledged securities may be sold outside of the
        marketplace on which the security is traded or admitted for trading.
        Where the collateral consists of funds on a cash account with Lysa, Lysa
        may immediately obtain payment of the amount due and payable by
        withdrawing the amount from the account, without prior notice to the
        customer.
      </p>
      <p>
        The customer authorises Lysa to, either itself or through a third party
        appointed by Lysa, sign the customer’s name to the extent required in
        order to sell pledged property or otherwise to protect or exercise
        Lysa’s rights with respect to pledged property. Lysa is thus entitled to
        also open separate custody accounts and/or VP accounts at Euroclear
        Sweden AB or accounts in another account-based system. The customer may
        not revoke this authorisation as long as the pledge is in force.
      </p>
      <p>
        The customer may not, without Lysa’s prior consent in each individual
        case, pledge to a third party, or otherwise dispose of, property which
        is pledged pursuant to the Custody Account Agreement. Any use of such
        property by the customer entitles Lysa to terminate the agreement with
        immediate effect without taking into account the period of notice
        stipulated in Section G.7.
      </p>
      <p>
        Where the customer has pledged securities or cash registered on the
        custody account to a third party, Lysa may, notwithstanding that the
        customer has objected thereto, release/transfer securities and transfer
        funds to the custody account to the pledgee or a third party following
        instructions from the pledgee. An accounting of such release/transfer
        shall be provided to the customer.
      </p>
      <p>
        The customer may not, without Lysa’s prior consent in each individual
        case otherwise dispose of securities which are pledged pursuant to the
        Custody Account Agreement.
      </p>
    </section>

    <section>
      <h2>E. Trading in securities on the custody account</h2>
      <p>
        Pursuant to orders from the customer (also including what the customer
        and Lysa may have agreed in a special agreement on trading via
        electronic medium), Lysa executes purchases and sales of financial
        instruments pursuant to orders from the customer as well as other orders
        regarding trading in financial instruments on behalf of the customer.
        Following the execution and provided the conditions for this exist, Lysa
        reports these transactions on the customer’s custody account.
      </p>
      <p>
        The customer is aware and acknowledges that Lysa may record and retain
        telephone conversations and other electronic communications when the
        customer places orders with Lysa during contacts with the customer.
      </p>
      <p>
        By signing the agreement or by using Lysa’s services in terms of
        portfolio management, the customer is bound by Lysa’s at all time
        applicable Best execution policy and the conditions applicable from time
        to time governing trading in certain financial instruments. Upon request
        by the customer, Lysa shall provide the customer with the guidelines and
        terms and conditions covered by the first paragraph on Lysa’s website.
        In conjunction with trading in financial instruments, in addition to the
        guidelines and terms and conditions set forth in the first paragraph,
        other rules may apply as adopted by Lysa, Swedish or foreign issuers,
        trading venues, clearing organisations, or central securities
        depositories. Upon request from the customer, Lysa may provide the
        customer with information on where to find such information.
      </p>
      <p>
        Lysa is entitled to cancel purchases or sales where trades have been
        entered into on behalf of the customer to the extent the trade is
        cancelled by the relevant trading venue. The aforesaid right shall also
        apply in the event Lysa otherwise deems cancellation of a trade
        necessary, taking into consideration that a manifest error has been
        committed by Lysa, a market counterparty, or by the customer itself,
        where the customer through an order acts in breach of governing law or
        other regulations, or where the customer is otherwise in breach of
        generally accepted practices on the securities market.
      </p>
      <p>
        In the event Lysa or the customer is declared bankrupt or a company
        reorganisation order is issued with respect to the customer in
        accordance with the Company Reorganisation Act (SFS 1996:764), all
        outstanding obligations relating to trading in financial instruments
        between the parties shall be settled through final settlement of account
        as of the day on which such event occurred. The amounts due to a party
        following such final settlement of account shall become immediately due
        and payable.
      </p>
    </section>

    <section>
      <h2>F. Taxes, etc.</h2>
      <p>
        The customer is liable to pay taxes and other charges payable according
        to Swedish or foreign law, regulations or decisions by Swedish or
        foreign public authorities, treaties, or Lysa’s agreements with Swedish
        or foreign public authorities in respect of securities recorded on the
        customer’s custody account or arising from orders executed by Lysa on
        behalf of the customer, for example withholding tax on income, foreign
        withholding tax and Swedish withholding tax on dividends.
      </p>
      <p>
        As a consequence of Swedish or foreign law, a regulation or decision of
        a Swedish or foreign public authority, treaties, or Lysa’s agreement
        with a Swedish or foreign public authority, Lysa may be obliged to take
        measures on behalf of the customer concerning taxes or other fees based
        on dividends/interest/disposals/holdings concerning the customer’s
        securities. The customer must provide any information, including signing
        written documentation, which Lysa deems necessary in order to perform
        such obligation. If Lysa has paid tax on behalf of the customer, Lysa
        may debit the linked cash account in the equivalent amount as set out in
        Section C.3.
      </p>
      <p>
        If the right thereto exists and if Lysa considers it feasible and
        appropriate, Lysa will, upon specific instructions from the customer and
        taking into consideration, among other things, the interests of the
        customer, endeavour to assist in achieving a reduction or refund of tax
        and in collecting any balance owed to the customer by the tax
        authorities. Lysa may in this connection sign on behalf of the customer
        and disclose information, to the extent required, concerning the
        customer and the customer’s securities.
      </p>
    </section>

    <section>
      <h2>G. Other provisions</h2>
      <h3>G.1 Fees and costs, etc.</h3>
      <p>
        Fees for safe custody and the services under the Custody Account
        Agreement are payable as agreed in the Custody Account Agreement or as
        subsequently notified the customer in the manner stated in Section G.9.
      </p>
      <p>
        Information on the applicable fees and currencies which the fees are
        debited in is available on Lysa’s website.
      </p>
      <p>
        The customer shall compensate Lysa for its costs and expenses associated
        with Lysa’s services pursuant to the Custody Account Agreement and shall
        compensate Lysa for any costs and expenses relating to Lysa’s services
        under the Custody Account Agreement in maintaining and collecting Lysa’s
        claims against the customer.
      </p>

      <h3>G.2 Notices</h3>
      <h4>Notice from Lysa</h4>
      <p>
        Lysa may send notices to the customer via e-mail, other electronic
        communication or regular mail. Lysa can provide information via
        electronic message in Lysa’s internet service or to such an e-mail
        address or postal address that the customer has notified Lysa.
      </p>
      <p>
        The customer undertakes to notify Lysa of any change in contact details
        without delay by providing updated information when signed in to Lysa’s
        website.
      </p>
      <p>
        Notification by e-mail or other electronic communication is considered
        to have been received by the customer at the time of dispatch if sent to
        the e-mail address provided by the customer or via the message function
        to the customer on Lysa’s website. If a notice reaches the customer
        outside normal office hours, the message is deemed to have been
        delivered to the customer at the beginning of the following bank day in
        Sweden. Notification by regular mail will be deemed to be delivered on
        the second bank day after posting.
      </p>
      <h4>Notice to Lysa</h4>
      <p>
        The customer may send notices to Lysa via electronic communication. The
        contact details for such notice can be found at{" "}
        <a
          href="https://lysa.capital/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          lysa.capital/contact
        </a>
        , unless Lysa has requested a specific address to be used. Notification
        by e-mail is considered to have been received by Lysa at the time of
        dispatch. If a notice reaches Lysa outside normal office hours, the
        notice is deemed to have been received at the beginning of the following
        bank day in Sweden.
      </p>
      <p>
        For a complaint or notice of termination in the case of a commission
        assigned by a consumer which is a retail customer according to Lysa’s
        categorisation, the notification may be invoked if the customer can show
        that it was sent in an appropriate manner, even if it was delayed,
        distorted or did not arrive.
      </p>
      <p>
        However, if the customer has reason to believe that Lysa did not receive
        the message or that it has been distorted, the customer must resend the
        message to Lysa.
      </p>
      <h3>G.3 Reporting</h3>
      <p>
        Lysa will provide accounting for the custody account to the customer
        continuously when signed in to Lysa’s website. The same applies
        to an accounting of annual summaries and statement of earnings and tax
        deductions prior to the declaration.
      </p>
      <p>
        Lysa is not responsible for the accuracy of information regarding
        securities which is obtained by Lysa from external information
        providers.
      </p>
      <h3>G.4 Entries in the custody account made in error, etc.</h3>
      <p>
        In the event Lysa mistakenly registers securities on the customer’s
        custody account or mistakenly deposits funds on a linked cash account,
        Lysa is entitled to rectify the entry or deposit in question as soon as
        possible. Where the customer has used securities registered, or funds
        deposited, in error, the customer will return the securities to Lysa as
        soon as possible, or repay the funds received upon the sale or deposit.
        Where the customer fails to do so, Lysa is entitled to purchase the
        securities in question and debit the customer’s account for the amount
        required for payment.
      </p>
      <p>
        Lysa will immediately notify the customer that the correction has been
        effected according to the above. The customer is not entitled to bring
        any claim against Lysa as consequence of such errors.
      </p>
      <p>
        The provisions of the preceding paragraphs also applies where Lysa has
        otherwise registered securities or deposited funds on the custody
        account which were not intended to vest in the customer.
      </p>
      <h3>G.5 Limitation of Lysa’s obligations, etc.</h3>
      <h4>Technical problems</h4>
      <p>
        The customer is aware that there may be interruptions in the customer’s,
        Lysa’s or other’s computer systems (hardware or software),
        telecommunications or electrical systems or similar defaults which may
        entail that the services under the Custody Account Agreement cannot be
        performed as agreed, for example:
      </p>
      <ul>
        <li>that the customer cannot make a deposit;</li>
        <li>that the customer cannot make a withdrawal;</li>
        <li>that the customer cannot change its investment guidelines; or</li>
        <li>
          that information which Lysa provides is not available for the
          customer, or is incorrect.
        </li>
      </ul>
      <p>
        Lysa is only liable for damage or loss which the customer has been
        caused as a result of the above technical problems if Lysa has been
        negligent. For damage caused during commission assignments in relation
        to consumers, Lysa must prove that the damage did not occur due to
        Lysa’s negligence. Lysa is only responsible for indirect damage which
        have been caused by Lysa’s gross negligence except in the case of
        assignments submitted by a consumer where the indirect damage was caused
        by Lysa’s negligence.
      </p>
      <p>
        If the customer detects any faults or interruption as specified in this
        section, the customer is obliged to immediately inform Lysa. If the
        customer fails to do so despite the fact that the customer has
        discovered or should have discovered such a fault or such an
        interruption, the customer loses the right to claim compensation or
        claim any other sanction based on the default or interruption.
      </p>
      <p>
        In the case of a commission assigned by a consumer as a retail customer,
        a complaint in the above cases must be made within a reasonable time
        from the time the customer discovered or should have discovered the
        default or interruption.
      </p>
      <h4>Limitation of liability </h4>
      <p>
        Lysa is not liable for loss or damage due to Swedish or foreign
        legislation, acts of a Swedish or foreign public authority, acts of war,
        strikes, blockades, boycotts, lockouts, epidemic, pandemic, quarantine
        or other similar events. The reservation in the case of strikes,
        blockades, boycotts, and lockouts shall apply notwithstanding that Lysa
        itself is subject to or takes such measures.
      </p>
      <p>
        Lysa is not liable for any loss or damage caused by any, Swedish or
        foreign, trading venue, third party custodian, central securities
        depository, clearing organisation or other party that provides
        equivalent services, or by an agent that has been engaged by Lysa with
        due care or that has been recommended by the customer. The same applies
        to loss or damage caused by the insolvency of any of the organisations
        or agents stated above. Lysa is not liable for any loss or damage
        incurred by the customer or any third party as a consequence of
        restrictions on the rights of disposition that may be applied against
        Lysa with respect to financial instruments. Nor is Lysa responsible for
        any tax consequences of the investments made under the Agreement.
      </p>
      <p>
        Lysa is only liable for damage or loss incurred in other circumstances
        if Lysa has been negligent. For damage caused during commission
        assignments in relation to consumers, Lysa must prove that the damage
        did not occur due to Lysa’s negligence. Lysa is only responsible for
        indirect damage caused by Lysa’s gross negligence except in the case of
        assignments submitted by a consumer where the indirect damage was caused
        by Lysa’s negligence.
      </p>
      <p>
        If Lysa, as a consequence of an event referred to in the first
        paragraph, is prevented from carrying out measures in accordance with
        these provisions or purchase or sale orders of financial instruments, in
        whole or in part, such measure or action may be postponed until the
        obstacle has ceased. If Lysa is prevented from executing or receiving
        payment/delivery as a result of such a circumstance, neither Lysa nor
        the customer shall be obliged to pay interest.
      </p>
      <p>
        The above provisions shall apply unless otherwise is prescribed by the
        Swedish Financial Instruments Accounts Act (1988:1479).
      </p>
      <h3>G.6 Refusal of assignments</h3>
      <p>
        Lysa is entitled to refuse assignments in accordance with the Custody
        Account Agreement regarding Swedish financial instruments registered
        with a central securities depository, for example Euroclear Sweden AB,
        within five bank days and for other Swedish securities and for foreign
        financial instruments within fifteen bank days following Lysa’s receipt
        of such securities. In such a case, what is stated in Section G.7 about
        transfer upon termination is applicable on the relevant security.
      </p>
      <h3>G.7 Termination</h3>
      <p>
        Lysa may terminate the agreement effective two months after the customer
        is deemed to have received the notice in accordance with Section G.2.
      </p>
      <p>
        The customer may terminate the agreement effective immediately when
        signed in to Lysa’s website, subject to the settlement of any
        outstanding transactions. The customer may also terminate the Agreement
        in the manner set forth in Section G.2 effective one month after the
        message in accordance with the same section is deemed to have been
        received by Lysa. Consumers can also terminate the agreement via Lysa’s
        customer service after having properly identified themselves with the
        verification method that Lysa accepts at each time, effective after one
        month.
      </p>
      <p>
        The Custody Account Agreement will be terminated, having regard to the
        provisions below governing the settlement of the parties’ obligations,
        no later than the date on which the Portfolio Management Agreement
        between the parties expire.
      </p>
      <p>
        Upon termination of the Custody Account Agreement, the parties will
        immediately settle all their obligations under the Custody Account
        Agreement. However, the Agreement will remain in force in applicable
        parts until a party has performed all of its obligations to the
        counterparty. Furthermore, each party may on the same terms as stated
        above, terminate the Custody Account Agreement concerning a specific
        security.
      </p>
      <p>
        Notwithstanding the provisions above, a party may terminate the Custody
        Account Agreement with immediate effect if the other party is in
        material breach of the Agreement. In such cases, each breach of contract
        which has not been remedied as soon as possible, notwithstanding a
        demand to do so, shall be deemed to constitute a material breach of
        contract. Lysa may also terminate the Agreement with immediate effect
        if:
      </p>
      <ul>
        <li>
          it is due to securities law or other restrictions in any country;
        </li>
        <li>
          the customer goes bankrupt, has been subject to a company
          reorganisation, has been granted a public arrangement and negotiation
          with creditors, or has been found in distraint and has no assets to
          pay off its debts;
        </li>
        <li>
          the customer has canceled the its payments or is otherwise considered
          to be insolvent;
        </li>
        <li>
          the customer has acted in violation of applicable law through
          transactions with Lysa; or
        </li>
        <li>
          as a consequence of any changes in the customer’s tax domicile, Lysa
          is no longer able to perform its obligation to take measures regarding
          tax on behalf of the customer in accordance with the provisions in
          Section F or where the performance of such obligation is rendered
          significantly more onerous.
        </li>
      </ul>
      <p>
        Upon termination of the Custody Account Agreement, Lysa will transfer to
        the customer all securities and money in the custody account. The
        customer shall provide Lysa with instructions about the transfer. If
        such instructions are not provided within sixty calendar days after the
        date of termination of the Custody Account Agreement, or if the transfer
        cannot be made in accordance with the instructions provided by the
        customer, Lysa may sell the relevant securities and transfer the
        settlement to the customer's pre-registered withdrawal account.
      </p>
      <p>
        Fund units in Lysa’s funds must be sold upon termination of the Custody
        Account Agreement. Upon termination of the Custody Account Agreement,
        Lysa has the right to sell the customer’s holdings in Lysa’s funds and
        pay the settlement to the customer’s pre-registered withdrawal account.
        If the customer does not have a pre-registered withdrawal account, the
        customer must submit instructions to Lysa about the transfer.
      </p>
      <h3>
        G.8 Limitation of obligations and relationship to other agreements
      </h3>
      <p>
        Lysa is not obliged to take any measures other than as stated in the
        Custody Account Agreement unless specifically agreed and recorded in a
        written agreement. The express terms of such specific agreement shall
        prevail over the Custody Account Agreement.
      </p>
      <h3>
        G.9 Amendments to the Custody Account Agreement and changes in fees{" "}
      </h3>
      <p>
        Amendments to the Custody Account Agreement, including these General
        Terms and Conditions, and changes to Lysa’s fees take effect two months
        after the customer is deemed to have received notice of the amendment or
        change pursuant to Section G.2. The customer is entitled to terminate
        the Custody Account Agreement within the specified period with immediate
        effect if it does not accept the amendment/change.
      </p>
      <p>
        Lysa may implement an amendment or change that is considered
        insignificant to the customer without the customer being informed in
        accordance with the above.
      </p>
      <p>
        Amendments and additions to the Agreement must be made in writing to be
        valid.
      </p>
      <h3>G.10 Information about scheduled maintenance of Lysa’s website </h3>
      <p>
        When closing Lysa’s website for scheduled maintenance etc., Lysa will
        inform the customer well in advance.
      </p>
      <h3>G.11 Assignment</h3>
      <p>
        Lysa may at any time assign its rights or obligations under the Custody
        Account Agreement to companies within the same corporate group as the
        company or other company as a consequence of any procedure under company
        law.
      </p>
      <h3>G.12 Governing law</h3>
      <p>
        The interpretation and the application of the Custody Account Agreement
        and these General Terms and Conditions is governed by Swedish law.
      </p>
    </section>
  </article>
);

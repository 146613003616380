import React from "react";

export const InformationAboutAttributesAndRisksSV = () => (
  <article className="lysa-doc information-about-attributes-and-risks">
    <header>
      <h1>
        Information om egenskaper och risker avseende finansiella instrument
      </h1>
      <p>v. 2020:1</p>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>Du som kund måste vara införstådd med bl.a. följande:</p>
      <ul>
        <li>
          att placeringar eller andra positioner i finansiella instrument sker
          på kundens egen risk, och
        </li>
        <li>
          att du som kund själv noga måste sätta dig in i värdepappersbolagets
          allmänna villkor samt information om aktuellt finansiellt instrument,
          dess egenskaper och risker.
        </li>
      </ul>
    </section>

    <section>
      <h2>1. Handel med finansiella instrument</h2>
      <p>
        Handel med finansiella instrument, d.v.s. bl.a. aktier i aktiebolag och
        motsvarande andelsrätter i andra typer av företag, obligationer,
        depåbevis, fondandelar, penningmarknadsinstrument, finansiella
        derivatinstrument eller andra sådana värdepapper utom betalningsmedel
        som kan bli föremål för handel på kapitalmarknaden, sker i huvudsak i
        organiserad form vid en handelsplats. Handeln sker genom de
        värdepappersföretag som deltar i handeln vid handelsplatsen. Som kund
        måste du normalt kontakta ett sådant värdepappersföretag för att köpa
        eller sälja finansiella instrument.
      </p>
      <h3>1.1 Handelsplatser</h3>
      <p>
        Med handelsplatser avses reglerad marknad, handelsplattform
        (Multilateral Trading Facility, MTF) och systematisk internhandlare (SI)
        samt där det sker handel hos värdepappersföretagen.
      </p>
      <p>
        På en reglerad marknad handlas olika typer av finansiella instrument.
        När det gäller aktier kan endast aktier i publika bolag noteras och
        handlas på en reglerad marknad och det ställs stora krav på sådana
        bolag, bl.a. avseende bolagets storlek, verksamhetshistoria,
        ägarspridning och offentlig redovisning av bolagets ekonomi och
        verksamhet.
      </p>
      <p>
        En handelsplattform (MTF) kan beskrivas som ett handelssystem som
        organiseras och tillhandahålls av en börs eller ett värdepappersföretag.
        Det ställs typiskt sett lägre krav, i form av t.ex. informationsgivning
        och verksamhetshistoria, på de finansiella instrument som handlas på en
        handelsplattform jämfört med finansiella instrument som handlas på en
        reglerad marknad.
      </p>
      <p>
        En systematisk internhandlare är ett värdepappersföretag som på ett
        organiserat, frekvent och systematiskt sätt handlar för egen räkning
        genom att utföra kundorder utanför en reglerad marknad eller en
        handelsplattform. En systematisk internhandlare är skyldig att
        offentliggöra marknadsmässiga bud på köp- och/eller säljpriser för
        likvida aktier som handlas på en reglerad marknad och för vilka den
        systematiska internhandlaren bedriver systematisk internhandel.
      </p>
      <p>
        Handel kan även äga rum genom ett värdepappersföretag utan att det är
        fråga om systematisk internhandel, mot institutets egna lager eller mot
        annan av institutets kunder.
      </p>
      <p>
        I Sverige finns i dagsläget två reglerade marknader, dels den reglerade
        marknad som drivs av OMX Stockholm AB (nedan ”Stockholmsbörsen”), dels
        den som drivs av Nordic Growth Market NGM AB (nedan ”NGM”). Härutöver
        sker organiserad handel på andra handelsplatser, t.ex. First North,
        Nordic MTF och Aktietorget (handelsplattformar) samt på
        värdepappersföretagens egna listor.
      </p>
      <p>
        Handeln på reglerade marknader, handelsplattformar och andra
        handelsplatser utgör en andrahandsmarknad för finansiella instrument som
        ett bolag redan givit ut (emitterat). Om andrahandsmarknaden fungerar
        väl, d.v.s. det är lätt att hitta köpare och säljare och det fortlöpande
        noteras anbudskurser från köpare och säljare samt avslutskurser
        (betalkurser) från gjorda affärsavslut, har även bolagen en fördel genom
        att det blir lättare att vid behov emittera nya instrument och därigenom
        få in mer kapital till bolagets verksamhet. Förstahandsmarknaden, eller
        primärmarknaden, kallas den marknad där köp/teckning av nyemitterade
        instrument sker.
      </p>
      <h3>1.2 Handels-/noteringslistor</h3>
      <p>
        När det gäller aktier indelar vanligen handelsplatser aktierna i olika
        listor, vilka publiceras t.ex. på handelsplatsens hemsida, i
        dagstidningar och andra medier. Avgörande för på vilken lista ett bolags
        aktier handlas kan vara bolagets börsvärde (t.ex. Stockholmsbörsens
        Large-, Mid- och Small cap). De mest omsatta aktierna kan också finnas
        på en särskild lista. Vissa värdepappersföretag publicerar också egna
        listor över finansiella instrument som handlas via institutet, kurser
        till vilka instrumenten handlas etc., t.ex. via institutets hemsida.
        Aktier på listor med höga krav och hög omsättning anses normalt kunna
        innebära en lägre risk än aktier på andra listor.
      </p>
      <p>
        Information om kurser m.m. avseende aktier såväl som andra typer av
        finansiella instrument, exempelvis fondandelar, optioner och
        obligationer, publiceras också regelbundet via t.ex. handelsplatsernas
        hemsidor, i dagstidningar och andra medier.
      </p>
    </section>
    <section>
      <h2>
        2. Risker med finansiella instrument och handel med finansiella
        instrument
      </h2>
      <h3>2.1 Allmänt om risker</h3>
      <p>
        Finansiella instrument kan ge avkastning i form av utdelning (aktier och
        fonder) eller ränta (räntebärande instrument). Härutöver kan priset
        (kursen) på instrumentet öka eller minska i förhållande till priset när
        placeringen gjordes. I den fortsatta beskrivningen inräknas i ordet
        placering även eventuella negativa positioner (negativa innehav) som
        tagits i instrumentet. Den totala avkastningen är summan av
        utdelning/ränta och prisförändring på instrumentet.
      </p>
      <p>
        Vad placeraren eftersträvar är naturligtvis en total avkastning som är
        positiv, d.v.s. som ger vinst, helst så hög som möjligt. Men det finns
        också en risk att den totala avkastningen blir negativ d.v.s. att det
        blir en förlust på placeringen. Risken för förlust varierar med olika
        instrument. Vanligen är chansen till vinst på en placering i ett
        finansiellt instrument kopplad till risken för förlust. Ju längre tiden
        för innehavet av placeringen är desto större är vinstchansen respektive
        förlustrisken. I placeringssammanhang används ibland ordet risk som
        uttryck för såväl förlustrisk som vinstchans. I den fortsatta
        beskrivningen används dock ordet risk enbart för att beteckna
        förlustrisk. Det finns olika sätt att placera som minskar risken.
        Vanligen anses det bättre att inte placera i ett enda eller ett fåtal
        finansiella instrument utan att i stället placera i flera olika
        finansiella instrument. Dessa instrument bör då erbjuda en spridning av
        riskerna och inte samla risker som kan utlösas samtidigt. En spridning
        av placeringarna till utländska marknader minskar normalt också risken i
        den totala portföljen, även om det vid handel med utländska finansiella
        instrument tillkommer en valutarisk.
      </p>
      <p>
        Placeringar i finansiella instrument är förknippade med ekonomisk risk,
        vilket närmare kommer att beskrivas i denna information. Kunden svarar
        själv för risken och måste därför själv hos anlitat värdepappersföretag
        - eller genom sitt kapitalförvaltande ombud - skaffa sig kännedom om de
        villkor, i form av allmänna villkor, prospekt eller liknande, som gäller
        för handel med sådana instrument och om instrumentens egenskaper och
        risker förknippade därmed. Kunden måste också fortlöpande bevaka sina
        placeringar i sådana instrument. Detta gäller även om kunden fått
        individuell rådgivning vid placeringstillfället. Kunden bör i eget
        intresse vara beredd att snabbt vidta åtgärder, om detta skulle visa sig
        påkallat, exempelvis genom att avveckla placeringar som utvecklas
        negativt eller att ställa ytterligare säkerhet vid placeringar som
        finansierats med lån och där säkerhetsvärdet minskat. Det är också
        viktigt att beakta den risk det kan innebära att handla med finansiella
        instrument på en annan handelsplats än en reglerad marknad, där kraven
        som ställs generellt är lägre.
      </p>

      <h3>2.2. Olika typer av riskbegrepp m.m.</h3>
      <p>
        I samband med den riskbedömning som bör ske då du som kund gör en
        placering i finansiella instrument, och även fortlöpande under
        innehavstiden, finns en mängd olika riskbegrepp och andra faktorer att
        beakta och sammanväga. Nedan följer en kort beskrivning av några av de
        vanligaste riskbegreppen.
      </p>
      <ul>
        <li>
          Marknadsrisk – risken att marknaden i sin helhet, eller viss del därav
          där du som kund har din placering, t.ex. den svenska aktiemarknaden,
          går ner.
        </li>
        <li>
          Kreditrisk – risken för bristande betalningsförmåga hos exempelvis en
          emittent eller en motpart.
        </li>
        <li>
          Prisvolatilitetsrisk – risken för stora svängningar i kursen/priset på
          ett finansiellt instrument påverkar placeringen negativt.
        </li>
        <li>
          Kursrisk – risken att kursen/priset på ett finansiellt instrument går
          ner.
        </li>
        <li>
          Skatterisk – risken att skatteregler och/eller skattesatser är oklara
          eller kan komma att ändras.
        </li>
        <li>
          Valutarisk – risken att en utländsk valuta till vilken ett innehav är
          relaterat (exempelvis fondandelar i en fond som placerar i amerikanska
          värdepapper noterade i USD) försvagas.
        </li>
        <li>
          Hävstångseffektsrisk – konstruktionen av derivatinstrument som gör att
          det finns en risk att prisutvecklingen på den underliggande egendomen
          får ett större negativt genomslag i kursen/priset på
          derivatinstrumentet.
        </li>
        <li>
          Legal risk – risken att relevanta lagar och regler är oklara eller kan
          komma att ändras.
        </li>
        <li>
          Bolagsspecifik risk – risken att ett visst bolag går sämre än
          förväntat eller drabbas av en negativ händelse och de finansiella
          instrument som är relaterade till bolaget därmed kan falla i värde.
        </li>
        <li>
          Branschspecifik risk – risken att en viss bransch går sämre än
          förväntat eller drabbas av en negativ händelse och de finansiella
          instrument som är relaterade till bolag i branschen därmed kan falla i
          värde.
        </li>
        <li>
          Likviditetsrisk – risken att du inte kan sälja eller köpa ett
          finansiellt instrument vid en viss önskad tidpunkt på grund av att
          omsättningen i det finansiella instrumentet är låg.
        </li>
        <li>
          Ränterisk – risken att det finansiella instrument du placerat i
          minskar i värde pga. förändringar i marknadsräntan.
        </li>
      </ul>
    </section>

    <section>
      <h2>3. Aktier och aktierelaterade instrument</h2>
      <h3>3.1 Allmänt om aktier</h3>
      <h4>3.1.1 Aktier och aktiebolag </h4>
      <p>
        Aktier i ett aktiebolag ger ägaren rätt till en andel av bolagets
        aktiekapital. Går bolaget med vinst lämnar bolaget vanligen utdelning på
        aktierna. Aktier ger också rösträtt på bolagsstämman, som är det högsta
        beslutande organet i bolaget. Ju fler aktier ägaren har desto större
        andel av kapitalet, utdelningen och rösterna har aktieägaren. Beroende
        på vilken serie aktierna tillhör kan rösträtten variera. Det finns två
        slag av bolag, publika och privata. Endast publika bolag får låta
        aktierna handlas på en handelsplats.
      </p>
      <h4>3.1.2 Aktiekursen </h4>
      <p>
        Kursen (priset) på en aktie påverkas i första hand av utbudet respektive
        efterfrågan på den aktuella aktien vilket i sin tur, åtminstone på lång
        sikt, styrs av bolagets framtidsutsikter. En aktie upp- eller
        nedvärderas främst grundat på investerarnas analyser och bedömningar av
        bolagets möjligheter att göra framtida vinster. Den framtida
        utvecklingen i omvärlden av konjunktur, teknik, lagstiftning, konkurrens
        osv. avgör hur efterfrågan blir på bolagets produkter eller tjänster och
        är därför av grundläggande betydelse för kursutvecklingen på bolagets
        aktier.
      </p>
      <p>
        Det aktuella ränteläget spelar också en stor roll för prissättningen.
        Stiger marknadsräntorna ger räntebärande finansiella instrument, som
        samtidigt ges ut (nyemitteras), bättre avkastning. Normalt sjunker då
        kurserna på aktier som regelbundet handlas liksom på redan utelöpande
        räntebärande instrument. Skälet är att den ökade avkastningen på
        nyemitterade räntebärande instrument relativt sett blir bättre än
        avkastningen på aktier, liksom på utelöpande räntebärande instrument.
        Dessutom påverkas aktiekurserna negativt av att räntorna på bolagets
        skulder ökar när marknadsräntorna går upp, vilket minskar vinstutrymmet
        i bolaget.
      </p>
      <p>
        Också andra till bolaget direkt knutna förhållanden, t.ex. förändringar
        i bolagets ledning och organisation, produktionsstörningar m m kan
        påverka bolaget negativt. Aktiebolag kan i värsta fall gå så dåligt att
        de måste försättas i konkurs. Aktiekapitalet dvs. aktieägarnas insatta
        kapital är det kapital som då först används för att betala bolagets
        skulder. Detta leder oftast till att aktierna i bolaget blir värdelösa.
      </p>
      <p>
        Även kurserna på vissa större utländska reglerade marknader eller
        handelsplatser inverkar på kurserna i Sverige, bl.a. därför att flera
        svenska aktiebolag är noterade även på utländska marknadsplatser och
        prisutjämningar sker mellan marknadsplatserna. Kursen på aktier i bolag
        som tillhör samma branschsektor påverkas ofta av förändringar i kursen
        hos andra bolag inom samma sektor. Denna påverkan kan även gälla för
        bolag i olika länder.
      </p>
      <p>
        Aktörerna på marknaden har olika behov av att placera kontanter (likvida
        medel) eller att få fram likvida medel. Dessutom har de ofta olika
        mening om hur kursen borde utvecklas. Dessa förhållanden, som även
        innefattar hur bolaget värderas, bidrar till att det finns såväl köpare
        som säljare. Är placerarna däremot samstämmiga i sina uppfattningar om
        kursutvecklingen vill de antingen köpa och då uppstår ett köptryck från
        många köpare, eller också vill de sälja och då uppstår ett säljtryck
        från många säljare. Vid köptryck stiger kursen och vid säljtryck faller
        den.
      </p>
      <p>
        Omsättningen, dvs hur mycket som köps och säljs av en viss aktie,
        påverkar i sin tur aktiekursen. Vid hög omsättning minskar skillnaden,
        även kallad spread, mellan den kurs köparna är beredda att betala
        (köpkursen) och den kurs säljarna begär (säljkursen). En aktie med hög
        omsättning, där stora belopp kan omsättas utan större inverkan på
        kursen, har en god likviditet och är därför lätt att köpa respektive att
        sälja. Bolagen på de reglerade marknadernas listor har ofta en högre
        likviditet. Olika aktier kan under dagen eller under längre perioder
        uppvisa olika rörlighet (volatilitet) i kurserna, dvs upp- och nedgångar
        samt storlek på kursförändringarna.
      </p>
      <p>
        De kurser till vilka aktierna har handlats (betalkurser), såsom
        högst/lägst/senast betalt under dagen samt sist noterade köp/säljkurser
        och vidare uppgift om handlad volym i kronor publiceras bl.a. i de
        flesta större dagstidningarna och på olika internetsidor som upprättas
        av marknadsplatser, värdepappersföretag och medieföretag såsom text-TV.
        Aktualiteten i dessa kursuppgifter kan variera beroende på sättet de
        publiceras på.
      </p>

      <h4>3.1.3 Olika aktieserier</h4>
      <p>
        Aktier finns i olika serier, vanligen A- och B-aktier vilket normalt har
        med rösträtten att göra. A-aktier ger normalt en röst medan B-aktier ger
        en begränsad rösträtt, oftast en tiondels röst. Skillnaderna i rösträtt
        beror bl.a. på att man vid ägarspridning vill värna om de ursprungliga
        grundarnas eller ägarnas inflytande över bolaget genom att ge dem en
        starkare rösträtt. Nya aktier som ges ut får då ett lägre röstvärde än
        den ursprungliga A-serien och betecknas med B, C eller D etc.
      </p>

      <h4>3.1.4 Kvotvärde, split och sammanläggning av aktier</h4>
      <p>
        En akties kvotvärde är den andel som varje aktie representerar av
        bolagets aktiekapital. En akties kvotvärde erhålls genom att dividera
        aktiekapitalet med det totala antalet aktier. Ibland vill bolagen ändra
        kvotvärdet, t.ex. därför att kursen, dvs marknadspriset på aktien, har
        stigit kraftigt. Genom att dela upp varje aktie på två eller flera
        aktier genom en s.k. split, minskas kvotvärdet och samtidigt sänks
        kursen på aktierna. Aktieägaren har dock efter en split sitt kapital
        kvar oförändrat, men detta är fördelat på fler aktier som har ett lägre
        kvotvärde och en lägre kurs per aktie.
      </p>
      <p>
        Omvänt kan en sammanläggning av aktier (omvänd split) göras om kursen
        sjunkit kraftigt. Då slås två eller flera aktier samman till en aktie.
        Aktieägaren har dock efter en sammanläggning av aktier samma kapital
        kvar, men detta är fördelat på färre aktier som har ett högre kvotvärde
        och en högre kurs per aktie.
      </p>
      <h4>3.1.5 Marknadsintroduktion, privatisering och uppköp</h4>
      <p>
        Marknadsintroduktion innebär att aktier i ett bolag introduceras på
        aktiemarknaden, dvs upptas till handel på en reglerad marknad eller en
        MTF-plattform. Allmänheten erbjuds då att teckna(köpa) aktier i bolaget.
        Oftast rör det sig om ett befintligt bolag, som inte tidigare handlats
        på en reglerad marknad eller annan handelsplats, där ägarna beslutat att
        vidga ägarkretsen och underlätta handeln i bolagets aktier. Om ett
        statligt ägt bolag introduceras på marknaden kallas detta för
        privatisering.
      </p>
      <p>
        Uppköp tillgår i regel så att någon eller några investerare erbjuder
        aktieägarna i ett bolag att på vissa villkor sälja sina aktier. Om
        uppköparen får in 90 % eller mer av antalet aktier i det uppköpta
        bolaget, kan uppköparen begära tvångsinlösen av kvarstående aktier från
        de ägare som ej accepterat uppköpserbjudandet. Dessa aktieägare är då
        tvungna att sälja sina aktier till uppköparen mot en ersättning som
        fastställs genom ett skiljedomsförfarande.
      </p>
      <h4>3.1.6 Emissioner</h4>
      <p>
        Om ett aktiebolag vill utvidga sin verksamhet krävs ofta ytterligare
        aktiekapital. Detta skaffar bolaget genom att ge ut nya aktier genom
        nyemission. Oftast får de gamla ägarna teckningsrätter som ger företräde
        att teckna aktier i en nyemission. Antalet aktier som får tecknas sätts
        normalt i förhållande till hur många aktier ägaren tidigare hade.
        Tecknaren måste betala ett visst pris (emissionskurs), oftast lägre än
        marknadskursen, för de nyemitterade aktierna. Direkt efter det att
        teckningsrätterna - som normalt har ett visst marknadsvärde - avskiljts
        från aktierna sjunker vanligen kursen på aktierna, samtidigt ökar
        antalet aktier för de aktieägare som tecknat. De aktieägare som inte
        tecknar kan, under teckningstiden som oftast pågår några veckor, sälja
        sina teckningsrätter på den marknadsplats där aktierna handlas. Efter
        teckningstiden förfaller teckningsrätterna och blir därmed obrukbara och
        värdelösa.
      </p>
      <p>
        Aktiebolag kan även genomföra s.k. riktad nyemission, vilket genomförs
        som en nyemission men enbart riktad till en viss krets investerare.
        Aktiebolag kan även genom s.k. apportemission ge ut nya aktier för att
        förvärva andra bolag, affärsrörelser eller tillgångar i annan form än
        pengar. Såväl vid riktad nyemission som vid apportemission sker s.k.
        utspädning av befintliga aktieägares andel av antalet röster och
        aktiekapital i bolaget, men antalet innehavda aktier och marknadsvärdet
        på det placerade kapitalet påverkas normalt inte.
      </p>
      <p>
        Om tillgångarna eller de reserverade medlen i ett aktiebolag har ökat
        mycket i värde, kan bolaget föra över en del av värdet till sitt
        aktiekapital genom en s.k. fondemission. Vid fondemission tas det hänsyn
        till antalet aktier som varje aktieägare redan har. Antalet nya aktier
        som tillkommer genom fondemissionen sätts i förhållande till hur många
        aktier ägaren tidigare hade. Genom fondemissionen får aktieägaren fler
        aktier, men ägarens andel av bolagets ökade aktiekapital förblir
        oförändrad. Kursen på aktierna sänks vid en fondemission, men genom
        ökningen av antalet aktier bibehåller aktieägaren ett oförändrat
        marknadsvärde på sitt placerade kapital. Ett annat sätt att genomföra
        fondemission är att bolaget skriver upp kvotvärdet på aktierna.
        Aktieägaren har efter uppskrivning ett oförändrat antal aktier och
        marknadsvärde på sitt placerade kapital.
      </p>

      <h3>3.2 Allmänt om aktierelaterade instrument</h3>
      <p>
        Nära knutna till aktier är, aktieindexobligationer, depåbevis,
        konvertibler, aktie- och aktieindexoptioner, aktie- och
        aktieindexterminer, warranter samt hävstångscertifikat.
      </p>
      <h4>3.2.1 Indexobligationer/Aktieindexobligationer</h4>
      <p>
        Indexobligationer/aktieindexobligationer är obligationer där
        avkastningen istället för ränta är beroende t.ex. av ett aktieindex.
        Utvecklas indexet positivt följer avkastningen med. Vid en negativ
        indexutveckling kan avkastningen utebli. Obligationen återbetalas dock
        alltid med sitt nominella belopp, som kan vara lägre än
        investeringsbeloppet, på inlösendagen och har på så sätt en begränsad
        förlustrisk jämfört med t.ex. aktier och fondandelar. Risken med en
        placering i en aktieindexobligation kan, förutom eventuellt erlagd
        överkurs och kostnader, definieras som den alternativa avkastningen,
        dvs. den avkastning investeraren skulle ha fått på det investerade
        beloppet med en annan placering.
      </p>
      <p>
        Indexobligationer kan ha olika benämningar som aktieindexobligationer,
        SPAX, aktieobligationer, kreditkorgsobligationer,
        räntekorgsobligationer, valutakorgsobligationer osv. beroende på vilket
        underliggande tillgångsslag som bestämmer obligationens avkastning. När
        man talar om indexobligationer så benämns dessa ofta också som
        kapitalskyddade produkter. Med detta begrepp avses att beskriva, som
        ovan nämnts, att oavsett om produkten ger avkastning eller ej så
        återbetalas det nominella beloppet, vanligtvis investeringsbeloppet
        minskat med eventuellt erlagd överkurs.
      </p>
      <p>
        OBS att kapitalskyddet inte gäller om emittenten försätts i konkurs
        eller blir föremål för företagsrekonstruktion med offentligt ackord
        (nedsättning av fordringsbeloppet). (Banker, andra kreditinstitut och
        värdepappersbolag kan i stället bli föremål för resolution. Det innebär
        att staten kan ta kontroll över institutet och dess förluster kan komma
        att hanteras genom att dess aktie- och fordringsägare får sina innehav
        nedskrivna och/eller för fordringsägare sina fordringar konverterade
        till aktieinnehav (s.k skuldnedskrivning eller bail-in).)
      </p>
      <h4>3.2.2 Depåbevis</h4>
      <p>
        Svenskt Depåbevis är ett bevis om rätt till utländska aktier, som
        utgivaren av beviset förvarar/innehar för innehavarens räkning.
        Depåbevis handlas precis som aktier på en reglerad marknad eller
        handelsplats och prisutvecklingen följer normalt prisutvecklingen på den
        utländska marknadsplats där aktien handlas. Utöver de generella risker
        som finns vid handel med aktier eller andra typer av andelsrätter bör
        ev. valutakursrisk beaktas.
      </p>
      <h4>3.2.3 Konvertibler</h4>
      <p>
        Konvertibler (konverteringslån eller konvertibler) är räntebärande
        värdepapper (lån till utgivaren/emittenten av konvertibeln) som inom en
        viss tidsperiod kan bytas ut mot aktier. Avkastningen på konvertiblerna,
        dvs kupongräntan, är vanligen högre än utdelningen på utbytes aktierna.
        Konvertibelkursen uttrycks i procent av det nominella värdet på
        konvertibeln.
      </p>
      <h4>3.2.4 Omvända konvertibler </h4>
      <p>
        Omvända konvertibler är ett mellanting mellan en ränte- och en
        aktieplacering. Den omvända konvertibeln är knuten till en eller flera
        underliggande aktier eller index. Denna placering ger en ränta, dvs en
        fast, garanterad avkastning. Om de underliggande aktierna eller index
        utvecklas positivt återbetalas det placerade beloppet plus den fasta
        avkastningen. Om de underliggande aktierna eller index däremot skulle
        falla, finns det en risk att innehavaren i stället för det placerade
        beloppet, utöver en på förhand fastställd avkastning kan få en eller
        flera aktier som ingår i den omvända konvertibeln eller motsvarande
        kontantlikvid.
      </p>
      <h4>3.2.5 Aktieoptioner och aktieindexoptioner</h4>
      <p>
        Aktieoptioner finns av olika slag. Förvärvade köpoptioner (eng. call
        options) ger innehavaren rätt att inom en viss tidsperiod köpa redan
        utgivna aktier till ett på förhand bestämt pris. Säljoptioner (eng. put
        options) ger omvänt innehavaren rätt att inom en viss tidsperiod sälja
        aktier till ett på förhand bestämt pris. Mot varje förvärvad option
        svarar en utfärdad option. Risken för den som förvärvar en option är, om
        inte riskbegränsande åtgärder vidtas, att den minskar i värde eller
        förfaller värdelös på slutdagen. I det senare fallet är den vid
        förvärvet betalda premien för optionen helt förbrukad. Utfärdaren av en
        option löper en risk som i vissa fall, om inte riskbegränsande åtgärder
        vidtas, kan vara obegränsat stor. Kursen på optioner påverkas av kursen
        på motsvarande underliggande aktier eller index, men oftast med större
        kurssvängningar och prispåverkan än dessa. Den mest omfattande handeln i
        aktieoptioner sker på de reglerade marknaderna. Där förekommer även
        handel med aktieindexoptioner. Dessa indexoptioner ger vinst eller
        förlust direkt i kontanter (kontantavräkning) utifrån utvecklingen av
        ett underliggande index. Se även avsnitt 5 om derivat.
      </p>
      <h4>3.2.6 Aktieterminer och aktieindexterminer samt futures</h4>
      <p>
        En termin eller forward innebär att parterna ingår ett ömsesidigt
        bindande avtal med varandra om köp respektive försäljning av den
        underliggande egendomen till ett på förhand avtalat pris och med
        leverans eller annan verkställighet, t.ex. kontantavräkning, av avtalet
        vid en i avtalet angiven tidpunkt (stängningsdagen). Någon premie
        betalas inte eftersom parterna har motsvarande skyldigheter enligt
        avtalet.En future är en variant på termin eller forward. Skillnaden
        mellan en future och en forward består i hur avräkningen går till, det
        vill säga när man som part i ett kontrakt får betalt eller betalar
        beroende på om positionen gett vinst eller förlust. För en future sker
        en daglig avräkning i form av löpande betalningar mellan köpare och
        säljare på basis av värdeförändringen dag för dag av underliggande
        tillgång. För en forward sker avräkning först i anslutning till
        instrumentets slutdag.Se även avsnitt 5 om derivat.
      </p>
      <h4>3.2.7 Warranter</h4>
      <p>
        Handel förekommer också med vissa köp- och säljoptioner med längre
        löptider, i Sverige vanligen kallade warranter. Warranter kan utnyttjas
        för att köpa eller sälja underliggande aktier eller i andra fall ge
        kontanter om kursen på underliggande aktie utvecklas på rätt sätt i
        förhållande till warrantens lösenpris. Teckningsoptioner (eng.
        subscription warrants) avseende aktier kan inom en viss tidsperiod
        utnyttjas för teckning av motsvarande nyutgivna aktier. Se även avsnitt
        5 om derivat.
      </p>
      <h4>3.2.8 Hävstångscertifikat</h4>
      <p>
        Hävstångscertifikat, som ofta kallas enbart certifikat eller i vissa
        fall bevis, är ofta en kombination av ex.vis en köp- och en säljoption
        och är beroende av en underliggande tillgång, exempelvis en aktie, ett
        index eller en råvara. Ett certifikat har inget nominellt belopp.
        Hävstångscertifikat skall inte förväxlas med t.ex. företagscertifikat,
        vilket är en slags skuldebrev som kan ges ut av företag i samband med
        att företaget lånar upp pengar på kapitalmarknaden.
      </p>
      <p>
        En utmärkande egenskap för hävstångscertifikat är att relativt små
        kursförändringar i underliggande tillgång kan medföra avsevärda
        förändringar i värdet på innehavarens placering. Dessa förändringar i
        värdet kan vara till investerarens fördel, men de kan också vara till
        investerarens nackdel. Innehavare bör vara särskilt uppmärksamma på att
        hävstångscertifikat kan falla i värde och även förfalla helt utan värde
        med följd att hela eller delar av det investerade beloppet kan förloras.
        Motsvarande resonemang kan i många fall gälla även för optioner och
        warranter. Se även avsnitt 5 om derivat.
      </p>
    </section>
    <section>
      <h2>4. Ränterelaterade instrument</h2>
      <p>
        Ett räntebärande finansiellt instrument är en fordringsrätt på utgivaren
        (emittenten) av ett lån. Avkastning lämnas i form av ränta. Det finns
        olika former av räntebärande instrument beroende på vilken emittent som
        givit ut instrumentet, den säkerhet som emittenten kan ha ställt för
        lånet,löptiden fram till återbetalningsdagen och formen för utbetalning
        av räntan. Räntan (kupongen) betalas vanligen ut årsvis.
      </p>
      <p>
        En annan form för räntebetalning är att sälja instrumentet med rabatt
        (diskonteringspapper). Vid försäljningen beräknas priset på instrumentet
        genom att diskontera lånebeloppet inklusive beräknad ränta till nuvärde.
        Nuvärdet eller kursen är lägre än det belopp som erhålls vid
        återbetalningen (nominella beloppet).Bankcertifikat och statsskuldväxlar
        är exempel på diskonteringspapper, liksom obligationer med s.k.
        nollkupongskonstruktion.
      </p>
      <p>
        Ytterligare en annan form för räntebärande obligationer är statens
        premieobligationer där låneräntan lottas ut bland innehavarna av
        premieobligationer. Det förekommer även ränteinstrument och andra
        sparformer där räntan är skyddad mot inflation och placeringen därför
        ger en fast real ränta.
      </p>
      <p>
        Risken i ett räntebärande instrument utgörs dels av den kursförändring
        (kursrisk) som kan uppkomma under löptiden på grund av att
        marknadsräntorna förändras, dels att emittenten kanske inte klarar att
        återbetala lånet (kreditrisk). Lån för vilka fullgod säkerhet för
        återbetalningen har ställts är därvid typiskt sett mindre riskfyllda än
        lån utan säkerhet. Rent allmänt kan dock sägas att risken för förlust på
        räntebärande instrument kan anses som lägre än för aktier. Ett
        räntebärande instrument utgivet av en emittent med hög kreditvärdighet
        kan således vara ett bra alternativ för den som vill minimera risken att
        sparkapitalet minskar i värde och kan vara att föredra vid ett
        kortsiktigt sparande. Även vid långsiktigt sparande där kapitalet inte
        skall äventyras, t.ex. för pensionsåtaganden, är inslag av räntebärande
        placeringar mycket vanliga. Nackdelen med en räntebärande placering är
        att den i regel ger en låg värdestegring. Exempel på räntebärande
        placeringar är sparkonton, privatobligationer och räntefonder.
      </p>
      <p>
        Kurserna fastställs löpande såväl på instrument med kort löptid (mindre
        än ett år) t.ex. statsskuldväxlar som på instrument med längre löptider
        t.ex. obligationer. Detta sker på penning-och obligationsmarknaden.
        Marknadsräntorna påverkas av analyser och bedömningar som Riksbanken och
        andra större institutionella marknadsaktörer gör av hur utvecklingen av
        ett antal ekonomiska faktorer som inflation, konjunktur,
        ränteutvecklingen i Sverige och i andra länder osv kommer att utvecklas
        på kort och lång sikt. Riksbanken vidtar även s.k. penningpolitiska
        operationer i syfte att styra utvecklingen av marknadsräntorna så att
        inflationen håller sig inom fastställda mål. De finansiella instrument
        som handlas på penning- och obligationsmarknaden (t.ex.
        statsobligationer, statsskuldväxlar och bostadsobligationer) handlas
        ofta i mycket stora poster (mångmiljonbelopp).
      </p>
      <p>
        Om marknadsräntorna går upp kommer kursen på utelöpande(redan utgivna)
        räntebärande finansiella instrument att falla om de har fast ränta,
        eftersom nya lån ges ut med en räntesats som följer aktuell
        marknadsränta och därmed ger högre ränta än vad utelöpande instrumentet
        ger. Omvänt stiger kursen på utelöpande instrument när marknadsräntan
        går ned.
      </p>
      <p>
        Lån utgivna av stat och kommun anses vara riskfria vad gäller
        återbetalningen, vilket således gäller för stats- och
        kommunobligationer. Andra emittenter än stat och kommun kan ibland, vid
        emission av obligationer, ställa säkerhet i form av andra finansiella
        instrument eller annan egendom (sak- eller realsäkerhet).
      </p>
      <p>
        Det finns även andra räntebärande instrument som innebär en högre risk
        än obligationer om emittenten skulle få svårigheter att återbetala
        lånet, t. ex. förlagsbevis eftersom de lånen återbetalas först efter det
        alla andra fordringsägare fått betalt. Villkorade konvertibla
        obligationer (contingent convertibles), så kallade cocos, är en annan
        typ av komplex produkt med risker som kan vara mycket svåra att förstå.
        I grunden är de obligationer som under vissa förutbestämda händelser kan
        skrivas ned, det vill säga förlora hela eller delar av sitt värde, eller
        omvandlas till aktier.
      </p>
      <p>
        En form av ränterelaterade instrument är säkerställda obligationer.
        Dessa är förenade med en särskild förmånsrätt enligt speciell
        lagstiftning. Regelverket kring säkerställda obligationer syftar till
        att en investerare skall få full betalning enligt avtalad tidplan även
        om obligationens utgivare skulle gå i konkurs, förutsatt att den egendom
        som säkerställer obligationen är tillräckligt mycket värd.
      </p>
    </section>
    <section>
      <h2>5. Derivatinstrument</h2>
      <p>
        Derivatinstrument såsom optioner, terminer m.fl. förekommer med olika
        slag av underliggande tillgång, t.ex. aktier, obligationer, råvaror och
        valutor. Derivatinstrument kan utnyttjas för att minska risken i en
        placering.
      </p>
      <p>
        Kursen i ett derivatinstrument är beroende av kursen i det underliggande
        instrumentet. En särskild omständighet är att prisutvecklingen på
        derivatinstrumentet oftast är kraftigare än prisutvecklingen på den
        underliggande egendomen. Prisgenomslaget kallas hävstångseffekt och kan
        leda till större vinst på insatt kapital än om placeringen hade gjorts
        direkt i den underliggande egendomen. Å andra sidan kan
        hävstångseffekten lika väl medföra större förlust på derivatinstrumenten
        jämfört med värdeförändringen på den underliggande egendomen, om
        prisutvecklingen på den underliggande egendomen blir annorlunda än den
        förväntade. Hävstångseffekten, dvs möjligheten till vinst respektive
        risken för förlust, varierar beroende på derivatinstrumentets
        konstruktion och användningssätt. Stora krav ställs därför på
        bevakningen av prisutvecklingen på derivatinstrumentet och på den
        underliggande egendomen. Investeraren bör i eget intresse vara beredd
        agera snabbt, ofta under dagen, om placeringen i derivatinstrument
        skulle komma att utvecklas i ofördelaktig riktning. Det är också viktigt
        att i sin riskbedömning beakta att möjligheten att avveckla en
        position/ett innehav kan försvåras vid en negativ prisutveckling.
      </p>
    </section>
    <section>
      <h2>6. Fonder och fondandelar</h2>
      <p>
        En fond är en ”portfölj” av olika slags finansiella instrument, t.ex.
        aktier och obligationer. Fonden ägs gemensamt av alla som sparar i
        fonden, andelsägarna, och förvaltas av ett fondbolag. Det finns olika
        slags fonder med olika placeringsinriktning. Med placeringsinriktning
        menas vilken typ av finansiella instrument som fonden placerar i. Nedan
        redogörs i korthet för några av de vanligaste typerna av fonder. För
        ytterligare information se Konsumenternas bank- och finansbyrås hemsida,{" "}<a href="https://www.konsumenternas.se/">www.konsumentbankbyran.se</a>, och Fondbolagens Förenings hemsida,
        {" "}<a href="https://www.fondbolagen.se/">www.fondbolagen.se</a>.
      </p>
      <p>
        En aktiefond placerar allt eller huvudsakligen allt kapital som
        andelsägarna inbetalat i aktier. Blandfonder med både aktier och
        räntebärande instrument förekommer också, liksom rena räntefonder där
        kapitalet placeras huvudsakligen i räntebärande instrument. Det finns
        även till exempel indexfonder som inte förvaltas aktivt av någon
        förvaltare utan som istället placerar i finansiella instrument som
        följer sammansättningen i ett visst bestämt index.
      </p>
      <p>
        En av idéerna med en aktiefond är att den placerar i flera olika aktier
        och andra aktierelaterade finansiella instrument, vilket gör att risken
        för andelsägaren minskar jämfört med risken för den aktieägare som
        placerar i endast en eller i ett fåtal aktier. Andelsägaren slipper
        vidare att välja ut, köpa, sälja och bevaka aktierna och annat
        förvaltningsarbete runt detta.
      </p>
      <p>
        Principen för räntefonder är densamma som för aktiefonder – placering
        sker i olika räntebärande instrument för att få riskspridning i fonden
        och förvaltningen i fonden sker efter analys av framtida räntetro.
      </p>
      <p>
        En fondandelsfond är en fond som placerar i andra fonder. En
        fondandelsfond kan ses som ett alternativ till att själv välja att
        placera i flera olika fonder. Man kan därmed uppnå den riskspridning som
        en väl sammansatt egen fondportfölj kan ha. Det finns fondandelsfonder
        med olika placeringsinriktningar och risknivåer.
      </p>
      <p>
        En ytterligare typ av fond är hedgefond. Hedge betyder skydda på
        engelska. Trots att hedging är avsett att skydda mot oväntade
        förändringar i marknaden kan en hedgefond vara en fond med hög risk, då
        sådana fonder ofta är högt belånade. Skillnaderna är dock stora mellan
        olika hedgefonder. Det finns även hedgefonder med låg risk. Hedgefonder
        försöker ge en positiv avkastning oavsett om aktie- eller räntemarknaden
        går upp eller ner. En hedgefond har mycket större frihet i sina
        placeringsmöjligheter än traditionella fonder. Placeringsinriktningen
        kan vara allt från aktier, valutor och räntebärande instrument till
        olika arbitragestrategier (spekulation i förändringar av t.ex. räntor
        och/eller valutor). Hedgefonder använder sig oftare än traditionella
        fonder av derivat i syfte att öka eller minska fondens risk. Blankning är också ett vanligt inslag.</p>
      <p>
        Fonder kan också delas in i värdepappersfonder (även kallade
        UCITS-fonder) och specialfonder. Samlingsnamnet för dessa är
        investeringsfonder och båda typerna regleras av den svenska lagen om
        investeringsfonder. Värdepappersfonder är de fonder som uppfyller det
        s.k. UCITS-direktivets krav, främst vad gäller placeringsbestämmelser
        och riskspridning. Såväl svenska som utländska värdepappersfonder (som
        fått tillstånd i sitt hemland inom EES), får säljas och marknadsföras
        fritt i samtliga EES-länder. Specialfonder (exempelvis s.k. hedgefonder)
        är fonder som på något sätt avviker från reglerna i UCITS-direktivet,
        och det är därför särskilt viktigt för dig som kund att ta reda på vilka
        placeringsregler som en specialfond du avser att placera i kommer att
        iaktta. Detta framgår av fondens informationsbroschyr och faktablad.
        Varje fondbolag är skyldigt att självmant erbjuda potentiella
        investerare det faktablad som avser fonden. Specialfonder får inte
        marknadsföras och säljas fritt utanför Sverige. För de fonder som
        placerar i utländska finansiella instrument tillkommer även en
        valutarisk (se även avsnitt 2.2 ovan).
      </p>
      <p>
        Andelsägarna får det antal andelar i fonden som motsvarar andelen insatt
        kapital i förhållande till fondens totala kapital. Andelarna kan köpas
        och lösas in genom värdepappersföretag som saluför andelar i fonden
        eller direkt hos fondbolaget. Det är dock viktigt att beakta att vissa
        fonder kan ha förutbestämda tidpunkter då fonden är ”öppen” för köp och
        inlösen, varför det inte alltid är möjligt med regelbunden handel.
        Andelarnas aktuella värde beräknas regelbundet av fondbolaget och
        baseras på kursutvecklingen av de finansiella instrument som ingår i
        fonden. Det kapital som placeras i en fond kan både öka och minska i
        värde och det är därför inte säkert att placeraren får tillbaka hela det
        insatta kapitalet.
      </p>
      <p>
        Information om olika typer av finansiella instrument och handel med
        finansiella instrument jämte förslag till ytterligare litteratur på
        området återfinns också t.ex. på Konsumenternas bank- och finansbyrås
        hemsida,{" "}<a href="https://www.konsumenternas.se/">www.konsumentbankbyran.se</a>, och på SwedSecs hemsida,{" "}
        <a href="https://www.swedsec.se/">www.swedsec.se</a>.
      </p>
    </section>
  </article>
);


import React from "react";
import { DocProps, DocMap } from "../../Language";
import { AccountTermsSV as SV } from "./AccountTermsSV";
import { AccountTermsEN as EN } from "./AccountTermsEN";
import "../../Documents.css";

const docsMap: DocMap = { SV, EN };

export const AccountTerms: React.FC<DocProps> = props =>
  React.createElement(docsMap[props.language], null);

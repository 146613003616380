import React from "react";

export const InformationAboutAttributesAndRisksEN = () => (
  <article className="lysa-doc risks">
    <header>
      <h1>
        Information regarding characteristics and risks relation to financial
        instruments
      </h1>
      <p>v. 2020:1</p>
    </header>

    <section>
      <h2>Introduction</h2>
      <p>
        As a customer, you must fully understand, <i>inter alia</i>, the
        following:
      </p>
      <ul>
        <li>
          the investments made or other positions taken in financial instruments
          are at the customer’s own risk, and
        </li>
        <li>
          that you as a customer must yourself study the securities
          institution’s general terms and conditions and information regarding
          the relevant financial instrument, its characteristics and risks.
        </li>
      </ul>
    </section>

    <section>
      <h2>1. Trading of financial instruments</h2>
      <p>
        The trading of financial instruments, i.e., inter alia, shares in
        limited liability companies and equivalent participation rights in other
        types of undertakings, bonds, depository receipts, fund units, money
        market instruments, financial derivative instruments or other such
        securities, except instruments for payment, which are negotiable on the
        capital market, mainly takes place in an organized manner on at an
        execution venue. Trading is carried out by securities institutions
        participating in the trading at the execution venue. As a customer, you
        normally have to contact such a securities institution in order to buy
        and sell financial instruments
      </p>
      <h3>1.1 Execution venues</h3>
      <p>
        “Execution venues” means regulated markets, multilateral trading
        facilities (MTF) and systematic internalisers (SI), as well as where
        trading takes place within securities institutions.
      </p>
      <p>
        Various types of financial instruments are traded on a regulated market.
        In relation to shares, only shares of publicly listed companies can be
        listed and traded on a regulated market and there are stringent
        requirements for such companies, inter alia, regarding the company’s
        size, operational history, the spread of ownership and public reporting
        of the company’s finances and operations.
      </p>
      <p>
        A multilateral trading facility (MTF) can be described as a trading
        system that is organised and provided by an exchange or a securities
        institution. Typically, less stringent requirements, such as the
        provision of information and operational history, apply for financial
        instruments traded on a multilateral trading facility compared to
        financial instruments traded on a regulated market.
      </p>
      <p>
        A systematic internaliser is a securities institution which, in an
        organised, frequent and systematic manner, trades on its own behalf by
        executing client orders outside a regulated market or a multilateral
        trading facility. A systematic internaliser is obligated to publish buy
        and/or sales bids on prices that correspond to the market price for
        liquid shares which are traded on a regulated market and for which the
        systematic internaliser carries out systematic internal trading.
      </p>
      <p>
        Trading can also take place through a securities institution without it
        being a systematic internaliser, by executing a client’s order against
        the institution’s own account or against orders from other clients of
        the institution.
      </p>
      <p>
        There are currently two regulated markets in Sweden, OMX Nordiska Börs
        Stockholm AB (hereinafter referred to as the “Stockholm Stock Exchange”)
        and Nordic Growth Market NGM AB (hereinafter referred to as ”NGM”). In
        addition, organised trading takes place on other execution venues, e.g.
        First North and Nordic MTF (multilateral trading facilities) as well as
        on securities institutions’ own lists.
      </p>
      <p>
        Trading on regulated markets, multilateral trading facilities and other
        execution venues constitutes a secondary market for financial
        instruments which are not newly issued. Where the secondary market
        functions well, i.e. it is easy to find buyers and seller and there is
        continuous notation of bid prices from buyers and sellers, as well as
        closing rates (transaction prices) for completed transactions, the
        companies are also at an advantage because it is easier, when required,
        to issue new instruments and thereby obtain more capital for the
        company's operations. The primary market is the market on which the
        purchase of/subscription of newly issued instruments takes place.
      </p>
      <h3>1.2 Trading/quoting lists</h3>
      <p>
        In relation to shares, the execution venues usually divide the shares
        into different lists which are published, e.g. on the execution venues'
        website, in daily newspapers and other forms of media. A deciding factor
        in relation to the list on which the company's shares are traded may be
        the company's market value (e.g., Stockholm Stock Exchange's Large, Mid
        and Small cap). The shares with the highest turnover may also be on a
        special list. Certain securities institutions publish their own lists of
        financial instruments which are traded through the institute, prices at
        which the instruments are traded, etc., e.g. via the institution's
        website. Shares quoted on lists with stringent requirements and high
        turnover are generally deemed to involve a lower risk than shares on
        other lists.
      </p>
      <p>
        Information regarding prices, etc. regarding shares as well as other
        types of financial instruments, for example fund units, options and
        bonds, are also published regularly on, e.g. the execution venues'
        websites, in daily newspapers and other forms of media.
      </p>
    </section>

    <section>
      <h2>
        2. Risks associated with financial instruments and trading of financial
        instruments
      </h2>
      <h3>2.1 Generally regarding risks</h3>
      <p>
        Financial instruments can provide a return in the form of dividends
        (shares and funds) or interest (fixed income instruments). In addition,
        the price of the instrument may increase or decrease compared to the
        price when the investment was made. In the description below, the word
        investment also means any negative positions (negative holdings) in the
        instrument, compare with e.g. that which is stated in section 7 below
        regarding short selling. The total return is the sum of the
        dividends/interest and price change for the instrument.
      </p>
      <p>
        Naturally, the investor seeks a total return that is positive, i.e.
        yields a profit, preferably as high as possible. However, there is also
        a risk that the total return will be negative, i.e. that there will be a
        loss on the investment. The risk of loss varies between different
        instruments. Normally, the chance of a profit on an investment in a
        financial instrument is linked to the risk of a loss. The longer the
        time for which the investment is held, the greater the chance is of a
        profit or the risk of a loss. In an investment context, the word "risk"
        is used to express both the risk of loss and the chance of profit.
        However, in the description below the word "risk" is used solely to
        designate the risk of loss. There are various ways to invest which may
        reduce the risk. It is normally regarded as preferable not to invest in
        a single or only a few financial instruments but, instead, to invest in
        several different financial instruments. These instruments should then
        offer a spreading of the risks and do not concentrate risks that can be
        triggered simultaneously. Normally, a spreading of the investments to
        include foreign markets also reduces the risk of the total portfolio,
        even if, when trading in foreign financial instruments, there is also a
        currency risk.
      </p>
      <p>
        Investments in financial instruments are associated with economic risks¸
        which will be described in some greater detail in this information. The
        customer is personally liable for the risk and must, therefore, himself
        at the retained securities institution - or via his own asset management
        representative -, become acquainted with the terms and conditions, in
        the form of general terms and conditions, prospectuses and suchlike,
        which apply to the trade of such instruments and the characteristics of
        the instruments and risks associated therewith. The customer must also
        regularly monitor his investments in such instruments. This is the case
        even if the customer has received personal advice in conjunction with
        the investment. The customer should, in his own interests, be prepared
        to take measures promptly where such prove necessary, for example
        through selling investments that have performed negatively or by
        providing additional collateral in conjunction with investments financed
        through loans and where the collateral value has fallen.
      </p>
      <p>
        It is also important to consider the risks involved in trading with
        financial instruments on an execution venue other than a regulated
        market, where the requirements imposed are generally less stringent.
      </p>
      <h3>2.2. Different types of risk concepts, etc.</h3>
      <p>
        In conjunction with the risk assessment which you should carry out when
        you as a customer make an investment in a financial instrument, and also
        regularly during the holding period, there are many different risk
        concepts and other factors to consider and weigh-up. A short description
        of some of the most common risk concepts are set out below.
      </p>
      <ul>
        <li>
          Market risk – the risk that the market as a whole, or a particular
          part thereof in which you as a customer have your investment, e.g. the
          Swedish equities market, falls.
        </li>
        <li>
          Credit risk – the risk of e.g. an issuer or a counterparty having an
          insufficient ability to make payment.
        </li>
        <li>
          Price volatility risk – the risk of large swings in the price of a
          financial instrument negatively affecting the investment.
        </li>
        <li>
          Price risk – the risk of the price of a financial instrument falling.
        </li>
        <li>
          Tax risk – the risk that the tax rules and/or tax rates are unclear or
          may be changed.
        </li>
        <li>
          Currency risk – the risk that a foreign currency to which a holding is
          related (e.g. fund units in a fund which invests in US securities
          listed in USD) are weakened.
        </li>
        <li>
          Risk of leveraged effect – the construction of a derivative instrument
          which means that there is a risk that the price of the underlying
          asset has a greater negative effect on the price of the derivative
          instrument.
        </li>
        <li>
          Legal risk – the risk that relevant legislation and rules are unclear
          or may be changed.
        </li>
        <li>
          Company-specific risk – the risk that a certain company performs worse
          than expected or is affected by a negative event and the financial
          instruments related to the company may thereby fall in value.
        </li>
        <li>
          Sector-specific risk – the risk that a certain sector performs worse
          than expected or is affected by a negative event and the financial
          instruments related to the company within the sector may thereby fall
          in value.
        </li>
        <li>
          Liquidity risk – the risk that you are unable to sell or purchase a
          financial instrument at a certain chosen time due to the turnover in
          the financial instrument being low.
        </li>
        <li>
          Interest risk – the risk that the financial instrument in which you
          have invested decreases in value due to changes in the market interest
          rate.
        </li>
      </ul>
    </section>
    <section>
      <h2>3. Shares and share-related instruments</h2>
      <h3>3.1 Generally regarding shares</h3>
      <h4>3.1.1 Shares and limited liability companies</h4>
      <p>
        Shares in limited liability companies entitle the owner to a portion of
        the company's share capital. Where the company makes a profit, the
        company usually distributes dividends on the shares. Shares also entitle
        the holder to voting rights at the general meeting of the company, which
        is the highest-ranking decision-making body in the company. The more
        shares the holder owns, the greater the portion of the capital,
        dividends and votes that inure to him. Voting rights may vary depending
        on the class of shares concerned. There are two types of companies,
        public and private. Only public companies may cause their shares to be
        traded on an execution venue.
      </p>
      <h4>3.1.2 The share price</h4>
      <p>
        The price of a share is affected mainly by the supply and demand for the
        relevant share which in turn, at least in the long term, is affected by
        the company's future prospects. A share is valued upwards or downwards
        depending primarily on the investors’ analysis and assessment of the
        company's possibilities to make future profits. Future external
        developments regarding the global economy, technology, legislation,
        competition, etc. determine the demand for the company's products or
        services and, consequently are of fundamental significance regarding
        changes in the price of the company's shares.
      </p>
      <p>
        Current interest rate levels also play a large role in the pricing.
        Where the market interest rates increase, fixed interest financial
        instruments that are issued at the same time (newly issued) provide a
        better return. In such cases, the prices of shares which are regularly
        traded normally fall, as well as those already traded fixed interest
        instruments. The reason is that the increased return on the newly issued
        fixed income instruments become, relatively speaking, better than the
        return on shares, as well as on already traded fixed income instruments.
        In addition, share prices are negatively affected by the fact that the
        interest payments on the company's debts increase when market interest
        rates increase, a factor which reduces the scope for profits in the
        company.
      </p>
      <p>
        Also, other factors directly related to the company, e.g. changes in the
        company's management and organisation, disruptions in production, etc.
        may strongly affect the company's future ability to create profits, both
        in the long and short-term. In the worst case, a limited liability
        company may perform so poorly that it must be declared bankrupt. The
        share capital, i.e. the capital invested by the shareholders, is the
        capital that is used first in order to pay the company's debts. This
        often results in the shares of the company becoming worthless.
      </p>
      <p>
        Even prices on major foreign regulated markets or execution venues
        affect the prices in Sweden, inter alia, since many Swedish limited
        liability companies are also listed on foreign execution venues and
        price equalisation (arbitrage) takes place between different execution
        venues. Prices in shares in companies that belong to the same industrial
        sector are often affected by changes in the prices of shares of other
        companies within the same sector. This effect can also apply with
        respect to companies in other countries.
      </p>
      <p>
        Investors on the market have different needs for investing cash (liquid
        funds) or obtaining liquid funds. In addition, they often have different
        opinions as to how the price will develop. These factors, which also
        include the way in which the company is valued, contribute to there
        being both buyers and sellers. On the other hand, if the investors have
        the same opinions regarding price trends, they will either wish to buy
        and thereby creating buying pressure from many buyers, or they will wish
        to sell and thereby creating selling pressure from many sellers. The
        prices increase in the event of buying pressure and fall in the event of
        selling pressure.
      </p>
      <p>
        Turnover, i.e. the quantity of a certain share which is purchased and
        sold, in turn affects the share price. In the event of high turnover the
        difference is reduced between the price the buyer is prepared to pay
        (bid price) and the price the seller demands (ask price). (The
        difference between bid and ask prices are often referred to as the
        spread.) A share with a high turnover, where large amounts can be traded
        without affecting the price, enjoys good liquidity and is therefore easy
        to buy or sell. Companies on the regulated markets’ lists (e.g. the
        Stockholm Stock Exchange’s Nordic list and NGM's NGM Equity) more often
        have high liquidity. During the day or during longer periods, different
        shares can exhibit different degrees of price stability (volatility),
        i.e. increases and declines, as well as in size of the price changes.
      </p>
      <p>
        The prices at which shares are traded (transaction prices), such as
        highest/lowest/most recently paid during the day, as well as the last
        quoted bid/ask prices and further information regarding traded volume in
        kronor is published, inter alia, in most major daily newspapers, on
        text-TV and on various websites maintained by execution venues,
        securities institutions and media companies. How current such price
        information is can vary depending on the manner in which it is
        published.
      </p>
      <h4>3.1.3 Various classes of shares</h4>
      <p>
        There are various classes of shares, commonly class A and B shares which
        normally refer to voting rights. Class A shares normally entitle the
        holder to one vote while class B shares entitle the holder to a
        restricted voting right, often one-tenth of the vote. The differences in
        voting rights are due to, inter alia, the fact that in conjunction with
        diversification of ownership the original founders or owners of the
        company wish to maintain their influence in the company by being given
        stronger voting rights. Therefore, newly issued shares are accorded a
        lower voting value than the original class A shares and are designated
        with the letters B, C or D, etc.
      </p>
      <h4>3.1.4 Quotient value, split and consolidation of shares</h4>
      <p>
        A share's quotient value is the equal portion of the company's share
        capital that each share represents. The quotient value is obtained by
        dividing the share capital with the total amount of shares.
        Occasionally, companies wish to change the quotient value, e.g. because
        the price, i.e. the market price of the share, has risen significantly.
        By dividing up the share into two or several shares through a so-called
        split, the quotient value is reduced and at the same time the price per
        share is reduced. However, after a split the owner's capital remains
        unchanged but this is divided into a greater number of shares which have
        a lower quotient value and a lower price per share.
      </p>
      <p>
        Conversely, a consolidation of shares (reverse split) can be carried out
        where the price has fallen dramatically. In such case, two or several
        shares are merged into one share. Following a consolidation of shares,
        shareholders retain the same capital; however this is divided into fewer
        shares with a higher quotient value and a higher share price.
      </p>
      <h4>3.1.5 Market introduction, privatisation and take-overs</h4>
      <p>
        Market introduction (in English often referred to as Initial Public
        Offering, IPO) means that the shares in a company are introduced on to
        the equities market, i.e. are approved for trading on a regulated market
        or a multilateral trading facility (MTF). The public is then invited to
        subscribe for (purchase) shares of the company. Most often, this is
        related to an existing company which has not previously been traded on a
        regulated market or other execution venue, where the owners have decided
        to expand the number of shareholders and facilitate trading of the
        company's shares. Where a state-owned company is introduced on the
        market, this is called privatisation.
      </p>
      <p>
        A take-over (company acquisition) normally involves one or more
        investors making an offer to the shareholders of a company, on certain
        terms and conditions, to sell their shares. Where the buyer obtains 90%
        or more of the number of shares in the acquired company, the buyer can
        request compulsory purchase of the remaining shares from the
        shareholders who have not accepted the company acquisition offer. These
        shareholders are then obliged to sell their shares to the buyer for
        payment which is determined through an arbitration proceeding.
      </p>
      <h4>3.1.6 Share issues</h4>
      <p>
        Where a company wishes to expand its operations, additional share
        capital is often required. The company raises additional capital by
        issuing new shares through a new issue. The existing shareholders often
        receive subscription rights entailing a pre-emptive right to subscribe
        for shares in a new issue. The number of shares that may be subscribed
        for is normally established in relation to the number of shares
        previously held by the shareholders. The subscriber must pay a certain
        price (issue price) which is often lower than the market price, for the
        newly issued shares. Immediately after the subscription rights (which
        normally have a certain market value) are detached from the shares, the
        price of the shares normally declines but, at the same time,
        shareholders who have subscribed have a larger number of shares. During
        the subscription period, which often lasts for several weeks, those
        shareholders who do not subscribe may sell the subscription rights on
        the marketplace on which the shares are traded. Upon the expiry of the
        subscription period, the subscription rights lapse and thus become
        useless and worthless.
      </p>
      <p>
        A limited liability company can also carry out a directed rights issue
        (a kind of private placement) which is carried out as a new issue but
        directed solely to a limited group of investors. The limited liability
        company can also carry out non-cash issues of new shares in order to
        acquire other companies, business operations, or assets other than cash.
        In conjunction with both directed issues (private placement) and
        non-cash issues, dilution takes place of an existing shareholder's
        portion of the voting capital and share capital in the company, but the
        number of the shares held and the market value of the invested capital
        is normally not affected.
      </p>
      <p>
        If the assets or the reserve funds in a limited liability company have
        greatly increased in value, the company can transfer part of the value
        to its share capital through what is commonly referred to as a bonus
        issue. In conjunction with bonus issues, consideration is given to the
        number of shares already held by each shareholder. The number of new
        shares that inure through the bonus issue is established in proportion
        to the number of shares previously held. Through the bonus issue, the
        shareholder receives more shares but the owner's portion of the
        company's increased share capital remains unchanged. The price of the
        shares declines in conjunction with a bonus issue but, through the
        increase in the number of shares, the shareholder retains an unchanged
        market value for his or her invested capital. Another method of carrying
        out a bonus issue is for the company to redenominate the quotient value
        of the shares. Following a redenomination, the shareholders have an
        unchanged number of shares and market value for their invested capital.
      </p>
      <h3>3.2 Generally regarding share-related instruments</h3>

      <p>
        Some instruments often closely connected to shares are share-index
        bonds, depositary receipts, convertible debentures, shares and share
        index options, share and share index futures, warrants and leverage
        certificates.
      </p>
      <h4>3.2.1 Index bonds/Share-index bonds</h4>
      <p>
        Index bonds/share-index bonds are bonds where the yield, instead of
        interest, depends on, e.g. a share index. Where the index develops
        positively so does the return. In the event of a decline in the index,
        there may be no return. However, the nominal value of the bond is always
        repaid on the maturity date and therefore has a limited risk of loss
        compared to e.g. shares and fund units. The risk with an investment in a
        share index bond can, except for any paid premium, be defined as the
        alternative interest income, i.e. the interest the investor would have
        received on the invested amount with an alternative investment.
      </p>
      <p>
        Index bonds can have different names, such as share index bonds, SPAX,
        share bonds, credit basket bonds, interest basket bonds, currency basket
        bonds, etc. depending on the underlying type of asset that determines
        the bond's return. When talking about index bonds, these are also often
        termed as capital-guaranteed or capital-protected products. These
        concepts are meant to describe, as stated above, that irrespective of
        whether or not the product yields a profit or not, the nominal amount is
        repaid, i.e. normally the same as the amount invested less any paid
        premium.
      </p>
      <p>
        Note that the capital protection does not apply if the issuer goes
        bankrupt or becomes the subject of a corporate reorganisation with a
        public composition (reduction of the receivable amount). (Banks, other
        credit institutions and securities companies may instead be subject to
        resolution. This means that the state can take control of the
        institution and its losses can be managed by having its shareholders and
        creditors have their holdings written down and / or for creditors their
        receivables converted to shareholding (so-called debt write-down or
        bail-in).)
      </p>
      <h4>3.2.2 Depositary receipts</h4>
      <p>
        Swedish Depositary receipts are receipts regarding the right to foreign
        shares which the issuer of the receipt holds on behalf of the holder.
        Depositary receipts are traded just as shares on a regulated market or
        execution venue and the price normally follows the price on the foreign
        execution venues on which the share is traded. In addition to the
        general risks associated with trading of shares or other types of
        participating interests, currency risks should be considered.
      </p>
      <h4>3.2.3 Convertible Instruments</h4>
      <p>
        Convertibles (convertibles or convertible instruments) are fixed income
        securities (loans to the issuer of the convertible) which may be
        exchanged for shares within a certain period of time. The return on the
        convertible, i.e. the coupon interest, is normally higher than the
        dividend of the shares received in exchange. The price of the
        convertibles is expressed as a percentage of the nominal value of the
        convertible.
      </p>
      <h4>3.2.4 Reverse convertibles</h4>
      <p>
        Reverse convertibles are a cross between an interest and a share
        investment. The reverse convertible is connected to one or several
        underlying shares or indexes. This investment yields an interest, i.e. a
        fixed, guaranteed return. Where the underlying shares or indexes perform
        well, the invested amount is repaid plus the fixed return. However,
        where the underlying shares or indexes fall, there is a risk that the
        investor instead of the invested amount receives one or several shares
        included in the reverse convertible or an equivalent amount in cash.
      </p>
      <h4>3.2.5 Share options and share index options</h4>
      <p>
        There are various types of share options. Acquired call options entitle
        the holder to purchase already issued shares at a predetermined price
        within a specific period of time. Conversely, put options entitle the
        holder to sell shares at a predetermined price within a specific period
        of time. There is an issued option corresponding to each acquired
        option. The risk for the person who acquires an option is, unless
        measures are undertaken to limit the risks, that it will decrease in
        value or become worthless on the expiry date. In the latter case, the
        premium paid upon purchase of the option is consumed in its entirety.
        The issuer of the option runs the risk which in certain cases, unless
        measures are undertaken to limit the risks, may be unlimited in scope.
        The price of the options normally follows the price of the underlying
        share or indexes, but with greater volatility.
      </p>
      <p>
        The most extensive trading in share options takes place on regulated
        markets. Trading also takes place in share index options. These index
        options yield a profit or loss directly in cash (cash settlement)
        related to the changes in an underlying index.
      </p>
      <h4>3.2.6 Share futures and share index futures.</h4>
      <p>
        A future means that parties enter into a mutually enforceable agreement
        regarding the purchase and sale of the underlying asset at a
        predetermined price and with delivery or other completion event, e.g.
        cash settlement, of the agreement at an agreed time (closing date). No
        premium is paid as the parties have corresponding obligations under the
        agreement.
      </p>
      <h4>3.2.7 Warrants</h4>
      <p>
        There is also trading in certain call and put options with longer terms
        until expiration, which in Sweden are normally referred to as warrants.
        Warrants may be used in order to purchase or sell underlying shares or,
        in other cases, provide cash settlement where the price of the
        underlying share performs well in relation to the warrant's redemption
        price. Subscription rights (in English referred to as subscription
        warrants) for shares may within a certain period be used to subscribe
        for corresponding newly issued shares.
      </p>
      <h4>3.2.8 Leverage certificate</h4>
      <p>
        A leverage certificate, which is often just called a certificate, is
        often a combination of e.g. a call and put option and is dependent on an
        underlying asset, for example a share, an index or a commodity. A
        certificate has no nominal amount. A leverage certificate should not be
        confused with e.g. a commercial paper, which is a type of debt
        instrument which can be issued by companies in conjunction with the
        company borrowing money on the capital market, often referred to in
        Swedish as certifikat.
      </p>
      <p>
        A significant characteristic of a leverage certificate is that
        relatively small changes in the price of the underlying assets can
        result in significant changes in the value of the holder's investment.
        These changes in value may be to the investor's advantage, but may also
        be to the investor's disadvantage. The investor should be particularly
        aware that the leverage certificate may fall in value and also
        completely lose its value resulting in all or parts of the invested
        amount being lost. The same reasoning may also apply to options and
        warrants.
      </p>
    </section>
    <section>
      <h2>4. Fixed income instruments</h2>
      <p>
        A fixed income financial instrument is a claim against the issuer of a
        loan. The return is normally paid in the form of interest. There are
        various types of fixed income instruments depending on the issuer that
        has issued the instrument, the collateral provided for the loan by the
        issuer, the term until the maturity date and the type of payment of
        interest. The interest (the coupon) is normally paid annually.
      </p>
      <p>
        Another form of interest payment is to sell the instrument at a discount
        (discount paper). Upon sale, the price of the instrument is calculated
        by discounting the loan amount including calculated interest to current
        value. The current value or the price is lower than the amount received
        upon maturity (the nominal amount). Certificates of deposit and treasury
        bills are examples of discount papers, as well as bonds with so-called
        zero coupon construction.
      </p>
      <p>
        A third form of fixed income bond is the state's premium bonds, where
        the interest on the bond is distributed by lottery among the holders of
        premium bonds. There are also fixed income instruments and other types
        of savings where the interest is protected against inflation and the
        investment thus yields a fixed real interest.
      </p>
      <p>
        The risk associated with a fixed income instrument is based on the fact
        that price changes (price risk) may occur during the term of the
        instrument due to changes in market interest rates, and that the issuer
        might be unable to repay the loan (credit risk). Therefore, bonds for
        which satisfactory security has been provided for redemption are
        typically less risky than fixed income instruments without security.
        However, in purely general terms, it can be stated that the risk of loss
        associated with fixed income instruments may be deemed lower than for
        shares. A fixed income instrument issued by an issuer with high
        creditworthiness may therefore be a good alternative for someone who
        wishes to minimise the risk that the capital saved decreases in value
        and may be preferable for short-term savings. Also for long-term savings
        where the capital is not to be jeopardised, e.g. for pension
        commitments, fixed income-related investments are commonly included. The
        disadvantage of a fixed income investment is that, as a rule, it yields
        a low increase in value. Examples of fixed income investments are
        savings accounts, private bonds and interest funds.
      </p>
      <p>
        The prices are determined each day both for instruments with short terms
        until maturity (less than one year), e.g. treasury bills, and for
        instruments with longer terms until maturity, e.g. bonds. This takes
        place on the money market and bond market. The market interest rates are
        affected by analysis and assessments which are conducted by the Central
        Bank of Sweden and other major institutional market players regarding
        short-term and long-term trends with respect to a number of economic
        factors such as inflation, the state of the global economy, and interest
        rate changes in Sweden and other countries. The Central Bank of Sweden
        also conducts monetary policy operations in order to control changes in
        market interest rates to ensure that inflation does not exceed an
        established target. The financial instruments traded on the money market
        and bond market (e.g. treasury bills, treasury bonds and bonds issued by
        home loan institutions) are often traded in large quantities
        (multi-million amounts).
      </p>
      <p>
        Where market interest rates increase, the price of already issued fixed
        income financial instruments will fall if they provide fixed interest,
        since new bonds are issued bearing rates of interest that follow current
        market rates of interest and thereby provide a higher rate of interest
        than the already issued instruments. Conversely, the price of already
        issued instruments increases when market interest rates decline.
      </p>
      <p>
        Loans issued by the state and municipalities are deemed to be risk-free
        with respect to redemption, which thereby applies to treasury bonds and
        municipal bonds. Issuers other than the state and municipalities may
        occasionally, in conjunction with the issuance of bonds, provide
        security in the form of other financial instruments or other assets
        (security in the form of property or real security).
      </p>
      <p>
        Also there are other fixed income instruments associated with a higher
        risk than bonds if and when the issuer encounters difficulties to repay
        the loan, e.g. subordinated debentures.
      </p>
      <p>
        A type of fixed income-related instrument is secured bonds. These are
        associated with a specific priority right according to special
        legislation. The regulations concerning secured bonds aim at ensuring
        that an investor will receive full payment according to the agreed term
        even where the issuer of the bond was to be placed in insolvent
        liquidation/declared bankrupt, provided that the assets which secures
        the bond have a sufficient value.
      </p>
    </section>
    <section>
      <h2>5. Derivative instruments</h2>
      <p>
        Derivative instruments, such as options, futures, etc. exist with
        various types of underlying assets, e.g. shares, bonds, commodities, and
        currencies. Derivative instruments may be utilised in order to reduce
        the risks associated with an investment.
      </p>
      <p>
        A particular characteristic which should be considered upon investment
        in derivative instruments is that the construction of a derivative
        instrument means that the changes in price of the underlying asset have
        an effect on the price of the derivative instrument. This price effect
        is often stronger in relation to the invested amount (paid premium) than
        the change in value of the underlying asset. The price effect is
        therefore called the leverage effect and may result in a larger profit
        on the invested capital than where the investment had been made directly
        in the underlying asset. Conversely, the leverage effect may just as
        well result in a larger loss on the derivative instrument compared to
        the change in value of the underlying asset where the price of the
        underlying asset becomes different than expected. The leverage effect,
        i.e. the chance for a profit and the risk of a loss, varies depending on
        the derivative instrument's construction and manner of use. Stringent
        requirements are therefore imposed on the monitoring of prices of
        derivative instruments and the underlying asset. In their own interest,
        investors should be prepared to act quickly, often during the day, in
        case the investment in the derivative instrument performs in a negative
        way. It is also important to consider when the investor makes its risk
        assessment that the ability to dispose of a holding can be more
        difficult where the price decreases.
      </p>
    </section>
    <section>
      <h2>6. Funds and fund units</h2>
      <p>
        A fund is a "portfolio" of various types of financial instruments, e.g.
        shares and bonds. The fund is owned jointly by all the savers in the
        fund, unit holders, and is managed by a fund management company. There
        are various types of funds with various investment focuses. Investment
        focus means the type of financial instruments in which the fund invests.
        A brief summary is set out below of some of the most common types of
        funds. For further information see the Swedish Consumers' Bank and
        Finance Bureau's website,{" "}
        <a
          href="https://www.konsumentbankbyran.se"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.konsumentbankbyran.se
        </a>
        , and the Swedish Investment Fund Association's website,{" "}
        <a
          href="https://www.fondbolagen.se"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.fondbolagen.se
        </a>
        .
      </p>
      <p>
        An equity fund invests all or most of the capital paid in by the unit
        holders in shares. There are also mixed funds that invest in both
        equities and fixed income instruments, and only interest funds where the
        capital is mainly invested in fixed income instruments. There are also,
        for example, index funds which are not actively managed by a fund
        manager, instead investments are made in financial instruments which
        copy and follow the performance of a certain specified index.
      </p>
      <p>
        One of the ideas underlying an equity fund is that it invests in several
        different shares and other share-related financial instruments, which
        means that the risk for the unit holders is reduced compared with the
        risk faced by shareholders who invest in only one or a few different
        shares. In addition, the fund's unit holders do not have to deal with
        choosing, buying, selling and monitoring the shares and other management
        work associated therewith holdings.
      </p>
      <p>
        The idea of interest funds is the same as for equity funds; investments
        are made in different fixed income-related instruments in order to
        obtain a spreading of risk in the fund and management of the fund is
        carried out based on analysis of future interest beliefs.
      </p>
      <p>
        A fund of funds (FoF) is a fund which invests in other funds. A fund of
        funds can be seen as an alternative to investing in several different
        funds yourself. Therefore, you may obtain the spreading of risk which a
        well-considered personal fund portfolio could have. There are fund of
        funds with various investment focuses and risk levels.
      </p>
      <p>
        Another type of fund is a hedge fund. Hedge means to protect. Even
        though hedging is meant to protect against unexpected changes in the
        market, a hedge fund can be a fund with high risk as such funds are
        often heavily leveraged. However, the differences between hedge funds
        are great. There are also hedge funds with low risk. Hedge funds try to
        yield a return regardless of whether the share or interest market goes
        up or down. A hedge fund has greater freedom in its choice of
        investments than traditional funds. The investment focus can be anything
        from shares, currencies and fixed income instruments to different types
        of arbitrage strategies (speculation on the changes of e.g. interest
        rates and/or currencies). Hedge funds use derivatives more often than
        traditional funds in order to increase or decrease the fund's risk.
        Short selling is also often common.
      </p>
      <p>
        Funds can also be divided into UCITS (Undertakings for Collective
        Investments In Transferable Securities) and special funds. The
        collective name for these is investment fund and both types are
        regulated by the Swedish Investment Funds Act. UCITS are funds which
        meet the so-called UCITS Directive’s requirements (EU directive), mainly
        in relation to the investment rules and spreading of risk. Swedish and
        foreign UCITS (which have received licences in their home country within
        the EEA), may be sold and marketed freely in all the EEA countries.
        Special funds (for example, so-called hedge funds) are funds which in
        some manner deviate from the rules in the UCITS Directive and it is
        therefore particularly important for you as a client to find out which
        investment rules that apply for a special fund in which you intend to
        invest. This will be stated in the fund's prospectus and fact sheet.
        Each management company is obliged to provide each potential investor
        with a fact sheet regarding the fund. Special funds may not be marketed
        and sold freely outside of Sweden. A currency risk is also associated
        with funds which invest in foreign financial instruments (see section
        2.2 above).
      </p>
      <p>
        Unit holders receive the number of units in the fund which correspond to
        the share of invested capital in relation to the fund's total capital.
        The units can be subscribed for and redeemed through securities
        institutions which market units in funds or directly with the management
        company. However, it is important to note that certain funds have a
        predetermined period when the fund is "open" for subscription and
        redemption, resulting in regular trade not always being possible. The
        unit's current value is regularly calculated by the management company
        and is based on the prices of the financial instruments covered by the
        fund. The capital invested in a fund can increase and decrease in value
        and it is therefore the investor can not be sure to receive the entire
        invested capital when selling.
      </p>
      <p>
        Information regarding various types of financial instruments and trading
        in financial instruments as well as suggestions of other literature
        within this area may also be found, e.g. on the Swedish Consumers' Bank
        and Finance Bureau's website,{" "}
        <a
          href="https://www.konsumentbankbyran.se"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.konsumentbankbyran.se
        </a>{" "}
        and on SwedSec's website,{" "}
        <a
          href="https://www.swedsec.se"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.swedsec.se
        </a>
        .
      </p>
    </section>
  </article>
);

import React from "react";
import { DocCurrencyProps } from "../../Language";

export const DepositorInformationEN = ({ currency }: DocCurrencyProps) => {
  return (
    <article className="lysa-doc depositor-information">
      <header>
        <h1>Depositor Information</h1>
      </header>

      <section>
        <h2>Basic information about the protection of deposits:</h2>
        <h3>
          Deposits with Lysa AB are kept at a client funds account with Danske
          Bank A/S, Denmark, Denmark branch office and are protected by:
        </h3>
        <p>
          Garantiformuen
          <sup>
            <a href="#fn1" id="fnref1">
              1
            </a>
          </sup>
        </p>

        <h3>Limit of protection:</h3>
        <p>
          EUR 100,000 per depositors per credit institution.
          <sup>
            <a href="#fn2" id="fnref2">
              2
            </a>
          </sup>
        </p>

        <h3>If you have more deposits at the same credit institution:</h3>
        <p>
          All your deposits at the same credit institution are “aggregated” and
          the total is subject to the limit of EUR 100,000.
          <sup>
            <a href="#fn2" id="fnref2_2">
              2
            </a>
          </sup>
        </p>

        <h3>If you have a joint account with other person(s):</h3>
        <p>The limit of EUR 100,000 applies to each depositor separately.</p>

        <h3>Reimbursement period in case of credit institution’s failure:</h3>
        <p>
          Within seven business days.
          <sup>
            <a href="#fn3" id="fnref3">
              3
            </a>
          </sup>
        </p>

        <h3>Currency:</h3>
        <p>{ currency }.</p>

        <h3>Contact:</h3>
        <p>
          <address>
            Garantiformuen
            <br />
            Sankt Annæ Plads 13, 2. tv.
            <br />
            DK-1250 København K
            <br />
            Telephone: <a href="tel:+45 33 14 62 45">+45 33 14 62 45</a>
            <br />
            E-mail: <a href="mailto:gii@gii.dk">gii@gii.dk</a>
            <br />
            Website:{" "}
            <a
              href="https://www.gii.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.gii.dk
            </a>
            <br />
          </address>
        </p>
      </section>

      <section>
        <h2>Other important information</h2>
        <p>
          In general, all retail and business depositors are covered by Deposit
          Guarantee Schemes. Exceptions for certain deposits are stated on the
          website of the responsible Deposit Guarantee Scheme. Your credit
          institution will also inform you on request whether certain products
          are covered or not. If deposits are covered, the credit institution
          shall also confirm this on the statement of account.
        </p>
      </section>

      <section className="footnotes">
        <hr />
        <ol>
          <li id="fn1">
            <h1>Scheme responsible for the protection of your deposit</h1>
            <p>
              Your deposit is covered by a statutory Deposit Guarantee Scheme.
              If insolvency should occur, your deposits would be repaid up to
              EUR 100,000 by the Deposit Guarantee Scheme.
            </p>
            <a href="#fnref1" className="footnote-backref">
              ↩
            </a>
          </li>
          <li id="fn2">
            <h1>General limit of protection</h1>
            <p>
              If a deposit is unavailable because a credit institution is unable
              to meet its financial obligations, depositors are repaid by a
              Deposit Guarantee Scheme. This repayment covers at maximum EUR
              100,000 per credit institution. This means that all deposits at
              the same credit institution are added up in order to determine the
              coverage level. If, for instance, a depositors holds a saving
              account with EUR 90,000 and a salary account with EUR 20,000, the
              depositor will only be repaid EUR 100,000.
            </p>
            <p>
              In certain cases also deposits in excess of EUR 100,000 are
              covered.
            </p>
            <ul>
              <li>
                Deposits of individual pension savings are wholly covered by
                law.
              </li>
              <li>
                Deposits of funds which relate to the transfer of real property
                are covered up to an amount corresponding to EUR 10 million
                during 12 months from the deposit, under the condition that the
                real estate has been used or will be used mainly for
                non-commercial purposes.
              </li>
              <li>
                Deposits which by law has a social purpose and which are
                connected to certain life situations, such as marriage, divorce
                or disability, are covered up to to an amount corresponding to
                EUR 150,000 during six months following the deposit.
              </li>
              <li>
                Deposits which by law has a social purpose and which are
                connected to certain life situations, such as marriage, divorce
                or disability, are covered up to to an amount corresponding to
                EUR 150,000 during six months following the deposit.
              </li>
            </ul>
            <p>
              Further information can be found on{" "}
              <a
                href="https://www.gii.dk"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gii.dk
              </a>
              .
            </p>
            <a href="#fnref2" className="footnote-backref">
              ↩
            </a>
          </li>
          <li id="fn3">
            <h1>Reimbursement</h1>
            <p>The responsible Deposit Guarantee Scheme is:</p>
            <p>
              <address>
                Garantiformuen
                <br />
                Sankt Annæ Plads 13, 2. tv.
                <br />
                DK-1250 København K
                <br />
                Telephone: <a href="tel:+45 33 14 62 45">+45 33 14 62 45</a>
                <br />
                E-mail: <a href="mailto:gii@gii.dk">gii@gii.dk</a>
                <br />
                Website:{" "}
                <a
                  href="https://www.gii.dk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.gii.dk
                </a>
                <br />
              </address>
            </p>
            <p>
              The Deposit Guarantee Scheme will repay your deposits up to EUR
              100,000 by within seven business days sending a payment order to
              the institution in your home country which administers the deposit
              guarantee fund, which then will make the payment.
            </p>
            <p>
              If you have not received repayment within the time specified, you
              should contact the Deposit Guarantee Scheme since the opportunity
              to claim repayment might cease after a certain time. Further
              information can be found at{" "}
              <a
                href="https://www.gii.dk"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gii.dk
              </a>
              .
            </p>
            <a href="#fnref3" className="footnote-backref">
              ↩
            </a>
          </li>
        </ol>
      </section>
    </article>
  );
};

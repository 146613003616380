import React from "react";
import { DocProps, DocMap } from "../../Language";
import { TermsRetrieveExternalAccountAndTransferSV as SV } from "./TermsRetrieveExternalAccountAndTransferSV";
import "../../Documents.css";

const docsMap: DocMap = { SV };

export const TermsRetrieveExternalAccountAndTransfer: React.FC<DocProps> = ({
  language,
}) => React.createElement(docsMap[language], null);

import React from "react";

export const CustomerAgreementSV = () => {
  return (
    <article className="lysa-doc customer-agreement">
      <header>
        <h1>Avtal och allmänna villkor för portföljförvaltning</h1>
        <p>v. 2020:3</p>
      </header>

      <section>
        <h2>1. Allmänt</h2>
        <p>
          Mellan dig som kund och Lysa AB, org. nr 559028-0821 ("<b>Lysa</b>")
          träffas avtal om portföljförvaltning enligt nedanstående villkor samt
          enligt vid var tid gällande placeringsriktlinjer ("<b>Avtalet</b>").
        </p>
        <p>
          Avtalet börjar gälla när kunden undertecknat Avtalet eller signerat
          Avtalet elektroniskt via Lysas hemsida, Lysa tagit del av sådan
          signatur och Lysa efter sedvanlig prövning godkänt kunden.
        </p>
      </section>

      <section>
        <h2>2. Lysas uppdrag</h2>
        <p>
          Lysa är en automatisk investeringstjänst. Genom ingåendet av Avtalet
          uppdrar kunden åt Lysa att förvalta samtliga kundens konton hos Lysa
          (vardera ett "<b>Lysakonto</b>") på de villkor som framgår av Avtalet.
          Lysa åtar sig detta portföljförvaltningsuppdrag ("<b>Uppdraget</b>").
        </p>
        <p>
          Uppdraget ska ske i kundens intresse och med tillbörlig omsorg. Kunden
          har inte rätt att göra gällande några andra inskränkningar i Uppdraget
          än som framgår av Avtalet.
        </p>
      </section>

      <section>
        <h2>3. Fullmakt</h2>
        <p>
          Kunden befullmäktigar Lysa att vidta alla de åtgärder som krävs för
          att fullgöra Avtalet, innefattande exempelvis köp och försäljning av
          finansiella instrument.
        </p>
      </section>

      <section>
        <h2>4. Kundens försäkran m.m.</h2>
        <p>
          Kunden är medveten om och accepterar de risker som är förenade med
          placeringar enligt Avtalet. Kunden är vidare medveten om:
        </p>
        <ul>
          <li>
            att värdet av tillgångarna på Lysakontot och dess avkastning kan
            påverkas av ett antal faktorer såsom exempelvis olika
            marknadsfaktorer eller andra omständigheter som påverkar värdet av
            finansiella instrument och att utfallet av Uppdraget kan bli både
            positivt och negativt,
          </li>
          <li>
            att Lysa inte lämnar någon garanti eller ikläder sig något ansvar
            för värdeutvecklingen av tillgångarna på Lysakontot eller att visst
            resultat uppnås,
          </li>
          <li>
            att Lysas tjänst inte är byggd för att tajma marknaden och att Lysa
            därför inte garanterar kunden en viss stängningskurs,
          </li>
          <li>
            att Lysa inte ansvarar för resultatet av gjorda eller underlåtna
            investeringar och försäljningar enligt Avtalet, och
          </li>
          <li>
            att Lysa inte beaktar de skattemässiga konsekvenserna för kunden av
            placeringar gjorda i enlighet med Avtalet.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Kundkrav</h2>
        <h3>5.1 Fysisk person</h3>
        <p>
          Kunden ska tillhandahålla de uppgifter som Lysa kräver för att Lysa
          ska uppnå tillfredsställande krav på kundkännedom och godkänd
          legitimation i enlighet med vad Lysa vid var tid kräver. Kunden ska ha
          ett konto hos en bank i det land där kunden är bosatt och har
          skatterättslig hemvist.
        </p>
        <h3>5.2 Juridisk person</h3>
        <p>
          Kunden ska tillhandahålla de uppgifter som Lysa kräver för att Lysa
          ska uppnå tillfredsställande krav på kundkännedom och godkänd
          legitimation i enlighet med vad Lysa vid var tid kräver. Kunden ska ha
          ett konto hos bank i det land där kunden har sitt säte.
        </p>
        <h3>5.3 FATCA</h3>
        <p>
          Lysas tjänster enligt Avtalet riktar sig inte till fysiska personer
          bosatta i USA eller juridiska personer med säte i USA eller andra U.S.
          Persons (som det definieras i vid var tid gällande Regulation S till
          United States Securities Act 1933) eller till sådana personer i andra
          länder där det krävs att Lysa har vidtagit registreringsåtgärder eller
          andra liknande åtgärder.
        </p>
      </section>

      <section>
        <h2>6. Försäkran om skatterättslig hemvist</h2>
        <p>
          Kunden ska själv förvissa sig om sin skatterättsliga hemvist. För
          fysisk person är det normalt det land/stat vars lag anger
          skattskyldighet på grund av huvudsaklig vistelse, bosättning eller
          liknande omständighet. För juridisk person är det normalt det land där
          den juridiska personen är registrerad eller där företaget har sitt
          säte.
        </p>
        <p>
          Kunden försäkrar att till Lysa lämnade uppgifter för beskattning,
          tillämpning av lagregler om uppgiftsskyldighet m.m. är riktiga. Kunden
          förbinder sig att utan dröjsmål informera Lysa om kunden byter
          skatterättslig hemvist.
        </p>
      </section>

      <section>
        <h2>7. Förvaltningsersättning, skatter m.m.</h2>
        <p>
          Avgifter utgår i enlighet med vid var tid av Lysa tillämpad prislista
          som publiceras på Lysas hemsida. Avgifterna består av (i) avgift för
          portföljförvaltning och (ii) avgifter för de värdepappersfonder som är
          distribuerade och förvaltade av bolag inom Lysa-koncernen (“
          <b>Lysas Fonder</b>”) i vilka Lysa investerar kundens medel.
        </p>
        <p>
          Avgift för portföljförvaltningen är en avgift till Lysa. Den beräknas
          dagligen på utgående kontovärde och debiteras Lysakontot månadsvis i
          efterskott. Om kunden är en juridisk person kan Lysa erbjuda kunden
          ett annat sätt att betala. Lysa tillämpar en minimumgräns innan
          debitering genomförs. Om kunden gör ett uttag avseende hela
          kontovärdet debiteras Lysakontot för innestående
          portföljförvaltningsavgift. Lysa äger rätt att sälja innehav på
          kundens Lysakonto för att täcka kostnader för
          portföljförvaltningsavgiften.
        </p>
        <p>
          Vidare utgör förvaltningsavgiften för Lysas Fonder en avgift till Lysa
          Fonder AB. Vid Lysas Fonders placering i underliggande fondandelar
          tillkommer avgifter för courtage och valutaväxling samt
          förvaltningsavgift som uttas av ansvarigt fondbolag för den aktuella
          underliggande fonden.
        </p>
        <p>
          Kunden svarar för eventuella skatter och andra avgifter som ska
          erläggas enligt lag med anledning av Uppdraget.
        </p>
      </section>

      <section>
        <h2>8. Lysakonto</h2>
        <p>
          Ett Lysakonto utgörs av, för juridiska personer, en depå eller
          kapitalförsäkring avsedd för portföljförvaltning av finansiella
          instrument samt likvida medel som mottagits och förvaras av Lysa med
          redovisningsskyldighet (klientmedel) eller, för fysiska personer, ett
          investeringssparkonto avsett för portföljförvaltning av finansiella
          instrument samt likvida medel som mottagits och förvaras av Lysa med
          redovisningsskyldighet (klientmedel).
        </p>
        <p>Avtalet omfattar samtliga kundens Lysakonton från tid till annan.</p>
        <p>
          Kunden har inte rätt att självständigt förfoga över de finansiella
          instrument och likvida medel som ingår i Lysakontot och som omfattas
          av Uppdraget. Kunden har emellertid rätt att göra uttag i enlighet med
          vad som anges i punkt 9 (<i>Insättningar och uttag</i>).
        </p>
      </section>

      <section>
        <h2>9. Insättningar och uttag</h2>
        <h3>9.1 Insättningar</h3>
        <p>
          Lysa ska investera insättningar på kundens Lysakonto i linje med
          Lysakontots placeringsriktlinjer (se vidare punkt 10 –{" "}
          <i>Placeringsriktlinjer</i>) i de instrument som framgår av punkt 11 (
          <i>Instrument som kan ingå i portföljen</i>) så snart som möjligt, i
          normalfallet inte senare än tre svenska bankdagar från den svenska
          bankdag som insättningen mottagits på kundens Lysakonto. Bl.a. av
          säkerhetsskäl kan exempelvis Lysa komma att företa manuella kontroller
          eller dylikt som medför en längre handläggningstid före det att Lysa
          kan lägga en order. Insättningar som kommer in senare än kl. 16:00
          svensk tid anses mottagna först nästkommande bankdag. Lysa garanterar
          inte tjänstens funktionalitet om kundens initiala insättning
          understiger 1 000 kronor.
        </p>
        <p>
          Om en kund har fler än ett Lysakonto och det inte framgår av vilket
          Lysakonto en insättning avser, har Lysa rätt att debitera insättningen
          på kundens först öppnade Lysakonto om inte kunden meddelat Lysa annat
          inom 14 dagar från den dag som insättningen mottagits enligt ovan.
        </p>
        <p>
          Om det inte framgår vilken kund som har gjort eller är mottagare av en
          insättning har Lysa rätt, men inte en skyldighet, att vidta åtgärder
          för att spåra vilken kund insättningen avser. Om sådana åtgärder är
          förenade med kostnader för Lysa (t.ex. banks avgift för spårning) har
          Lysa rätt till ersättning för sådana kostnader från kunden. Lysa äger
          rätt att avräkna belopp motsvarande kostnaden från den relevanta
          insättningen och debitera kvarvarande belopp av insättningen på
          kundens först öppnade Lysakonto. Om en okänd insättning uppgår till
          högst 1 500 kronor, har Lysa efter två år från det att insättningen
          kommit Lysa tillhanda och kund inte gjort anspråk på insättningen,
          rätt att skänka beloppet till välgörande ändamål av Lysas val.
        </p>
        <h3>9.2 Uttag</h3>
        <p>
          Kunden kan göra uttag genom att begära uttag i inloggat läge på Lysas
          hemsida till ett av kunden föranmält uttagskonto. Uttagskonto ska
          tillhöra kunden.
        </p>
        <p>
          Vid kunds begäran om uttag ska Lysa lägga en försäljningsorder
          avseende fondandelar hos relevant fondbolag. Fondbolaget avyttrar
          därefter kundens innehav i enlighet med Lysas begäran och i enlighet
          med tillämpliga fondbestämmelser. När Lysa mottagit försäljningslikvid
          för relevant avyttring ska Lysa betala likviden till kundens
          uttagskonto. Lysa ska begära avyttring av kundens innehav och
          verkställa uttaget genom att betala likviden till kunden så snart som
          möjligt. Lysa garanterar inte när Lysa kan begära avyttring av kundens
          innehav eller verkställa ett uttag och garanterar alltså inte heller
          en viss stängningskurs (NAV) för relevant uttag. Bl.a. av
          säkerhetsskäl kan exempelvis Lysa komma att företa manuella kontroller
          eller dylikt som medför en längre handläggningstid före det att Lysa
          kan lägga en order. Kunden är införstådd med att större uttag kan
          föranleda Lysa att snabbt avyttra i Lysakontot befintliga finansiella
          instrument, varvid det ekonomiska utfallet kan komma att påverkas
          negativt och att avsteg i samband härmed kan komma att ske från
          placeringsriktlinjerena.
        </p>
      </section>

      <section>
        <h2>10. Placeringsriktlinjer</h2>
        <p>
          För Uppdraget gäller de särskilda placeringsriktlinjerna som
          fastställts för varje Lysakonto. Placeringsriktlinjerna fastställs
          genom en interaktiv process med kunden, där kunden fått ta del av
          Lysas lämplighetsbedömning. I placeringsriktlinjerna fastställs en
          målfördelning mellan instrument som ger exponering mot aktiemarknaden
          och instrument som ger exponering mot räntemarknaden. Gällande
          placeringsriktlinjer för varje Lysakonto finns tillgängliga för kunden
          i inloggat läge på Lysas hemsida.
        </p>
        <p>
          Inom ramen för Uppdraget kan Lysa ombalansera kundens portfölj mellan
          olika instrument. Lysa åtar sig bl.a. att ombalansera kundens portfölj
          för att ligga i linje med portföljens målfördelning
        </p>
        <p>
          Kunden har rätt att, med vissa begränsningar, ändra portföljens
          målfördelning genom att ändra placeringsriktlinjerna. Sådan ändring
          görs av kunden i inloggat läge på Lysas hemsida. Ändring av
          placeringsriktlinjerna ersätter tidigare gällande riktlinjer och
          träder i kraft samma dag. Lysa ska omplacera kundens innehav på det
          Lysakonto som omfattas av placeringsriktlinjerna. Förvärv och
          avyttring av innehav för att genomföra omplaceringen sker i enlighet
          med vad som anges i punkt 9 (<i>Insättningar och uttag</i>).
        </p>
        <p>
          Kunden kan genom förändring av placeringsriktlinjerna eller på anvisat
          sätt i inloggat läge på Lysas hemsida även göra sådana val avseende
          inriktningen på sin portfölj som Lysa från tid till annan erbjuder,
          t.ex. för närvarande val avseende investeringsinriktning. Om Lysa
          avser upphöra att erbjuda ett visst sådant val som erbjudits kunden
          ska Lysa skyndsamt informera kunden om detta varefter kunden har rätt
          att säga upp Avtalet med omedelbar verkan. Om kunden inte vidtagit
          någon åtgärd avseende det aktuella valet inom en månad efter att
          kunden har fått meddelandet enligt punkt 20 (<i>Meddelanden</i>) har
          Lysa rätt att placera om kundens tillgångar på sådant sätt som Lysa
          anser tillbörligt. Om det, på grund av omständigheter som ligger
          utanför Lysas kontroll, inte är möjligt att fortsatt tillhandahålla
          kundens val under en sådan tid, eller om Lysa bedömer att det inte är
          i kundens bästa intresse att fortsatt göra så (t.ex. på grund av ökad
          kostnad), får Lysa placera om kundens tillgångar på ett sådant sätt
          som Lysa anser tillbörligt före det att en månad har gått från Lysas
          meddelande. Lysa ska i sådant fall skyndsamt meddela kunden om detta
          samt redogöra för skälen härför. Kundens placeringsriktlinjer kommer i
          förekommande fall att uppdateras i enlighet med kundens nya val eller
          Lysas omplacering.
        </p>
        <p>
          Kunden åtar sig att informera Lysa vid ändringar av de uppgifter som
          lämnats i det rådgivningsunderlag som ligger till grund för Lysa
          lämplighetsbedömning. Information om sådana ändringar ska lämnas i
          inloggat läge på Lysas hemsida.
        </p>
      </section>

      <section>
        <h2>11. Instrument som kan ingå i portföljen</h2>
        <p>
          Kundens portfölj består av en sammansättning av Lysas Fonder
          (värdepappersfonder distribuerade och förvaltade av bolag inom
          Lysa-koncernen). Lysas Fonder kan inte handlas direkt utan utgör
          endast beståndsdelar i Lysas portföljförvaltning. Kunden har inte rätt
          att flytta innehav i sin portfölj från sitt Lysakonto. Lysas
          investeringsfilosofi beskrivs utförligt i{" "}
          <a
            href="https://lysa.se/whitepaper"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lysas White Paper
          </a>
          .
        </p>
      </section>

      <section>
        <h2>12. Målsättning och metod för jämförelse</h2>
        <p>
          Målet med förvaltningen är att maximera kundens avkastning givet
          kundens individuella mål, ekonomiska situation och riskpreferenser.
          Lysas investeringsfilosofi (se{" "}
          <a
            href="https://lysa.se/whitepaper"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lysas White Paper
          </a>
          ) innebär att förvaltningens resultat kommer vara förknippat med
          marknadsutvecklingen. Kunden kan jämföra utvecklingen mot relevanta
          jämförelseindex i inloggat läge på Lysas hemsida.
        </p>
      </section>

      <section>
        <h2>13. Information, rapportering och värdering</h2>
        <h3>13.1 Information om Uppdraget</h3>
        <p>
          Kunden har löpande tillgång till all information om Uppdraget så som
          uppgift om portföljens innehav och värde, uppgifter om den totala
          summan av arvoden och avgifter samt information om utförda affärer, i
          inloggat läge på Lysas hemsida. Avräkningsnotor avseende genomförda
          transaktioner finns att tillgå i inloggat läge på Lysas hemsida.
        </p>
        <h3>13.2 Värdering av förvaltade fonder</h3>
        <p>
          Värderingen av kundens portfölj baseras på aktuellt NAV för de av
          Lysas Fonder som ingår i kundens portfölj och presenteras för kunden i
          inloggat läge på Lysas hemsida. Aktuellt NAV för Lysas Fonder
          presenteras för kunden på{" "}
          <a
            href="https://lysa.se/fonder/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.se/fonder
          </a>
          . Detta fastställs med en dags fördröjning, sk. fördröjd NAV-sättning
          och utgörs av de dagligen uppdaterade NAV-kurserna på underliggande
          fonder.
        </p>
        <p>
          Redovisning av fondandelar sker med det decimaltal som Lysa tillämpar.
          Redovisar en fond innehav av fondandelar till Lysa med angivande av
          fler decimaler än vad Lysa tillämpar avrundar Lysa enligt gängse
          avrundningsrutiner innan redovisning sker till kunden.
        </p>
        <p>
          Lysa kommer meddela kunden om värdet på kundens innehav på ett
          Lysakonto minskar med tio procent eller mer jämfört med värdet det
          första datumet under innevarande kvartal och därefter vid multiplar om
          tio procent.
        </p>
        <h3>13.3 Information om förvaltade fonder</h3>
        <p>
          Av fondbestämmelserna för respektive av Lysas Fonder framgår villkoren
          för fonden, bl.a. i vilka värdepapper fonden får investera och vilka
          avgifter som tas ut. Faktablad, informationsbroschyr och
          fondbestämmelser finns tillgängliga på{" "}
          <a
            href="https://lysa.se/fonder/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.se/fonder
          </a>
          .
        </p>
        <p>
          Genom Avtalet avsäger sig kunden sådan information som fondbolagen
          tillsänder Lysa i egenskap av registrerad för fondandelar i kundens
          ställe. Lysa ska dock alltid lämna kunden information om väsentliga
          ändringar av fondbestämmelser eller information som avser fusion i
          enlighet med i enlighet med 4 kap. 12 § lag (SFS 2004:46) om
          värdepappersfonder.
        </p>
        <h3>13.4 Tilläggstjänster avseende information och rapporter m.m.</h3>
        <p>
          Kunden har möjlighet att anmäla sig till sådana tilläggstjänster som
          Lysa från tid till annan tillhandahåller som innebär att kunden kan få
          information skickad till sig via den e-postadress som kunden har
          meddelat Lysa. Kunden är medveten om att informationen som kunden
          erhåller från Lysa inom ramen för sådan tilläggstjänst kan innehålla
          uppgifter som skyddas av sekretess. Genom att kunden anmäler sig till
          sådan tilläggstjänst godkänner kunden att Lysa okrypterat skickar
          information inom ramen för den relevanta tjänsten till den
          e-postadress som kunden har meddelat Lysa. Kunden är medveten om och
          accepterar risken att någon obehörig får del av den information som
          förmedlas och att informationen kan förvrängas under förmedlingen.
        </p>
      </section>

      <section>
        <h2>14. Reklamation</h2>
        <p>
          Kunden ska för Lysa påtala fel eller brister i Uppdraget (för
          tydlighets skull inklusive avvikelser från gällande
          placeringsriktlinjer) som framgår av redovisning eller rapport från
          Lysa, utebliven redovisning eller rapportering samt andra fel eller
          brister som hänför sig till Avtalet. Kunden ska granska avräkningsnota
          eller annat besked, kontoutdrag, transaktionssammandrag eller annan
          rapport avseende genomförda transaktioner som avsänts från Lysa och
          skriftligen till Lysa påtala eventuella fel och brister som framgår
          därav.
        </p>
        <p>
          Reklamation ska ske omgående efter att kunden insåg eller borde ha
          insett felet eller bristen.
        </p>
        <p>
          För utfört kommissionsuppdrag lämnat av en konsument i egenskap av
          icke-professionell kund gäller att reklamation i ovanstående fall ska
          framföras inom skälig tid från det att kunden insåg eller borde ha
          insett felet eller bristen.
        </p>
        <p>
          Om reklamation inte lämnas enligt ovan förlorar kunden rätten att
          begära ersättning eller kräva andra åtgärder från Lysa.
        </p>
      </section>

      <section>
        <h2>15. Uppdrag som lämnas av obehörig</h2>
        <p>
          Kunden har tillgång till Lysa genom inloggning med sådan
          verifieringsmetod som Lysa vid var tid godkänner. Om kunden är en
          juridisk person har kundens förvalda administratörer tillgång till
          kundens Lysakonto genom inloggning med sådan verifieringsmetod som
          Lysa vid var tid godkänner. Juridisk person ansvarar för att informera
          Lysa om en förvald administratör inte längre ska ha behörighet till
          kundens Lysakonto.
        </p>
        <p>
          Kunden är ansvarig för att ingen obehörig får åtkomst till kundens
          Lysakonto, för att vidta rimliga åtgärder för att skydda sina
          inloggningsuppgifter och att inte avslöja sina inloggningsuppgifter
          för någon obehörig. Kunden är införstådd med att samtliga
          instruktioner som lämnas till Lysa i inloggat läge är bindande för
          kunden. Lysa är inte ansvarigt för direkt eller indirekt skada som
          åsamkas kunden genom att obehörig får tillgång till kundens Lysakonto.
        </p>
      </section>

      <section>
        <h2>16. Förfoganderätt</h2>
        <p>
          Kunden förbinder sig att tillse att förfoganderätten till Lysakontot,
          de finansiella instrument och likvida medel som ingår i Lysakontot
          inte är inskränkt i vidare mån än som framgår av Avtalet och de
          kontoavtal som reglerar kundens Lysakonton.
        </p>
      </section>

      <section>
        <h2>17. Rätt att stänga av kunder</h2>
        <p>
          Lysa äger rätt att utan föregående meddelande till kunden med
          omedelbar verkan stänga av kunden från Lysas tjänster om:
        </p>
        <ul>
          <li>
            Lysa skulle misstänka att kundens utnyttjande av Lysas tjänster
            skulle stå i strid med vid var tid gällande lagstiftning om
            insiderbrott eller otillbörlig marknadspåverkan eller god sed på
            marknaden,
          </li>
          <li>
            det föreligger fel eller brister i tele- eller annan
            kommunikationsförbindelse, datautrustning eller datasystem,
          </li>
          <li>
            Lysa finner att det bör ske för att skydda kunden, andra kunders,
            Lysas eller andras intressen av en väl fungerande marknad,
          </li>
          <li>kunden sagt upp Avtalet eller samtliga sina depå-/kontoavtal,</li>
          <li>kunden inte inkommit med försäkran om skatterättslig hemvist,</li>
          <li>
            kunden inte inkommit med tillräckliga uppgifter för att Lysa ska
            uppnå tillfredsställande krav på kundkännedom,
          </li>
          <li>
            Lysa får kännedom om att kunden är bosatt i, har väsentlig
            anknytning till eller har medborgarskap i annat land med
            restriktioner för handel med finansiella instrument,
          </li>
          <li>
            kunden är eller blir deklarationsskyldig i annat land än det land
            vari kunden hade skatterättslig hemvist vid tidpunkten för ingåendet
            av Avtalet, eller
          </li>
          <li>
            kunden enligt Lysas bedömning agerar på ett sätt som är olagligt,
            olämpligt eller stötande.
          </li>
        </ul>

        <p>
          Om Lysa stängt av kunden ska Lysa snarast möjligt informera kunden
          därom.
        </p>
      </section>

      <section>
        <h2>18. Instruktion för bästa orderutförande</h2>
        <p>
          Lysa ska iaktta vid var tid gällande instruktion för bästa
          orderutförande. I samband med ingåendet av Avtalet erhåller kunden de
          instruktioner som gäller vid tidpunkten för Avtalets ingående. Lysas
          vid var tid gällande instruktion för orderutförande är tillgängliga på
          Lysas hemsida.
        </p>
      </section>

      <section>
        <h2>19. Tekniska problem</h2>
        <p>
          Kunden är medveten om att det kan uppkomma driftavbrott i kundens,
          Lysas eller annans datasystem (hård- eller mjukvara), tele- eller
          elsystem eller liknande fel som kan medföra att tjänsterna enligt
          Avtalet inte kan utföras som avtalat, exempelvis att kunden inte kan
          göra insättningar eller uttag på sitt Lysakonto eller ändra sina
          placeringsriktlinjer. Lysa ansvarar endast för skada som kunden
          åsamkats till följd av ovanstående tekniska problem om Lysa varit
          oaktsamt. För skada som uppkommit vid kommissionsuppdrag i förhållande
          till konsument ankommer det på Lysa att visa att skadan inte uppkommit
          på grund av Lysas vårdslöshet. Lysa ansvarar endast för indirekt skada
          som orsakats av Lysas grova vårdslöshet. Begränsningen gäller inte vid
          uppdrag som lämnats av konsument om den indirekta skadan orsakats av
          Lysas vårdslöshet.
        </p>
      </section>

      <section>
        <h2>
          20. Information om stängning av Lysas hemsida vid planerat underhåll
        </h2>
        <p>
          Vid stängning av Lysas hemsida för planerat underhåll m.m. ska Lysa i
          god tid informera kunden om detta.
        </p>
      </section>

      <section>
        <h2>21. Stängning av Lysas Fonder vid extraordinära förhållanden</h2>
        <p>
          Av fondbestämmelserna för respektive av Lysas Fonder framgår att
          fonderna kan stängas för insättning och uttag av fondandelar om sådana
          extraordinära förhållanden har inträffat som gör att en värdering av
          fonden inte kan göras på ett sätt som säkerställer andelägarnas lika
          rätt. Om så sker kommer Lysa alltjämt att lägga köp- och säljordrar i
          enlighet med Avtalet. Kunden är medveten om att en sådan situation
          dock kan innebära viss fördröjning i insättningar, uttag och
          ombalanseringar, fram tills dess att den relevanta fonden åter öppnat
          för handel.
        </p>
      </section>

      <section>
        <h2>22. Begränsning av Lysas ansvar</h2>
        <p>
          Lysa är inte ansvarigt för skada som beror av svenskt eller utländskt
          lagbud, svensk eller utländsk myndighetsåtgärd, krigshändelse, strejk,
          blockad, bojkott, lockout, epidemi, pandemi, karantän eller
          omständighet utanför Lysas kontroll. Förbehållet i fråga om strejk,
          blockad, bojkott och lockout gäller även om Lysa självt är föremål för
          eller vidtar sådan konfliktåtgärd.
        </p>
        <p>
          Lysa är inte ansvarigt för skada som orsakats av - svensk eller
          utländsk - utförandeplats, depåförande tredjepart,
          värdepapperscentral, clearingorganisation, eller andra som
          tillhandahåller motsvarande tjänster, och inte heller av
          uppdragstagare som Lysa med tillbörlig omsorg anlitat eller som
          anvisats av kunden. Detsamma gäller skada som orsakats av att ovan
          organisationer eller uppdragstagare blivit insolventa. Lysa ansvarar
          inte för skada som uppkommer för kunden eller annan med anledning av
          förfogandeinskränkning som kan komma att tillämpas mot Lysa
          beträffande finansiella instrument. Lysa ansvarar inte heller för de
          skattemässiga konsekvenser som gjorda placeringar medför.
        </p>
        <p>
          Lysa ansvarar endast för skada som uppkommit i andra fall om Lysa
          varit oaktsamt. Vid direkt eller indirekt skada som uppkommit vid
          kommissionsuppdrag i förhållande till konsument ankommer det på Lysa
          att visa att skadan inte uppkommit på grund av Lysas vårdslöshet. Lysa
          ansvarar endast för indirekt skada som orsakats av Lysas grova
          vårdslöshet. Begränsningen gäller inte vid uppdrag som lämnats av
          konsument om den indirekta skadan orsakats av Lysas vårdslöshet.
        </p>
        <p>
          Föreligger hinder för Lysa, på grund av omständighet som anges i
          första stycket, att helt eller delvis utföra åtgärd enligt Avtalet får
          åtgärd skjutas upp till dess hindret har upphört. Om Lysa till följd
          av sådan omständighet är förhindrat att verkställa eller ta emot
          betalning/leverans, ska varken Lysa eller kunden vara skyldig att
          betala ränta.
        </p>
        <p>
          Vad ovan sagts gäller i den mån inte annat följer av lagen (1998:1479)
          om värdepapperscentraler och kontoföring av finansiella instrument.
        </p>
      </section>

      <section>
        <h2>23. Meddelanden</h2>
        <h3>23.1 Meddelande från Lysa</h3>
        <p>
          Meddelande från Lysa till kunden får skickas med e-post eller annan
          elektronisk kommunikation, exempelvis via inloggat läge på Lysas
          hemsida. Kunden samtycker till att Lysa tillhandahåller information
          via elektroniskt meddelande i Lysas internettjänst eller till sådan
          e-postadress som kunden meddelat Lysa eller till kundens
          folkbokföringsadress.
        </p>
        <p>
          Kunden ska utan dröjsmål underrätta Lysa om ändring av
          kontaktuppgifter i inloggat läge på Lysas hemsida.
        </p>
        <p>
          Meddelande anses ha kommit kunden tillhanda vid avsändandet om det
          sänds till den elektroniska adress som kunden har uppgivit eller via
          inloggat läge på Lysas hemsida. Om ett sådant meddelande når kunden
          utanför normal kontorstid ska meddelandet anses ha kommit kunden
          tillhanda vid början av påföljande bankdag i kundens hemvistland.
          Meddelande brevledes anses ha kommit kunden tillhanda den andra
          bankdagen efter det att det avsänts.
        </p>
        <h3>23.2 Meddelande till Lysa</h3>
        <p>
          Meddelande från kunden till Lysa ska ställas till{" "}
          <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a>, såvida Lysa inte
          begärt svar till annan adress. Meddelande genom e-post eller annan
          elektronisk kommunikation anses ha kommit Lysa tillhanda vid
          avsändandet. Om ett sådant meddelande når Lysa utanför normal
          kontorstid ska meddelandet anses ha kommit Lysa tillhanda vid början
          av påföljande bankdag i Sverige.
        </p>
        <p>
          För meddelande avseende reklamation och hävning med anledning av
          kommissionsuppdrag som konsument lämnat i egenskap av icke
          professionell kund enligt Lysas kategorisering gäller att meddelande
          kan göras gällande om kunden kan visa att det skickats på ett
          ändamålsenligt sätt, även om det försenats, förvanskats eller inte
          kommit fram. Kunden ska dock, om denne har anledning att anta att Lysa
          inte fått meddelandet eller att det förvanskats, skicka om meddelandet
          till Lysa.
        </p>
      </section>

      <section>
        <h2>24. Avtalstid och uppsägning</h2>
        <p>
          Lysa kan säga upp Avtalet till upphörande två månader efter det att
          kunden enligt punkt 23 (<i>Meddelanden</i>) ska anses ha fått
          meddelandet.
        </p>
        <p>
          Kunden kan omgående säga upp Avtalet i inloggat läge på Lysas hemsida.
          Kunden kan även säga upp Avtalet på de sätt som framgår enligt punkt
          23 (<i>Meddelanden</i>) till upphörande en månad efter det Lysa enligt
          samma punkt ska anses ha fått meddelandet. Konsument kan även säga upp
          Avtalet till upphörande efter en månad muntligen via Lysas kundtjänst
          efter att ha vederbörligen identifierat sig med den verifieringsmetod
          som Lysa vid var tid godkänner.
        </p>
        <p>
          Vid Avtalets upphörande ska parterna genast reglera samtliga sina
          skyldigheter enligt Avtalet. Avtalet gäller dock i tillämpliga delar
          till dess att part fullgjort samtliga sina åtaganden gentemot
          motparten.
        </p>
        <p>
          Oavsett vad som sagts i föregående stycke får part säga upp Avtalet
          med omedelbar verkan om motparten väsentligen brutit mot Avtalet. I
          sådant fall ska varje avtalsbrott, där rättelse trots anmaning inte
          snarast möjligt vidtagits, anses som ett väsentligt avtalsbrott. Lysa
          får även säga upp Avtalet med omedelbar verkan:
        </p>
        <ul>
          <li>
            om det föranleds av värdepappersrättsliga eller andra restriktioner
            i Sverige eller annat land,
          </li>
          <li>
            om kunden försätts i konkurs, blivit föremål för
            företagsrekonstruktion, fått till stånd offentlig ackordsförhandling
            eller vid utmätning befunnits sakna tillgångar att betala sina
            skulder,
          </li>
          <li>
            om kunden ställt in sina betalningar eller i övrigt anses vara på
            obestånd, eller
          </li>
          <li>
            om kunden via transaktioner med Lysa agerat i strid med gällande
            lag.
          </li>
        </ul>
        <p>
          Vid Avtalets upphörande ska Lysa till kunden överföra samtliga på
          Lysakontot förtecknade värdepapper och likvida medel. Kunden ska lämna
          anvisningar till Lysa om överföringen. Om inte sådana anvisningar
          lämnats inom sextio kalenderdagar efter den dag Avtalet enligt
          uppsägningen upphört eller om överföringen inte kan ske enligt av
          kunden lämnade anvisningar, får Lysa sälja aktuella värdepapper och
          överföra likviden till kundens förvalda uttagskonto.
        </p>
        <p>
          Fondandelar i Lysas Fonder måste säljas vid Avtalets upphörande. Vid
          Avtalets upphörande äger Lysa rätt att sälja kundens innehav i Lysas
          Fonder och betala ut likviden till kundens föranmälda uttagskonto. Om
          kunden inte har föranmält uttagskonto ska kunden lämna anvisningar
          till Lysa om överföringen.
        </p>
      </section>

      <section>
        <h2>25. Ändring av Avtalet och avgifter</h2>
        <p>
          Ändringar av Avtalet eller Lysas avgifter har verkan gentemot kunden
          två månader efter det att kunden enligt punkt 23 (<i>Meddelanden</i>)
          ska anses ha mottagit meddelandet. Om kunden inte godtar ändringen har
          kunden rätt att inom nämnda tid säga upp Avtalet till omedelbart
          upphörande utan iakttagande av i punkt 24 (
          <i>Avtalstid och uppsägning</i>) angiven uppsägningstid.
        </p>
        <p>
          En ändring som anses vara obetydlig för kunden får genomföras utan att
          kunden informeras om det enligt ovan.
        </p>
        <p>
          Ändringar av och tillägg till Avtalet ska ske skriftligen för att vara
          gällande.
        </p>
      </section>

      <section>
        <h2>26. Överlåtelse</h2>
        <p>
          Lysa äger rätt att överlåta sina rättigheter eller skyldigheter enligt
          Avtalet till företag inom samma koncern som bolaget ingår eller annat
          företag till följd av associationsrättslig reglering.
        </p>
      </section>

      <section>
        <h2>27. Tillämplig lag</h2>
        <p>Tolkning och tillämpning av Avtalet ska ske enligt svensk rätt.</p>
      </section>
    </article>
  );
};

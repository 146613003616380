import React from "react";

export function InstructionOfBestExecutionEN() {
  return (
    <article className="lysa-doc instruction-of-best-execution">
      <header>
        <h1>Best execution policy</h1>
        <p className="ingress">
          The Best execution policy has been adopted by the Board of Directors
          of Lysa AB (”Lysa”) 2020-11-24 and replaces the previously applicable
          policy.
        </p>
        <p>Responsible for the document: Chief Executive Officer</p>
        <p className="ingress-note">
          The Swedish original version of the Best execution policy (
          <i>
            Swe. Instruktion för bästa orderutförande och sammanläggning av
            order
          </i>
          ) has been adopted by the Board of Directors. The English version is a
          translation.
        </p>
      </header>

      <section>
        <h2>1. Background</h2>
        <p>
          In accordance with Chapter 9, Section 33 of the Securities Markets
          Act, a securities company must establish guidelines for the best
          execution of orders. Further, Lysa must under the Commission Delegated
          Regulation (EU) 2017/565 make an annual revision of the guidelines.
          Therefore, Lysa has adopted the following policy.
        </p>
        <p>
          This policy includes information on how Lysa will ensure that the
          customers’ best interest is taken into account when placing orders.
          Orders are placed against the fund company Lysa Fonder AB when trading
          in the mutual funds distributed by Lysa (“Lysa’s funds”).
        </p>
      </section>

      <section>
        <h2>2. Best execution and order accumulation in Lysa’s funds</h2>
        <p>
          Lysa’s funds are mutual funds which are traded outside the market by
          transmitting an order to Lysa Fonder AB for execution in accordance
          with the fund rules. Lysa will execute a customer’s order fast,
          efficient and fair.
        </p>
        <p>
          Lysa will accumulate a customer’s order of fund shares in Lysa’s funds
          with other customers’ orders. An accumulated order which has been
          executed in its entirety will be distributed with regard to the NAV
          applicable for the relevant business day. If an accumulated order only
          can be partly executed, the executed part will be divided
          proportionally between the customers based on the NAV applicable for
          the relevant business day.
        </p>
      </section>

      <section>
        <h2>3. Disruptions</h2>
        <p>
          In case of market disruptions, insufficient access to Lysa’s internal
          technical systems or other force majeure events outside of Lysa’s
          control, it may according to Lysa’s assessment be impossible or
          inappropriate to execute an order according to any of the methods
          specified in this section. If so, Lysa will take all reasonable
          measures to by other means achieve the best possible result for the
          customer.
        </p>
      </section>

      <section>
        <h2>4. Compliance and updates </h2>
        <p>
          The Chief Executive Officer is responsible for the compliance and
          implementation of this document. Lysa will on an annual basis review
          its policy which has been adopted for best execution. The policy will
          also be reviewed when there has been a material change which affects
          Lysa’s possibilities to achieve the best possible result when
          executing customers’ orders. The policy will be updated annually or
          more regularly if necessary.
        </p>
      </section>
    </article>
  );
}

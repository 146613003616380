export enum Language {
  SV = "SV",
  EN = "EN",
}

export interface DocProps {
  language: Language;
}

export interface DocEntityProps extends DocProps {
  date?: string;
  name?: string;
  id?: string;
}

export interface DocCurrencyProps extends DocProps {
  currency: string;
}

export interface DocAccountProps extends DocProps {
  account: {
    accountSigning: string;
    name?: string;
    id?: string;
    companyId?: string;
    accountName: string;
    accountId?: string;
    stocks: number;
    bonds: number;
    investmentType: "BROAD" | "SUSTAINABLE";
  };
}

export interface DocAccountMap {
  [key: string]: React.FC<DocAccountProps>;
}

export interface DocCurrencyMap {
  [key: string]: React.FC<DocCurrencyProps>;
}

export interface DocEntityMap {
  [key: string]: React.FC<DocEntityProps>;
}

export interface DocMap<P = unknown> {
  [key: string]: React.FC<P>;
}

export const PLACEHOLDER = {
  [Language.SV]: "Fylls i senare",
  [Language.EN]: "Filled in at a later stage",
};

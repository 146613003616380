import React from "react";

export const AutogiroTermsSV = () => (
  <article className="lysa-doc autogiro-terms">
    <header>
      <h1>Villkor för autogiro</h1>
    </header>

    <p>
      Med betalningsmottagare avses nedan Lysa. Med betalarens
      betaltjänstleverantör avses nedan banken för kundens bankkonto.
    </p>

    <section>
      <h3>Medgivande till betalning via Autogiro</h3>
      <p>
        Undertecknad ("betalaren"), medger att betalning får göras genom uttag
        från angivet konto eller av betalaren senare angivet konto, på begäran
        av angiven betalningsmottagare för betalning till denne på viss dag
        (”förfallodagen”) via Autogiro. Betalaren samtycker till att behandling
        av personuppgifter som lämnats i detta medgivande behandlas av
        betalarens betaltjänstleverantör, betalningsmottagaren,
        betalningsmottagarens betaltjänstleverantör och Bankgirocentralen BGC AB
        för administration av tjänsten. Personuppgiftsansvariga för denna
        personuppgiftsbehandling är betalarens betaltjänstleverantör,
        betalningsmottagaren samt betalningsmottagarens betaltjänstleverantör.
        Betalaren kan när som helst begära att få tillgång till eller rättelse
        av personuppgifterna genom att kontakta betalarens
        betaltjänstleverantör. Ytterligare information om behandling av
        personuppgifter i samband med betalningar kan finnas i villkoren för
        kontot och i avtalet med betalningsmottagaren. Betalaren kan när som
        helst återkalla sitt samtycke, vilket medför att tjänsten i sin helhet
        avslutas.
      </p>
    </section>

    <section>
      <h3>Allmänt</h3>
      <p>
        Autogiro är en betaltjänst som innebär att betalningar utförs från
        betalarens konto på initiativ av betalningsmottagaren. För att betalaren
        ska kunna betala via Autogiro, ska betalaren lämna sitt medgivande till
        betalningsmottagaren om att denne får initiera betalningar från
        betalarens konto. Dessutom ska betalarens betaltjänstleverantör (t ex
        bank eller betalningsinstitut) godkänna att kontot kan användas för
        Autogiro och betalningsmottagaren ska godkänna betalaren som användare
        av Autogiro. Betalarens betaltjänstleverantör är inte skyldig att pröva
        behörigheten av eller meddela betalaren i förväg om begärda uttag. Uttag
        belastas betalarens konto enligt de regler som gäller hos betalarens
        betaltjänstleverantör. Meddelande om uttag får betalaren från sin
        betaltjänstleverantör. Medgivandet kan på betalarens begäran överflyttas
        till annat konto hos betaltjänstleverantören eller till konto hos annan
        betaltjänstleverantör.
      </p>
    </section>

    <section>
      <h3>Definition av bankdag</h3>
      <p>
        Med bankdag avses alla dagar utom lördag, söndag, midsommarafton,
        julafton eller nyårsafton eller annan allmän helgdag.
      </p>
    </section>

    <section>
      <h3>Information om betalning</h3>
      <p>
        Betalaren kommer av betalningsmottagaren att meddelas belopp,
        förfallodag och betalningssätt senast åtta bankdagar före förfallodagen.
        Detta kan meddelas inför varje enskild förfallodag eller vid ett
        tillfälle avseende flera framtida förfallodagar. Om meddelandet avser
        flera framtida förfallodagar ska meddelandet lämnas senast åtta
        bankdagar före den första förfallodagen. Detta gäller dock inte fall då
        betalaren godkänt uttaget i samband med köp eller beställning av vara
        eller tjänst. I sådant fall får betalaren meddelande av
        betalningsmottagaren om belopp, förfallodag och betalningssätt i samband
        med köpet och/eller beställningen. Genom undertecknandet av detta
        medgivande lämnar betalaren sitt samtycke till att betalningar som
        omfattas av betalningsmottagarens meddelande enligt denna punkt
        genomförs.
      </p>
    </section>

    <section>
      <h3>Täckning måste finnas på kontot</h3>
      <p>
        Betalaren ska se till att täckning finns på kontot senast kl. 00.01 på
        förfallodagen. Har betalaren inte täckning på kontot på förfallodagen
        kan det innebära att betalningar inte blir utförda. Om täckning saknas
        för betalning på förfallodagen får betalningsmottagaren göra ytterligare
        uttagsförsök under de kommande bankdagarna. Betalaren kan på begäran få
        information från betalningsmottagaren om antalet uttagsförsök.
      </p>
    </section>

    <section>
      <h3>Stoppa betalning (återkallelse av betalningsorder)</h3>
      <p>
        Betalaren får stoppa en betalning genom att kontakta antingen
        betalningsmottagaren senast två bankdagar före förfallodagen eller sin
        betaltjänstleverantör senast bankdagen före förfallodagen vid den
        tidpunkt som anges av betaltjänstleverantören Om betalaren stoppar en
        betalning enligt ovan innebär det att den aktuella betalningen stoppas
        vid ett enskilt tillfälle. Om betalaren vill att samtliga framtida
        betalningar som initieras av betalningsmottagaren ska stoppas måste
        betalaren återkalla medgivandet.
      </p>
    </section>

    <section>
      <h3>Medgivandets giltighetstid, återkallelse</h3>
      <p>
        Medgivandet gäller tills vidare. Betalaren har rätt att när som helst
        återkalla medgivandet genom att kontakta betalningsmottagaren eller sin
        betaltjänstleverantör. Meddelandet om återkallelse av medgivandet ska
        för att stoppa ännu inte genomförda betalningar vara
        betalningsmottagaren tillhanda senast fem bankdagar före förfallodagen
        alternativt vara betalarens betaltjänstleverantör tillhanda senast
        bankdagen före förfallodagen vid den tidpunkt som anges av
        betaltjänstleverantören.
      </p>
    </section>

    <section>
      <h3>
        Rätten för betalningsmottagaren och betalarens betaltjänstleverantör att
        avsluta anslutningen till Autogiro
      </h3>
      <p>
        Betalningsmottagaren har rätt att avsluta betalarens anslutning till
        Autogiro trettio dagar efter det att betalningsmottagaren underrättat
        betalaren härom. Betalningsmottagaren har dock rätt att omedelbart
        avsluta betalarens anslutning till Autogiro om betalaren vid upprepade
        tillfällen inte har haft tillräcklig kontobehållning på förfallodagen
        eller om det konto som medgivandet avser avslutas eller om
        betalningsmottagaren bedömer att betalaren av annan anledning inte bör
        delta i Autogiro. Betalarens betaltjänstleverantör har rätt att avsluta
        betalarens anslutning till Autogiro i enlighet med de villkor som gäller
        mellan betalarens betaltjänstleverantör och betalaren.
      </p>
    </section>
  </article>
);

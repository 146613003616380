import React from "react";
import { PLACEHOLDER, DocAccountProps } from "../../Language";

export const CustodyAccountAgreementEN = ({
  language,
  account,
}: DocAccountProps) => (
  <article className="lysa-doc custody-account-agreement">
    <header>
      <h1>Custody account agreement</h1>
    </header>

    <ul>
      <li>
        <strong>Date:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Customer:</strong> {account.name}
      </li>
      <li>
        <strong>Account name:</strong> {account.accountName}
      </li>
      <li>
        <strong>Account ID:</strong>{" "}
        {account.accountId ?? PLACEHOLDER[language]}
      </li>
    </ul>

    <section>
      <h2>1. Agreement</h2>
      <p>
        This Custody Account Agreement between the Customer and Lysa AB (“
        <b>Lysa</b>”) is entered into on the following conditions and as set out
        in the at each time applicable{" "}
        <i>General terms and conditions for custody accounts</i>
        and Lysa’s <i>Best execution Policy</i> (the “<b>Agreement</b>”).
      </p>
    </section>

    <section>
      <h2>2. Pledge</h2>
      <p>
        As security for all of the Customer’s current and future obligations
        vis-à-vis Lysa, the Customer pledges all the financial instruments at
        each time listed on the Customer’s account and all the financial
        instruments which the Customer has acquired to be deposited on the
        account at each time, as well as all the the money on the account at
        each time, to Lysa.
      </p>
    </section>

    <section>
      <h2>3. Power of attorney </h2>
      <p>
        The Customer authorises Lysa, itself or through the person designated by
        Lysa, to subscribe to the transfer of shares, fixed income instruments
        and other securities on behalf of the Customer and to represent the
        Customer in the acquisition/sale of securities and to receive and give
        receipt of securities and cash.
      </p>
    </section>

    <section>
      <h2>4. Tax domicile etc.</h2>
      <p>
        The Customer must make sure of its tax domicile. For natural persons, it
        is normally the country/state under which laws the person is liable for
        tax due to its principal residence, domicile or any similar
        circumstance. The Customer guarantees that the information which the
        Customer has provided Lysa with respect to taxation and the application
        of any regulations with respect to Lysa’s duty to report etc. is
        correct. The Customer is required to inform Lysa without undue delay if
        the Customer changes its tax domicile, such as in case of a relocation
        abroad, as well as of any changes in name, telephone number, and postal
        and email address.
      </p>
    </section>

    <section>
      <h2>5. Signing</h2>
      <p>
        This Agreement is signed by the Customer electronically on Lysa’s
        website.
      </p>
    </section>
  </article>
);

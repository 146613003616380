import React from "react";
import { DocAccountProps } from "../../Language";

export const InvestmentGuidelinesSV = ({ account }: DocAccountProps) => (
  <article className="lysa-doc investment-guidelines">
    <header>
      <h1>Placeringsriktlinjer</h1>
    </header>

    <ul>
      <li>
        <strong>Datum:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Kund:</strong> {account.name}, {account.companyId} ("
        <strong>Kunden</strong>")
      </li>
      <li>
        <strong>Kontonamn:</strong> {account.accountName} ("
        <strong>Kontot</strong>")
      </li>
    </ul>

    <p>
      Detta dokument utgör en bilaga till avtal om diskretionär förvaltning som
      ingåtts mellan kunden och Lysa. I placeringsriktlinjerna fastslås
      målfördelning och investeringsinriktning för Kontot. Målfördelningen har
      bestämts av kunden efter en lämplighetsbedömning, där bl.a. kundens
      ekonomisk situation och investeringsmål har klarlagts. Underlaget för
      lämplighetsbedömningen har dokumenterats i ett rådgivningsunderlag. Både
      rådgivningsunderlaget och placeringsriktlinjerna kan uppdateras av kunden
      i inloggat läge på Lysa.se.
    </p>

    <p>
      Kundens medel ska investeras i enlighet med nedanstående målfördelning och
      investeringsinriktning:
    </p>

    <ul>
      <li>
        <strong>{account.stocks}%</strong> i instrument som ger exponering mot
        aktiemarknaden, och
      </li>
      <li>
        <strong>{account.bonds}%</strong> i instrument som ger exponering mot
        räntemarknaden.
      </li>
      <li>
        Investeringsinriktning:{" "}
        <strong>
          {account.investmentType === "SUSTAINABLE" ? "Hållbar" : "Bred"}
        </strong>
      </li>
    </ul>

    <p>
      Ombalansering sker i enlighet med vad som anges i de allmänna villkoren
      för diskretionär portföljförvaltning.
    </p>

    <p>
      Placeringsriktlinjerna signeras av Kunden med elektronisk signatur och
      finns tillgängligt för Kunden i inloggat läge på Lysa.se.
    </p>
  </article>
);

import React from "react";

export function EligibilityAssessmentSV() {
  return (
    <article className="lysa-doc privacy-policy">
      <header>
        <h1>Information om lämplighetsbedömning</h1>
      </header>

      <section>
        <p>
          För att kunna agera i ditt bästa intresse utför vi något som kallas
          “lämplighetsbedömning”. Lämplighetsbedömningen består i att vi samlar
          in information om din kunskap och erfarenhet gällande placeringar,
          dina investeringsmål, din riskprofil och din finansiella situation.
          Detta gör vi för att kunna avgöra om ett sparande hos Lysa är lämpligt
          för dig, samt vilken typ av portfölj som är lämplig.
        </p>
        <p>
          Lämplighetsbedömningen görs för att vi ska kunna agera i ditt bästa
          intresse. Det är därför viktigt att du lämnar korrekta uppgifter på de
          frågor vi ställer. Frågorna kan ibland upplevas som närgångna men Lysa
          behöver informationen för att kunna utföra tjänsten på bästa sätt för
          dig.
        </p>
        <p>
          Vi kommer be dig att svara på frågorna igen en gång om året för att
          säkerställa att din portfölj fortfarande är lämplig för dig. Om något
          inträffar däremellan bör du gå in och ändra på dina svar i
          lämplighetsbedömningen.
        </p>
        <p>
          Vår rekommendation baseras endast på svaren du ger till frågorna i den
          digitala lämplighetsbedömningen. Om du behöver hjälp att förstå en
          fråga är du varmt välkommen att maila oss på{" "}
          <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a> eller ringa{" "}
          <a href="tel:+46852503570">08-525 035 70</a> vardagar mellan kl 9 -
          18.
        </p>
      </section>
    </article>
  );
}

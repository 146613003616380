import React from "react";

export const GenericTermsISKSV = () => (
  <article className="lysa-doc generic-terms-isk">
    <header>
      <h1>Allmänna villkor för investeringssparkonto</h1>
      <p>v. 2020:1</p>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>
        Dessa allmänna villkor för investeringssparkonto (dessa “
        <b>Allmänna Villkor</b>”) gäller för investeringssparkonto hos Lysa AB,
        org. nr 559028-0821 (“<b>Lysa</b>”) och utgör del av avtal om
        investeringssparkonto som ingås mellan kunden och Lysa. Kunden ansöker
        om investeringssparkonto genom registrering på Lysas hemsida.
      </p>
      <p>
        I samband med kundens ansökan om investeringssparkonto godkänner kunden
        (A) dessa Allmänna Villkor, (B) Förhandsinformation om
        investeringssparkonto, (C) Allmänna bestämmelser för depå/konto, (D)
        Lysas instruktion för bästa orderutförande och sammanläggning av order
        och (E) Information om egenskaper och risker om finansiella instrument.
        Om dessa dokument innehåller motstridiga villkor gäller de i den ordning
        som angetts här.
      </p>
    </section>

    <section>
      <h2>Definitioner</h2>
      <ul>
        <li>
          <b>Annat Eget Konto:</b> sådan depå/konto som inte är ett
          Investeringssparkonto och som Kunden, eller Lysa för Kundens räkning,
          har öppnat hos Lysa eller som Kunden har öppnat hos ett annat
          institut.
        </li>
        <li>
          <b>Avtalet:</b> det av parterna undertecknade avtalet om
          Investeringssparkonto inklusive Lysas vid var tid gällande ALLMÄNNA
          VILLKOR FÖR INVESTERINGSSPARKONTO, ALLMÄNNA BESTÄMMELSER FÖR
          DEPÅ/KONTO samt INSTRUKTION FÖR BÄSTA ORDERUTFÖRANDE OCH
          SAMMANLÄGGNING AV ORDER.
        </li>
        <li>
          <b>Finansiella Instrument:</b> sådana instrument som avses i lagen om
          värdepappersmarknaden.
        </li>
        <li>
          <b>Fondandelar:</b> andelar i en värdepappersfond eller specialfond
          som avses i lagen om värdepappersfonder och lagen om förvaltare av
          alternativa investeringsfonder.
        </li>
        <li>
          <b>Godkända Investeringstillgångar:</b> sådana av Lysa vid var tid
          godkända Investeringstillgångar som enligt lagen om
          investeringssparkonto och Avtalet vid var tid får förvaras på
          Investeringssparkontot.
        </li>
        <li>
          <b>MTF-plattform:</b> sådan marknad inom EES som avses i lagen om
          värdepappersmarknaden.
        </li>
        <li>
          <b>Investeringsföretag:</b> värdepappersinstitut som enligt lag får
          ingå avtal om investeringssparkonto.
        </li>
        <li>
          <b>Investeringstillgångar:</b> tillgångar som enligt lagen om
          investeringssparkonto får förvaras på ett Investeringssparkonto, dvs.
          sådana Finansiella Instrument som (i) är upptagna till handel på en
          Reglerad Marknad eller motsvarande marknad utanför EES, (ii) handlas
          på en MTF-plattform eller (iii) utgör Fondandelar.
        </li>
        <li>
          <b>Investeringssparkonto:</b> sådant konto som uppfyller kraven i
          lagen om investeringssparkonto.
        </li>
        <li>
          <b>Kontofrämmande Tillgång:</b> sådant Finansiellt Instrument som inte
          är en Investeringstillgång.
        </li>
        <li>
          <b>Kunden:</b> Den person som innehar ett Investeringssparkonto hos
          Lysa och som ingått Avtalet med Lysa.
        </li>
        <li>
          <b>Lysas fonder:</b> värdepappersfonder distribuerade och förvaltade
          av bolag inom Lysa-koncernen.
        </li>
        <li>
          <b>Reglerad Marknad:</b> sådan marknad som avses i lagen om
          värdepappersmarknaden.
        </li>
      </ul>
    </section>

    <section>
      <h2>1. Förvaring av tillgångar på Investeringssparkontot</h2>
      <p>
        <span className="doc-number">1.1</span> På Investeringssparkontot får
        Kunden förvara endast Godkända Investeringstillgångar samt kontanta
        medel, om inte annat framgår av Avtalet.
      </p>
      <p>
        <span className="doc-number">1.2</span> En uppräkning av vilka
        Finansiella Instrument som utgör Godkända Investeringstillgångar
        återfinns i Lysas FÖRHANDSINFORMATION OM INVESTERINGSSPARKONTO.
      </p>
      <p>
        <span className="doc-number">1.3</span> Ett Finansiellt Instrument är
        förvarat på Investeringssparkontot när Lysa har förtecknat det på
        kontot.
      </p>
      <p>
        <span className="doc-number">1.4</span> Det åligger Kunden att
        fortlöpande hålla sig uppdaterad om vilka Finansiella Instrument som vid
        varje givet tillfälle förvaras på Investeringssparkontot. Ändring som
        innebär en begränsning av vad som utgör Godkända Investeringstillgångar
        ska dock meddelas Kunden i enlighet med punkt 15.
      </p>
    </section>

    <section>
      <h2>
        2. Överföring av Finansiella Instrument till Investeringssparkontot
      </h2>
      <p>
        <span className="doc-number">2.1</span> Överföring av Finansiella
        Instrument kan inte göras till Investeringssparkonto hos Lysa.
      </p>
    </section>

    <section>
      <h2>
        3. Överföring av Finansiella Instrument från Investeringssparkontot
      </h2>
      <p>
        <b>
          <span className="doc-number">3.1</span> Överföring till eget konto
        </b>
      </p>
      <p>
        <span className="doc-number">3.1.1</span> Kunden får överföra
        Investeringstillgångar från Investeringssparkontot till ett annat eget
        Investeringssparkonto endast om tillgångarna får förvaras på det
        mottagande kontot.
      </p>
      <p>
        <span className="doc-number">3.1.2</span> Kunden får inte överföra
        Investeringstillgångar från Investeringssparkontot till ett Annat Eget
        Konto.
      </p>
      <p>
        <span className="doc-number">3.1.3</span> Kunden får överföra
        Kontofrämmande Tillgångar från Investeringssparkontot till ett Annat
        Eget Konto.
      </p>
      <p>
        <span className="doc-number">3.1.4</span> Oaktat vad som sagts ovan i
        punkt 3.1.1–3.1.3 får Fondandelar i Lysas fonder inte överföras från
        Investeringssparkontot.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">3.2</span> Överföring till annan person
        </b>
      </p>
      <p>
        <span className="doc-number">3.2.1</span> Kunden får överföra
        Investeringstillgångar och Kontofrämmande Tillgångar från
        Investeringssparkontot till en annan person endast om Kunden genom
        försäljning, byte eller liknande har överlåtit tillgångarna:
      </p>
      <ol>
        <li>
          på en Reglerad Marknad eller annan motsvarande marknad utanför EES
          eller en MTF-plattform,
        </li>
        <li>på så sätt att Fondandelar löses in,</li>
        <li>till den som har emitterat tillgångarna,</li>
        <li>till Lysa,</li>
        <li>
          till budgivaren om överlåtelsen var ett led i ett offentligt
          uppköpserbjudande,
        </li>
        <li>
          till köpande bolag om överlåtelsen var ett led i ett förfarande om
          andelsbyte, eller
        </li>
        <li>
          till majoritetsaktieägaren i ett bolag om överlåtelsen var ett led i
          ett förfarande om inlösen av minoritetsaktier i samma bolag.
        </li>
      </ol>
      <p>
        <span className="doc-number">3.2.2</span> Kunden får även överföra
        Investeringstillgångar vilka Kunden har överlåtit genom försäljning,
        byte eller liknande från Investeringssparkontot till en annan person om
        tillgångarna vid överlåtelsen direkt förs över till dennes
        Investeringssparkonto och om tillgångarna får förvaras på det mottagande
        kontot.
      </p>
      <p>
        <span className="doc-number">3.2.3</span> Kunden får överföra
        Investeringstillgångar vilka Kunden har överlåtit genom arv, testamente,
        gåva, bodelning eller på liknande sätt från Investeringssparkontot till
        en annan person endast om överföringen sker direkt till dennes
        Investeringssparkonto och om tillgångarna får förvaras på det mottagande
        kontot.
      </p>
      <p>
        <span className="doc-number">3.2.4</span> Kunden får överföra
        Kontofrämmande Tillgångar vilka Kunden har överlåtit genom arv,
        testamente, gåva, bodelning eller liknande från Investeringssparkontot
        till en annan person endast om överföringen sker till ett konto som inte
        är ett Investeringssparkonto.
      </p>
    </section>

    <section>
      <h2>4. Tillfällig förvaring av vissa typer av Finansiella Instrument</h2>
      <p>
        <b>
          <span className="doc-number">4.1</span> Informationsskyldighet
        </b>
      </p>
      <p>
        <span className="doc-number">4.1.1</span> Kunden ska om det kommer till
        dennes kännedom att (i) Investeringstillgångar som inte är godkända av
        Lysa eller (ii) Kontofrämmande Tillgångar förvaras på
        Investeringssparkontot, så snart som möjligt informera Lysa om detta.
      </p>
      <p>
        <span className="doc-number">4.1.2</span> Lysa ska inom fem (5) dagar
        från det att det kommit till Lysas kännedom att Kontofrämmande
        Tillgångar förvaras på Investeringssparkontot, informera Kunden om detta
        samt inom vilken tid tillgångarna senast måste flyttas från
        Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">4.1.3</span> Vid tillämpning av 4.1.2 ska
        Lysa anses ha fått kännedom om att Kontofrämmande Tillgångar förvaras på
        Investeringssparkontot när tjugofem (25) dagar har gått från utgången av
        det kvartal då de Kontofrämmande Tillgångarna först förvarades på
        Investeringssparkontot i egenskap av Kontofrämmande Tillgångar.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">4.2</span> Flytt av
          Investeringstillgångar som inte är godkända av Lysa och av
          Kontofrämmande Tillgångar
        </b>
      </p>
      <p>
        <span className="doc-number">4.2.1</span> Kunden ska senast den
        trettionde (30) dagen efter den dag då tillgångarna först
        klassificerades som sådana tillgångar eller förtecknades på kontot
        flytta Investeringstillgångar som inte är godkända av Lysa och/eller
        Kontofrämmande Tillgångar från Investeringssparkontot. En sådan flytt
        kan ske antingen genom att Kunden, i enlighet med Avtalet, överför
        tillgångarna till ett annat förvar eller genom att Kunden överlåter
        tillgångarna.
      </p>
      <p>
        <span className="doc-number">4.2.2</span> Om Kunden inte senast den
        sextionde (60) dagen efter utgången av det kvartal då tillgångarna blev
        klassificerade som sådana tillgångar respektive blev förtecknade på
        kontot har överlåtit Investeringstillgångar som Lysa inte godkänner och
        som tillfälligt förvaras på Investeringssparkontot eller anvisat Lysa
        till vilket annat eget Investeringssparkonto sådana tillgångar ska
        överföras, får Lysa efter eget bestämmande välja mellan att för Kundens
        räkning (i) överföra tillgångarna till Annat Eget Konto i Lysa eller
        (ii) vid den tidpunkt och på det sätt som Lysa bestämmer, avyttra hela
        eller delar av Kundens innehav av tillgångarna.
      </p>
    </section>

    <section>
      <h2>5. Kontanta medel</h2>
      <p>
        <span className="doc-number">5.1</span> Kunden får sätta in kontanta
        medel på och ta ut kontanta medel från Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">5.2</span> En annan person än Kunden får
        sätta in kontanta medel på Investeringssparkontot.
      </p>
    </section>

    <section>
      <h2>6. Ränta, utdelning och annan avkastning</h2>
      <p>
        <span className="doc-number">6.1</span> Ränta, utdelning och annan
        avkastning som är hänförlig till de tillgångar som vid var tid förvaras
        på Investeringssparkontot ska överföras direkt till
        Investeringssparkontot. För ersättning hänförlig till överlåtelse av
        Finansiella Instrument gäller dock vad som framgår av avsnitt 8.3.
      </p>
    </section>

    <section>
      <h2>7. Åtaganden avseende förvarade Finansiella Instrument</h2>
      <p>
        <span className="doc-number">7.1</span> Lysas åtaganden vad gäller de
        tillgångar som förvaras på Investeringssparkontot följer de av Lysa vid
        var tid gällande ALLMÄNNA BESTÄMMELSER FÖR DEPÅ/KONTO.
      </p>
      <p>
        <span className="doc-number">7.2</span> Lysa förbehåller sig rätten att
        inte genomföra de åtaganden som framgår av ALLMÄNNA BESTÄMMELSER FÖR
        DEPÅ/KONTO, om detta skulle strida mot övriga bestämmelser i Avtalet
        eller lagen om investeringssparkonto.
      </p>
    </section>

    <section>
      <h2>8. Handel med Finansiella Instrument</h2>
      <p>
        <b>
          <span className="doc-number">8.1</span> Allmänt
        </b>
      </p>
      <p>
        <span className="doc-number">8.1.1</span> Vid köp och försäljning av
        tillgångar på Investeringssparkontot gäller Lysas särskilda Riktlinjer
        för utförande av order samt sammanläggning och fördelning av order.
        Gällande riktlinjer för bästa orderutförande och sammanläggning av order
        finns tillgängligt för Kunden på Lysas hemsida.
      </p>
      <p>
        <span className="doc-number">8.1.2</span> Lysa förbehåller sig rätten
        att inte genomföra köp- eller försäljningsuppdrag för Kundens räkning
        avseende Finansiella Instrument i de fall de Finansiella Instrumenten
        inte utgör Investeringstillgångar som är godkända av Lysa respektive av
        mottagande Institut samt i de fall uppdraget i övrigt skulle strida mot
        Avtalet eller lagen om investeringssparkonto.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">8.2</span> Förvärv av Finansiella
          Instrument
        </b>
      </p>
      <p>
        <span className="doc-number">8.2.1</span> Kunden ska själv och i förväg
        kontrollera att de Finansiella Instrument som Lysa ges i uppdrag att
        förvärva för Kundens räkning utgör Godkända Investeringstillgångar.
      </p>
      <p>
        <span className="doc-number">8.2.2</span> Vid Kundens förvärv av
        Finansiella Instrument till Investeringssparkontot ska betalning ske med
        tillgångar som förvaras på Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">8.2.3</span> Vid Kundens förvärv av
        Finansiella Instrument till Investeringssparkontot får betalning ske i
        form av Finansiella Instrument som förvaras på Investeringssparkontot
        (byte) endast om sådan överföring sker i enlighet med avsnitt 3.2.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">8.3</span> Ersättning vid överlåtelse av
          Finansiella Instrument
        </b>
      </p>
      <p>
        <span className="doc-number">8.3.1</span> Vid Kundens överlåtelse av
        Finansiella Instrument som sker på det sätt som anges i 3.2.1 punkterna
        1–7, ska ersättning som erhålls i form av kontanta medel överföras
        direkt till Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">8.3.2</span> Vid Kundens överlåtelse av
        Investeringstillgångar som sker på det sätt som anges i 3.2.2, ska
        ersättning som erhålls i form av kontanta medel överföras direkt till
        Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">8.3.3</span> Vid Kundens överlåtelse av
        Finansiella Instrument som sker på det sätt som anges i 3.2.1 och 3.2.2,
        ska ersättning som erhålls i form av Investeringstillgångar överföras
        direkt till Investeringssparkontot eller till Annat Eget Konto.
      </p>
    </section>

    <section>
      <h2>9. Pantsättning</h2>
      <p>
        <span className="doc-number">9.1</span> För pantsättning gäller, utöver
        vad som framgår nedan, de av Lysa vid var tid gällande ALLMÄNNA
        BESTÄMMELSER FÖR DEPÅ/KONTO.
      </p>
      <p>
        <span className="doc-number">9.2</span> Kunden pantsätter till Lysa,
        till säkerhet för samtliga Kundens nuvarande och blivande förpliktelser
        gentemot Lysa (i) samtliga de Finansiella Instrument som vid var tid
        finns förtecknade på Investeringssparkontot, (ii) samtliga de
        Finansiella Instrument som Kunden vid var tid har förvärvat för
        förvaring på Investeringssparkontot samt (iii) samtliga kontanta medel
        som vid var tid finns insatta på Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">9.3</span> Kunden får inte pantsätta
        Finansiella Instrument som förvaras på Investeringssparkontot som
        säkerhet för förpliktelser som Kunden har gentemot annan än Lysa.
      </p>
      <p>
        <span className="doc-number">9.4</span> Om Kunden inte fullgör sina
        förpliktelser gentemot Lysa enligt Avtalet, t.ex. att erforderliga
        Finansiella Instrument i samband med försäljning inte finns förvarade på
        Investeringssparkontot, får Lysa ta panten i anspråk på det sätt Lysa
        finner lämpligt. Vid ianspråktagande av pant ska Lysa förfara med omsorg
        och, om så är möjligt och det enligt Lysas bedömning kan ske utan
        förfång för Lysa, i förväg underrätta Kunden härom.
      </p>
    </section>

    <section>
      <h2>10. Överlåtelse av Investeringssparkonto</h2>
      <p>
        <span className="doc-number">10.1</span> Investeringssparkontot kan inte
        överlåtas.
      </p>
    </section>

    <section>
      <h2>
        11. Överföringar mellan Investeringssparkonton hos olika
        Investeringsföretag
      </h2>
      <p>
        <span className="doc-number">11.1</span> Vid överföringar av
        Investeringstillgångar mellan Investeringssparkonton hos olika
        Investeringsföretag åligger det Kunden att på förhand försäkra sig om
        att det mottagande Investeringsföretaget kan ta emot de aktuella
        tillgångarna.
      </p>
      <p>
        <span className="doc-number">11.2</span> Kundens överföring av
        Investeringstillgångar till ett annat Investeringsföretag ska anses
        verkställd då tillgångarna har förtecknats på Investeringssparkontot hos
        det mottagande Investeringsföretaget.
      </p>
      <p>
        <span className="doc-number">11.3</span> Vid överföringar till
        Investeringssparkontot från ett annat Investeringsföretag får Lysa vägra
        att ta emot tillgångar för förvaring på Investeringssparkontot som inte
        är Godkända Investeringstillgångar.
      </p>
    </section>

    <section>
      <h2>12. Uppsägning av Investeringssparkontot</h2>
      <p>
        <span className="doc-number">12.1</span> Kunden får säga upp Avtalet
        till upphörande med omedelbar verkan. Lysa får säga upp Avtalet till
        upphörande två månader efter det att Lysa har skickat uppsägning till
        Kunden.
      </p>
      <p>
        <span className="doc-number">12.2</span> Oavsett vad som sagts i 12.1
        får vardera parten säga upp Avtalet till upphörande med omedelbar verkan
        om motparten väsentligen har brutit mot Avtalet eller andra
        instruktioner eller överenskommelser som gäller för de handels- och
        förvaringstjänster avseende Finansiella Instrument eller de
        kontotjänster som är anslutna till Investeringssparkontot.
      </p>
      <p>
        <span className="doc-number">12.3</span> Avtalet upphör dock tidigast
        vid Investeringssparkontots avslutande enligt 12.4.
      </p>
      <p>
        <span className="doc-number">12.4</span> Investeringssparkontot ska som
        huvudregel avslutas vid den tidpunkt Avtalet upphör enligt 12.1 och
        12.2. Om det vid tidpunkten för uppsägning av Avtalet förvaras
        Finansiella Instrument och/eller kontanta medel på
        Investeringssparkontot eller om Finansiella Instrument har förvärvats
        för att förvaras på Investeringssparkontot men ännu inte har förtecknats
        på kontot ska Investeringssparkontot dock istället avslutas så snart
        samtliga tillgångar flyttats från Investeringssparkontot. Kunden ska i
        dessa situationer snarast (i) anvisa ett annat eget
        Investeringssparkonto till vilket Investeringstillgångar ska överföras,
        (ii) anvisa ett Annat Eget Konto till vilket Kontofrämmande Tillgångar
        ska överföras och/eller (iii) överlåta tillgångarna i enlighet med 3.2.
      </p>
      <p>
        <span className="doc-number">12.5</span> Om Kunden inte senast tjugofem
        (25) dagar från uppsägning enligt 12.1 eller senast tio (10) dagar från
        uppsägning enligt 12.2 varken har anvisat till vilket annat förvar
        Investeringstillgångar och/eller Kontofrämmande Tillgångar ska överföras
        eller överlåtit tillgångarna, får Lysa för Kundens räkning vid tidpunkt
        och på sätt som Lysa bestämmer (i) avyttra tillgångarna och därefter
        överföra samtliga kontanta medel från Investeringssparkontot till ett
        Annat Eget Konto i Lysa och/eller (ii) överföra Kontofrämmande
        Tillgångar till ett Annat Eget Konto i Lysa.
      </p>
    </section>

    <section>
      <h2>13. Upphörande av Investeringssparkontot på grund av lag</h2>
      <p>
        <span className="doc-number">13.1</span> Om kontot upphör som
        Investeringssparkonto enligt 28 § lagen om investeringssparkonto ska de
        tillgångar som förvaras på kontot inte längre schablonbeskattas. Lysa
        ska, inom fem (5) dagar från det att Lysa fått kännedom därom, informera
        Kunden om att Investeringssparkontot har upphört och att det åligger
        Kunden att snarast överföra samtliga tillgångar som förvaras på
        Investeringssparkontot till ett annat förvar eller överlåta
        tillgångarna.
      </p>
      <p>
        <span className="doc-number">13.2</span> Om Kunden inte senast fem (5)
        dagar från det att Lysa har informerat Kunden om Investeringssparkontots
        upphörande enligt 13.1.1 varken har anvisat till vilket annat förvar som
        tillgångarna ska överföras eller överlåtit tillgångarna, får Lysa vidta
        de åtgärder som framgår av 12.1.5 och därefter avsluta kontot. Avtalet
        upphör att gälla när kontot har avslutats.
      </p>
    </section>

    <section>
      <h2>14. Skatter, avgifter och övriga kostnader</h2>
      <p>
        <b>
          <span className="doc-number">14.1</span> Skatt på
          Investeringssparkontot
        </b>
      </p>
      <p>
        <span className="doc-number">14.1.1</span> Kunden ansvarar för skatter
        och andra avgifter som ska betalas enligt svensk eller utländsk lag med
        avseende på tillgångar som förtecknas på Investeringssparkontot, t.ex.
        utländsk källskatt och svensk kupongskatt på utdelning.
      </p>
      <p>
        <span className="doc-number">14.1.2</span> Lysa ska varje år tillställa
        Skatteverket uppgifter om den schablonintäkt som är hänförlig till
        Kundens Investeringssparkonto.
      </p>
      <p>
        <span className="doc-number">14.1.3</span> Andra Kontofrämmande
        Tillgångar som förvaras på ett investeringssparkonto än sådana
        Kontofrämmande Tillgångar som förvaras på investeringssparkontot med
        stöd av 17 § ISKL ska inte ingå i den schablonmässigt beräknade intäkten
        utan istället beskattas konventionellt.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">14.2</span> Skatterättslig hemvist
        </b>
      </p>
      <p>
        <span className="doc-number">14.2.1</span> Investeringssparkontot kan
        innehas av Kunden oavsett om denne är obegränsat eller begränsat
        skattskyldig.
      </p>
      <p>
        <span className="doc-number">14.2.2</span> Det åligger Kunden att vid
        var tid ha kännedom om sin skatterättsliga hemvist.
      </p>
      <p>
        <span className="doc-number">14.2.3</span> Kunden förbinder sig att utan
        dröjsmål till Lysa skriftligen anmäla förändringar i sin skatterättsliga
        hemvist, t.ex. vid flytt utomlands.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">14.3</span> Avgifter
        </b>
      </p>
      <p>
        <span className="doc-number">14.3.1</span> Uppgifter om Lysas avgifter
        för Investeringssparkontot samt tjänster som har samband med
        Investeringssparkontot framgår av Lysas FÖRHANDSINFORMATION OM
        INVESTERINGSSPARKONTO.
      </p>
      <p>
        <span className="doc-number">14.3.2</span> Information om vid var tid
        gällande avgifter för Investeringssparkontot publiceras på Lysas
        hemsida. På Kundens begäran ska Lysa även tillhandahålla uppgifter om
        aktuella avgifter direkt till Kunden.
      </p>
      <p>
        <span className="doc-number">14.3.3</span> För det fall det enligt Lysas
        bedömning föreligger risk för att Kundens medel på kontot kan komma att
        understiga beräknad men ännu inte inbetald skatt eller avgift får Lysa
        på det sätt som är lämpligt avyttra tillgångar på Investeringssparkontot
        i sådan utsträckning att nämnda risk inte längre bedöms föreligga. Lysa
        får även underlåta att utföra av Kunden begärd omplacering och varje
        annan disposition av tillgångarna som förvaras på Investeringssparkontot
        i den utsträckning detta enligt Lysas bedömning medför att nämnda risk
        skulle uppstå eller öka. Lysa ska förfara med omsorg och, om så är
        möjligt och det enligt Lysas bedömning kan ske utan förfång för Lysa, i
        förväg underrätta Kunden om åtgärd och/eller underlåtenhet enligt ovan.
      </p>
      <br />
      <p>
        <b>
          <span className="doc-number">14.4</span> Övriga kostnader
        </b>
      </p>
      <p>
        <span className="doc-number">14.4.1</span> Kunden ska stå för de
        kostnader som uppstår vid avyttring, överföring eller öppnande av annan
        förvaring enligt Avtalet.
      </p>
    </section>

    <section>
      <h2>15. Ändring av allmänna villkor och avgifter</h2>
      <p>
        <span className="doc-number">15.1</span> Beträffande ändringar av dessa
        allmänna villkor eller Lysas avgifter tillämpas avsnitt G.9 i ALLMÄNNA
        VILLKOR FÖR DEPÅ/KONTO.
      </p>
    </section>
  </article>
);

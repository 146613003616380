import React from "react";
import { DocCurrencyMap, DocCurrencyProps } from "../../Language";
import { DepositorInformationEN as EN } from "./DepositorInformationEN";
import { DepositorInformationSV as SV } from "./DepositorInformationSV";
import "../../Documents.css";

const docsMap: DocCurrencyMap = { EN, SV };

export const DepositorInformation: React.FC<DocCurrencyProps> = (props) =>
  React.createElement(docsMap[props.language], props);

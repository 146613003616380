import React from "react";
import { DocMap, DocProps } from "../../Language";
import { CustomerAgreementSV as SV } from "./CustomerAgreementSV";
import { CustomerAgreementEN as EN } from "./CustomerAgreementEN";
import "../../Documents.css";

const docsMap: DocMap = { SV, EN };

export const CustomerAgreement: React.FC<DocProps> = (props) =>
  React.createElement(docsMap[props.language], null);

import React from "react";
import { DocAccountProps } from "../../Language";

export const InvestmentGuidelinesEN = ({ account }: DocAccountProps) => (
  <article className="lysa-doc investment-guidelines">
    <header>
      <h1>Investment guidelines</h1>
    </header>

    <ul>
      <li>
        <strong>Date:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Customer:</strong> {account.name}, {account.companyId} (the “
        <strong>Customer</strong>”)
      </li>
      <li>
        <strong>Account name:</strong> {account.accountName} (the “
        <strong>Account</strong>”)
      </li>
    </ul>

    <p>
      This document is an appendix to the portfolio agreement between the
      Customer and Lysa. In the investment guidelines, the target allocation is
      decided for the Account. The target allocation has been decided by the
      Customer following a suitability assessment where, amongst other things,
      the Customer’s financial situation and investment goal has been clarified.
      The basis for the suitability assessment has been documented. Both the
      basis for the suitability assessment and the investment guidelines can be
      updated by the Customer when signed in to Lysa’s website.
    </p>

    <p>
      The Customer’s money will be invested in accordance with the goal
      distribution set out below:
    </p>

    <ul>
      <li>
        <strong>{account.stocks} %</strong> in instruments providing exposure to
        the stock market; and
      </li>
      <li>
        <strong>{account.bonds} %</strong> in instruments providing exposure to
        the fixed income market.
      </li>
    </ul>

    <p>
      A re-balance of the Customer’s portfolio will be made in accordance with
      the portfolio agreement.
    </p>

    <p>
      The Investment guidelines are signed by the Customer electronically and
      are available in logged-in mode on Lysa’s website.
    </p>
  </article>
);

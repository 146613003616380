import React from "react";
import { DocProps, DocMap } from "../../Language";
import { InformationAboutAttributesAndRisksSV as SV } from "./InformationAboutAttributesAndRisksSV";
import { InformationAboutAttributesAndRisksEN as EN } from "./InformationAboutAttributesAndRisksEN";
import "../../Documents.css";

const docsMap: DocMap = { SV, EN };

export const InformationAboutAttributesAndRisks: React.FC<DocProps> = props =>
  React.createElement(docsMap[props.language], null);

import React, { FC } from "react";
import { PrivacyPolicySV } from "./PrivacyPolicySV";
import { DocProps, DocMap } from "../../Language";
import "../../Documents.css";

const docsMap: DocMap<DocProps> = {
  PrivacyPolicySV,
};

export const PrivacyPolicy: FC<DocProps> = (props) => {
  const Doc = docsMap[`PrivacyPolicy${props.language}`];
  return <Doc {...props} />;
};

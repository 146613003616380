import React from "react";

export const ModelAssumptionsSV = () => (
  <article className="lysa-doc model-assumptions">
    <header>
      <h1>Antaganden</h1>
    </header>

    <section>
      <p>
        Lysas verktyg "investeringshjälpen" är ett beslutsstöd som beräknar
        den förväntade avkastning och den bedömda sannolikheten för olika
        utfall som presenteras i en graf. Grafens syfte är att visa effekten
        av olika portföljsammansättningar, risknivåer och tidshorisonter. Att
        tänka i termer av sannolikhet för olika utfall leder till bättre
        investeringsbeslut.
      </p>
      <p>
        Det faktiska utfallet kommer att variera beroende på
        marknadsutveckling, tidpunkten för investering, investeringsbeloppet,
        insättningar och uttag, transaktionskostnader samt skatter. Förväntad
        avkastning utgör inte en tillförlitlig indikation om framtida
        resultat.
      </p>
      <p>
        Beräkningarna är baserade på en multifaktorsimulering som antar
        logaritmisk normalfördelning med följande hyperparametrar:
      </p>
      <ul>
        <li>Förväntad avkastning för aktier: 7,0 %</li>
        <li>Förväntad avkastning för räntor: 1,5 %</li>
        <li>Standardavvikelse för aktier: 15 %</li>
        <li>Standardavvikelse för räntor: 2 %</li>
        <li>Korrelation mellan aktier och räntor: 0,1</li>
      </ul>
      <p>
        Parametrarna baseras på historisk data för respektive tillgångsslag
        och tar inte hänsyn till specifika delklasser inom tillgångsslagen.
      </p>
    </section>
  </article>
);

import React from "react";
import { DocAccountMap, DocAccountProps } from "../../Language";
import { CustodyAccountAgreementSV as SV } from "./CustodyAccountAgreementSV";
import { CustodyAccountAgreementEN as EN } from "./CustodyAccountAgreementEN";
import "../../Documents.css";

const docsMap: DocAccountMap = { SV, EN };

export const CustodyAccountAgreement: React.FC<DocAccountProps> = (props) =>
  React.createElement(docsMap[props.language], props);

import React from "react";
import { PLACEHOLDER, DocAccountProps } from "../../Language";

export const AgreementAccountSV = ({ language, account }: DocAccountProps) => (
  <article className="lysa-doc agreement-account">
    <header>
      <h1>Avtal om investeringssparkonto</h1>
    </header>

    <ul>
      <li>
        <strong>Datum:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Kund:</strong> {account.name ?? PLACEHOLDER[language]},{" "}
        {account.id || ""} ("
        <strong>Kunden</strong>")
      </li>
      <li>
        <strong>Kontonamn:</strong> {account.accountName}
      </li>
      <li>
        <strong>Kontoid:</strong> {account.accountId ?? PLACEHOLDER[language]}
      </li>
    </ul>

    <section>
      <h2>1. Avtal</h2>

      <p>
        Mellan Kunden och Lysa AB ("<strong>Lysa</strong>") träffas avtal om
        Investeringssparkonto för Kunden hos Lysa enligt nedanstående villkor
        samt enligt vid var tid gällande Allmänna villkor för
        investeringssparkonto ("<strong>ISK-villkoren</strong>"), Allmänna
        villkor för depå/konto och Lysas särskilda Riktlinjer för utförande och
        placering av order ("<strong>Avtalet</strong>"). Om ISK-villkoren och
        sådana allmänna bestämmelser, allmänna villkor eller riktlinjer är
        motstridiga, ska ISK-villkoren ha företräde.
      </p>
    </section>

    <section>
      <h2>2. Pantsättning</h2>
      <p>
        Kunden pantsätter till Lysa, till säkerhet för samtliga Kundens
        nuvarande och blivande förpliktelser gentemot Lysa, dels samtliga de
        Finansiella Instrument som vid var tid finns förtecknade på
        Investeringssparkontot, dels samtliga de Finansiella Instrument som
        Kunden vid var tid förvärvat för förvaring på Investeringssparkontot
        samt dels samtliga kontanta medel som vid var tid finns insatta på
        Investeringssparkontot.
      </p>
    </section>

    <section>
      <h2>3. Fullmakt</h2>
      <p>
        Kunden befullmäktigar härmed Lysa att självt eller genom den som Lysa
        utser för Kundens räkning teckna transport av aktier, obligationer och
        andra värdepapper och att företräda Kunden vid förvärv/avyttring av
        värdepapper samt att mottaga och kvittera värdepapper och kontanter.
      </p>
      <p>
        Kunden befullmäktigar härmed Lysa att, om enligt Lysas bedömning behov
        finns, för Kundens räkning öppna en annan depå eller ett annat
        Investeringssparkonto i Lysa samt att, i enlighet med lagen om
        investeringssparkonto och villkoren i Avtalet och till sådan depå eller
        investeringssparkonto överföra sådana tillgångar som enligt lagen om
        investeringssparkonto eller Avtalet inte får förvaras på
        Investeringssparkontot.
      </p>
    </section>

    <section>
      <h2>4. Skatterättslighemvist m.m.</h2>
      <p>
        Kunden ska själv förvissa sig om sitt skatterättsliga hemvist. För
        fysisk person är det normalt det land/stat vars lag anger
        skattskyldighet på grund av huvudsaklig vistelse, bosättning, eller
        liknande omständighet.
      </p>
      <p>
        Kunden försäkrar härmed att här lämnade uppgifter av betydelse för
        beskattning, tillämpning av lagregler om uppgiftsskyldighet m.m. är
        riktiga och förbinder sig att utan dröjsmål anmäla förändringar i detta
        avseende, t ex flytt utomlands samt ändringar av namn, telefonnummer och
        e-post adress. Sådana förändringar görs i inloggat läge på Lysa.se.
      </p>
    </section>

    <section>
      <h2>5. Signering</h2>
      <p>
        Detta Avtal signeras av Kunden med elektronisk signatur. Avtalet och vid
        var tid gällande villkor finns tillgängligt i inloggat läge på Lysa.se.
      </p>
    </section>
  </article>
);

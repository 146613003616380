import React from "react";

export function TermsRetrieveExternalAccountAndTransferSV() {
  return (
    <article className="lysa-doc terms-retrieve-external-account-and-transfer">
      <header>
        <h1>Villkor för hämtning av konto och överföring</h1>
        v. 2020:1
        <p className="ingress">
          Hämtning av konto och överföring (<b>"Tjänsten"</b>) är ett verktyg
          som en kund kan använda för att genomföra överföringar från sin bank
          till sitt Lysakonto, anmäla uttagskonto och/eller välja konto för
          månadssparande, utan att lämna Lysas sajt.
        </p>
      </header>

      <section>
        <h3>1. Allmänt om tjänsten</h3>
        <p>
          Tjänsten består av ett extra gränssnitt mellan kunden och kundens
          internetbanktjänsts gränssnitt och har utformats specifikt för att
          möjliggöra hämtning av konto och överföringar i ett användarvänligt
          gränssnitt.
        </p>
        <p>
          Kunden utför personligen alla steg för att genomföra hämtning av konto
          och initiera en överföring, inklusive att logga in på sin
          internetbank, välja konto och signera en överföring. Kunden har därmed
          full kontroll över genomförandet av en transaktion.
        </p>
        <p>
          Läs igenom villkoren noga. Gå inte vidare om du inte accepterar
          villkoren. Genom att använda Tjänsten samtycker du till villkoren.
          Villkoren kan komma att ändras från tid till annan utan föregående
          meddelande och kunden måste därför vara noga med att läsa igenom
          villkoren före varje enskild transaktion. Om villkoren ändras kommer
          den reviderade versionen att förses med ett nytt versionsnummer.
        </p>
        <p>
          Tjänsten erbjuds inte av din bank och Lysa är inte ansluten till din
          bank. Tjänsten erbjuds till dig av Lysa AB, org. nr. 559028-0821
          (“Lysa”). Om Lysa av någon anledning inte kan erbjuda Tjänsten kan
          Lysa erbjuda kunden att hämta konto och genomföra insättning med hjälp
          av tredje part. Kunden är medveten om (i) att sådan tredje part
          skickar den information som anges i punkten 3 (Information som hämtas)
          till Lysa och (ii) att Lysa behandlar denna information i enlighet med
          punkten 9 (Personuppgifter).
        </p>
      </section>

      <section>
        <h3>2. Inloggningsinformation mm</h3>
        <p>
          Den inloggningsinformation som tillhandahålls av dig vid användandet
          av Tjänsten samlas in och behandlas av Lysa. Lysa vidarebefordrar
          informationen via Tjänstens gränssnitt till motsvarande gränssnitt hos
          din internetbank över en säker anslutning. All kommunikation hanteras
          krypterat.
        </p>
        <p>
          Alla transaktioner som utförs med hjälp av BankID skall anses utföras
          av kontots auktoriserade användare.
        </p>
      </section>

      <section>
        <h3>3. Information som hämtas</h3>
        <p>
          Genom att använda Tjänsten samtycker kunden till att information som
          tillhandahålls om kundens konton i internetbanktjänsten (kontonummer
          och saldo på kontot) hämtas av Lysa i syfte att kunden ska kunna
          genomföra överföring, välja uttagskonto och/eller välja konto för
          månadssparande. Kunden godkänner att Lysa sparar information om
          bankkontonummer. Information om saldo sparas inte av Lysa.
        </p>
      </section>

      <section>
        <h3>4. Användandet av tjänsten</h3>
        <p>
          Överföringar som sker genom tjänsten går genom clearingsystemet vilket
          innebär att det i undantagsfall kan ta upp till tre bankdagar innan
          insättningen når kundens Lysakonto.
        </p>
      </section>

      <section>
        <h3>5. Ansvar</h3>
        <p>
          Lysa har rätt att när som helst stänga av en användare från Tjänsten
          om användaren bryter mot dessa villkor eller använder Tjänsten på ett
          sätt som kan orsaka Lysa eller tredje man skada.
        </p>
        <p>
          Lysa har för avsikt att all information i Tjänsten ska vara korrekt
          och så uppdaterad som möjligt. Lysa kan inte garantera
          tillgängligheten till Tjänsten eller tillförlitligheten eller
          korrektheten på informationen som finns presenterad i Tjänsten. Lysa
          kan inte ställas till svars för om Tjänsten ligger nere eller om någon
          information eller funktionalitet inte fungerar som förväntat.
        </p>
        <p>
          Lysa ska inte ha något ansvar för eventuella ekonomiska eller andra
          beslut du tar baserat på användning av Tjänsten.
        </p>
      </section>

      <section>
        <h3>6. Avgifter och begränsningar som tillämpas av kundens bank</h3>
        <p>Lysa tar inte ut några avgifter för användandet av tjänsten.</p>
        <p>
          Kunden är ensamt ansvarig för alla eventuella avgifter som kan tas ut
          av hans eller hennes bank vid genomförande av betalningar, i enlighet
          med bankens gällande villkor. Kunden är vidare medveten om att vissa
          banker tillämpar begränsningar på hur stora överföringar som kan göras
          från ett bankkonto.
        </p>
      </section>

      <section>
        <h3>7. Meddelanden om funktionsstörning och liknande</h3>
        <p>
          Om kunden upptäcker funktionsstörningar, avbrott eller fel på Tjänsten
          ska Lysa meddelas om detta omedelbart.
        </p>
      </section>

      <section>
        <h3>8. Ansvarsbegränsning</h3>
        <p>
          Lysa är inte ansvarigt för skada som beror av svenskt eller utländskt
          lagbud, svensk eller utländsk myndighetsåtgärd, krigshändelse, strejk,
          blockad, bojkott, lockout eller annan liknande omständighet.
          Förbehållet i fråga om strejk, blockad, bojkott och lockout gäller
          även om Lysa självt är föremål för eller vidtar sådan konfliktåtgärd.
        </p>
        <p>
          Skada som uppkommit i andra fall ska inte ersättas av Lysa, om Lysa
          varit normalt aktsam.
        </p>
        <p>
          Lysa ansvarar inte för skada som orsakats av - svensk eller utländsk -
          handelsplats, depåförande institut, värdepapperscentral,
          clearingorganisation, eller andra som tillhandahåller motsvarande
          tjänster, och inte heller av uppdragstagare som Lysa med tillbörlig
          omsorg anlitat eller som anvisats av kunden. Detsamma gäller skada som
          orsakats av att ovan organisationer eller uppdragstagare blivit
          insolventa. Lysa ansvarar inte för skada som uppkommer för kunden
          eller annan med anledning av förfogandeinskränkning som kan komma att
          tillämpas mot Lysa beträffande finansiella instrument. Därutöver
          ansvarar Lysa inte i något fall för avbrott i tillgängligheten för
          Lysas tjänster eller för skada som uppkommer på grund av fel eller
          brister i tele- eller dataförbindelser (mjuk- eller hårdvara) eller
          annan kommunikationsförbindelse.
        </p>
        <p>
          Lysa ansvarar inte för indirekt skada. Denna begränsning gäller dock
          inte om den indirekta skadan har orsakats av grov vårdslöshet.
          Begränsningen gäller inte heller vid uppdrag som lämnats av konsument
          om den indirekta skadan orsakats av Lysas vårdslöshet.
        </p>
      </section>

      <section>
        <h3>9. Personuppgifter</h3>
        <p>
          För behandling av personuppgifter gäller{" "}
          <a
            href="https://lysa.se/legal-information/personuppgiftspolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lysas information om personuppgifter
          </a>
          .
        </p>
      </section>
      <section>
        <h3>10. Tillämplig lag</h3>
        <p>
          Tolkning och tillämpning av dessa villkor ska ske enligt svensk rätt.
        </p>
      </section>
    </article>
  );
}

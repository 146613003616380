import React from "react";

export const PreInfoISKSV = () => (
  <article className="lysa-doc preinfo-isk">
    <header>
      <h1>Förhandsinformation om investeringssparkonto</h1>
      <p>v. 2016:1</p>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>
        Lysas ambition är att ha marknadens nöjdaste kunder. Ytterligare
        information om Lysa, Lysas tjänster samt allmän företagsinformation
        finns att tillgå på vår hemsida på internet, Lysa.se.
      </p>
      <p>
        Lysa har tillstånd av Finansinspektionen, Box 7821, 103 97 Stockholm,
        att bedriva värdepappersrörelse. Tillsynsmyndighet för Lysas verksamhet
        är Finansinspektionen.
      </p>
      <p>
        Den information som enligt lag och föreskrift ska lämnas innan ett avtal
        ingås tillhandahålls genom denna förhandsinformation och samt de
        allmänna villkoren för investeringssparkonto och de dokument som
        villkoren hänvisar till.
      </p>
    </section>

    <section>
      <h2>Vad är ett Investeringssparkonto?</h2>
      <p>
        Investeringssparkontot är en schablonbeskattad sparform som är
        tillgänglig för privatpersoner och dödsbon. Sparformen är frivillig och
        utgör ett alternativ till sparande i värdepapper på en vanlig depå
        och/eller vanligt servicekonto, som är föremål för konventionell
        beskattning, eller till ett schablonbeskattat
        kapitalförsäkringssparande. Ett Investeringssparkonto kan inte innehas
        gemensamt med en annan person.
      </p>
      <p>
        Investeringssparkontot kan beskrivas som ett för skatteändamål samlat
        sparande av godkända kapitalplaceringar. Genom att innehavet på
        Investeringssparkontot schablonbeskattas så kan du sätta in pengar på
        kontot och handla med finansiella instrument och omplacera dessa utan
        att behöva redovisa varje kapitalvinst eller kapitalförlust i din
        deklaration. För att du ska kunna avgöra om det är en fördel för dig att
        placera ditt sparande på ett Investeringssparkonto jämfört med andra
        sparformer är det dock viktigt att du beaktar ett antal faktorer såsom
        din bedömning av marknadsutvecklingen, din sparhorisont, vilka
        värdepapper du vill spara i samt dina personliga förhållanden i övrigt.
      </p>
    </section>

    <section>
      <h2>Hur fungerar det?</h2>
      <p>
        För att öppna ett Investeringssparkonto måste du ingå ett särskilt avtal
        om Investeringssparkonto med Lysa. Kontot innefattar dels ett förvar av
        finansiella instrument, dels förvar av likvida medel. De tillgångar som
        på detta sätt hör till och förvaras på Investeringssparkontot redovisas
        skattemässigt som en enhet.
      </p>
      <p>
        Lagen om Investeringssparkonto och avtalet om Investeringssparkonto
        anger ramarna för vilka tillgångar som får förvaras på
        Investeringssparkontot och hur överföringar av tillgångar till och från
        kontot får ske. Det är dock allmänna civilrättsliga bestämmelser och
        principer som avgör vem som äger och vem som har rätt till de tillgångar
        som förvaras på ett Investeringssparkonto. Vidare gäller sedvanliga
        regler om hur Lysa ska hantera kunders tillgångar m.m. Tillgångar som
        förvaras och/eller sätts in på ett Investeringssparkonto kan omfattas av
        bestämmelserna om investerarskydd och insättningsgaranti.
      </p>
    </section>

    <section>
      <h2>Vilka avgifter tas ut?</h2>
      <p>
        För Investeringssparkontot kan Lysa ta ut avgift enligt vid var tid
        gällande prislista. För de anslutna tjänsterna som erbjuds inom ramen
        för Investeringssparkontot tar Lysa ut de avgifter som gäller enligt
        avtal och villkor för respektive erbjudande.
      </p>
      <p>
        Information om vid var tid gällande priser finns att tillgå på Lysas
        hemsida, Lysa.se.
      </p>
    </section>

    <section>
      <h2>Hur beskattas sparandet?</h2>
      <p>
        Tillgångar som är hänförliga till Investeringssparkontot
        schablonbeskattas. Det innebär att den enskilde inte beskattas utifrån
        faktiska inkomster och utgifter på de tillgångar som förvaras på
        Investeringssparkontot. Istället utgår en schablonskatt som beräknas på
        förväntad årlig avkastning. Denna skatt beräknas utifrån ett så kallat
        kapitalunderlag som baseras på marknadsvärdet på tillgångarna som
        förvaras på kontot.
      </p>
      Kapitalunderlaget för ett visst beskattningsår uppgår till en fjärdedel av
      summan av marknadsvärdet av:
      <ul>
        <li>
          tillgångar som vid ingången av varje kvartal under året förvaras på
          Investeringssparkontot,
        </li>
        <li>
          kontanta medel som sätts in på Investeringssparkontot under året, om
          insättningen inte sker från ett annat eget Investeringssparkonto,
        </li>
        <li>
          investeringstillgångar som investeringsspararen under året överför
          till kontot, om överföringen inte sker från ett annat eget
          Investeringssparkonto, och
        </li>
        <li>
          investeringstillgångar som under kalenderåret överförs till kontot
          från någon annans Investeringssparkonto.
        </li>
      </ul>
      <p>
        Kapitalunderlaget multipliceras med en räntefaktor som uppgår till
        statslåneräntan den 30 november året före beskattningsåret. Detta
        resulterar i en schablonintäkt som ska tas upp till beskattning -
        oavsett om värdet på ditt innehav på Investeringssparkontot ökar eller
        minskar. Lysa beräknar och rapporterar schablonintäkten till
        Skatteverket.
      </p>
      <p>
        Som angavs ovan ökar insättningar av kontanta medel på
        Investeringssparkontot kapitalunderlaget och därmed schablonintäkten som
        ju skatten beräknas på. När det gäller ränta på kontanta medel finns det
        dessutom en särskild regel som i vissa fall kan leda till
        dubbelbeskattning. Om räntesatsen som legat till grund för räntans
        beräkning överstigit statslåneräntan den 30 november föregående år ska
        räntan beskattas på vanligt sätt samtidigt som de kontanta medlen ingår
        i kapitalunderlaget. Denna särregel gäller dock inte för
        ränteplaceringar som du gör i form av finansiella instrument som
        exempelvis räntefonder eller noterade obligationer.
      </p>
      <p>
        För personer som är begränsat skattskyldiga i Sverige, gäller särskilda
        skatteregler. Det är därför viktigt att du informerar Lysa om din
        skatterättsliga hemvist förändras, t.ex. vid utlandsflytt.
      </p>
    </section>

    <section>
      <h2>Hur tas skatten ut?</h2>
      <p>
        Lysa gör inte några preliminära skatteavdrag på Investeringssparkontot
        men ska årligen lämna kontrolluppgift till Skatteverket om
        schablonintäktens storlek. I din deklaration tas sedan schablonintäkten
        upp som en vanlig kapitalinkomst från vilken du kan göra ränteavdrag
        etc. Ett överskott i inkomstslaget kapital beskattas på sedvanligt sätt
        med 30 procent och ett underskott i inkomstslaget kapital kan medföra
        skattereduktion. Skatteverket bestämmer den slutliga skatten som framgår
        av det årliga skattebeskedet.
      </p>
      <p>
        Om du är bosatt utomlands eller sparar i utländska värdepapper kan det
        även utgå andra skatter på ditt innehav, t.ex. svensk kupongskatt eller
        utländsk källskatt.
      </p>
    </section>

    <section>
      <h2>Vilka placeringsbegränsningar gäller?</h2>
      <p>
        På ett Investeringssparkonto kan du spara i sådana
        investeringstillgångar som Lysa vid var tid tillåter, dvs. godkända
        investeringstillgångar. Du kan också sätta in kontanta medel på kontot,
        i sådana valutor som Lysa vid var tid tillåter.
      </p>
      Enligt lagen om Investeringssparkonto utgör följande
      investeringstillgångar:
      <ol>
        <li>
          Finansiella instrument som är upptagna till handel på reglerad marknad
          eller motsvarande marknad utanför EES (t.ex. NASDAQ)
        </li>
        <li>
          Finansiella instrument som handlas på en handelsplattform inom EES
          (t.ex. First North)
        </li>
        <li>Andelar i värdepappers- och specialfonder (fondandelar)</li>
      </ol>
      <p>
        Med finansiella instrument avses t.ex. aktier, obligationer samt andra
        delägarrätter eller fordringsrätter som är utgivna för allmän
        omsättning. För fondandelar uppställs inget krav på handel såsom det
        görs för övriga finansiella instrument.
      </p>
      <h3>
        Lysa godkänner för närvarande endast andelar i sådana fonder som
        förvaltas av Lysa som godkända investeringstillgångar.
      </h3>
      Enligt lagen om Investeringssparkonto utgör t.ex. följande kontofrämmande
      tillgångar:
      <ol>
        <li>
          Finansiella instrument som inte uppfyller handelskravet (t.ex.
          onoterade aktier och obligationer)
        </li>
        <li>
          Andelar i utländska fonder som inte motsvarar svenska värdepappers-
          och specialfonder.
        </li>
      </ol>
      <p>
        Som huvudregel får du på ditt Investeringssparkonto alltså inte förvara
        sådana investeringstillgångar som Lysa inte godkänner eller
        kontofrämmande tillgångar.
      </p>
      <p>
        Du bör även vara särskilt uppmärksam på att förvaring av kontofrämmande
        tillgångar på ditt investeringssparkonto i strid med vad som framgår
        ovan kan medföra att dessa tillgångar inte längre blir föremål för
        schablonbeskattning.
      </p>
    </section>

    <section>
      <h2>Kan befintligt sparande överföras till Investeringssparkontot?</h2>
      <p>
        Till ditt Investeringssparkonto hos Lysa kan du endast överföra likvida
        medel.
      </p>
      <p>
        Om du vill föra över befintligt sparande från ett annat institut till
        Lysa måste du först sälja av det befintliga sparandet, detta kan dock
        utlösa kapitalvinstbeskattning. Marknadsvärdet av det överförda
        sparandet betraktas som en inbetalning som läggs till kapitalunderlaget
        vid beräkningen av årets schablonintäkt för Investeringssparkontot.
      </p>
    </section>

    <section>
      <h2>Hur kan man ta ut sparande från Investeringssparkontot?</h2>
      <p>
        Du kan när som helst ta ut ditt sparande i form av kontanta medel från
        Investeringssparkontot, t.ex. för att använda pengarna till konsumtion,
        genom att göra ett uttag.
      </p>
      <p>
        Genom att underteckna avtalet om Investeringssparkonto lämnar du en
        fullmakt till Lysa som kan användas för att i ditt namn öppna vanlig
        depå/konto eller annat investeringssparkonto hos Lysa. Sådan möjlighet
        kan Lysa utnyttja, t.ex. om det skulle uppstå behov av att flytta ut
        sådana kontofrämmande tillgångar som inte får förvaras på
        Investeringssparkontot annat än under en begränsad tid.
      </p>
    </section>

    <section>
      <h2>Hur avslutar man Investeringssparkontot?</h2>
      <p>
        Du kan avsluta ditt Investeringssparkonto när som helst genom att
        kontakta Lysa. Innan kontot avslutas måste dock de tillgångar och
        kontanta medel som förvaras på Investeringssparkontot säljas och
        därefter kan de kontanta medlen överföras till ett annat konto.
      </p>
      <p>
        I lagen om investeringssparkonto finns även bestämmelser som innebär att
        Investeringssparkontot omedelbart ska upphöra i vissa situationer. Detta
        gäller t.ex. om du eller Lysa bryter mot lagens bestämmelser om
        överföring av finansiella instrument från Investeringssparkontot.
      </p>
      <p>
        När Investeringssparkontot upphör blir tillgångarna föremål för
        konventionell beskattning och får som huvudregel ett anskaffningsvärde
        som motsvarar marknadsvärdet när Investeringssparkontot upphör.
      </p>
    </section>

    <section>
      <h2>Var finns mer information?</h2>
      <p>
        För detaljerade regler om förvärv, förvaring och överföring av
        investeringstillgångar respektive kontofrämmande tillgångar hänvisas
        till Avtal om Investeringssparkonto och lagen (2011:1268) om
        Investeringssparkonto. Information om vilka godkända
        investeringstillgångar som Lysa vid var tid tillåter på ditt
        Investeringssparkonto finns på Lysa.se.
      </p>
    </section>
  </article>
);

import React from "react";
import { PLACEHOLDER, DocAccountProps } from "../../Language";

export const AgreementKFSV = ({ language, account }: DocAccountProps) => (
  <article className="lysa-doc agreement-account-kf">
    <header>
      <h1>Avtal om kapitalförsäkringsdepå</h1>
    </header>

    <ul>
      <li>
        <strong>Datum:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Kund:</strong> {account.name}, {account.companyId} ("
        <strong>Kunden</strong>")
      </li>
      <li>
        <strong>Kontonamn:</strong> {account.accountName}
      </li>
      <li>
        <strong>Kontoid:</strong> {account.accountId ?? PLACEHOLDER[language]}
      </li>
    </ul>

    <section>
      <h2>1. Avtal</h2>
      <p>
        Mellan Kunden och Lysa AB (<b>"Lysa"</b>) träffas avtal om
        kapitalförsäkringsdepå för Kunden hos Lysa enligt nedanstående villkor
        samt enligt vid var tid gällande Allmänna bestämmelser för
        kapitalförsäkringsdepå och Lysas Instruktion för bästa orderutförande
        och sammanläggning av order (<b>"Avtalet"</b>).
      </p>
      <p>
        Lysa förmedlar kapitalförsäkring där Lysa är depåinstitut och
        tillhandahåller kapitalförsäkringsdepån kopplad till ett av Kunden,
        genom egen försorg eller Lysas, tecknat försäkringsavtal (
        <b>"Försäkringsavtal"</b>).
      </p>
    </section>

    <section>
      <h2>2. Lysas uppdrag och fullmakt</h2>
      <p>
        Lysa utvärderar löpande huruvida det finns andra alternativa
        försäkringsavtal som är förenade med bättre försäkringsvillkor än
        befintligt Försäkringsavtal. Lysa garanterar inte någon frekvens med
        vilken utvärdering av alternativa försäkringsavtal sker och åtar sig
        inte heller något ansvar för att Kunden vid varje tidpunkt har det
        billigaste eller bästa försäkringsavtalet tillgänglig på marknaden
      </p>
      <p>
        I det fall Lysa identifierar ett alternativt försäkringsavtal som enligt
        Lysas självständiga bedömning är förenat med bättre försäkringsvillkor
        än Kundens befintliga Försäkringsavtal får Lysa, på eget initiativ och
        utan föregående godkännande av Kunden, för Kundens räkning, teckna nytt
        försäkringsavtal, återköpa befintligt Försäkringsavtal samt vidta andra
        därtill hörande administrativa åtgärder för att flytta Kundens
        tillgångar från det befintliga Försäkringsavtalet till det nya
        försäkringsavtalet. Lysa får endast genomföra ett byte av
        Försäkringsavtal om kund efter bytet erhåller samma eller lägre
        totalkostnad.
      </p>
      <p>
        Kunden befullmäktigar således Lysa att självt eller genom den som Lysa
        utser för Kundens räkning att vidta följande åtgärder:
      </p>
      <ul>
        <li>
          <b>att</b> teckna transport av värdepapper,
        </li>
        <li>
          <b>att</b> företräda Kunden vid förvärv/avyttring av värdepapper samt
          att mottaga och kvittera värdepapper och kontanter,
        </li>
        <li>
          <b>att</b> teckna eller på annat sätt ingå försäkringsavtal,
        </li>
        <li>
          <b>att</b> löpande förvalta ingått Försäkringsavtal,
        </li>
        <li>
          <b>att</b> återköpa eller på annat sätt avveckla Försäkringsavtalet,
        </li>
        <li>
          <b>att</b> hos Lysa öppna ny värdepappersdepå kopplad till ett nytt
          försäkringsavtal med samma placeringsinriktning som kundens befintliga
          Försäkringsavtal,
        </li>
        <li>
          <b>att</b> vidta administrativa åtgärder för att flytta Kundens
          tillgångar från det befintliga Försäkringsavtalet till det nya
          Försäkringsavtalet kopplat till ny värdepappersdepå, samt
        </li>
        <li>
          <b>att</b> vidta samtliga övriga därtill hörande administrativa eller
          andra åtgärder rörande förvaltningen eller fullgörandet av
          Försäkringsavtal.
        </li>
      </ul>
      <p>
        I det fall Lysa avser att företa åtgärder rörande Försäkringsavtal för
        Kunds räkning inom ramen för uppdraget ska Kunden erhålla information om
        åtgärden minst 30 dagar innan åtgärden genomförs.
      </p>
      <p>
        Lysa ansvarar inte för de skattemässiga konsekvenser som ingående,
        återköp eller andra åtgärder avseende Försäkringsavtal har för Kunden
        och erbjuder inte heller rådgivning kring sådana skattemässiga frågor.
      </p>
    </section>

    <section>
      <h2>3. Skatterättslig hemvist m.m.</h2>
      <p>
        Kunden ska själv förvissa sig om sitt skatterättsliga hemvist. För
        juridisk person avses normalt det land där den juridiska personen är
        registrerad eller där företaget/styrelsen har sitt säte.
      </p>
      <p>
        Kunden försäkrar härmed att här lämnade uppgifter av betydelse för
        beskattning, tillämpning av lagregler om uppgiftsskyldighet m.m. är
        riktiga och förbinder sig att utan dröjsmål till Lysa skriftligen anmäla
        förändringar i detta avseende, t.ex. flytt utomlands samt ändringar av
        namn, telefonnummer och e-postadress. Sådana förändringar görs i
        inloggat läge på{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://lysa.se/">
          www.lysa.se
        </a>
        .
      </p>
    </section>

    <section>
      <h2>4. Signering</h2>
      <p>Detta Avtal signeras av Kunden med elektronisk signatur.</p>
    </section>
  </article>
);

import React from "react";
import { PLACEHOLDER, DocAccountProps } from "../../Language";

export const CustodyAccountAgreementSV = ({
  language,
  account,
}: DocAccountProps) => (
  <article className="lysa-doc custody-account-agreement">
    <header>
      <h1>Depåavtal</h1>
    </header>

    <ul>
      <li>
        <strong>Datum:</strong> {account.accountSigning}
      </li>
      <li>
        <strong>Kund:</strong> {account.name}, {account.companyId} ("
        <strong>Kunden</strong>")
      </li>
      <li>
        <strong>Kontonamn:</strong> {account.accountName}
      </li>
      <li>
        <strong>Kontoid:</strong> {account.accountId ?? PLACEHOLDER[language]}
      </li>
    </ul>

    <section>
      <h2>1. Avtal</h2>
      <p>
        Mellan Kunden och Lysa AB ("<strong>Lysa</strong>") träffas avtal om
        depå/ konto för Kunden hos Lysa enligt nedanstående villkor samt enligt
        vid var tid gällande <em>Allmänna villkor för depå/konto</em> och Lysas{" "}
        <em>
          Instruktion för bästa orderutförande och sammanläggning av order
        </em>{" "}
        ("<strong>Avtalet</strong>").
      </p>
    </section>

    <section>
      <h2>2. Pantsättning</h2>
      <p>
        Kunden pantsätter till Lysa, till säkerhet för samtliga Kundens
        nuvarande och blivande förpliktelser gentemot Lysa, dels samtliga de
        finansiella instrument som vid var tid finns förtecknade på
        depån/kontot, dels samtliga de finansiella instrument som Kunden vid var
        tid förvärvat för förvaring på depån/kontot samt dels samtliga kontanta
        medel som vid var tid finns insatta på depån/kontot.
      </p>
    </section>

    <section>
      <h2>3. Fullmakt</h2>
      <p>
        Kunden befullmäktigar härmed Lysa att självt eller genom den som Lysa
        utser för Kundens räkning teckna transport av aktier, obligationer och
        andra värdepapper och att företräda Kunden vid förvärv/avyttring av
        värdepapper samt att mottaga och kvittera värdepapper och kontanter.
      </p>
    </section>

    <section>
      <h2>4. Skatterättslighemvist m.m.</h2>
      <p>
        Kunden ska själv förvissa sig om sitt skatterättsliga hemvist. För
        juridisk person avses normalt det land där den juridiska personen är
        registrerad eller där företaget/styrelsen har sitt säte.
      </p>
      <p>
        Kunden försäkrar härmed att här lämnade uppgifter av betydelse för
        beskattning, tillämpning av lagregler om uppgiftsskyldighet m.m. är
        riktiga och förbinder sig att utan dröjsmål till Lysa skriftligen anmäla
        förändringar i detta avseende, t ex flytt utomlands samt ändringar av
        namn, telefonnummer och e-postadress. Sådana förändringar görs i
        inloggat läge på Lysa.se.
      </p>
    </section>

    <section>
      <h2>5. Signering</h2>
      <p>
        Detta Avtal signeras av Kunden med elektronisk signatur. Avtalet, Lysas
        allmänna villkor för diskretionär portföljförvaltning samt gällande
        placeringsriktlinjer för varje Lysakonto finns tillgängligt för Kunden i
        inloggat läge på Lysa.se.
      </p>
    </section>
  </article>
);

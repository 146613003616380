import React from "react";
import { DocCurrencyProps } from "../../Language";

export const DepositorInformationSV = ({ currency }: DocCurrencyProps) => {
  return (
    <article className="lysa-doc depositor-information">
      <header>
        <h1>Information till insättare</h1>
      </header>

      <section>
        <h2>Grundläggande information om skydd för insättningar</h2>
        <h3>
          Insättningar i Lysa AB förvaras på klientmedelskonto hos Danske Bank
          A/S, Danmark, Sverige Filial och skyddas av:
        </h3>
        <p>
          Garantiformuen
          <sup>
            <a href="#fn1" id="fnref1">
              1
            </a>
          </sup>
        </p>

        <h3>Skyddets begränsning:</h3>
        <p>
          100 000 EUR per insättare per kreditinstitut
          <sup>
            <a href="#fn2" id="fnref2">
              2
            </a>
          </sup>
        </p>

        <h3>Om du har flera insättningar hos samma kreditinstitut:</h3>
        <p>
          Alla dina insättningar hos samma kreditinstitut läggs samman och det
          sammanlagda beloppet omfattas av gränsen på 100 000 EUR
          <sup>
            <a href="#fn2" id="fnref2_2">
              2
            </a>
          </sup>
        </p>

        <h3>Om du har ett gemensamt konto tillsammans med andra personer:</h3>
        <p>
          Gränsen på 100 000 EUR gäller för varje insättare separat
          <sup>
            <a href="#fn3" id="fnref3">
              3
            </a>
          </sup>
        </p>

        <h3>Ersättningsperiod om kreditinstitutet fallerar:</h3>
        <p>
          Inom sju arbetsdagar skickar Garantiformuen ett betalningsuppdrag till
          Riksgäldskontoret, som därefter utför utbetalningen
          <sup>
            <a href="#fn4" id="fnref4">
              4
            </a>
          </sup>
        </p>

        <h3>Valuta:</h3>
        <p>Ersättning ges i {currency}.</p>

        <h3>Kontakt:</h3>
        <p>
          <address>
            Garantiformuen
            <br />
            Sankt Annæ Plads 13, 2. tv.
            <br />
            DK-1250 København K
            <br />
            Telefon: <a href="tel:+45 33 14 62 45">+45 33 14 62 45</a>
            <br />
            E-post: <a href="mailto:gii@gii.dk">gii@gii.dk</a>
            <br />
            Webbplats:{" "}
            <a
              href="https://www.gii.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.gii.dk
            </a>
            <br />
          </address>
        </p>
      </section>

      <section>
        <h2>Ytterligare information</h2>
        <p>
          Insättningar av privatkunder och företag omfattas i regel av
          insättningsgarantisystem. Undantag för vissa insättningar anges på det
          ansvariga insättningsgarantisystemets webbplats. På begäran informerar
          ditt kreditinstitut om huruvida vissa produkter är garanterade. Om
          insättningar är garanterade ska kreditinstitutet även bekräfta det på
          kontoutdraget.
        </p>
      </section>

      <section className="footnotes">
        <hr />
        <ol>
          <li id="fn1">
            <h1>System som ansvarar för skyddet av din insättning</h1>
            <p>
              Din insättning täcks av ett lagstadgat insättningsgarantisystem.
              Om insolvens uppstår kommer dina insättningar att återbetalas med
              upp till 100 000 EUR av insättningsgarantisystemet.
            </p>
            <a href="#fnref1" className="footnote-backref">
              ↩
            </a>
          </li>
          <li id="fn2">
            <h1>Allmän begränsning för skyddet</h1>
            <p>
              Om en insättning är indisponibel på grund av att ett
              kreditinstitut inte kan uppfylla sina finansiella åtaganden ska
              insättarna få återbetalning via ett insättningsgarantisystem.
              Denna återbetalning täcker maximalt 100 000 EUR per
              kreditinstitut. Detta innebär att alla insättningar hos ett och
              samma kreditinstitut läggs samman för att kunna fastställa
              garantinivån. Om en insättare till exempel har ett sparkonto med
              90 000 EUR och ett lönekonto med 20 000 EUR kommer insättaren
              enbart att ersättas med 100 000 EUR.
            </p>
            <p>I vissa fall skyddas insättningar utöver 100 000 EUR.</p>
            <ul>
              <li>
                Insättningar på kontot för Individuellt pensionssparande täcks
                fullt ut enligt lag.
              </li>
              <li>
                Insättningar som avser medel som hänförs till överlåtelser av
                fast egendom täcks till ett belopp motsvarande 10 miljoner EUR
                under 12 månader från insättningen, förutsatt den fasta
                egendomen har använts eller ska användas huvudsakligen icke
                kommersiellt.
              </li>
              <li>
                Insättningar som enligt lag har ett socialt ändamål och som är
                knutna till vissa livssituationer, såsom äktenskap, skilsmässa
                eller invaliditet, täcks till ett belopp motsvarande 150 000 EUR
                under sex månader från insättningen.
              </li>
              <li>
                Insättningar som avser ersättning eller gottgörelse i enlighet
                med lag för skador till följd av brott eller felaktig dom, täcks
                till ett belopp motsvarande 150 000 EUR under sex månader från
                insättningen.
              </li>
            </ul>
            <p>
              Ytterligare information finns på{" "}
              <a
                href="https://www.gii.dk"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gii.dk
              </a>
              .
            </p>
            <a href="#fnref2" className="footnote-backref">
              ↩
            </a>
          </li>
          <li id="fn3">
            <h1>Begränsning för gemensamma konton</h1>
            <p>
              Om det rör sig om gemensamma konton gäller gränsvärdet på 100 000
              EUR för varje insättare.
            </p>
            <a href="#fnref3" className="footnote-backref">
              ↩
            </a>
          </li>
          <li id="fn4">
            <h1>Återbetalning</h1>
            <p>Ansvarigt insättningsgarantisystem är:</p>
            <p>
              <address>
                Garantiformuen
                <br />
                Sankt Annæ Plads 13, 2. tv.
                <br />
                DK-1250 København K
                <br />
                Telefon: <a href="tel:+45 33 14 62 45">+45 33 14 62 45</a>
                <br />
                E-post: <a href="mailto:gii@gii.dk">gii@gii.dk</a>
                <br />
                Webbplats:{" "}
                <a
                  href="https://www.gii.dk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.gii.dk
                </a>
                <br />
              </address>
            </p>
            <p>
              Insättningsgarantisystemet kommer att återbetala dina insättningar
              med upp till 100 000 EUR genom att senast inom sju arbetsdagar
              skicka ett betalningsuppdrag till Riksgäldskontoret, som därefter
              utför utbetalningen.
            </p>
            <p>
              Om du inte erhållit någon återbetalning inom dessa tidsfrister bör
              du kontakta insättningsgarantisystemet eftersom möjligheten att
              kräva återbetalning kan upphöra efter en viss tid. Ytterligare
              information fås på{" "}
              <a
                href="https://www.gii.dk"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gii.dk
              </a>
              .
            </p>
            <a href="#fnref4" className="footnote-backref">
              ↩
            </a>
          </li>
        </ol>
      </section>
    </article>
  );
};

import React from "react";

export const CustomerAgreementEN = () => {
  return (
    <article className="lysa-doc customer-agreement">
      <header>
        <h1>
          Agreement and general terms and conditions for portfolio management
        </h1>
        <p>v. 2020:3</p>
      </header>

      <section>
        <h2>1. General terms</h2>
        <p>
          This portfolio management agreement between you as a customer and Lysa
          AB, Reg. No. 559028-0821 (“<b>Lysa</b>”) is entered into on the terms
          and conditions set out below and as set out in the at each time
          applicable investment guidelines (the “<b>Agreement</b>”).
        </p>
        <p>
          The Agreement becomes legally binding when the customer signs the
          Agreement electronically and Lysa has approved the customer following
          a customary review.
        </p>
      </section>

      <section>
        <h2>2. Lysa’s service</h2>
        <p>
          Lysa is an automated asset manager. By entering into the Agreement,
          the customer assigns Lysa to manage all of the customer’s accounts
          with Lysa (each a “<b>Lysa account</b>”) on the terms and conditions
          set out in the Agreement. Lysa accepts this portfolio management
          assignment (the “<b>Services</b>”).
        </p>
        <p>
          Lysa will perform the Services in the customer’s interest and with due
          care. The customer is not entitled to invoke any restrictions with
          respect to the Services other than as set out in the Agreement.
        </p>
      </section>

      <section>
        <h2>3. Power of attorney</h2>
        <p>
          The customer authorises Lysa to represent the customer and to take all
          measures required to fulfill the Agreement, including, for example, to
          purchase and sell financial instruments.
        </p>
      </section>

      <section>
        <h2>4. The customer’s assurance</h2>
        <p>
          The customer is aware of and accepts the risks which are inherent in
          investments under the Agreement. The customer is further aware:
        </p>
        <ul>
          <li>
            that the value of the assets in the Lysa account and their return
            can be affected by a number of factors such as various market
            factors or other circumstances that affect the value of financial
            instruments, and that the value of investments may decrease as well
            as increase;
          </li>
          <li>
            that Lysa does not guarantee or assume any responsibility neither
            with respect to the performance of the assets in the Lysa account
            nor that a certain result is achieved;
          </li>
          <li>
            that Lysa’s service is not built to time the market and that Lysa
            therefore does not guarantee the customer a certain closing rate;
          </li>
          <li>
            that Lysa is not responsible for the result of investments or
            divestments made or omitted in accordance with the Agreement;
          </li>
          <li>
            that all capital gains within the customer’s Lysa account will be
            subject to income tax and capital gains tax, according to the
            customer’s individual circumstances; and
          </li>
          <li>
            that Lysa does not take into account any tax consequences for the
            customer of investments made under the Agreement.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Customer conditions</h2>
        <h3>5.1 Customer due diligence etc.</h3>
        <p>
          The customer must be of legal age. The customer must provide the
          information which Lysa requires in order for Lysa to achieve a
          satisfying know your customer (KYC), as well as provide Lysa with an
          approved identification in accordance with Lysa’s requirements from
          time to time. The customer must be the holder of a bank account with a
          bank in the country where the customer is resident and has its tax
          domicile.
        </p>
        <h3>5.2 FATCA</h3>
        <p>
          The Services are not addressed to natural persons residing in the
          United States or other U.S. Persons (as defined at each time in
          Regulation S of the United States Securities Act 1933) or to such
          persons in other countries where it is required that Lysa has taken
          certain registration measures, or similar.
        </p>
      </section>

      <section>
        <h2>6. Assurance of tax domicile</h2>
        <p>
          The customer must make sure of its tax domicile. For natural persons,
          it is normally the country/state under which laws the person is liable
          for tax due to its principal residence, domicile or any similar
          circumstance.
        </p>
        <p>
          The customer guarantees that the information which the customer has
          provided Lysa with respect to taxation and the application of any
          regulations with respect to Lysa’s reporting duty etc. is correct. The
          customer is required to inform Lysa without undue delay if the
          customer changes its tax domicile.
        </p>
      </section>

      <section>
        <h2>7. Management fees, taxes etc.</h2>
        <p>
          The fees for the Services are payable in accordance with the at each
          time applied price list published on Lysa’s website. The fees consist
          of (i) fee for the portfolio management and (ii) fees for the mutual
          funds which are distributed and managed by companies within the Lysa
          group (“<b>Lysa’s Funds</b>”) in which Lysa invests the customer’s
          money.
        </p>
        <p>
          The portfolio management fee is a fee to Lysa. It is calculated on a
          daily basis based on the account value and is debited from the
          customer’s Lysa account monthly in arrears. Lysa is entitled to sell
          financial instruments on the customer’s Lysa account in order to
          charge the portfolio management fee. Lysa applies a minimum limit
          before charging the fee. If the customer makes a withdrawal of the
          entire account value, the Lysa account is charged for any accrued
          fees.
        </p>
        <p>
          Furthermore, the management fee for Lysa’s Funds is a fee to Lysa
          Fonder AB. There are also fees for brokerage, currency exchange and
          management fees charged by the fund companies responsible for the
          underlying funds in which Lysa’s Funds invest.
        </p>
        <p>
          The customer is responsible for any taxes and other fees that are
          payable by law as a consequence of the Services.
        </p>
      </section>

      <section>
        <h2>8. Lysa account</h2>
        <p>
          A Lysa account is a custody account intended for portfolio management
          of financial instruments and money received and deposited by Lysa with
          requirements of record keeping (client funds).
        </p>
        <p>The Agreement covers all of the customer’s Lysa accounts.</p>
        <p>
          The customer may not independently dispose of the financial
          instruments or money in the Lysa account. However, the customer can
          make withdrawals from the Lysa account in accordance with Section 9 (
          <i>Deposits and withdrawals</i>).
        </p>
      </section>

      <section>
        <h2>9. Deposits and withdrawals</h2>
        <h3>9.1 Deposits</h3>
        <p>
          Lysa will invest deposits on the customer’s Lysa account in line with
          with the Lysa account’s investment guidelines (see further under
          Section 10 - <i>Investment guidelines</i>) as soon as possible, but
          under normal circumstances no later than three Swedish bank days from
          the Swedish bank day on which the deposit was received on the
          customer’s Lysa account. For example, Lysa may due to security reasons
          have to perform manuell checks etc on certain deposits which may
          entail a longer processing time before Lysa can place an order.
          Deposits received later than 16:00 (GMT+1) are considered to have been
          received on the following bank day. Lysa does not guarantee the
          functionality of the Services if the customer’s first deposit is an
          amount less than the equivalence of 1,000 SEK.
        </p>
        <p>
          When determining whether to accept a payment from the customer, in
          accordance with Lysa’s legal and regulatory obligations regarding
          financial crime, Lysa reserves the right to reject any payments where
          it is found that the customer is not the beneficial owner of the
          funds. In order to comply with money laundering regulations, Lysa may
          occasionally need to ask for evidence that the customer’s wealth is
          from a legitimate source and that the customer is the beneficial owner
          of the funds. The customer agrees to comply with any reasonable
          request Lysa makes for these purposes.
        </p>
        <p>
          If a customer has more than one Lysa account and it is not clear for
          which Lysa account a deposit is intended, Lysa reserves the right to
          transfer the deposit to the customer’s first opened Lysa account
          unless the customer notifies Lysa otherwise within 14 days from the
          date the deposit was received in accordance with the above.
        </p>
        <p>
          If it is not clear which customer has made or is the recipient of a
          deposit, Lysa is entitled, but not obliged, to take measures to track
          which customer the deposit is intended for. If such measures result in
          costs for Lysa (eg. bank’s tracking fee), Lysa is entitled to
          compensation for such costs from the customer. Lysa has the right to
          deduct an amount corresponding to the cost from the relevant deposit
          and transfer the remaining amount of the deposit to the customer’s
          first opened Lysa account. If an unknown deposit amounts to a maximum
          of the equivalence of SEK 1,500, and the customer has not claimed the
          deposit within two years from the time the deposit was received by
          Lysa, Lysa has the right to donate the deposited amount to a
          charitable purpose at Lysa’s own discretion.
        </p>
        <h3>9.2 Withdrawals</h3>
        <p>
          The customer can make withdrawals when signed in to Lysa’s website by
          requesting a withdrawal to its pre-registered withdrawal account. The
          withdrawal account must belong to the customer.
        </p>
        <p>
          Upon the customer’s withdrawal request, Lysa will placa a sales order
          of fund units with the relevant fund company. Thereafter, the fund
          company will sell the customer’s holdings in accordance with Lysa’s
          request and in accordance with the applicable fund rules. When Lysa
          has received the sales proceeds for the relevant divestment, Lysa will
          disburse the proceeds to the customer’s withdrawal account.
        </p>
        <p>
          Lysa will request a sale of the customer’s holdings and execute
          withdrawals as soon as possible. Lysa does not guarantee when Lysa can
          request a sale of the customer’s holdings or execute a withdrawal and
          thus, does not guarantee a certain closing rate (NAV) for the relevant
          withdrawal. For example, Lysa may due to security reasons have to
          perform manuell checks etc on certain deposits which may entail a
          longer processing time before Lysa can place an order. The customer
          understands that larger withdrawals may cause Lysa to quickly dispose
          of existing financial instruments in the Lysa account, whereby the
          financial outcome may be adversely affected and that, in connection
          therewith, deviations from the agreed investment guidelines may occur.
        </p>
      </section>

      <section>
        <h2>10. Investment guidelines</h2>
        <p>
          Investment guidelines are established for each Lysa account. The
          guidelines form part of this Agreement. The investment guidelines are
          established through an interactive process with the customer, where
          the customer takes part of Lysa’s suitability assessment. In the
          investment guidelines, a target allocation that provides exposure to
          the stock market and exposure to the fixed income market is
          established. The applicable investment guidelines for each Lysa
          account are available for the customer when signed in to Lysa’s
          website.
        </p>
        <p>
          Within Lysa’s mandate to perform the Services, Lysa can rebalance the
          customer’s portfolio between different instruments. For example, Lysa
          undertakes to rebalance the customer’s portfolio for it to be in line
          with the portfolio’s target allocation.
        </p>
        <p>
          The customer is entitled to, within certain limitations, change the
          target allocation by changing the investment guidelines. The customer
          can perform such changes when signed in to Lysa’s website. When
          altering the investment guidelines, the earlier applicable guidelines
          are replaced and the new investment guidelines come into effect the
          same day. Lysa will re-balance the customer’s holdings on the Lysa
          account for which the investment guidelines apply in order to fulfill
          the updated target allocation. Acquisitions and sales of holdings in
          order to realise the re-balance are performed in accordance with
          Section 9 (<i>Deposits and withdrawals</i>).
        </p>
        <p>
          By changing the investment guidelines, or as instructed when signed in
          to Lysa’s website, the customer can make such options regarding the
          direction of its portfolio which Lysa offers at each time. If Lysa
          intends to cease to provide an option that has been provided to the
          customer, Lysa must inform the customer without undue delay after
          which the customer is entitled to terminate the Agreement effective
          immediately. If the customer has not taken any action with respect to
          the relevant option within one month following the receipt of such
          information in accordance with Section 20 (<i>Messages</i>), Lysa is
          entitled to reposition the customer’s assets in a way which Lysa deems
          appropriate. If it due to circumstances outside Lysa’s control not is
          possible to continue to provide the customer’s option during such a
          time, or where Lysa deems it not to be in the customer’s best interest
          to do so (e.g. due to increased costs), Lysa may reposition the
          customer’s assets in a way which Lysa deems appropriate prior to the
          the expiration of such a one-month period. If so, Lysa must without
          undue delay inform the customer and give an account of the reasons for
          this. Where applicable, the customer’s investment guidelines will be
          updated in accordance with the customer’s new option or Lysa’s
          reposition.
        </p>
        <p>
          The customer undertakes to inform Lysa in the event of changes of the
          information which the customer has provided Lysa as the basis for
          Lysa’s suitability assessment. Such information must be provided by
          the customer when signed in to Lysa’s website.
        </p>
      </section>

      <section>
        <h2>
          11. Instruments which may be included in the customer’s portfolio
        </h2>
        <p>
          The customer’s portfolio consists of a combination of Lysa’s Funds
          (mutual funds which are distributed and managed by companies within
          the Lysa group). Lysa’s Funds are components of Lysa’s portfolio
          management and cannot be traded directly. The customer is not entitled
          to move holdings in its portfolio from its Lysa account. Lysa’s
          investment philosophy is described in detail in{" "}
          <a
            href="https://lysa.se/whitepaper"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lysa’s White Paper
          </a>
          .
        </p>
      </section>

      <section>
        <h2>12. Investment objectives and method of comparison</h2>
        <p>
          The objective of the management is to maximise the customer’s return
          given the customer’s individual investment objectives, financial
          situation and risk preferences. Lysa's investment philosophy (see
          Lysa’s White Paper) means that the result of the management will be
          associated with the market developments. The customer can compare the
          trend against relevant financial indexes when signed in to Lysa’s
          website.
        </p>
      </section>

      <section>
        <h2>13. Information, reporting and valuation</h2>
        <h3>13.1 Information about the Services</h3>
        <p>
          The customer has continuous access to all information about the
          Services, such as information about the portfolio and its value, the
          total amount of fees and charges and completed transactions, when
          signed in to Lysa's website. Contract notes regarding completed
          transactions are available to the customer when signed in to Lysa’s
          website.
        </p>
        <h3>13.2 Valuation of managed mutual funds</h3>
        <p>
          The valuation of the customer’s portfolio is based on the current NAV
          for Lysa’s Funds included in the customer’s portfolio, and is
          presented to the customer in logged-in mode on Lysa’s website. The
          current NAV for Lysa’s Funds is presented to the customer at{" "}
          <a
            href="https://lysa.capital/funds/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.capital/funds
          </a>
          . The NAV for Lysa’s Funds is established with one day’s delay, based
          on the daily updated NAV quotations of each underlying fund.
        </p>
        <p>
          Fund units are presented using the decimal number that Lysa applies.
          If a mutual fund reports holdings of fund units using more decimal
          numbers than Lysa applies, Lysa rounds off the numbers according to
          normal rounding routines before reporting to the customer.
        </p>
        <p>
          Lysa will notify the customer if the value of the customer’s holdings
          in a Lysa account is reduced by ten percent or more compared to the
          value on the first date during the current quarter and thereafter in
          case of reductions at multiples of ten percent.
        </p>
        <h3>13.3 Information on managed funds</h3>
        <p>
          The fund rules of each of Lysa’s Funds stipulates the terms and
          conditions for the fund, including in which instruments the fund may
          invest and what fees are charged. Fact sheets, information brochures
          and fund rules are available at{" "}
          <a
            href="https://lysa.capital/funds/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.capital/funds
          </a>
          .
        </p>
        <p>
          Lysa is registered for fund units on the customer’s behalf. Lysa
          therefore receives information from the fund companies regarding the
          relevant fund. The customer resigns the right to such information.
          However, Lysa must always provide the customer with information about
          material changes to fund rules or information relating to mergers in
          accordance with Chapter 4. § 12 of the Swedish Act (SFS 2004:46) on
          Mutual Funds.
        </p>
        <h3>
          13.4 Additional services relating to information and reports etc.
        </h3>
        <p>
          The customer may sign up for such additional services which Lysa at
          each time provides. Such additional services may entail that the
          customer receives certain information via the email address that the
          customer has notified Lysa. The customer is aware that the information
          so received may contain information that is protected by
          confidentiality. By signing up for an additional service, the customer
          agrees that Lysa may send unencrypted information within the scope of
          such service to the e-mail address which the customer has notified
          Lysa. Lysa may also send information about the customer’s activity on
          Lysa’s website via e-mail. The customer is aware of and accepts the
          risk that someone unauthorised can receive the information that is
          communicated and that the information may be distorted during the
          mediation.
        </p>
      </section>

      <section>
        <h2>14. Complaints</h2>
        <p>
          If the customer has a complaint in relation to any lack of conformity
          or default in the Services (including deviations from the applicable
          investment guidelines) included in any account or report from Lysa,
          the omission of such account or report, or any other default relating
          to the Agreement, the customer must notify Lysa. The customer must
          review settlement notes or any other messages, bank statements,
          transaction summaries or other reports regarding completed
          transactions received from Lysa and, in writing, report to Lysa any
          lack of conformity or default that appears thereof.
        </p>
        <p>
          Complaints should be made within a reasonable time from the time the
          customer realised or should have realised the default.
        </p>
        <p>
          If a complaint has not been made in accordance with the above, the
          customer is not entitled to request compensation or any other measure
          from Lysa.
        </p>
      </section>

      <section>
        <h2>15. Instructions by unauthorised persons</h2>
        <p>
          The customer has access to Lysa by signing in to Lysa’s website using
          such a verification method which Lysa approves at each time.
        </p>
        <p>
          If the customer loses its login credentials necessary to have access
          to Lysa, Lysa must re-verify the customer’s identity. Lysa is entitled
          to debit the cost for such re-verification (e.g. fee to a verification
          service) to the customer’s Lysa account and sell financial instruments
          on the customer’s Lysa account in order to charge the cost.
        </p>
        <p>
          The customer is responsible for ensuring that no unauthorised person
          has access to the customer's Lysa account and agrees to take all
          reasonable measures to keep the customer’s account secure and to not
          disclose account details to a person not duly authorised by the
          customer. The customer acknowledges and agrees that Lysa will rely on
          all instructions provided to Lysa when signed in to Lysa’s website to
          have been made by the customer or to have been duly authorised by the
          customer.
        </p>
      </section>

      <section>
        <h2>16. Right of disposition</h2>
        <p>
          The customer undertakes to ensure that the right of disposition to the
          Lysa account, the financial instruments and money on the Lysa account
          not are limited to a greater extent than as stated in the Agreement
          and the agreements governing the customer's Lysa accounts.
        </p>
      </section>

      <section>
        <h2>17. Right to suspend customers</h2>
        <p>
          Lysa is entitled to immediately suspend the customer from Lysa’s
          services without prior notice if:
        </p>
        <ul>
          <li>
            Lysa suspects that the customer’s use of Lysa’s services might be in
            violation of the legislation on insider dealing or market
            manipulation or good market practices;
          </li>
          <li>
            there are defaults or shortcomings in telecommunications or other
            communication connection, data equipment or computer systems;
          </li>
          <li>
            Lysa believes that the customer should be suspended in order to
            protect the customer’s, other customers’, Lysa’s or others’
            interests in ensuring a well-functioning market;
          </li>
          <li>
            the customer terminates the Agreement or all of its custody/account
            agreements for Lysa accounts;
          </li>
          <li>the customer has not submitted a declaration of tax domicile;</li>
          <li>
            the customer has not provided sufficient information for Lysa to
            achieve a satisfactory know your customer;
          </li>
          <li>
            Lysa receives information that the customer is resident in, has a
            significant connection to or has citisenship in another country with
            restrictions on trading in financial instruments;
          </li>
          <li>
            the customer is or becomes liable for declaration in a country other
            than the country in which the customer was domiciled at the time
            when entering into the Agreement; or
          </li>
          <li>
            the customer acts in a way which Lysa deems illegal, inappropriate
            or offending.
          </li>
        </ul>

        <p>
          If Lysa suspends the customer, Lysa must notify the customer as soon
          as possible.
        </p>
      </section>

      <section>
        <h2>18. Best execution policy</h2>
        <p>
          Lysa must at all times follow the Best execution policy. When entering
          into the Agreement, the customer will receive the at the time
          applicable policy. The at each time applicable policy is available on
          Lysa’s website.
        </p>
      </section>

      <section>
        <h2>19. Technical problems</h2>
        <p>
          The customer is aware that there may be interruptions in the
          customer’s, Lysa’s or others’ computer systems (hardware or software),
          telecommunications or electrical systems or similar defaults which may
          entail that the Services cannot be performed as agreed; for example
          that the customer cannot make deposits to or withdrawals from its Lysa
          account, or change its investment guidelines.
        </p>
        <p>
          Lysa is only liable for damage or loss which the customer has been
          caused as a result of the above technical problems if Lysa has been
          negligent. Lysa must prove that such damage did not occur due to
          Lysa’s negligence.
        </p>
      </section>

      <section>
        <h2>20. Information about scheduled maintenance of Lysa’s website</h2>
        <p>
          Lysa will inform the customer well in advance if and when Lysa’s
          website is closed for scheduled maintenance.
        </p>
      </section>

      <section>
        <h2>21. Closure of Lysa’s Funds due to extraordinary circumstances</h2>
        <p>
          The fund rules for each of Lysa’s Funds stipulates that the funds can
          be closed for subscription and redemption of fund units if
          extraordinary circumstances have occurred which entails that a
          valuation of the fund cannot be made in a way that ensures the
          equality of the unit holders. If such extraordinary circumstances are
          at hand, Lysa will still place purchase and sales orders in accordance
          with the Agreement. However, the customer is aware that in such a
          situation there may be delays in deposits, withdrawals and
          re-balances, until the relevant fund reopens for trading.
        </p>
      </section>

      <section>
        <h2>22. Limitation of Lysa’s liability</h2>
        <p>
          Lysa is not liable for damage caused by Swedish or foreign
          legislation, acts of a Swedish or foreign public authority, acts of
          war, strikes, blockades, boycotts, lockouts, epidemics, pandemics,
          quarantines or other events outside Lysa’s control. The reservation in
          the case of strikes, blockades, boycotts and lockouts applies
          notwithstanding that Lysa itself is subject to or takes such measures.
        </p>
        <p>
          Lysa is not liable for any loss or damage caused by any, Swedish or
          foreign, trading venue, third party custodian, central securities
          depository, clearing organisation, or other party that provides
          equivalent services, or by an agent that has been engaged by Lysa with
          due care or which has been recommended by the customer. The same
          applies to damage caused by the insolvency of any of the organisations
          or agents stated above. Lysa is not liable for any loss or damage
          incurred by the customer or others as a consequence of restrictions on
          the rights of disposition that may be applied to Lysa in respect of
          financial instruments. Nor is Lysa responsible for any tax
          consequences of the investments which are made under the Agreement.
        </p>
        <p>
          Lysa is only liable for damage or loss incurred in other circumstances
          if Lysa has been negligent. Lysa must prove that the damage did not
          occur due to Lysa’s negligence.
        </p>
        <p>
          If Lysa, as a consequence of an event referred to in the first
          paragraph, is prevented from carrying out a certain obligation under
          the Agreement, in whole or in part, such obligation may be postponed
          until the obstacle has ceased. If Lysa is prevented from executing or
          receiving payment/delivery as a result of such a circumstance, neither
          Lysa nor the customer shall be obliged to pay interest.
        </p>
        <p>
          The above provisions shall apply unless otherwise is prescribed by the
          Swedish Financial Instruments Accounts Act (1988:1479).
        </p>
      </section>

      <section>
        <h2>23. Notices</h2>
        <h3>23.1 Notice from Lysa</h3>
        <p>
          Lysa may send notices to the customer via email, other electronic
          communication or regular mail. Lysa can provide information via
          electronic message in Lysa’s internet service or to such an email
          address or postal address that the customer has notified Lysa.
        </p>
        <p>
          The customer undertakes to notify Lysa of any change in contact
          details without delay by providing updated information when signed in
          to Lysa’s website.
        </p>
        <p>
          Notification is considered to have been received by the customer at
          the time of dispatch if sent to the email address provided by the
          customer or via the message function on Lysa’s website. If a notice
          reaches the customer outside normal office hours, the message is
          deemed to have been delivered at the beginning of the following bank
          day in Sweden. Notification by regular mail will be deemed to be
          delivered on the second bank day after posting.
        </p>
        <h3>23.2 Notice to Lysa</h3>
        <p>
          The customer may send notices to Lysa via electronic communication.
          The contact details for such notice can be found at{" "}
          <a
            href="https://lysa.capital/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lysa.capital/contact
          </a>
          , unless Lysa has requested a specific address to be used.
          Notification by e-mail is considered to have been received by Lysa at
          the time of dispatch. If a notice reaches Lysa outside normal office
          hours, the notice is deemed to have been received at the beginning of
          the following bank day in Sweden.
        </p>
        <p>
          For a complaint or notice of termination in the case of a commission
          assigned by a consumer which is a retail customer according to Lysa’s
          categorisation, the notification may be invoked if the customer can
          show that it was sent in an appropriate manner, even if it was
          delayed, distorted or did not arrive.
        </p>
        <p>
          However, if the customer has reason to believe that Lysa did not
          receive the message or that it has been distorted, the customer must
          resend the message to Lysa.
        </p>
      </section>

      <section>
        <h2>24. Term and termination</h2>
        <p>
          Lysa may terminate the Agreement effective two months after the
          customer is deemed to have received the notice in accordance with
          Section 23 (<i>Messages</i>).
        </p>
        <p>
          The customer may terminate the Agreement effective immediately when
          signed in to Lysa’s website, subject to the settlement of any
          outstanding transactions. The customer may also terminate the
          Agreement in the manner set forth in Section 23 (<i>Messages</i>)
          effective one month after the message in accordance with the same
          section is deemed to have been received by Lysa. The customer may also
          terminate the Agreement via Lysa’s customer service after having
          properly identified themselves with the verification method that Lysa
          accepts at each time, effective after one month.
        </p>
        <p>
          Upon termination of the Agreement, the parties shall immediately
          settle all their obligations under the Agreement. However, the
          Agreement shall remain in force in applicable parts until a party has
          performed all of its obligations to the counterparty.
        </p>

        <p>
          Notwithstanding the provisions above, a party may terminate the
          Agreement effective immediately if the other party is in material
          breach of the Agreement. In such cases, each breach of contract which
          has not been remedied as soon as possible, notwithstanding a demand to
          do so, constitutes a material breach of contract. Lysa may also
          terminate the Agreement with immediate effect if:
        </p>
        <ul>
          <li>
            it is due to securities law or other restrictions in any country;
          </li>
          <li>
            the customer goes bankrupt or has been found in distraint and has no
            assets to pay off its debts;
          </li>
          <li>
            the customer has canceled its payments or is otherwise considered to
            be insolvent; or
          </li>
          <li>
            the customer has acted in violation of applicable law through
            transactions with Lysa.
          </li>
        </ul>
        <p>
          Upon termination of the Agreement, Lysa will transfer all securities
          and money in the Lysa account to the customer. The customer must
          provide Lysa with instructions about the transfer. If such
          instructions are not provided within sixty calendar days after the
          date of termination of the Agreement, or if the transfer cannot be
          made in accordance with the instructions provided by the customer,
          Lysa may sell the relevant securities and transfer the settlement and
          money in the Lysa account to the customer's pre-registered withdrawal
          account.
        </p>
        <p>
          Fund units in Lysa’s Funds must be sold upon termination of the
          Agreement. Upon termination of the Agreement, Lysa has the right to
          sell the customer’s holdings in Lysa’s Funds and transfer the
          settlement to the customer’s pre-registered withdrawal account. If the
          customer does not have a pre-registered withdrawal account, the
          customer must submit instructions to Lysa about the transfer.
        </p>
        <p>
          Lysa cannot help with the administration of a deceased customer’s
          account until Lysa has received an original or certified copy of a
          death certificate.
        </p>
      </section>

      <section>
        <h2>25. Amendments to the Agreement and changes in fees</h2>
        <p>
          Amendments to the Agreement or changes to Lysa’s fees shall take
          effect two months after the customer is deemed to have received notice
          of the amendment or change pursuant to Section 23 (<i>Messages</i>).
          If the customer does not accept the amendment, the customer may
          terminate the Agreement in accordance with Section 24 above.
        </p>
        <p>
          Lysa may implement an amendment or change that is considered
          insignificant to the customer without the customer being notified in
          accordance with the above.
        </p>
        <p>
          Amendments and additions to the Agreement must be made in writing to
          be valid.
        </p>
      </section>

      <section>
        <h2>26. Assignment</h2>
        <p>
          Lysa may at any time assign its rights and obligations under the
          Agreement to companies within the same group as the company or other
          company as a consequence of any procedure under company law.
        </p>
      </section>

      <section>
        <h2>27. Governing law</h2>
        <p>
          The interpretation and application of the Agreement is governed by
          Swedish law.
        </p>
      </section>
    </article>
  );
};

import React from "react";
import { DocAccountMap, DocAccountProps } from "../../Language";
import { InvestmentGuidelinesSV as SV } from "./InvestmentGuidelinesSV";
import { InvestmentGuidelinesEN as EN } from "./InvestmentGuidelinesEN";
import "../../Documents.css";

const docsMap: DocAccountMap = { SV, EN };

export const InvestmentGuidelines: React.FC<DocAccountProps> = props =>
  React.createElement(docsMap[props.language], props);

import React, { FC } from "react";
import { DocProps, DocMap } from "../../Language";
import { EligibilityAssessmentSV } from "./EligibilityAssessmentSV";
import "../../Documents.css";

const docsMap: DocMap = {
  EligibilityAssessmentSV,
};

export const EligibilityAssessment: FC<DocProps> = ({ language }) => {
  const Doc = docsMap[`EligibilityAssessment${language}`];
  return <Doc />;
};

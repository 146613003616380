import React from "react";

export function InformationAboutLysaSV() {
  return (
    <article className="lysa-doc information-about-lysa">
      <header>
        <h1>Information om Lysa</h1>
        v. 2021:1
      </header>

      <section>
        <h2>Kontaktinformation</h2>
        Du är alltid välkommen att kontakta oss. Våra kontaktuppgifter är:
        <br />
        <br />
        <address>
          Lysa AB
          <br />
          Löjtnantsgatan 21
          <br />
          115 50 Stockholm
          <br />
          <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a>
          <br />
          <a href="https://lysa.se">lysa.se</a>
        </address>
      </section>

      <section>
        <h2>Språk</h2>
        <p>
          Lysa använder svenska eller engelska i all dokumentation. Information
          från Lysa kan även vara på språket i det land där kunden registrerar
          sig. Kontakterna mellan Lysa och kund sker på svenska eller engelska.
        </p>
      </section>

      <section>
        <h2>Tillstånd</h2>
        <p>
          Lysa AB, org. nr 559028-0821, bedriver värdepappersrörelse och står
          under Finansinspektionens tillsyn. Lysa har följande tillstånd:
        </p>
        <ul>
          <li>
            Portföljförvaltning (2 kap. 1 § 4p lagen (2007:528) om
            värdepappersmarknaden (<strong>"VPML"</strong>))
          </li>
          <li>
            Förvara finansiella instrument för kunders räkning och ta emot medel
            med redovisningsskyldighet (2 kap. 2 § 1p VPML)
          </li>
          <li>
            Förvaltare av fondandelar (4 kap. 12 § lagen (2004:46) om
            värdepappersfonder)
          </li>
          <li>
            Förmedling av direkt försäkring inom klass Ia) livförsäkring och
            III. Försäkring anknuten till värdepappersfonder (2 kap. 1 och 2 §§
            Lag (2018:1219) om försäkringsdistribution samt 2 kap. 12 §
            försäkringsrörelselagen (2010:2043)
          </li>
        </ul>
        <p>
          För mer information kan du besöka Finansinspektionens hemsida{" "}
          <a href="https://fi.se">fi.se</a>. Vill du komma i kontakt med
          Finansinspektionen når du dem på telefonnummer{" "}
          <a href="tel:+46840898000">+468 408 980 00</a> eller adress
          Finansinspektionen, Box 7821, 103 97 Stockholm.
        </p>
      </section>

      <section>
        <h2>Rapporter från Lysa och information om kundportföljen m.m.</h2>
        <p>
          Varje kund har löpande tillgång till all information om
          förvaltningsuppdraget (så som uppgift om portföljens innehav och
          värde, uppgifter om den totala summan av arvoden och avgifter samt
          information om utförda affärer) i inloggat läge på Lysas hemsida.
          Avräkningsnotor avseende genomförda transaktioner finns att tillgå i
          inloggat läge på Lysas hemsida.
        </p>

        <p>
          För information avseende de finansiella instrument som kan ingå i
          kundportföljen, metod och frekvens för värdering av dessa,
          referensvärden som portföljens utveckling kan jämföras mot och målen
          med förvaltningen samt risknivå hänvisas till våra allmänna villkor
          och vid var tid tillämpliga placeringsriktlinjer för kundens konto hos
          Lysa.
        </p>

        <p>
          För information om pantsättning hänvisas till våra allmänna villkor.
        </p>
      </section>

      <section>
        <h2>Information om kundkategorisering och kundskydd</h2>
        <p>
          Lysa har en skyldighet enligt VPML och EU regelverk att dela in sina
          kunder i kategorier. Inledningen i kategorier baseras på dina
          kunskaper, erfarenheter och övriga förhållanden. Den kundkategori du
          faller inom påverkar bland annat vilken information du får från Lysa
          och därmed din kundskyddsnivå. Icke-professionella kunder har den
          högsta skyddsnivån, medan jämbördiga motparter har den lägsta.
        </p>

        <p>
          Samtliga av Lysas kunder kategoriseras som icke-professionella kunder.
          Nedan beskrivs de olika kundkategorierna.
        </p>

        <h4>Icke-professionella kunder</h4>
        <p>
          Icke-professionella kunder tillhör den högsta skyddsnivån. Det innebär
          bland annat att Lysa har en omfattande informationsskyldighet.
          Därutöver kommer du att erhålla information om finansiella instrument
          och de risker som dessa är förenade med samt information om kostnader
          och avgifter.
        </p>

        <p>
          För att Lysa ska kunna tillhandahålla portföljförvaltning till en
          icke-professionell kund måste Lysa först göra en bedömning av kunden
          (en så kallad lämplighetsbedömning) baserat på kundens ekonomiska
          situation, om kunden kan bära den ekonomiska risken med placeringen,
          riskbenägenhet, placeringshorisont, kundens behov och önskemål samt
          mål med placeringen.
        </p>

        <h4>Professionella kunder</h4>
        <p>
          Professionella kunder har inte lika stort skydd som
          icke-professionella kunder. Till professionella kunder hör t.ex.
          större företag, finansinstitut och institutionella investerare.
          Skyddet till professionella kunder omfattar bl.a.:
        </p>
        <ul>
          <li>
            Information om risker i samband med investering i finansiella
            instrument.
          </li>
          <li>
            Vid förvaltning till professionella kunder måste Lysa först göra en
            lämplighetsbedömning baserat på målet med investeringen och om
            kunden kan bära den finansiella risken med placeringen.
          </li>
        </ul>

        <h4>Jämbördig motpart</h4>
        <p>
          En jämbördig motpart har lägst skydd. Till denna grupp hör till
          exempel värdepappersbolag, försäkringsföretag och kreditinstitut.
        </p>

        <h4>Byte av kundkategori</h4>
        <p>
          En kund kan begära att få byta kundkategori. Sådan begäran ska vara
          skriftlig. Lysa avgör om kunden får byta kundkategori och meddelar
          kunden om bytet godkänns eller inte. Byte av kundkategori innebär
          bland annat att kunden får ett annat skydd, se beskrivningarna ovan.
        </p>
      </section>

      <section>
        <h2>Avgifter och ersättning</h2>
        <p>
          Information om avgifter och ersättningar återfinns på Lysas hemsida.
        </p>
      </section>

      <section>
        <h2>Klagomålshantering</h2>
        <p>
          Lysa eftersträvar att upprätthålla en hög servicenivå. Om du är
          missnöjd med våra tjänster tar vi tacksamt emot dina synpunkter via
          vår kundtjänst. Om du fortfarande är missnöjd efter sådan kontakt, och
          du har ett krav på ekonomisk ersättning eller rättelse, kan du föra
          ärendet vidare till vår klagomålsansvarige.
        </p>
        <p>
          Ditt klagomål ska framställas skriftligen. Ange tidpunkter och
          händelseförlopp så utförligt du kan. Bifoga gärna också de underlag du
          har i form av notor, korrespondens etc. Detta för att säkerställa att
          alla fakta och bakgrunden till klagomålet kommer med i reklamationen.
          Ange även ditt krav på ekonomisk ersättning.
        </p>
        <p>
          Klagomål som kommer till Lysa behandlas omsorgsfullt och skyndsamt,
          och vår målsättning är att alla ärenden ska besvaras inom fjorton
          dagar. Skulle en utredning av ärendet kräva längre handläggningstid
          kommer du få information om detta samt när vi tror att vi kommer kunna
          svara. Om vi inte kan tillmötesgå ditt klagomål har du som kund rätt
          att få ett skriftligt svar med motivering till detta.
        </p>
        <p>
          Klagomålsansvarig är Linn Kihlstrand. Klagomål skickas med e-post till{" "}
          <a href="mailto:klagomal@lysa.se">klagomal@lysa.se</a>.
        </p>
        <p>
          Du har möjlighet att få kostnadsfri rådgivning av Konsumenternas Bank-
          och Finansbyrå, se{" "}
          <a href="https://konsumenternas.se">konsumenternas.se</a>. Du kan även
          vända dig till konsumentvägledaren i din kommun. Om du inte vinner
          framgång med ditt ärende hos funktionen Klagomålsansvarig hos Lysa har
          du möjlighet att föra ärendet vidare till Allmänna Reklamationsnämnden
          (ARN), se <a href="https://arn.se">arn.se</a> för information om
          Allmänna Reklamationsnämnden, eller till allmän domstol.
        </p>
      </section>

      <section>
        <h2>Intressekonflikter</h2>
        <p>
          Ett värdepappersbolag som Lysa ska identifiera de intressekonflikter
          som kan uppkomma i verksamheten, exempelvis mellan Lysa och dess
          kunder eller mellan olika kunder i samband med att Lysa
          tillhandahåller tjänster. Exempel på omständigheter som ska beaktas är
          om Lysa eller dess ägare, utöver överenskomna ersättningar, kan komma
          att erhålla en vinst eller undvika en ekonomisk förlust på kundens
          bekostnad.
        </p>
        <p>
          Lysa har vidare en skyldighet att förhindra att kunders intressen
          påverkas negativt av intressekonflikter. Om vidtagna åtgärder inte
          räcker för att förhindra att kunders intressen kan komma att påverkas
          negativt ska Lysa tydligt informera kunden om arten eller källan till
          intressekonflikten innan det utförs en tjänst för kundens räkning.
        </p>
        <p>
          Styrelsen för Lysa har antagit en instruktion för hantering av
          intressekonflikter. Instruktionen syftar till att förhindra att
          intressekonflikter uppkommer och, om en konflikt ändå uppstår, att
          förhindra en negativ påverkan på kundernas intressen. I instruktionen
          beskrivs potentiella intressekonflikter och vilka åtgärder som vidtas
          för att förhindra sådan negativ påverkan. Instruktionen anger bl. a.
          hur en tillräcklig grad av oberoende ska kunna uppnås för att så långt
          som möjligt undvika att intressekonflikter uppkommer mellan olika
          avdelningar och enheter inom Lysa och hur eventuellt uppkomna
          intressekonflikter ska hanteras.
        </p>
        <p>
          På kunds begäran kan Lysa lämna närmare uppgifter om instruktionen för
          hantering av intressekonflikter.
        </p>
      </section>

      <section>
        <h2>Betald ersättning vid samarbeten</h2>
        <p>
          Lysa betalar ersättning till affiliates och samarbetspartners när en
          kund blir kund via samarbete med tredje part eller genom
          affiliatelänk.
        </p>
      </section>

      <section>
        <h2>Kunders finansiella instrument och medel</h2>
        <p>
          Lysa är skyldigt att hålla dina värdepapper avskilda från sina egna
          tillgångar. Detsamma gäller för de pengar som Lysa tar emot med
          redovisningsskyldighet i samband med förvaltningen. Eftersom dina
          värdepapper och pengar ska vara åtskilda från Lysas tillgångar har du
          rätt att få ut dem om Lysa skulle försättas i konkurs. Lysas externa
          revisor kontrollerar en gång om året att bolagets åtgärder och rutiner
          för att säkerställa nämnda åtskiljning är lämpliga och ändamålsenliga.
        </p>
        <p>
          För fondandelar och fonder gäller att en enskild fonds tillgångar inte
          kan tas i anspråk vid en eventuell konkurs i Lysa eller i fondbolaget.
          En fonds tillgångar ägs gemensamt av fondens andelsägare och förvaras
          hos ett förvaringsinstitut. Om kundens fondandelar är registrerade på
          konto/depå hos Lysa är de förvaltarregistrerade hos fondbolaget.
          Kundens innehav av fondandelar registreras i Lysas andelsägarregister.
          I registret skiljer Lysa kundens innehav från andra kunders innehav.
        </p>

        <h4>Statligt investerarskydd</h4>
        <p>
          För det fall att Lysa trots detta inte kan lämna ut din egendom, till
          exempel därför att det efter konkursen inte går att reda ut vad som är
          dina respektive institutets tillgångar, har du rätt till ersättning
          från investerarskyddet. Investerarskyddet innebär att staten skyddar
          kunders värdepapper vid transaktioner eller när sådana förvaras hos
          bank eller värdepappersbolag.
        </p>
        <p>
          Investerarskyddet regleras i lagen (1999:158) om investerarskydd.
          Ersättningen är begränsad till 250 000 kronor per kund och institut.
          Skyddet gäller inte värdeförändringar på aktier och andra värdepapper.
          Rätten till ersättning gäller även för medel som institutet mottagit
          från kunder med redovisningsskyldighet, dvs. sådana medel som inte får
          sammanblandas med institutets egna tillgångar.
        </p>
        <p>
          Mer information om investerarskyddet finns på Riksgäldens hemsida,{" "}
          <a href="https://www.riksgalden.se">www.riksgalden.se</a>.
        </p>

        <h4>Statlig insättningsgaranti för dina pengar</h4>
        <p>
          De medel som Lysa tar emot tas emot med så kallad
          redovisningsskyldighet. Detta innebär att kundens medel ska hållas
          åtskilda från Lysas tillgångar vilket betyder att kunden har rätt till
          sina medel om Lysa skulle gå i konkurs.
        </p>
        <p>
          Lysa förvarar dessa medel på ett klientmedelskonto, för närvarande hos
          Danske Bank A/S, Danmark, Sverige Filial (<b>"Kreditinstitutet"</b>).
          Dina pengar skyddas enligt den danska insättningsgarantin som kallas
          Garantifonden. Den ger skydd för ett belopp motsvarande 100 000 euro
          per insättare.
        </p>
        <p>
          Ett klientmedelskonto är ett konto där pengar satts in för flera
          kunders räkning på samma konto. För klientmedelskonto gäller enligt
          huvudregeln att de bakomliggande ägarna till pengarna, det vill säga
          varje kund hos Lysa, kan vardera få ersättning från
          insättningsgarantin upp till det maximala beloppet.
        </p>
        <p>
          Om Kreditinstitutet fallerar kommer Garantiformuen i Danmark inom sju
          arbetsdagar att återbetala dina insättningar med upp till det maximala
          beloppet på 100 000 EUR genom att skicka ett betalningsuppdrag till
          det institut som administrerar insättningsgarantifonden, i Sverige
          Riksgäldskontoret, som därefter utför utbetalningen i lokal valuta.
        </p>
        <p>
          Mer information om Garantifonden finns på Garantiformuens hemsida,{" "}
          <a href="https://www.fs.dk/garantiformuen/om-garantiformuen">
            www.garantifonden.dk
          </a>
          .
        </p>
      </section>

      <section>
        <h2>Hållbarhetsinformation</h2>
        <h4>Integrering av hållbarhetsrisker i investeringsbeslutsprocessen</h4>
        <p>
          Som kund kan du välja mellan två olika investeringsinriktningar när du
          investerar med Lysa, “Bred” och “Hållbar”. Eftersom att Lysa inte
          investerar direkt i enskilda bolag handlar Lysas
          investeringsbeslutsprocess om att välja ut vilka fonder som ska
          inkluderas i portföljen för respektive investeringsinriktning. Detta
          görs genom en urvalsprocess där bland annat värdering av olika typer
          av risker, däribland hållbarhetsrisker, utgör en integrerad del. I
          praktiken innebär detta att Lysa, både inför ett val av fond och
          därefter löpande, utvärderar hur fonden och dess fondbolag arbetar med
          att integrera hållbarhetsrisker i sin investeringsbeslutsprocess.
        </p>
        <p>
          När det gäller investeringsbeslut som fattas inom Lysas Breda
          investeringsinriktning bedömer Lysa att hållbarhetsrisker inte är
          relevanta eftersom syftet är att erbjuda maximal riskspridning och om
          hållbarhetsrisker beaktas blir diversifieringen mindre.
        </p>
        <p>
          När det gäller investeringsbeslut som fattas inom Lysas Hållbara
          investeringsinriktning bedöms hållbarhetsriskerna vara relevanta för
          att uppnå syftet med investeringsinriktningen. Utvärderingen av
          hållbarhetsrisker inverkar på de investeringsbeslut som fattas genom
          att Lysa väljer ut fonder och fondbolag som bedöms integrera
          hållbarhetsrisker i sin investeringsbeslutsprocess på ett
          tillfredsställande sätt. Eftersom beaktandet av hållbarhetsrisker
          innebär att den Hållbara investeringsinriktningen blir mindre
          diversifierad bedömer Lysa att det finns risk att den riskjusterade
          avkastningen blir något lägre än om de inte hade beaktats.
        </p>
      </section>

      <section>
        <h4>Beaktande av negativa konsekvenser för hållbar utveckling</h4>
        <p>
          När du som kund väljer Lysas Breda investeringsinriktning får du en
          fondportfölj för vilken Lysa inte beaktar investeringsbesluts negativa
          konsekvenser för hållbar utveckling. Anledningen till detta är att
          syftet med fondportföljen är att erbjuda maximal riskspridning och
          skulle negativa konsekvenser för hållbar utveckling beaktas blir
          diversifieringen bli mindre
        </p>
      </section>

      <section>
        <h4>Främjandet av miljörelaterade och sociala egenskaper</h4>
        <p>
          När du som kund väljer Lysas Hållbara investeringsinriktning får du en
          fondportfölj som främjar miljörelaterade och sociala egenskaper genom
          att underliggande investeringar på olika sätt främjar dessa
          egenskaper. I praktiken görs det genom att de fonder som ingår i Lysas
          Hållbara investeringsinriktning, matarfonderna Lysa Sverige Aktier,
          Lysa Global Aktier Hållbar och Lysa Räntor Hållbar, investerar i
          mottagarfonderna Öhman Sverige Marknad Hållbar, Öhman Global Marknad
          Hållbar och Öhman Räntefond Kompass Hållbar (“
          <strong>Öhmans Fonder</strong>”).{" "}
        </p>
        <p>
          Öhmans Fonder investerar endast i bolag som följer praxis för god
          bolagsstyrning och både miljörelaterade och sociala egenskaper främjas
          i förvaltningen genom att Öhmans Fonder bland annat väljer bort bolag
          som är involverade i vissa typer av produkter och tjänster (t.ex.
          vapen, droger, kommersiell spelverksamhet och fossila bränslen) samt
          bolag som kränker internationella normer som rör frågor om miljö,
          mänskliga rättigheter, arbetsvillkor och affärsetik. Vidare väljer
          Öhmans Fonder in bolag som dess fondbolag, Öhman Fonder AB, utifrån en
          hållbarhetsanalys identifierar som en långsiktigt hållbar investering.
        </p>
        <p>
          För mer information om Öhmans Fonder och Öhman Fonder AB:s
          hållbarhetsarbete, se{" "}
          <a href="https://www.ohman.se/fonder/fondlista/">
            https://www.ohman.se/fonder/fondlista/
          </a>{" "}
          och{" "}
          <a href="https://www.ohman.se/hallbarhet/">
            https://www.ohman.se/hallbarhet/
          </a>
          .
        </p>
      </section>

      <section>
        <h2>Distansavtal och ångerrätt</h2>
        <p>
          Lysa ingår distansavtal med privatpersoner rörande
          investeringssparkonton och portföljförvaltning. Enligt nationell
          lagstiftning baserad på Europaparlamentets och rådets direktiv
          2011/83/EU av den 25 oktober 2011 om konsumenträttigheter, i Sverige
          lag (2005:59) om distansavtal och avtal utanför affärslokaler
          (Distansavtalslagen), åligger det Lysa att, i rimlig tid innan ett
          avtal om finansiella tjänster ingås, lämna viss information till
          konsumenten. I Information om Lysa ovan finns bolags- och
          verksamhetsinformation och nedan finns allmän information om
          ångerrätt. I övrigt hänvisas till de allmänna villkoren för våra
          tjänster.
        </p>

        <h4>Allmän information om ångerrätt</h4>
        <p>
          Kontohavaren har rätt att frånträda ingånget avtal (ångerrätt) med
          Lysa genom att sända ett meddelande till Lysa inom 14 dagar från den
          dagen avtalet ingicks. Ångerrätten gäller endast för det inledande
          produktavtalet och inte för sådana tjänster eller transaktioner som
          redan utförts. Detta innebär att du inte kan utnyttja ångerrätten om
          du har satt in pengar på ditt konto och Lysa har investerat dessa åt
          dig.
        </p>

        <p>
          Givetvis har du även efter att ångerrätten gått ut rätt att säga upp
          dina avtal, i enlighet med de allmänna villkoren.
        </p>
      </section>
    </article>
  );
}

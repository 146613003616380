import React, { ComponentType } from "react";
import {
  Language,
  DocEntityProps,
  DocAccountProps,
  DocCurrencyProps,
} from "./lib/Language";
import { InformationAboutLysa } from "./lib/Other/InformationAboutLysa/InformationAboutLysa";
import { CustomerAgreement } from "./lib/Customer/CustomerAgreement/CustomerAgreement";
import { DepositorInformation } from "./lib/Customer/DepositorInformation/DepositorInformation";
import { PrivacyPolicy } from "./lib/Other/PrivacyPolicy/PrivacyPolicy";
import { EligibilityAssessment } from "./lib/Other/EligibilityAssessment/EligibilityAssessment";
import { ModelAssumptions } from "./lib/Other/ModelAssumptions/ModelAssumptions";
import { TermsRetrieveExternalAccountAndTransfer } from "./lib/Other/TermsRetrieveExternalAccountAndTransfer/TermsRetrieveExternalAccountAndTransfer";
import { BjornLunden } from "./lib/Proxy/BjornLunden/BjornLunden";
import { AutogiroTerms } from "./lib/Other/AutogiroTerms/AutogiroTerms";
import { InstructionOfBestExecution } from "./lib/Other/InstructionOfBestExecution/InstructionOfBestExecution";
import { CustodyAccountAgreement } from "./lib/Account/CustodyAccountAgreement/CustodyAccountAgreement";
import { InvestmentGuidelines } from "./lib/Account/InvestmentGuidelines/InvestmentGuidelines";
import { AccountTerms } from "./lib/Other/AccountTerms/AccountTerms";
import { GenericTermsISK } from "./lib/Other/GenericTermsISK/GenericTermsISK";
import { InformationAboutAttributesAndRisks } from "./lib/Other/InformationAboutAttributesAndRisks/InformationAboutAttributesAndRisks";
import { AgreementAccount } from "./lib/Account/AgreementAccount/AgreementAccount";
import { PreInfoISK } from "./lib/Other/PreInfoISK/PreInfoISK";
import { AgreementKF } from "./lib/Proxy/Futur/AgreementKF";
import { GenericTermsKF } from "./lib/Proxy/Futur/GenericTermsKF";
import { LysaAsIntermediary } from "./lib/Proxy/Futur/LysaAsIntermediary";

import "./App.css";
import { MarkdownPage } from "./MarkdownPage";

interface ComponentMap {
  [key: string]: ComponentType<any>;
}

const mapCustomer: ComponentMap = {
  CustomerAgreement,
  DepositorInformation,
};

const mapAccount: ComponentMap = {
  AgreementAccount,
  CustodyAccountAgreement,
  InvestmentGuidelines,
  AccountTerms,
};

const mapOther: ComponentMap = {
  GenericTermsISK,
  InformationAboutLysa,
  PrivacyPolicy,
  EligibilityAssessment,
  ModelAssumptions,
  TermsRetrieveExternalAccountAndTransfer,
  AutogiroTerms,
  InstructionOfBestExecution,
  InformationAboutAttributesAndRisks,
  PreInfoISK,
};

const mapProxy: ComponentMap = {
  BjornLunden,
  AgreementKF,
  GenericTermsKF,
  LysaAsIntermediary,
};

const mapAll: ComponentMap = {
  ...mapCustomer,
  ...mapAccount,
  ...mapOther,
  ...mapProxy,
};

const supportedLanguages = [Language.SV, Language.EN];

interface State {
  doc: string;
  language: Language;
  error: boolean;
}

class App extends React.Component<{}, State> {
  state = {
    doc: "AgreementKF",
    language: Language.SV,
    error: false,
  };

  reset = () => {
    this.setState({
      doc: "AgreementKF",
      language: Language.SV,
      error: false,
    });
  };

  setLanguage = (language: Language) => {
    this.setState({
      language,
      error: false,
    });
  };

  setDoc = (doc: string) => {
    this.setState({
      doc,
      error: false,
    });
  };

  componentDidCatch() {
    this.setState({
      error: true,
    });
  }

  getLink = (name: string, doc: string) => (
    <li key={name}>
      <button
        className={name === doc ? "active" : ""}
        onClick={() => this.setDoc(name)}
      >
        {name}
      </button>
    </li>
  );

  render() {
    if (!window.location.pathname.includes('/old')) {
      return <MarkdownPage />
    }

    const { error, doc, language } = this.state;
    const Tag = mapAll[doc];
    let props:
      | Omit<DocAccountProps, "language">
      | Omit<DocEntityProps, "language">
      | Omit<DocCurrencyProps, "language">
      | null = null;

    if (Tag === CustomerAgreement) {
      props = {
        name: Math.random() > 0.5 ? "Maximilien de Robespierre" : undefined,
        date: Math.random() > 0.5 ? "2020-02-17" : undefined,
        id: Math.random() > 0.5 ? "17580506-1111" : undefined,
      };
    } else if (Tag === BjornLunden) {
      props = {
        name: Math.random() > 0.5 ? "Georges Jacques Danton" : undefined,
        date: Math.random() > 0.5 ? "2020-02-20" : undefined,
        id: Math.random() > 0.5 ? "17591026-3333" : undefined,
      };
    } else if (Tag === AgreementAccount) {
      props = {
        account: {
          accountSigning: "2020-03-25",
          accountName: "Lysakonto",
          stocks: 66,
          bonds: 34,
          investmentType: "SUSTAINABLE",
        },
      };
    } else if (Tag === CustodyAccountAgreement) {
      props = {
        account: {
          accountSigning: "2020-03-25",
          name: "Gyllene Skor AB",
          companyId: "HERREYS-1984",
          accountName: "Lysakonto",
          accountId:
            Math.random() > 0.5
              ? "1dcdc523-f2fd-4b4d-836f-6772fbea572a"
              : undefined,
          stocks: 50,
          bonds: 50,
          investmentType: "BROAD",
        },
      };
    } else if (Tag === InvestmentGuidelines) {
      props = {
        account: {
          accountSigning: "2020-03-26",
          name: "Kevin Mitnick LLC",
          companyId: "1337-H4X0R",
          accountName: "Lysakonto",
          accountId:
            Math.random() > 0.5
              ? "1dcdc523-f2fd-4b4d-636f-6772fbea572a"
              : undefined,
          stocks: 50,
          bonds: 50,
          investmentType: "BROAD",
        },
      };
    } else if (Tag === AgreementKF) {
      props = {
        account: {
          accountSigning: "2020-03-26",
          name: "Kevin Mitnick LLC",
          companyId: "1337-H4X0R",
          accountName: "Lysakonto",
          accountId:
            Math.random() > 0.5
              ? "1dcdc523-f2fd-4b4d-636f-6772fbea572a"
              : undefined,
          stocks: 50,
          bonds: 50,
          investmentType: "BROAD",
        },
      };
    } else if (Tag === DepositorInformation) {
      props = {
        currency:
          language === Language.SV
            ? "svenska kronor (SEK)"
            : Math.random() > 0.5
            ? "Euro (EUR)"
            : "Danish krone (DKK)",
      };
    }

    const buttonElems = supportedLanguages.map((lang) => (
      <li key={lang}>
        <button
          className={language === lang ? "active" : ""}
          onClick={() => this.setLanguage(lang)}
        >
          {lang}
        </button>
      </li>
    ));

    const docsCustomer = Object.keys(mapCustomer).map((name) =>
      this.getLink(name, doc)
    );

    const docsAccount = Object.keys(mapAccount).map((name) =>
      this.getLink(name, doc)
    );

    const docsOther = Object.keys(mapOther).map((name) =>
      this.getLink(name, doc)
    );

    const docsProxies = Object.keys(mapProxy).map((name) =>
      this.getLink(name, doc)
    );

    return (
      <div className="app old-lysa-docs">
        <nav>
          <ul>{buttonElems}</ul>
        </nav>

        <main>
          <aside>
            <h4>Customer</h4>
            <ul>{docsCustomer}</ul>

            <h4>Account</h4>
            <ul>{docsAccount}</ul>

            <h4>Other</h4>
            <ul>{docsOther}</ul>

            <h4>Proxies</h4>
            <ul>{docsProxies}</ul>
          </aside>

          <div>
            {error ? (
              <div className="error">
                Huugh! Something went wrong. Probably missing translation for
                <br />
                doc: <b>{doc}</b>
                <br />
                language: <b>{language}</b>
                <br />
                <button onClick={this.reset}>Reset</button>
              </div>
            ) : (
              <Tag language={language} {...props} />
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default App;

import React from "react";

export function InstructionOfBestExecutionSV() {
  return (
    <article className="lysa-doc instruction-of-best-execution">
      <header>
        <h1>
          Instruktion för bästa orderutförande och sammanläggning av order
        </h1>
        <p className="ingress">
          Instruktion för bästa orderutförande och sammanläggning av order är
          fastställd av styrelsen i Lysa AB ("Lysa") 2020-06-12 och ersätter
          tidigare gällande instruktion.
        </p>
        <p>Ansvarig för dokumentet: Verkställande direktör</p>
      </header>

      <section>
        <h2>1. Bakgrund</h2>
        <p>
          Enligt 9 kap. 33 § lagen (2007:528) om värdepappersmarknaden framgår
          att ett värdepappersbolag ska fastställa riktlinjer för bästa
          orderutförande (best execution). Enligt artikel 66 i Kommissionens
          delegerade förordning (EU) 2017/565 ska Lysa vidare göra en årlig
          översyn av riktlinjerna. Mot denna bakgrund har Lysa fastställt
          följande instruktioner.
        </p>
        <p>
          Denna instruktion innehåller information om hur Lysa ska säkerställa
          att kundernas bästa iakttas vid orderläggning. Order läggs mot
          fondbolaget Lysa Fonder AB vid handel i värdepappersfonder
          distribuerade av Lysa ("Lysas fonder").
        </p>
      </section>

      <section>
        <h2>2. Best execution och sammanläggning av order i Lysas fonder</h2>
        <p>
          Lysas fonder är värdepappersfonder som handlas utanför marknaden genom
          att ordern skickas till Lysa Fonder AB för utförande enligt
          fondbestämmelserna. Lysa ska utföra en kunds order snabbt, effektivt
          och rättvist.
        </p>
        <p>
          Lysa kommer att lägga samman en kunds order avseende fondandelar i
          Lysas fonder med andra kunders order. En sammanlagd order som utförts i
          sin helhet ska fördelas med hänsyn till det NAV som gäller för den
          aktuella affärsdagen. I det fall en sammanlagd eller kvittad order
          endast kan utföras till viss del, kommer den utförda delen att
          fördelas proportionellt mellan kunderna på basis av det NAV som gäller
          för den aktuella affärsdagen.
        </p>
      </section>

      <section>
        <h2>3. Störningar</h2>
        <p>
          I händelse av störningar i marknaden, otillräcklig tillgång till Lysas
          egna tekniska system eller andra force majeure-händelser utanför Lysas
          kontroll, kan det enligt Lysas bedömning vara omöjligt eller olämpligt
          att utföra en order enligt någon av de metoder som angivits i detta
          avsnitt. I sådant fall ska Lysa vidta alla skäliga åtgärder för att på
          annat sätt uppnå bästa möjliga resultat för kunden.
        </p>
      </section>

      <section>
        <h2>4. Efterlevnad och uppdatering</h2>
        <p>
          Verkställande direktör är ansvarig för detta dokuments efterlevnad och
          implementering. Lysa ska årligen se över sina instruktioner som
          antagits för bästa orderutförande och fördelning av order. En sådan
          översyn ska också utföras när en väsentlig förändring inträffar som
          påverkar Lysas möjligheter att uppnå bästa möjliga resultat vid
          utförande av kunders order. Uppdatering av instruktionen ska ske
          årligen eller oftare om nödvändigt.
        </p>
      </section>
    </article>
  );
}

import React from "react";

export const GenericTermsKFSV = () => (
  <article className="lysa-doc generic-terms-kf">
    <header>
      <h1>Allmänna bestämmelser för kapitalförsäkringsdepå</h1>
      <p>v. 2020:2</p>
    </header>

    <section>
      <h2>Inledning</h2>
      <p>
        Mellan innehavaren av kapitalförsäkringsdepån, nedan Kunden, och Lysa
        AB, org. nr 559028-0821 (<b>"Lysa"</b>) har träffats avtal om
        kapitalförsäkringsdepå (<b>"Avtalet"</b>) av vilket dessa Allmänna
        bestämmelser för kapitalförsäkringsdepå (<b>"bestämmelserna"</b>) utgör
        del.
      </p>
      <p>
        Lysa och Kunden har även ingått Avtal om portföljförvaltning. Uttag från
        Kundens kapitalförsäkringsdepå enligt punkt 9.2 i
        portföljförvaltningsavtalet ska godkännas av Kundens firmatecknare.
      </p>
      <p>
        Syftet med depån är att tillhandahålla portföljförvaltning. Lysa
        erbjuder inte möjlighet till köp och försäljning av finansiella
        instrument.
      </p>
    </section>

    <section>
      <h2>Definitioner</h2>
      <p>I Avtalet, inklusive i dessa bestämmelser, förstås med:</p>
      <ol>
        <li>
          <b>värdepapper:</b> dels finansiellt instrument såsom definierat i
          lagen (2007:528) om värdepappersmarknaden, dvs. 1) överlåtbara
          värdepapper som kan bli föremål för handel på kapitalmarknaden, 2)
          penningmarknadsinstrument, 3) fondandelar och 4) finansiella
          derivatinstrument, dels värdehandling, varmed avses handling som inte
          kan bli föremål för handel på kapitalmarknaden, dvs. 1) aktie eller
          enkelt skuldebrev som enligt definitionen ovan inte är finansiellt
          instrument 2) borgensförbindelse, 3) gåvobrev, 4) pantbrev eller
          liknande handling.
        </li>
        <li>
          <b>avräkningsnota:</b> besked om att en order/ett affärsuppdrag har
          utförts.
        </li>
        <li>
          <b>reglerad marknad:</b> såsom definierat i lagen (2007:528) om
          värdepappersmarknaden, dvs. ett multilateralt system inom EES som
          sammanför eller möjliggör sammanförande av ett flertal köp- och
          säljintressen i finansiella instrument från tredje man – regelmässigt,
          inom systemet och i enlighet med icke skönsmässiga regler – så att
          detta leder till avslut.
        </li>
        <li>
          <b>handelsplats:</b> en reglerad marknad eller en handelsplattform
          (MTF).
        </li>
        <li>
          <b>utförandeplats:</b> en handelsplats, en systematisk internhandlare
          eller en marknadsgarant inom EES eller en annan person som
          tillhandahåller likviditet inom EES.
        </li>
        <li>
          <b>förvaring av värdepapper:</b> såväl förvaring av fysiska
          värdepapper som sådant förvar av dematerialiserade värdepapper som
          uppkommer genom registrering i depå.
        </li>
        <li>
          <b>depåförande tredjepart:</b> värdepappersinstitut som på uppdrag av
          Lysa eller annan depåförande tredjepart förvarar värdepapper i depå
          för kunders räkning.
        </li>
        <li>
          <b>värdepappersinstitut:</b> såsom definierat i lagen (2007:528) om
          värdepappersmarknaden, dvs. värdepappersbolag, svenska kreditinstitut
          med tillstånd att driva värdepappersrörelse och utländska företag som
          driver värdepappersrörelse från filial, eller genom att använda
          anknutna ombud etablerade i Sverige, samt utländska företag med
          tillstånd att bedriva verksamhet som motsvarar värdepappersrörelse.
        </li>
        <li>
          <b>värdepapperscentral:</b> såsom definierat i lagen (1998:1479) om
          kontoföring av finansiella instrument, dvs. detsamma som i artikel
          2.1.1 i förordningen om värdepapperscentraler, i den ursprungliga
          lydelsen.
        </li>
        <li>
          <b>bankdag:</b> dag i Sverige som inte är söndag eller allmän helgdag
          eller som vid betalning av skuldebrev är likställd med allmän helgdag
          (sådana likställda dagar är f n lördag, midsommarafton, julafton samt
          nyårsafton).
        </li>
        <li>
          <b>Lysas fonder:</b> värdepappersfonder distribuerade och förvaltade
          av bolag inom Lysa-koncernen
        </li>
        <li>
          <b>Försäkringsavtal:</b> av Kunden, genom egen försorg eller Lysas,
          tecknat försäkringsavtal till vilket Kundens kapitalförsäkringsdepå är
          kopplad.
        </li>
      </ol>
    </section>

    <section>
      <h2>A. Förteckning och förvaring i depå m.m.</h2>
      <p>
        <b>A.1 Förteckning hos Lysa</b>
      </p>
      <p>
        Lysa ska i depån registrera sådant värdepapper som mottagits av Lysa för
        förvaring m.m. i depå. Lysa förbehåller sig rätten att från tid till
        annan bestämma vilka värdepapper som kan förtecknas och tas emot av Lysa
        på Kundens depå. Mottagna värdepapper förvaras av Lysa för Kundens
        räkning.
      </p>
      <p>
        Finansiellt instrument i kontobaserat system hos värdepapperscentral
        eller motsvarande ska anses ha mottagits när Lysa erhållit rätt att
        registrera eller låta registrera uppgifter avseende instrument i sådant
        system. Övriga värdepapper anses ha mottagits när de överlämnats till
        Lysa.
      </p>
      <p>
        Lysa förbehåller sig rätten att pröva mottagandet av visst värdepapper.
        Om Kunden har flera depåer/konton hos Lysa och Kunden inte instruerat
        Lysa om i vilken depå visst värdepapper ska registreras, får Lysa självt
        bestämma i vilken depå registreringen ska ske.
      </p>
      <p>
        Lysa tar inte emot fysiska värdepapper. Lysa utför ingen äkthetskontroll
        av Kundens värdepapper.
      </p>
      <p>
        Lysa förbehåller sig skälig tid för registrering, överföring och
        utlämnande av värdepapper.
      </p>
      <p>
        Lysa får avregistrera värdepapper från Kundens depå när den som gett ut
        värdepappret försatts i konkurs eller värdepappret av annat skäl
        förlorat sitt värde. Om det är praktiskt möjligt och lämpligt ska Lysa,
        bl.a. med beaktande av Kundens intresse, i sådant fall försöka
        registrera värdepappret i Kundens namn.
      </p>

      <p>
        <b>A.2 Depåförvaring hos tredjepart</b>
      </p>
      <p>
        Lysa får förvara sådana värdepapper som förvaras för Kundens räkning hos
        annat värdepappersinstitut, i sverige eller utlandet. Depåförande
        tredjepart kan i sin tur anlita annan depåförande tredjepart för
        förvaring av Kundens värdepapper.
      </p>
      <p>
        Depåförande tredjepart utses av Lysa efter eget val, med iakttagande av
        de skyldigheter som åligger Lysa enligt EU-förordning, lag och
        föreskrifter. Vid förvar hos depåförande tredjepart i utlandet (inom
        eller utanför EES) omfattas Kundens värdepapper av tillämplig nationell
        lag, vilket kan innebära att Kundens rättigheter avseende dessa
        värdepapper kan variera jämfört med vad som skulle gälla vid en
        förvaring i Sverige.
      </p>
      <p>
        Förvaring hos depåförande tredjepart sker normalt i Lysas namn för
        kunders räkning. I sådant fall får Kundens värdepapper registreras
        tillsammans med andra ägares värdepapper, exempelvis på samlingskonto.
        Lysa får även uppdra åt depåförande tredjepart att i Lysas ställe låta
        sig registreras för Kundens värdepapper.
      </p>
      <p>
        I särskilda fall får Lysa även låta Kundens värdepapper ingå i ett för
        flera ägare gemensamt dokument.
      </p>
      <p>
        Vid förvaring av Kundens värdepapper på samlingskonto hos depåförande
        tredjepart följer Kundens rättigheter av tillämplig nationell
        lagstiftning.
      </p>
      <p>
        Huruvida de värdepapper som ingår i Kundens Försäkringsavtal är
        sakrättsligt skyddade i händelse av att Lysa eller depåförande
        tredjepart skulle försättas i konkurs eller drabbas av annan åtgärd med
        motsvarande rättsverkningar kan variera och är beroende av tillämplig
        nationell lag. I Sverige föreligger sakrättsligt skyddad separationsrätt
        under förutsättning att värdepappren hålls avskilda från depåförande
        tredjepartens eller Lysas egna värdepapper.
      </p>
      <p>
        Vid förvaring hos depåförande tredjepart i utlandet kan det också, till
        följd av tillämplig utländsk lag, vara omöjligt att identifiera de
        värdeppper som förvars för kundens räkning separat från den tredje
        partens eller Lysas egna värdepapper. I sådant fall finns en risk att de
        värdepapper som ingår i Kundens Försäkringsavtal vid en konkurssituation
        eller annan åtgärd med motsvarande rättsverkningar skulle kunna komma
        att anses ingå i den tredje partens eller Lysas tillgångar.
      </p>
      <p>
        Depåförande tredjepart eller värdepapperscentral kan ha säkerhet i eller
        kvittningsrätt avseende Kundens värdepapper och till dem kopplade
        fordringar.
      </p>

      <p>
        <b>A.3 Förvaltarregistrering m.m.</b>
      </p>

      <p>
        Lysa har rätt att vidta samtliga åtgärder för att i Lysas namn
        förvaltarregistrera Kundens innehav i depån samt samregistrera innehavet
        med övriga innehav i Lysas namn för respektive värdepapper.
      </p>
      <p>
        Lysa har vidare rätt att såsom förvaltare vidta samtliga åtgärder för
        att för Kundens räkning i Lysas namn låta registrera mottagna
        värdepapper i eget namn hos den som i Sverige eller i utlandet fullgör
        registreringsuppgift avseende finansiella instrument, t.ex. Euroclear
        Sweden AB. Därvid får Kundens finansiella instrument registreras
        tillsammans med andra ägares finansiella instrument av samma slag. Lysa
        får även låta Kundens finansiella instrument ingå i ett för flera ägare
        gemensamt dokument. Lysa får uppdra åt depåförande tredjepart att i
        Lysas namn låta sig registreras för Kundens finansiella instrument.
      </p>
    </section>

    <section>
      <h2>B. Lysas åtaganden avseende värdepapper</h2>
      <p>
        <b>B.1 Allmänt</b>
      </p>
      <p>
        Lysa åtar sig att för Kundens räkning vidta under punkterna B.2–B.4
        angivna åtgärder beträffande mottagna värdepapper.
      </p>
      <p>
        Åtagandena inträder - om inte annat anges nedan eller särskilt
        överenskommits - för svenska finansiella instrument registrerade hos
        värdepapperscentral fr.o.m. den femte svenska bankdagen, och för övriga
        svenska (dvs. utgivna av emittenter med säte i Sverige) värdepapper samt
        för utländska finansiella instrument fr.o.m. den femtonde svenska
        bankdagen efter det att värdepapperen mottagits av Lysa. Lysa är således
        inte skyldigt att bevaka frister som löper ut dessförinnan.
      </p>
      <p>
        Lysa vidtar i punkterna B.2–B.4 angivna åtgärder under förutsättning att
        Lysa i god tid fått fullgod information om den omständighet som
        föranleder åtgärden genom meddelande från Kunden, depåförande
        tredjepart, emittent, agent (motsvarande) eller värdepapperscentral.
      </p>
      <p>
        Lysa får helt eller delvis underlåta att vidta en åtgärd, om det på
        depån inte finns medel eller kreditutrymme för åtgärden eller om Lysa
        inte får de uppgifter som krävs för åtgärden eller för att uppfylla krav
        enligt EU-förordning, lag och föreskrifter.
      </p>
      <p>
        Lysa får vidta eller underlåta att vidta åtgärd som anges i punkterna
        B.2 och B.3 om Lysa särskilt angivit detta i meddelande till Kunden och
        denne inte lämnat instruktion om annat inom den svarstid som angetts i
        meddelandet. Kunden är därefter bunden av åtgärd som Lysa vidtagit eller
        underlåtit att vidta på samma sätt som om Kunden själv gett uppdrag om
        åtgärden.
      </p>
      <p>
        <b>B.2 Svenska finansiella instrument</b>
      </p>
      <p>
        Med <i>svenska finansiella instrument</i> avses i denna punkt B.2
        finansiella instrument som är utgivna av emittenter med säte i Sverige
        och som är registrerade hos värdepapperscentral som har tillstånd att
        bedriva verksamhet i Sverige samt är upptagna till handel i Sverige
        eller handlas på svensk handelsplats. Beträffande andra finansiella
        instrument gäller i stället vad som anges under punkt B.3 beträffande
        utländska finansiella instrument.
      </p>
      <p>
        Beträffande <i>aktier</i> åtar sig Lysa att
      </p>
      <ol>
        <li>
          ta emot utdelning. Om Kunden har rätt att välja mellan utdelning i
          kontanter eller i annan form, får Lysa välja utdelning i form av
          kontanter om Kunden inte lämnat annan instruktion,
        </li>
        <li>
          vid nyemission avseende aktier i vilka Kunden har företrädesrätt,
          underrätta Kunden härom samt biträda Kunden med önskade åtgärder i
          samband därmed. Om instruktion om annat inte lämnats senast tre
          bankdagar före sista dagen för handel med teckningsrätten ska Lysa –
          om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
          beaktande av Kundens intresse – sälja teckningsrätt som inte
          utnyttjats,
        </li>
        <li>
          vid offentligt erbjudande om överlåtelse av finansiellt instrument
          riktat till Kunden från emittenten (inlösen/återköp) eller annan
          (uppköp) underrätta Kunden om och efter särskilt uppdrag från Kunden
          biträda denne med önskade åtgärder. Motsvarande gäller vid offentligt
          erbjudande om förvärv av finansiellt instrument riktat till Kunden,
        </li>
        <li>
          vid fondemission avseende aktier dels om möjligt göra sådana tillköp
          av fondaktierätter som behövs för att alla fondaktierätter som
          tillkommer Kunden på grund av i depån registrerade aktier ska kunna
          till fullo utnyttjas i fondemissionen, dels i depån förteckna så många
          nya aktier som Kunden därefter är berättigad till,
        </li>
        <li>
          såvitt avser aktier i avstämningsbolag underrätta Kunden om begärd
          tvångsinlösen,
        </li>
        <li>
          såvitt avser aktier i avstämningsbolag vid nedsättning av
          aktiekapitalet, inlösen eller likvidation motta respektive lyfta
          kapitalbelopp samt annat förfallet belopp, samt
        </li>
        <li>
          på uppdrag av Kunden ombesörja att hos värdepapperscentral
          förvaltarregistrerade aktiers rösträtt registreras i Kundens namn.
          Detta förutsätter dock att uppdraget är Lysa tillhanda senast femte
          bankdagen före sista dag för införande i aktieboken för rätt att delta
          i bolagsstämma och att sådan rösträttsregistrering kan ske enligt
          gängse rutin hos värdepapperscentral.
        </li>
      </ol>
      <p>
        Om en depå innehas av två eller flera gemensamt och det i uppdraget inte
        angetts i vems namn aktierna ska registreras sker rösträttsregistrering
        av aktierna i proportion till vars och ens andel. Rösträttsregistrering
        sker inte av överskjutande aktier.
      </p>
      <p>
        Beträffande <i>teckningsoptioner</i> åtar sig Lysa att i god tid
        underrätta Kunden om sista dag för aktieteckning och, efter särskilt
        uppdrag från Kunden, söka verkställa kompletteringsköp av
        teckningsoptioner och verkställa nyteckning av aktier. Om
        överenskommelse om annat inte träffats senast tre bankdagar före sista
        dag för handel med teckningsoptioner ska Lysa - om Lysa bedömer det som
        praktiskt möjligt och lämpligt, bl.a. med beaktande av Kundens intresse
        - sälja teckningsoption som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>inköpsrätter</i> åtar sig Lysa att i god tid underrätta
        Kunden om sista dag för köpanmälan och, efter särskilt uppdrag av
        Kunden, söka verkställa kompletteringsköp av inköpsrätt och ombesörja
        köpanmälan. Om överenskommelse om annat inte träffats senast tre
        bankdagar före sista dag för handel med inköpsrätten, ska Lysa - om Lysa
        bedömer det som praktiskt möjligt och lämpligt, bl.a. med beaktande av
        Kundens intresse - sälja inköpsrätt som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>inlösenrätter</i> åtar sig Lysa att i god tid underrätta
        Kunden om sista dag för inlösenanmälan och, efter särskilt uppdrag av
        Kunden, söka verkställa kompletteringsköp av inlösenrätt och ombesörja
        inlösenanmälan. Om överenskommelse om annat inte träffats senast tre
        bankdagar före sista dag för handel med inlösenrätten, ska Lysa - om
        Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med beaktande
        av Kundens intresse - sälja inlösenrätt som inte utnyttjats.
      </p>
      <p>
        Beträffande <i>svenska depåbevis</i> avseende utländska aktier åtar sig
        Lysa att vidta motsvarande åtgärder som för svenska aktier enligt ovan
        om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
        beaktande av Kundens intresse.
      </p>
      <p>
        Beträffande <i>skuldebrev och andra skuldförbindelser</i> som kan bli
        föremål för handel på kapitalmarknaden åtar sig Lysa att
      </p>
      <ol>
        <li>
          motta respektive lyfta ränta samt kapitalbelopp eller annat belopp som
          vid inlösen, utlottning eller uppsägning förfallit till betalning
          efter det att skuldförbindelsen mottagits,
        </li>
        <li>
          beträffande premieobligationer dessutom lyfta vinst på
          premieobligation som enligt dragningslista utfallit vid dragning som
          skett efter det att premieobligation mottagits av Lysa samt underrätta
          Kunden om utbyte och biträda Kunden med önskade åtgärder med anledning
          därav,
        </li>
        <li>
          beträffande konvertibler och andra konverteringsbara skuldförbindelser
          dessutom i god tid underrätta Kunden om sista konverteringsdag samt
          efter särskilt uppdrag av Kunden verkställa konvertering,
        </li>
        <li>
          vid emission av skuldebrev/ skuldförbindelser i vilka Kunden har
          företrädesrätt, för Kundens räkning teckna sådana
          skuldebrev/skuldförbindelser om inte annat överenskommits. Därvid ska
          gälla vad som angivits ovan i punkt B.2.2 b,
        </li>
        <li>
          vid offentligt erbjudande om överlåtelse av finansiellt instrument
          riktat till Kunden från emittenten eller annan och som Lysa erhållit
          information om, på sätt som angetts ovan i punkt B.1.3, underrätta
          Kunden därom och efter särskilt uppdrag av Kunden biträda denne med
          önskade åtgärder i samband därmed. Motsvarande gäller vid offentligt
          erbjudande om förvärv av finansiellt instrument riktat till Kunden,
          samt
        </li>
        <li>
          vid meddelande om förtida inlösen och kallelse till fordringshavarmöte
          eller liknande förfarande avseende skuldebrev/skuldförbindelser i
          vilka Kunden är innehavare och som Lysa erhållit information om, på
          sätt som angivits ovan i punkt B.1.3, underrätta Kunden därom och
          efter särskild överenskommelse med Kunden biträda denne i samband
          därmed.
        </li>
      </ol>
      <p>
        Beträffande finansiella instrument, som inte omfattas av punkterna ovan,
        såsom optioner, terminer och fondandelar, omfattar Lysas åtagande att
        beträffande fondandelar lyfta utdelning samt i övrigt att vidta de
        åtgärder som Lysa bedömer som praktiskt möjligt och lämpligt bland annat
        med beaktande av Kundens intresse eller som Lysa åtagit sig i särskilt
        avtal med Kunden.
      </p>
      <p>
        <b>B.3 Utländska finansiella instrument</b>
      </p>
      <p>
        Beträffande aktier och skuldförbindelser som inte omfattas av B.2 och
        som är upptagna till handel på en reglerad marknad eller på motsvarande
        marknad utanför EES eller på en MTF, omfattar Lysas åtagande att vidta
        samma åtgärder som för svenska finansiella instrument, när Lysa bedömer
        detta som praktiskt möjligt och lämpligt, bl.a. med beaktande av Kundens
        intresse.
      </p>
      <p>
        Kunden uppmärksammas särskilt på att det för vissa utländska aktier
        registrerade hos värdepapperscentral eller utländsk motsvarighet kan
        råda, på grund av inskränkningar i dess åtaganden, begränsade
        möjligheter för aktieägare att utöva vissa rättigheter, t.ex. att
        medverka vid bolagsstämma och att delta i emissioner samt att erhålla
        information därom.
      </p>
      <p>
        Beträffande andra utländska finansiella instrument än de i föregående
        punkter angivna omfattar Lysas åtagande endast att vidta de åtgärder
        Lysa åtagit sig i särskilt avtal med Kunden.
      </p>
      <p>
        Kunden är införstådd med att Kundens rättigheter avseende utländska
        finansiella instrument kan variera beroende på tillämplig utländsk lag
        eller regelverk. Kunden är även införstådd med att Lysa då åtgärd avser
        utländskt finansiellt instrument ofta kan komma att tillämpa andra
        tidsfrister gentemot Kunden än dem som tillämpas i det land där åtgärd
        ska verkställas.
      </p>
      <p>
        <b>B.4 Svenska och utländska värdehandlingar</b>
      </p>
      <p>
        Beträffande svenska och utländska värdehandlingar omfattar Lysas
        åtagande att göra vad Lysa åtagit sig i särskilt avtal med Kunden.
      </p>
    </section>
    <section>
      <h2>C. Klientmedel</h2>
      <p>
        Lysa mottar och förvarar Kundens likvida medel med
        redovisningsskyldighet (klientmedel), vilka placeras på ett från Lysas
        tillgångar avskilt konto hos bank eller kreditinstitut
        (klientmedelskonto).
      </p>
      <p>
        Klientmedel utgörs av likvida medel i den valuta som tillämpas för det
        klientmedelskonto vilket Kundens likvida medel placeras på. Lysa kan
        även motta likvida medel i annan valuta enligt särskild överenskommelse
        med Kunden. Lysa förbehåller sig rätten att avböja mottagandet av
        klientmedel utgörandes av annan valuta som Lysa av någon anledning inte
        kan förvara och/eller hantera.
      </p>
      <p>
        Klientmedel utgörs av förskott för köpuppdrag eller likvid vid
        säljuppdrag (motsvarande), avkastning på i depån förvarade värdepapper
        samt medel som Kunden överlämnat till Lysa eller som Lysa uppburit för
        Kundens räkning och som har samband med depån, såvitt Kunden inte har
        särskilt anvisat Lysa om annat konto för insättning.
      </p>
      <p>
        Lysa får ur klientmedel tillhörande Kunden tillgodogöra sig belopp som
        Kunden beordrat eller godkänt samt för varje utlägg, kostnad eller
        förskotterad skatt som har samband med depån. Lysa får vidare
        tillgodogöra sig belopp motsvarande dels utlägg, kostnad och arvode för
        uppdrag i övrigt som Lysa utfört åt Kunden, dels likvid för annan
        förfallen obetald fordran som Lysa vid var tid har gentemot Kunden.
      </p>
      <p>
        Medel i annan valuta som Lysa erlägger respektive mottager för Kundens
        räkning ska innan klientmedelskontot belastas respektive krediteras – om
        överenskommelse inte träffas om förvaring i annan valuta eller på annat
        konto – omräknas till den valuta som tillämpas för det klientmedelskonto
        vilket Kundens likvida medel placeras på genom Lysas försorg enligt av
        Lysa vid var tid tillämpad omräkningskurs.
      </p>
      <p>
        Om Kunden är i betalningsdröjsmål, har Lysa rätt till dröjsmålsränta på
        förfallet belopp från förfallodagen till dess betalning sker med den
        räntesats som Lysa vid var tid tillämpar.
      </p>
    </section>
    <section>
      <h2>D. Pant</h2>
      <p>Bestämmelser om pant finns i Avtalet under punkten Pantsättning.</p>
      <p>
        Kunden får inte utan att samtycke lämnats av Lysa till annan pantsätta
        värdepapper på depån. Sker pantsättning i strid mot denna bestämmelse
        får Lysa säga upp Avtalet till omedelbart upphörande utan iakttagande av
        i punkt G.7 nedan nämnd uppsägningstid.
      </p>
    </section>
    <section>
      <h2>E. Handel med värdepapper över depån</h2>
      <p>
        På uppdrag av Kunden - även innefattande vad Kunden och Lysa eventuellt
        har kommit överens om i särskilt avtal om handel via elektroniskt medium
        - utför Lysa köp och försäljning av finansiella instrument samt andra
        uppdrag avseende handel med finansiella instrument för Kundens räkning.
        Efter fullgörandet och om förutsättningar för detta föreligger redovisar
        Lysa dessa transaktioner i Kundens depå.
      </p>
      <p>
        Kunden är medveten om att Lysa kan spela in och bevara telefonsamtal och
        annan elektronisk kommunikation i samband med Kundens kommunikation med
        Lysa.
      </p>
      <p>
        I och med att Kunden undertecknar avtalet eller tar Lysas tjänster i
        anspråk vad avser portföljförvaltning är Kunden bunden av Lysas vid var
        tid gällande särskilda Riktlinjer för utförande av order samt
        sammanläggning och fördelning av order och de villkor som vid var tid
        gäller för handel med visst finansiellt instrument. Lysa ska på kunds
        begäran tillhandahålla Kunden gällande riktlinjer och villkor som avses
        i första stycket på Lysas hemsida. Vid handel med finansiella instrument
        kan utöver de riktlinjer och villkor som avses i första stycket
        tillkomma regler antagna av Lysa, svensk eller utländsk emittent,
        utförandeplats, clearingorganisation eller värdepapperscentral. På
        Kundens förfrågan kan Lysa lämna Kunden uppgift om var informationen
        finns tillgänglig.
      </p>
      <p>
        Lysa har rätt att makulera köp eller försäljning där avslut träffats för
        Kundens räkning i den omfattning som avslutet makulerats av aktuell
        utförandeplats. Samma rätt gäller om Lysa i annat fall finner makulering
        av avslut vara nödvändig med hänsyn till att ett uppenbart fel begåtts
        av Lysa, marknadsmotpart eller av Kunden själv eller, om Kunden genom
        order handlat i strid med gällande lag, annan författning eller om
        Kunden i övrigt brutit mot god sed på värdepappersmarknaden.
      </p>
      <p>
        För den händelse någon av parterna skulle försättas i konkurs eller
        företagsrekonstruktion skulle beslutas för Kunden, enligt lagen
        (1996:764) om företagsrekonstruktion, ska samtliga utestående
        förpliktelser med anledning av handel med finansiella instrument mellan
        parterna avräknas mot varandra genom slutavräkning per den dag sådan
        händelse inträffar. Vad som efter sådan slutavräkning tillkommer ena
        parten är omedelbart förfallet till betalning.
      </p>
    </section>
    <section>
      <h2>F. Skatter m.m.</h2>
      <p>
        Kunden ska svara för skatter och andra avgifter som ska erläggas enligt
        svensk eller utländsk lag, svensk/utländsk myndighets föreskrift eller
        beslut, mellanstatligt avtal eller Lysas avtal med svensk/utländsk
        myndighet med avseende på värdepapper registrerade i depån, t.ex.
        preliminärskatt, utländsk källskatt respektive svensk kupongskatt på
        utdelning.
      </p>
      <p>
        Lysa kan, till följd av svensk/utländsk lag, svensk/utländsk myndighets
        föreskrift eller beslut, mellanstatligt avtal eller Lysas avtal med
        svensk/utländsk myndighet vara skyldigt att för Kundens räkning vidta
        åtgärder beträffande skatt och andra avgifter grundat på
        utdelning/ränta/avyttring/innehav avseende Kundens värdepapper. Det
        åligger Kunden att tillhandahålla den information, inkluderande
        undertecknande av skriftliga handlingar, som Lysa bedömer vara nödvändig
        för att fullgöra sådan skyldighet. Om Lysa till följd av skyldighet för
        Kundens räkning betalat skatt får Lysa belasta anslutet konto med
        motsvarande belopp på sätt som anges i punkt C.3.
      </p>
      <p>
        Lysa ska, på särskilt uppdrag av Kunden, om rätt därtill föreligger och
        om Lysa bedömer det som praktiskt möjligt och lämpligt, bl.a. med
        beaktande av Kundens intresse söka medverka till nedsättning eller
        restitution av skatt samt utbetalning av tillgodohavande hos
        skattemyndighet. Lysa får i samband med detta teckna Kundens namn samt
        även lämna uppgift om Kunden och Kundens värdepapper i den omfattning
        som krävs.
      </p>
    </section>
    <section>
      <h2>G. Övriga bestämmelser</h2>
      <p>
        <b>G.1 Avgifter m.m.</b>
      </p>
      <p>
        För förvaring samt för övriga tjänster enligt Avtalet tas ut avgifter
        enligt vad som framgår i Avtalet eller som Lysa senare meddelat Kunden
        på sätt som anges i punkt G.9.
      </p>
      <p>
        Upplysning om vid var tid gällande avgifter finns tillgängliga på Lysas
        hemsida.
      </p>
      <p>Avgifter debiteras i svenska kronor om inte Lysa meddelar annat.</p>
      <p>
        Kunden ska ersätta Lysas kostnader och utlägg som har samband med Lysas
        uppdrag enligt Avtalet samt kostnader och utlägg för att bevaka och
        driva in Lysas fordran hos Kunden.
      </p>
      <p>
        <b>G.2 Meddelanden m.m.</b>
      </p>
      <p>
        <i>Meddelande från Lysa</i>
      </p>
      <p>
        Meddelande från Lysa till Kunden får skickas med e-post eller annan
        elektronisk kommunikation, exempelvis via inloggat läge på Lysas
        hemsida.
      </p>
      <p>
        Meddelande anses ha kommit Kunden tillhanda vid avsändandet om det sänds
        till den elektroniska adress som Kunden har uppgivit eller via inloggat
        läge på Lysas hemsida. Om ett sådant meddelande når Kunden utanför
        normal kontorstid ska meddelandet anses ha kommit mottagaren tillhanda
        vid början av påföljande bankdag. Meddelande brevledes anses ha kommit
        Kunden tillhanda den andra bankdagen efter det att det postats.
      </p>
      <p>
        Kunden ska utan dröjsmål underrätta Lysa om ändring av kontaktuppgifter
        i inloggat läge på Lysas hemsida.
      </p>
      <p>
        <i>Meddelande från Lysa</i>
      </p>
      <p>
        Meddelande från Kunden till Lysa ska ställas till kontakt@lysa.se,
        såvida Lysa inte begärt svar till annan adress. Meddelande genom e-post
        eller annan elektronisk kommunikation anses ha kommit Lysa tillhanda vid
        avsändandet. Om ett sådant meddelande når Lysa utanför normal kontorstid
        ska meddelandet anses ha kommit Lysa tillhanda vid början av påföljande
        bankdag i Sverige.
      </p>
      <p>
        <b>G.3 Redovisning</b>
      </p>
      <p>
        Redovisning för depån lämnas löpande för Kunden via inloggat läge på
        Lysas hemsida. Detsamma gäller redovisning av årssammandrag och
        kontrolluppgifter inför deklarationen.
      </p>
      <p>
        Lysa ansvarar inte för riktigheten av information om värdepapper, som
        inhämtats av Lysa från extern informationslämnare.
      </p>
      <p>
        <b>G.4 Felaktig registrering i depån m.m.</b>
      </p>
      <p>
        Om Lysa av misstag skulle registrera värdepapper eller medel i Kundens
        depå, har Lysa rätt att snarast möjligt korrigera registreringen eller
        insättningen i fråga. Om Kunden förfogat över av misstag registrerade
        värdepapper eller insatta medel, ska Kunden snarast möjligt till Lysa
        lämna tillbaka värdepapperen eller betala tillbaka de medel som
        mottagits vid avyttringen eller insättningen. Om Kunden underlåter detta
        har Lysa rätt att köpa in värdepapperen i fråga och belasta Kundens
        konto med beloppet för Lysas fordran samt, vid Kundens förfogande över
        medel, belasta Kundens konto med beloppet i fråga.
      </p>
      <p>
        Lysa ska omedelbart underrätta Kunden om att korrigering vidtagits
        enligt ovan. Kunden har inte rätt att ställa några krav gentemot Lysa
        med anledning av sådana misstag.
      </p>
      <p>
        Vad som angetts i de två föregående styckena gäller även när Lysa i
        andra fall registrerat värdepapper eller medel i depån, som inte skulle
        ha tillkommit Kunden.
      </p>
      <p>
        <b>G.5 Begränsning av Lysas ansvar m.m.</b>
      </p>
      <p>
        <i>Tekniska problem</i>
      </p>
      <p>
        Kunden är medveten om att det kan uppkomma driftavbrott i Kundens, Lysas
        eller annans datasystem (hård- eller mjukvara), tele- eller elsystem
        eller liknande fel som kan medföra att tjänsterna enligt Avtalet inte
        kan utföras som avtalat, exempelvis:
      </p>
      <ul>
        <li>att Kunden inte kan göra en insättning,</li>
        <li>att Kunden inte kan göra ett uttag, eller</li>
        <li>
          att information som Lysa tillhandahåller inte är tillgänglig för
          Kunden eller är felaktig.
        </li>
      </ul>
      <p>
        Lysa ansvarar endast för skada som Kunden åsamkats till följd av
        ovanstående tekniska problem om Lysa varit oaktsamt. Lysa ansvarar
        endast för indirekt skada som orsakats av Lysas grova vårdslöshet.
      </p>
      <p>
        Om Kunden upptäcker fel eller störningar som anges i denna punkt är
        Kunden skyldig att omedelbart påtala detta för Lysa. Om Kunden inte gör
        detta trots att Kunden upptäckt eller borde ha upptäckt sådant fel eller
        sådan störning förlorar Kunden rätt att begära ersättning eller göra
        gällande någon annan påföljd i anledning av felet eller störningen.
      </p>
      <p>
        <i>Ansvarsbegränsning</i>
      </p>
      <p>
        Lysa är inte ansvarigt för skada som beror av svenskt eller utländskt
        lagbud, svensk eller utländsk myndighetsåtgärd, krigshändelse, strejk,
        blockad, bojkott, lockout, epidemi, pandemi, karantän eller annan
        liknande omständighet. Förbehållet i fråga om strejk, blockad, bojkott
        och lockout gäller även om Lysa självt är föremål för eller vidtar sådan
        konfliktåtgärd.
      </p>
      <p>
        Lysa är inte ansvarigt för skada som orsakats av - svensk eller utländsk
        - utförandeplats, depåförande tredjepart, värdepapperscentral,
        clearingorganisation, eller andra som tillhandahåller motsvarande
        tjänster, och inte heller av uppdragstagare som Lysa med tillbörlig
        omsorg anlitat eller som anvisats av Kunden. Detsamma gäller skada som
        orsakats av att ovan organisationer eller uppdragstagare blivit
        insolventa. Lysa ansvarar inte för skada som uppkommer för Kunden eller
        annan med anledning av förfogandeinskränkning som kan komma att
        tillämpas mot Lysa beträffande finansiella instrument.
      </p>
      <p>
        Lysa ansvarar endast för skada som uppkommit i andra fall om Lysa varit
        oaktsamt. Lysa ansvarar endast för indirekt skada som orsakats av Lysas
        grova vårdslöshet.
      </p>
      <p>
        Föreligger hinder för Lysa, på grund av omständighet som anges i första
        stycket, att helt eller delvis utföra åtgärd enligt Avtalet eller köp-
        eller säljuppdrag avseende finansiella instrument får åtgärd skjutas upp
        till dess hindret har upphört. Om Lysa till följd av sådan omständighet
        är förhindrad att verkställa eller ta emot betalning/ leverans, ska
        varken Lysa eller Kunden vara skyldig att betala ränta.
      </p>
      <p>
        Vad ovan sagts gäller i den mån inte annat följer av lagen (1998:1479)
        om värdepapperscentraler och kontoföring av finansiella instrument.
      </p>
      <p>
        <b>G.6 Avstående från uppdrag</b>
      </p>
      <p>
        Lysa har rätt att avsäga sig uppdrag enligt Avtalet avseende svenskt
        finansiellt instrument registrerat hos värdepapperscentral t.ex.
        Euroclear Sweden inom fem bankdagar, och för övriga svenska värdepapper
        samt för utländska finansiella instrument inom femton bankdagar efter
        det att värdepapperen mottagits av Lysa. För utlämnande/överföring av
        värdepappret i fråga gäller därvid vad som i punkt G.7 anges om
        utlämnande/överföring vid uppsägning.
      </p>
      <p>
        <b>G.7 Uppsägning</b>
      </p>
      <p>
        Lysa kan säga upp Avtalet till upphörande två månader efter det att
        Kunden enligt punkten G.2 ska anses ha fått meddelandet.
      </p>
      <p>
        Kunden kan omgående säga upp avtalet i inloggat läge på Lysas hemsida.
        Kunden kan även säga upp avtalet på de sätt som framgår enligt G.2 till
        upphörande en månad efter det Lysa enligt samma punkt ska anses ha fått
        meddelandet.
      </p>
      <p>
        Avtalet upphör att gälla, med beaktande av nedan bestämmelser om
        reglering av parternas skyldigheter, senast den dag då Avtal om
        portföljförvaltning mellan parterna upphör att gälla.
      </p>
      <p>
        Vid Avtalets upphörande ska parterna genast reglera samtliga sina
        skyldigheter enligt Avtalet. Avtalet gäller dock i tillämpliga delar
        till dess att part fullgjort samtliga sina åtaganden gentemot motparten.
        Vidare får var och en av Lysa och Kunden säga upp uppdrag enligt Avtalet
        avseende visst värdepapper på samma villkor som anges ovan.
      </p>
      <p>
        Oavsett vad som sagts i föregående stycke får part säga upp Avtalet med
        omedelbar verkan om motparten väsentligen brutit mot avtalet. I sådant
        fall ska varje avtalsbrott, där rättelse trots anmaning inte snarast
        möjligt vidtagits, anses som ett väsentligt avtalsbrott. Lysa får även
        säga upp Avtalet med omedelbar verkan:
      </p>
      <ul>
        <li>
          om det föranleds av värdepappersrättsliga eller andra restriktioner i
          Sverige eller annat land,
        </li>
        <li>
          om Kunden försätts i konkurs, blivit föremål för
          företagsrekonstruktion, fått till stånd offentlig ackordsförhandling
          eller vid utmätning befunnits sakna tillgångar att betala sina
          skulder,
        </li>
        <li>
          om Kunden ställt in sina betalningar eller i övrigt anses vara på
          obestånd,
        </li>
        <li>
          om Kunden via transaktioner med Lysa agerat i strid med gällande lag,
        </li>
        <li>
          vid förändringar av Kundens skatterättsliga hemvist som medför att
          Lysa inte längre kan fullgöra sin skyldighet att för Kundens räkning
          vidta åtgärder beträffande skatt enligt vad som anges i avsnitt F
          eller att fullgörandet av sådan skyldighet avsevärt försvåras.
        </li>
        <li>
          om Kunden säger upp Försäkringsavtalet, återkallar Lysas
          skötselfullmakt hos det försäkringsbolag som håller Försäkringsavtalet
          eller liknande, begär byte av försäkringsbolag avseende
          Försäkringsavtalet, eller
        </li>
        <li>
          om Kunden återkallar Lysas fullmakt i punkt 2 i
          Kapitalförsäkringsdepåavtalet.
        </li>
      </ul>
      <p>
        Vid Avtalets upphörande ska Lysa till Kunden överföra samtliga i depån
        förtecknade värdepapper och likvida medel. Kunden ska lämna anvisningar
        till Lysa om överföringen. Om inte sådana anvisningar lämnats inom
        sextio kalenderdagar efter den dag Avtalet enligt uppsägningen upphört
        eller om överföringen inte kan ske enligt av Kunden lämnade anvisningar,
        får Lysa sälja aktuella värdepapper och överföra likviden till Kundens
        förvalda uttagskonto.
      </p>
      <p>
        Fondandelar i Lysas fonder måste säljas vid Avtalets upphörande. Vid
        Avtalets upphörande äger Lysa rätt att sälja Kundens innehav i Lysas
        fonder och betala ut likviden till Kundens föranmälda uttagskonto. Om
        Kunden inte har ett föranmält uttagskonto ska Kunden lämna anvisningar
        till Lysa om överföringen.
      </p>
      <p>
        <b>G.8 Begränsning av åtaganden och förhållanden till andra avtal</b>
      </p>
      <p>
        Lysa är inte skyldigt att vidta andra åtgärder än vad som anges i
        Avtalet om inte särskilt skriftligt avtal träffats om detta. Vad som
        uttryckligen anges i sådant särskilt avtal ska gälla före Avtalet.
      </p>
      <p>
        <b>G.9 Ändring av Avtalet och avgifter</b>
      </p>
      <p>
        Ändringar av Avtalet, inklusive dessa bestämmelser, eller Lysas avgifter
        ska ha verkan gentemot Kunden två månader efter det att Kunden enligt
        punkt G.2 ska anses ha mottagit meddelandet. Om Kunden inte godtar
        ändringen har Kunden rätt att inom nämnda tid säga upp Avtalet till
        omedelbart upphörande utan iakttagande av i punkt G.7 angiven
        uppsägningstid.
      </p>
      <p>
        En ändring som anses vara obetydlig för Kunden får genomföras utan att
        Kunden informeras om det enligt ovan.
      </p>
      <p>
        Ändringar och tillägg till Avtalet ska ske skriftligen för att vara
        gällande.
      </p>
      <p>
        <b>
          G.10 Information om stängning av Lysas hemsida vid planerat underhåll
        </b>
      </p>
      <p>
        Vid stängning av Lysas hemsida för planerat underhåll m.m. ska Lysa i
        god tid informera Kunden om detta.
      </p>
      <p>
        <b>G.11 Överlåtelse</b>
      </p>
      <p>
        Lysa äger rätt att överlåta sina rättigheter eller skyldigheter enligt
        Avtalet till företag inom samma koncern som bolaget ingår eller annat
        företag till följd av associationsrättslig reglering.
      </p>
      <p>
        <b>G.12 Tillämplig lag</b>
      </p>
      <p>Tolkning och tillämpning av Avtalet ska ske enligt svensk rätt.</p>
    </section>
  </article>
);

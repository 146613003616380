import React, { useState, useEffect } from "react";
import { LysaDoc } from "./lib";

import "./MarkdownPage.css";

type DocType = "customer" | "generic";

interface DocFile {
  fileName: string;
  title: string;
  type: DocType;
  lang: string;
  country: string;
}

type NavStructure = Record<string, Record<string, Record<string, DocFile[]>>>;

const variableValues = {
  SIGNING_DATE: "2021-01-17",
  COMPANY_NAME: "Test Company AB",
  COMPANY_ID: "3242094823",
  CUSTOMER_NAME: "Tolvan Tolvsson",
  CUSTOMER_ID: "19121212-1212",
  ACCOUNT_NAME: "Lysakonto 1",
  ACCOUNT_ID: "989283429834",
};

export const MarkdownPage: React.VFC = () => {
  const [doc, setDoc] = useState("");
  const [navStructure, setNavStructure] = useState<NavStructure>();

  useEffect(() => {
    if (navStructure) {
      return;
    }

    fetch(`${process.env.REACT_APP_LYSA_DOCS_URL}/docs.json`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("Failed to fetch docs.json");
        }
      })
      .then((docsJson: DocFile[]) => {
        const navStructure: NavStructure = {};

        docsJson.forEach((file) => {
          navStructure[file.type] = navStructure[file.type] || {};
          navStructure[file.type][file.country] =
            navStructure[file.type][file.country] || {};
          navStructure[file.type][file.country][file.lang] =
            navStructure[file.type][file.country][file.lang] || [];
          navStructure[file.type][file.country][file.lang].push(file);
        });

        setNavStructure(navStructure);

        if (!doc && docsJson.length > 0) {
          setDoc(docsJson[0].fileName);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [doc, navStructure]);

  if (!navStructure) {
    return null;
  }

  return (
    <div className="markdown-page">
      <nav>
        <ul className="tree">
          {Object.keys(navStructure).map((type) => {
            return (
              <li key={type}>
                {type === "signedout" ? "Signed Out" : "Signed In"}
                <ul>
                  {Object.keys(navStructure[type]).map((country) => {
                    return (
                      <li key={country}>
                        {country}
                        <ul>
                          {Object.keys(navStructure[type][country]).map(
                            (lang) => {
                              return (
                                <li key={`${country}-${lang}`}>
                                  {lang}
                                  <ul>
                                    {navStructure[type][country][lang].map(
                                      (file) => {
                                        return (
                                          <li key={file.fileName}>
                                            <button
                                              onClick={() =>
                                                setDoc(file.fileName)
                                              }
                                            >
                                              {file.title}
                                            </button>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="doc-wrapper">
        <pre>{doc}</pre>
        <LysaDoc path={doc} variableValues={variableValues} />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import frontmatter from "remark-frontmatter";

function parseExpressionString(expressionString: string) {
  // expressionString: {{ FOO_BAR OR FIZZ_BUZZ OR Fallback value }}
  // ->
  //   {
  //     expression: [ "FOO_BAR", "FIZZ_BUZZ" ]
  //     fallback: "Fallback value"
  //   }
  return expressionString
    .replace(/^\{\{\s*/, "")
    .replace(/\s*}}$/, "")
    .split(" OR ")
    .map((exprPart) => exprPart.trim())
    .reduce<{ expression: string[]; fallback: string }>(
      (acc, curr, i, arr) => {
        if (!acc.fallback && /^[A-Z_]{2,}$/.test(curr)) {
          acc.expression.push(curr);
        } else {
          acc.fallback += acc.fallback ? " OR " + curr : curr;
        }

        if (i === arr.length - 1 && acc.fallback.length > 0) {
          // expressionString: {{ ACCOUNT_TYPE OR "ISK" }}
          acc.fallback = acc.fallback.replace(/^"(.*)"$/, "$1");
        }
        return acc;
      },
      { expression: [], fallback: "" }
    );
}

function evaluateExpression(
  exprString: string,
  valueMap: Record<string, string>
) {
  const { expression, fallback } = parseExpressionString(exprString);

  return (
    valueMap[
      expression.find(
        (varName) => valueMap[varName] !== undefined && valueMap[varName] !== ""
      ) || "__undefined"
    ] || fallback
  );
}

interface Props {
  path?: string;
  variableValues?: Record<string, string>;
}

export const LysaDoc: React.VFC<Props> = ({ path, variableValues }) => {
  const [mdText, setMdText] = useState("");

  useEffect(() => {
    if (!path) {
      return;
    }

    let isMounted = true;

    fetch(`${process.env.REACT_APP_LYSA_DOCS_URL}/${path}`)
      .then((response) => {
        if (response.ok) {
          /* If response is of other type than markdown, the document is most likely not found */
          if (
            response.headers.get("content-type")?.startsWith("text/markdown")
          ) {
            return response.text();
          }
          return "Unfortunately, the document could not be found";
        } else {
          return Promise.reject("Failed to fetch markdown document");
        }
      })
      .then((text) => {
        // TypeScript complains if I try to do `[...text.matchAll()]`. So I
        // have to work with the iterator directly instead
        const it = text.matchAll(/\{\{[^}]+}}/g);
        for (let match = it.next(); !match.done; match = it.next()) {
          const expr = match.value[0];
          if (variableValues) {
            text = text.replace(expr, evaluateExpression(expr, variableValues));
          }
        }

        // The LysaDoc component might have been unmounted when we get here
        // (the slower the user's internet connection, the more likely this is)
        if (isMounted) {
          setMdText(text);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [path, variableValues]);

  return (
    <div className="lysa-doc">
      <ReactMarkdown remarkPlugins={[frontmatter]}>{mdText}</ReactMarkdown>
    </div>
  );
};

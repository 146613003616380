import React from "react";

export function PrivacyPolicySV() {
  return (
    <article className="lysa-doc privacy-policy">
      <header>
        <h1>Personuppgiftspolicy</h1>

        <p className="ingress">
          Information om behandling av personuppgifter vid nyttjande av Lysas
          tjänster.
        </p>
      </header>

      <section>
        <p>
          I samband med att du nyttjar Lysas tjänster kommer vi att behandla
          vissa personuppgifter om dig. Lysa AB är personuppgiftsansvarigt för
          all sådan behandling. I denna personuppgiftspolicy kan du läsa om (i)
          vilka uppgifter om dig som vi samlar in, (ii) varför vi behandlar dina
          uppgifter, (iii) hur vi använder automatiserat beslutsfattande, (iv)
          vilka andra bolag och myndigheter vi delar dina uppgifter med, (v) hur
          länge vi sparar dina uppgifter och (vi) dina rättigheter i förhållande
          till dina uppgifter.
        </p>
        <p>
          Lysa iakttar stor försiktighet för att skydda din personliga
          integritet och kommer endast att använda dina uppgifter på sätt som
          anges i denna personuppgiftspolicy. Vi vill även framhålla att Lysas
          kundregister och andra uppgifter Lysa har om dig som kund skyddas av
          sekretess enligt 1 kap 11 § lagen (2007:528) om värdepappersmarknaden.
          Detta innebär att Lysa inte obehörigen får röja dessa uppgifter.
        </p>
      </section>

      <section>
        <h3 id="vilka-personuppgifter-om-dig-samlar-vi-in">
          Vilka personuppgifter om dig samlar vi in?
        </h3>

        <h4 id="uppgifter-som-du-lämnar-till-oss">
          Uppgifter som du lämnar till oss
        </h4>
        <p>
          I samband med att du blir kund eller inger en intresseanmälan om att
          bli kund hos oss, lämnar du (eller i förekommande fall, din
          företrädare) personuppgifter till oss för att vi ska kunna
          administrera din begäran. De personuppgifter vi samlar in utgörs bl.a.
          av namn, personnummer, e-postadress, bankkontonummer, finansiell
          ställning, kontoutdrag och information rörande kundkännedom enligt vad
          som krävs enligt lagstiftning med syfte att stävja penningtvätt. I
          tillägg till det föregående kan vi i samband med vissa transaktioner
          behöva samla in ytterligare information från och om dig, såsom
          kvitton, fakturor eller andra handlingar som styrker att transaktionen
          är laglig. Vid användningen av tjänsten kommer vid även behandla
          uppgifter rörande utformningen av ditt månadssparande, frekvensen av
          och storleken på insättningar och uttag, placeringsriktlinjer och
          risknivåer, ditt saldo över tid.
        </p>

        <h4 id="uppgifter-vi-erhåller-från-andra-källor-än-dig">
          Uppgifter vi erhåller från andra källor än dig
        </h4>
        <p>
          Vi kan komma att komplettera dina personuppgifter genom inhämtning
          från privata- och offentliga register. Vi hämtar bl.a.
          företagsuppgifter från Checkbiz AB, dina namnuppgifter från BankID och
          information om kontonummer från din bank.
        </p>
      </section>

      <section>
        <h3 id="varför-behandlar-vi-dina-personuppgifter">
          Varför behandlar vi dina personuppgifter?
        </h3>
        <p>
          Vi använder dina personuppgifter endast för de syften och enligt de
          legala grunder som anges nedan. Behandlingen kommer att pågå under den
          tid som anges under rubriken "Tid för behandlingen" (notera dock att
          vi är skyldiga enligt lag att behålla vissa uppgifter under längre
          perioder, se mer under punkten "Lagkrav").
        </p>

        <h4 id="antagande-av-kund">Antagande av kund</h4>

        <h5 id="syfte-med-behandlingen">Syfte med behandlingen</h5>
        <p>
          Om du gör en intresseanmälan för att bli kund hos oss, kommer vi att
          behandla dina personuppgifter för att verifiera din identitet och dina
          uppgifter. Behandlingen sker för att besluta om vi kan godkänna dig
          som kund. Beslutet som nämns ovan är ett så kallat "automatiserat
          beslut". Om vi inte får en träff mot sådana sanktionslistor som vi
          måste tillämpa enligt lag, kommer du att kunna bli kund hos oss. Du
          kan alltid kontakta vår kundservice om du vill bestrida ett sådant
          beslut eller i övrigt vill diskutera beslutet med oss.
        </p>

        <h5 id="legal-grund-för-behandlingen">Legal grund för behandlingen</h5>
        <p>
          Behandlingen är nödvändig för att fullgöra Lysas rättsliga
          förpliktelser.
        </p>

        <h5 id="tid-för-behandlingen">Tid för behandlingen</h5>
        <p>
          Vi kommer behandla uppgifterna så länge som det krävs enligt gällande
          lagstiftning (såsom lagen om åtgärder mot penningtvätt och
          finansiering av terrorism).
        </p>

        <h4 id="tjänsten">Tjänsten</h4>

        <h5 id="syfte-med-behandlingen-1">Syfte med behandlingen</h5>
        <p>
          När du är kund hos oss kommer vi att behandla dina personuppgifter för
          att tillhandahålla dig tjänsterna i enlighet med kundavtalet.
        </p>
        <p>
          Vi använder oss av profilering när vi tar fram ett investeringsförslag
          till dig. Profileringen är baserad på den information som du
          tillhandahåller till oss i samband med att ett investeringsförslag ska
          upprättas: ekonomisk situation, risktolerans, kunskap och erfarenhet
          samt investeringshorisont.
        </p>

        <h5 id="legal-grund-för-behandlingen-1">
          Legal grund för behandlingen
        </h5>
        <p>Behandlingen är nödvändig för att fullgöra kundavtalet med dig.</p>

        <h5 id="tid-för-behandlingen-1">Tid för behandlingen</h5>

        <p>
          Vi kommer att behandla uppgifterna under den tid som du är kund hos
          oss.
        </p>

        <h4 id="kontaktperson-företagskunder">
          Kontaktperson (företagskunder)
        </h4>

        <h5 id="syfte-med-behandlingen-2">Syfte med behandlingen</h5>
        <p>
          Om du är kontaktperson hos en företagskund, kommer vi att behandla
          dina personuppgifter i syfte att tillhandahålla tjänsten till
          företagskunden.
        </p>

        <h5 id="legal-grund-för-behandlingen-2">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för vårt berättigade intresse att
          tillhandahålla tjänsten till företagskunden.
        </p>

        <h5 id="tid-för-behandlingen-2">Tid för behandlingen</h5>
        <p>
          Vi kommer att behandla dina uppgifter så länge som du finns
          registrerad som kontaktperson hos kundföretaget.
        </p>

        <h4 id="verklig-huvudman-företagskunder">
          Verklig huvudman (företagskunder)
        </h4>

        <h5 id="syfte-med-behandlingen-3">Syfte med behandlingen</h5>
        <p>
          Om du är verklig huvudman för en företagskund, kommer vi att behandla
          dina personuppgifter i syfte att uppfylla tillämplig lag.
        </p>

        <h5 id="legal-grund-för-behandlingen-3">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för att uppfylla Lysas rättsliga
          förpliktelser.
        </p>

        <h5 id="tid-för-behandlingen-3">Tid för behandlingen</h5>
        <p>
          Vi kommer behandla dina uppgifter så länge som det krävs enligt
          gällande lagstiftning (såsom lagen om åtgärder mot penningtvätt och
          finansiering av terrorism).
        </p>

        <h4 id="potentiell-kund">Potentiell kund</h4>

        <h5 id="syfte-med-behandlingen-4">Syfte med behandlingen</h5>
        <p>
          Om du inte är kund hos oss, utan endast har fått ett
          investeringsförslag på hemsidan eller lämnat intresseanmälan vid en
          kundträff, kommer vi att behandla dina uppgifter i syfte att vårda vår
          potentiella relation med dig.
        </p>

        <h5 id="legal-grund-för-behandlingen-4">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för vårt berättigade intresse att vårda
          potentiella kundrelationer.
        </p>

        <h5 id="tid-för-behandlingen-4">Tid för behandlingen</h5>
        <p>
          Om du inte inom 3 månader efter intresseanmälan eller
          investeringsförslaget har blivit kund eller hört av dig till oss på
          sådant sätt att det finns anledning för oss att anta att ditt intresse
          av att bli kund hos oss kvarstår, kommer vi radera dina uppgifter
          efter den tiden.
        </p>

        <h4 id="support">Support</h4>

        <h5 id="syfte-med-behandlingen-5">Syfte med behandlingen</h5>
        <p>
          Om du kontaktar oss genom vår kundtjänst eller på annat sätt, kommer
          vi att behandla dina uppgifter för att kunna assistera dig med den
          relevanta frågan.
        </p>

        <h5 id="legal-grund-för-behandlingen-5">
          Legal grund för behandlingen
        </h5>
        <p>Behandlingen är nödvändig för att fullgöra kundavtalet med dig.</p>

        <h5 id="tid-för-behandlingen-5">Tid för behandlingen</h5>
        <p>
          Vi kommer att radera dina personuppgifter inom 12 månader efter att
          ärendet är hanterat.
        </p>

        <h4 id="marknadsföring">Marknadsföring</h4>

        <h5 id="syfte-med-behandlingen-6">Syfte med behandlingen</h5>
        <p>
          Om du anmäler dig för att få nyhetsbrev eller annan information från
          oss, kommer vi att behandla dina personuppgifter i syfte att
          tillhandahålla dig den efterfrågade informationen.
        </p>

        <h5 id="legal-grund-för-behandlingen-6">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för vårt berättigade intresse att
          upprätthålla goda kundrelationer.
        </p>

        <h5 id="tid-för-behandlingen-6">Tid för behandlingen</h5>
        <p>
          Vi kommer att upphöra med behandlingen om du avböjer från
          marknadsföringen (detta kan du göra i inloggat läge på Lysa.se, samt i
          respektive marknadsföringsutskick).
        </p>
        <p>
          Vi kommer då även att radera dina uppgifter, såvida du inte är kund
          hos oss och vi behöver uppgifterna för våra övriga behandlingar.
        </p>

        <h4 id="lagkrav">Lagkrav</h4>

        <h5 id="syfte-med-behandlingen-7">Syfte med behandlingen</h5>
        <p>
          Om du är kund hos oss eller annars har fått ett investeringsförslag
          från oss, måste vi behandla dina uppgifter i syfte att uppfylla
          tillämplig lag, såsom regler om penningtvätt och finansiering av
          terrorism (inklusive krav på utförande av riskbedömningar,
          kundkännedom, riskklassificeringar, övervakning av transaktioner,
          granskning och rapportering av misstänkta transaktioner),
          lämplighetsbedömningar, kontroller mot sanktionslistor,
          kapitaltäckningskrav, bokföringsregler och skatteregler.
        </p>

        <h5 id="legal-grund-för-behandlingen-7">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för att uppfylla Lysas rättsliga
          förpliktelser.
        </p>

        <h5 id="tid-för-behandlingen-7">Tid för behandlingen</h5>
        <p>
          Vi kommer behandla uppgifterna så länge som det krävs enligt gällande
          lagstiftning (såsom bokföringslagen, lagen om åtgärder mot
          penningtvätt och finansiering av terrorism, finansinspektionens
          föreskrifter om värdepappersrörelse, skatteförfarandelagen).
        </p>

        <h4 id="missbruk-av-tjänsten">Missbruk av tjänsten</h4>

        <h5 id="syfte-med-behandlingen-8">Syfte med behandlingen</h5>
        <p>
          Om du anmäler intresse att bli kund eller är kund hos oss, kommer vi
          att behandla dina personuppgifter i syfte att förhindra och upptäcka
          missbruk av tjänsten (inklusive åtgärder för riskhantering, spårbarhet
          och bevisning och bedrägeridetektering).
        </p>

        <h5 id="legal-grund-för-behandlingen-8">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för vårt berättigade intresse att förhindra
          och upptäcka missbruk av tjänsten.
        </p>

        <h5 id="tid-för-behandlingen-8">Tid för behandlingen</h5>
        <p>
          Vi kommer att behandla uppgifterna under den tid du är kund hos oss.
        </p>

        <h4 id="anonymisering">Anonymisering</h4>

        <h5 id="syfte-med-behandlingen-9">Syfte med behandlingen</h5>
        <p>
          Vi kommer att anonymisera vissa av dina personuppgifter, i syfte att
          sammanställa anonym och aggregerad statistik (bl.a. rörande
          webbesökares beteenden, webbtrafik, sidvisningar, sökningar,
          kundanalyser, affärsuppföljning, utveckling av tjänsterna).
        </p>

        <h5 id="legal-grund-för-behandlingen-9">
          Legal grund för behandlingen
        </h5>
        <p>
          Behandlingen är nödvändig för vårt berättigade intresse av att skapa
          anonym statistik.
        </p>

        <h5 id="tid-för-behandlingen-9">Tid för behandlingen</h5>
        <p>
          Vi kommer att anonymisera dina personuppgifter varje dag. När dina
          personuppgifter har anonymiserats är de inte längre att betrakta som
          personuppgifter under gällande dataskyddslagstiftning.
        </p>
      </section>

      <section>
        <h3 id="mottagare-av-dina-personuppgifter-inklusive-tredjelandsöverföringar">
          Mottagare av dina personuppgifter (inklusive tredjelandsöverföringar)
        </h3>

        <h4 id="leverantörerbiträden">Leverantörer/biträden</h4>
        <p>
          Vi använder leverantörer som tillhandahåller lagrings- och
          kommunikationstjänster. Leverantörerna kommer, såsom del av tjänsterna
          till oss, att utföra viss behandling av dina personuppgifter för vår
          räkning. Vissa av dessa leverantörer finns inom EU/EES, men
          majoriteten av leverantörerna finns i USA. Överföringarna till USA är
          föremål för lämpliga skyddsåtgärder i form av certifiering under
          EU-U.S. Privacy Shield eller EU-kommissionens standardavtalsklausuler.
        </p>

        <h4 id="myndigheter-och-andra-tredje-parter">
          Myndigheter och andra tredje parter
        </h4>
        <p>
          Lysa kan komma att lämna ut information om dig till
          Finansinspektionen, Polisen, Skatteverket, Kronofogdemyndigheten och
          andra tillämpliga myndigheter om Lysa är skyldig att göra det enligt
          tillämplig lag, föreskrift eller myndighetsbeslut. Vi kan även komma
          att dela vissa uppgifter med din anslutna bank, Bankgirocentralen,
          Mobilt BankID och Swish.
        </p>

        <h3 id="dina-rättigheter">Dina rättigheter</h3>
        <p>
          Som registrerad har du möjlighet att utöva följande rättigheter i
          förhållande till vår behandling av dina personuppgifter:
        </p>
        <p>
          <strong>Rätt till tillgång.</strong> Du har rätt att få tillgång till
          dina personuppgifter (inklusive kopia) samt viss information rörande
          behandlingen av dem.
        </p>
        <p>
          <strong>Rätt till rättelse.</strong> Du har rätt att få felaktiga
          personuppgifter rättade samt att komplettera ofullständiga
          personuppgifter. Om du är kund kan du göra vissa av dessa justeringar
          på egen hand i inloggat läge på Lysa.se.
        </p>
        <p>
          <strong>Rätt till radering.</strong> Under vissa förutsättningar har
          du rätt att få dina personuppgifter raderade. Detta är den så kallade
          ”rätten att bli bortglömd”. Observera att vi är skyldiga att bevara
          vissa personuppgifter för att uppfylla krav som ställs på oss (såsom
          bokföringslagen, lagen om åtgärder mot penningtvätt och finansiering
          av terrorism, finansinspektionens föreskrifter om värdepappersrörelse
          och skatteförfarandelagen).
        </p>
        <p>
          <strong>Rätt till begränsning av behandling.</strong> Under vissa
          förutsättningar har du rätt få behandlingen av dina personuppgifter
          begränsad.
        </p>
        <p>
          <strong>Rätt till dataportabilitet.</strong> Du har rätt att få ut
          dina personuppgifter (eller under vissa omständigheter få dem
          överförda till en annan personuppgiftsansvarig) i ett strukturerat,
          allmänt använt och maskinläsbart format.
        </p>
        <p>
          <strong>Rätt att göra invändningar.</strong> Under vissa
          omständigheter har du rätt att invända mot särskilda
          personuppgiftsbehandlingar. Du kan bland annat avsäga dig elektronisk
          direktreklam i inloggat länge på Lysa.se.
        </p>
        <p>
          Du kan alltid kontakta vårt dataskyddsombud på{" "}
          <a href="mailto:dpo@lysa.se">dpo@lysa.se</a> eller ringa{" "}
          <a href="tel:+46852503570">08-525 035 70</a> för att göra en begäran
          enligt det föregående. Om du är kund kan du göra vissa av dessa
          justeringar på egen hand i inloggat läge på Lysa.se.
        </p>
        <p>
          Du har även rätt att inge klagomål rörande vår
          personuppgiftsbehandling till tillsynsmyndigheten Datainspektionen.
          Kontaktuppgifter till Datainspektionen hittar du här:{" "}
          <a href="https://www.datainspektionen.se/om-oss/kontakta-oss">
            https://www.datainspektionen.se/om-oss/kontakta-oss/
          </a>
          .
        </p>

        <h3 id="kontaktuppgifter-till-dataskyddsombud">
          Kontaktuppgifter till dataskyddsombud
        </h3>
        <p>
          Lysas dataskyddsombud: Helena Olander
          <br />
          Email: <a href="mailto:dpo@lysa.se">dpo@lysa.se</a>
        </p>
        <p>
          Lysa AB, org. nr 559028-0821
          <br />
          Email: <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a>
          <br />
          Telefon: <a href="tel:+46852503570">08-525 035 70</a>
        </p>
        <p>Personuppgiftspolicyn uppdaterades senast 2018-05-15.</p>
      </section>
    </article>
  );
}
